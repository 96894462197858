import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Eye, EyeOff } from "lucide-react";

const formSchema = z
  .object({
    password: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .max(16, "Password must not exceed 16 characters")
      .regex(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Password must contain an alphabet, a number, and a special character",
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

export default function ResetPasswordStep3({
  onContinue,
  onCancel,
  onBack,
  isLoading,
}: {
  onContinue: (password: string) => void;
  onCancel: () => void;
  onBack: () => void;
  isLoading: Boolean;
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    onContinue(values.password);
  }

  return (
    <div className="w-full max-w-md p-6 m-auto space-y-8">
      <h1 className="text-3xl font-normal">Reset your password</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
          <div>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-base font-normal">
                    Enter new password
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <Input
                        {...field}
                        type={showPassword ? "text" : "password"}
                        className="h-14 pr-10 focus:border-[#696969] "
                      />
                      <button
                        type="button"
                        className="absolute inset-y-0 right-0 flex items-center pr-3"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff strokeWidth={0.5} />
                        ) : (
                          <Eye strokeWidth={0.5} />
                        )}
                      </button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <p className="text-sm text-gray-500 my-2">
              Your password must have at least{" "}
              <span className="text-black">8-16 characters</span> and contain{" "}
              <span className="text-black">an alphabet, a number</span>, and{" "}
              <span className="text-black">a special character.</span>
            </p>
          </div>
          <FormField
            control={form.control}
            name="confirmPassword"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Re-enter new password
                </FormLabel>
                <FormControl>
                  <div className="relative">
                    <Input
                      {...field}
                      type={showConfirmPassword ? "text" : "password"}
                      className="h-14 pr-10 focus:border-[#696969] "
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 flex items-center pr-3"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? (
                        <EyeOff strokeWidth={0.5} />
                      ) : (
                        <Eye strokeWidth={0.5} />
                      )}
                    </button>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="flex items-center justify-between pt-4">
            <Button
              type="button"
              variant="link"
              onClick={onCancel}
              className="h-auto p-0 text-base font-normal text-black underline underline-offset-[16px]"
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              className="px-6 py-6 rounded-full bg-[#303030] hover:bg-[#262626] disabled:text-[#696969] disabled:bg-[#CFCFCF] disabled:opacity-100"
              disabled={!form.formState.isValid || Boolean(isLoading)}
            >
              {isLoading ? "Loading..." : "CONTINUE"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

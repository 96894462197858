import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";

const formSchema = z.object({
  phone: z
    .string()
    .min(10, "Invalid phone number")
    .max(10, "Invalid phone number"),
});

export default function ForgetPasswordStep1({
  onContinue,
  onBack,
  isLoading,
}: {
  onContinue: (phone: string) => void;
  onBack: () => void;
  isLoading: boolean;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    onContinue(values.phone);
  }

  return (
    <div className="w-full h-[70vh] max-w-md m-auto space-y-6">
      <h1 className="text-3xl font-normal">Reset your password</h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="phone"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Mobile number
                </FormLabel>
                <FormControl>
                  <div className="relative w-full font-sh5">
                    <div className="absolute inset-y-0 top-[-1px] left-0 flex items-center pl-3 pointer-events-none">
                      <span className="">+91</span>
                    </div>
                    <Input
                      type="number"
                      className="h-14 pl-12 pr-4 focus:border-[#696969]"
                      placeholder="Enter phone number"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <p className="text-sm text-gray-500">
            OTP will be sent on your mobile number.
          </p>
          <div className="flex items-center justify-between pt-6">
            <Button
              type="button"
              variant="link"
              className="h-auto p-0 text-base font-normal text-black underline underline-offset-[16px]"
              onClick={onBack}
            >
              BACK
            </Button>
            <Button
              type="submit"
              className="px-6 py-6 rounded-full bg-[#303030] hover:bg-[#262626] disabled:text-[#696969] disabled:bg-[#CFCFCF] disabled:opacity-100"
              disabled={!form.formState.isValid || isLoading}
            >
              {isLoading ? "Loading..." : "CONTINUE"}
            </Button>
          </div>
        </form>
      </Form>
    </div>
  );
}

import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useObjectContext } from "@/context/objects";
import GlobalDialog from "@/modal/GlobalDialog";
import React, { useState } from "react";

const AddProvenance = ({
  children,
  defaultId,
}: {
  children: React.ReactElement;
  defaultId?: string;
}) => {
  const { updateOrAddItem, currentObject } = useObjectContext();
  const defaultValue = currentObject?.provenance?.overview?.find(
    (item: any) => item._id === defaultId,
  );

  const [ownershipDetails, setOwnershipDetails] = useState(
    defaultValue?.title ?? "",
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length <= 280) {
      setOwnershipDetails(value);
    }
  };

  const handleSubmit = () => {
    if (defaultId) {
      updateOrAddItem({
        provenance: {
          overview: [
            {
              title: ownershipDetails,
              _id: defaultId,
            },
          ],
        },
      });
      return;
    }
    updateOrAddItem({
      provenance: {
        overview: [
          {
            title: ownershipDetails,
          },
        ],
      },
    });
    setOwnershipDetails("");
  };

  return (
    <GlobalDialog
      TriggerButton={children}
      title="Add Provenance"
      onConfirm={handleSubmit}
      onCancel={() => setOwnershipDetails("")}
    >
      <div>
        <div>
          <Label htmlFor="ownershipDetails" className="text-base">
            Ownership details*
          </Label>
          <Input
            id="ownershipDetails"
            value={ownershipDetails}
            onChange={handleInputChange}
            className="mt-2"
            placeholder="Add a single line describing how you acquired this object"
          />
          <div className="flex justify-between mt-2">
            <p className="text-sm text-gray-500">
              Add a single line describing how you acquired this object.
            </p>
            <p className="text-sm text-gray-500">
              {280 - ownershipDetails.length} characters left
            </p>
          </div>
        </div>
      </div>
    </GlobalDialog>
  );
};

export default AddProvenance;

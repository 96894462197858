import React from "react";
import { AlertCircle } from "lucide-react";
import { Button } from "@/components/ui/button";
import GlobalDialog from "@/modal/GlobalDialog";

export default function LabellingError() {
  return (
    <div className="w-full max-w-md  pt-6 sm:p-6 sm:pl-10 sm:pt-0 border-t border-solid border-gray-300 sm:border-t-0 bg-white text-gray-800 font-sh5">
      <div className="flex items-center mb-4">
        <div className="w-5 h-5 rounded-full bg-red-500 flex items-center justify-center mr-3">
          <AlertCircle className="w-5 h-5 text-white" />
        </div>
        <h2 className="text-[18px] font-normal">Labelling unsuccessful</h2>
      </div>
      <p className="text-[16px] font-normal text-[#696969] mb-4">
        We could not process your request to label this object because of the
        following reasons:
      </p>
      <ul className="list-disc pl-5 space-y-2 mb-6">
        <li className="text-sm font-normal text-[#696969]">
          &lt;Add more reasons&gt;
        </li>
        <li className="text-sm font-normal text-[#696969]">
          &lt;Add more reasons&gt;
        </li>
        <li className="text-sm font-normal text-[#696969]">
          &lt;Add more reasons&gt;
        </li>
      </ul>
      <GlobalDialog
        TriggerButton={
          <Button
            variant="link"
            className="p-0 h-auto text-[12px] font-medium  text-black underline"
          >
            REQUEST AGAIN
          </Button>
        }
        title="Request Again"
        confirmText="SUBMIT"
      >
        <div className="space-y-4 text-center">
          <p>Your request as been rejected for the following reason(s):</p>
          <p>Add reason from backend</p>
          <p>Please make the required changes and submit the request.</p>
        </div>
      </GlobalDialog>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { UseFormRegister, FieldErrors, UseFormWatch } from "react-hook-form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import { baseUrl } from "@/apis";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { cn } from "@/lib/utils";

// Define the Zod schema
const formSchema = z
  .object({
    fullName: z.string().min(2, "Full name must be at least 2 characters"),
    email: z.string().email("Invalid email address"),
    mobileNumber: z
      .string()
      .regex(/^\d{10}$/, "Mobile number must be 10 digits"),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });
type FormData = z.infer<typeof formSchema>;

interface InputField {
  id: string;
  name: string;
  label: string | React.ReactNode;
  type: string;
  helpText?: string;
}

interface DynamicFormStepProps {
  stepTitle: string;
  stepNumber: number;
  inputFields: InputField[];
  register: UseFormRegister<any>;
  errors: FieldErrors<any>;
  onNext: () => void;
  watch: UseFormWatch<any>;
  setType: (type: string) => void;
  setOtp: (otp: string) => void;
  setUserId: (userId: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
  setError: (name: keyof FormData, error: { message: string }) => void;
}

interface Payload {
  name: any;
  password: any;
  userType: number;
  email: any;
  phone: any;
  displayName?: string;
}

const DynamicFormStep: React.FC<DynamicFormStepProps> = ({
  stepTitle,
  stepNumber,
  inputFields,
  register,
  errors,
  onNext,
  watch,
  setType,
  setOtp,
  setUserId,
  setPhoneNumber,
  setError,
}) => {
  const allFormData = watch();
  const [showPassword, setShowPassword] = useState<Record<string, boolean>>({});

  const togglePasswordVisibility = (id: string) => {
    setShowPassword((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  useEffect(() => {
    console.log("Current form data:", allFormData);
  }, [allFormData]);

  let userType;
  if (allFormData.accountType === "artist") {
    userType = 1;
  } else if (allFormData.accountType === "business") {
    userType = 2;
  } else {
    userType = 3;
  }

  interface ApiError {
    message: string;
    status?: number;
  }

  interface ApiResponse {
    data: {
      otp: string;
      id: string;
      accessToken?: string;
      refreshToken?: string;
    };
    success: boolean;
    message?: string;
  }

  const handleGetOTP = async () => {
    try {
      const payload: Payload = {
        name:
          allFormData.fullName ||
          allFormData.registeredCompanyName ||
          allFormData.artistName ||
          "",
        password: allFormData.password || "",
        userType: userType,
        email: allFormData.email || "",
        phone: allFormData.mobileNumber || "",
      };

      if (allFormData.accountType !== "collector") {
        payload.displayName =
          allFormData.displayName ||
          allFormData.brandName ||
          allFormData.artistDisplayName ||
          allFormData.estateName ||
          "";
      }

      const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
        redirect: "manual",
      };

      const response = await fetch(
        `${baseUrl}/client/auth/register`,
        requestOptions,
      );

      if (!response.ok) {
        const errorData = await response.json();
        const errorMessage =
          errorData.message || "Failed to send OTP. Please try again.";
        throw new Error(errorMessage);
      }

      const result = await response.json();
      setPhoneNumber(allFormData.mobileNumber);

      if (result && result.data) {
        setOtp(result.data.otp);
        setUserId(result.data.id);
        setType("otp");

        Cookies.set("token", result.data.accessToken, { expires: 1 });
        Cookies.set("refreshToken", result.data.refreshToken, { expires: 30 });
      } else {
        throw new Error("Unexpected response structure");
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "An unexpected error occurred. Please try again.";
      toast.error(errorMessage);
    }
  };

  // const handleGetOTP = () => {
  //   setType("otp");
  // };

  return (
    <div className="flex flex-col w-full p-8 overflow-y-auto md:w-1/2 md:p-16">
      <div className="w-full max-w-md mx-auto">
        <p className="mb-2 text-sm text-gray-500">Step {stepNumber} of 4</p>
        <h1 className="mb-8 text-[32px] font-normal">{stepTitle}</h1>
        <div className="space-y-6 border-b border-solid border-gray-300">
          {inputFields.map((field) => (
            <div key={field.id}>
              <label
                htmlFor={field.id}
                className="block mb-1 text-sm font-normal text-gray-700"
              >
                {field.label}
              </label>
              <div className="relative">
                {field.id === "mobileNumber" && (
                  <p className="absolute inset-y-0 left-1 flex items-center font-sh5 font-normal">
                    +91
                  </p>
                )}
                <Input
                  type={showPassword[field.id] ? "text" : field.type}
                  id={field.id}
                  {...register(field.name)}
                  className={cn(
                    "block w-full px-3 py-2 mt-1 border border-gray-300 shadow-sm focus:outline-none focus:ring-black focus:border-black",
                    field.id === "mobileNumber" ? "pl-8" : "pl-3",
                  )}
                />
                {field.type === "password" && (
                  <span
                    onClick={() => togglePasswordVisibility(field.id)}
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                  >
                    {showPassword[field.id] ? (
                      <EyeOffIcon className="w-5 h-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="w-5 h-5 text-gray-500" />
                    )}
                  </span>
                )}
              </div>
              {field.helpText && (
                <p className="mt-1 text-xs text-gray-500">{field.helpText}</p>
              )}
              {errors[field.name] && (
                <p className="mt-1 text-xs text-red-500">
                  {errors[field.name]?.message as string}
                </p>
              )}
            </div>
          ))}
          <Button
            type="submit"
            onClick={handleGetOTP}
            className="w-full px-4 py-3 text-white bg-black rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
          >
            Get OTP
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DynamicFormStep;

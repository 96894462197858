import React from "react";
import { Button } from "@/components/ui/button";

interface StepperProps {
  title: string;
  currentStep: string;
  steps: string[];
  setCurrentStep: (step: string) => void;
}

export default function Stepper({
  title,
  steps,
  currentStep,
  setCurrentStep,
}: StepperProps) {
  return (
    <div className="z-50 flex flex-col gap-4 pl-9">
      <h2 className="flex items-center text-[20px] font-medium justify-between w-full">
        {title}
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M3 12H21" stroke="#1D1D1D" strokeMiterlimit="10" />
        </svg>
      </h2>
      <div className="flex flex-col w-full gap-5">
        {steps.map((step) => (
          <div
            key={step}
            className={`flex w-full justify-between items-center cursor-pointer font-normal text-[18px] ${
              step === currentStep ? "text-[#1d1d1d] " : "text-[#696969]"
            }`}
            onClick={() => setCurrentStep(step)}
          >
            <p>{step.charAt(0).toUpperCase() + step.slice(1)}</p>

            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.02656 17.9165L13.9766 9.95817L6.10156 2.08317"
                stroke="black"
                strokeMiterlimit="10"
              />
            </svg>
          </div>
        ))}
      </div>
    </div>
  );
}

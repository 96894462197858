import Header from "@/components/Global/header/Header";
import FallbackScreen from "@/components/SupportPage/FallbackScreen";
import { Button } from "@/components/ui/button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import useMediaQuery from "@/hooks/use-media-query";
import React, { useEffect, useState } from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { ChevronDown, ChevronUp, Search, X } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { Slider } from "@/components/ui/slider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import Global from "@/modal/Global";
import ShareModal from "@/components/ShareModal";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { cn } from "@/lib/utils";
import { DialogFooter } from "@/components/ui/dialog";
import { useNavigate } from "react-router-dom";
import useObjects from "@/apis/objects";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useFilter from "@/hooks/useFilter";
import { useObjectContext } from "@/context/objects";
import { useDebouncedCallback } from "use-debounce";
import Loader from "@/components/Global/Loader";
import toast from "react-hot-toast";
import { useAuth } from "@/hooks/useAuth";

const Detail = () => {
  const { filterOption, objectsList } = useObjectContext();
  const { email_verified } = useAuth();
  if (!email_verified) {
    return <FallbackScreen />;
  }
  return (
    <div className="container font-normal ">
      <Header />
      <div className=" md:px-12">
        {/* filter */}
        <h3 className="text-[28px] font-normal my-10">My Studio</h3>
        <Filter />
      </div>
      {/* detail card */}
      {objectsList.isLoading && <Loader />}
      {!objectsList.isLoading && (
        <div className="grid grid-cols-6 gap-5 md:px-12 mq450:gap-4 mq700:grid-cols-2 mq1125:grid-cols-4">
          {objectsList.data?.map((item: any, index: number) => (
            <DetailCard key={item.id} data={item} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Detail;

const Category: React.FC<AsignProtectProps> = ({
  openAccordion,
  setOpenAccordion,
}) => {
  const { objectsList, setQuery } = useObjectContext(); // Get objects and query handler from context
  const [searchTerm, setSearchTerm] = useState(""); // Search input state
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  // Define categories statically but calculate the count based on the objectsList

  const categories = [
    {
      name: "Painting",
      count: objectsList?.data?.filter((obj: any) => obj.type === "painting")
        .length,
    },
    {
      name: "Sculpture",
      count: objectsList?.data?.filter((obj: any) => obj.type === "sculpture")
        .length,
    },
    {
      name: "Photograph",
      count: objectsList?.data?.filter((obj: any) => obj.type === "photograph")
        .length,
    },
    {
      name: "Textile",
      count: objectsList?.data?.filter((obj: any) => obj.type === "textile")
        .length,
    },
    {
      name: "Ceramic",
      count: objectsList?.data?.filter((obj: any) => obj.type === "ceramic")
        .length,
    },
    {
      name: "Metal",
      count: objectsList?.data?.filter((obj: any) => obj.type === "metal")
        .length,
    },
    {
      name: "Glass",
      count: objectsList?.data?.filter((obj: any) => obj.type === "glass")
        .length,
    },
    {
      name: "Wood",
      count: objectsList?.data?.filter((obj: any) => obj.type === "wood")
        .length,
    },
    {
      name: "Other",
      count: objectsList?.data?.filter((obj: any) => obj.type === "other")
        .length,
    },
  ];

  // Filter categories based on the search term
  const filteredCategories = categories.filter((category) =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  // Handle category selection and search by setting query
  // Handle category selection and search by updating the selectedCategories array
  const handleCategorySearch = (categoryName: string, isSelected: boolean) => {
    if (isSelected) {
      setSelectedCategories((prev) => [...prev, categoryName.toLowerCase()]); // Add category if checked
    } else {
      setSelectedCategories((prev) =>
        prev.filter(
          (name) => name.toLowerCase() !== categoryName.toLowerCase(),
        ),
      ); // Remove category if unchecked
    }
  };
  useEffect(() => {
    if (selectedCategories.length == 0) {
      setQuery({
        type: undefined,
      });
      return;
    }
    setQuery({
      type: selectedCategories, // Update the query with selected categories
    });
  }, [selectedCategories.length]);
  const isDesktop = useMediaQuery("(min-width: 1125px)"); // Check if user is on a desktop

  return (
    <div>
      {/* Desktop version */}
      {isDesktop && (
        <Popover>
          <PopoverTrigger>
            <Button
              variant="outline"
              className="items-center px-5 py-3 text-sm rounded-full shadow-sm"
            >
              Category
              <ChevronDown className="w-4 h-4 ml-2" />
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-[300px] p-0 font-normal font-sh5"
            align="start"
          >
            <div className="px-[28px] py-6">
              {/* Search box */}
              <div className="relative">
                <Search className="absolute left-[22px] top-2.5 h-4 w-4 text-gray-100" />
                <input
                  type="text"
                  placeholder="Search category"
                  className="w-full py-[10px] pl-11 pr-4 text-sm border border-solid rounded-full border-gray-20 text-gray-60"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
            {/* List categories */}
            <div className="overflow-auto max-h-60 ">
              {filteredCategories.map((category) => (
                <label
                  key={category.name}
                  className="flex items-center cursor-pointer hover:bg-gray-10 px-[28px] gap-x-3 text-gray-60 mb-[21px] py-1"
                >
                  <Checkbox
                    id={category.name}
                    className="w-5 h-5 mr-2"
                    onCheckedChange={(checked: boolean) =>
                      handleCategorySearch(category.name, checked)
                    } //// Pass selected category to query
                  />
                  <p>
                    <span>{category.name}</span>
                    <span className="ml-1">({category.count})</span>
                  </p>
                </label>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      )}

      {/* Mobile version */}
      {!isDesktop && (
        <Accordion
          type="single"
          collapsible
          value={openAccordion === "category" ? "item-1" : ""}
          onValueChange={(value) => setOpenAccordion(value ? "category" : null)}
        >
          <AccordionItem value="item-1">
            <AccordionTrigger className="text-lg no-underline">
              Object type
            </AccordionTrigger>
            <AccordionContent>
              <div className="pb-5 ">
                <div className="relative">
                  <Search className="absolute left-[22px] top-2.5 h-4 w-4 text-gray-100" />
                  <input
                    type="text"
                    placeholder="Search category"
                    className="w-full py-[10px] pl-11 pr-4 text-sm border border-solid rounded-full border-gray-20 text-gray-60"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
              </div>
              <div className="overflow-auto max-h-60 ">
                {filteredCategories.map((category) => (
                  <label
                    key={category.name}
                    className="flex items-center cursor-pointer hover:bg-gray-10 gap-x-3 text-gray-60 mb-[21px] py-1 text-base"
                  >
                    <Checkbox
                      id={category.name}
                      className="w-5 h-5 mr-2"
                      onCheckedChange={(checked: boolean) =>
                        handleCategorySearch(category.name, checked)
                      }
                    />
                    <p>
                      <span>{category.name}</span>
                      <span className="ml-1">({category.count})</span>
                    </p>
                  </label>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

const sizeOptions = ["Height", "Width", "Depth", "Diameter"];

const Size: React.FC<AsignProtectProps> = ({
  openAccordion,
  setOpenAccordion,
}) => {
  const { setQuery } = useObjectContext();
  const [unit, setUnit] = useState("cm");
  const [expandedOption, setExpandedOption] = useState<string | null>("Width");
  const [rangeValues, setRangeValues] = useState({ min: 20, max: 30 });
  const [selectedSizeOption, setSelectedSizeOption] = useState<string | null>(
    "Width",
  ); // State for selected size option

  const toggleUnit = () => setUnit(unit === "cm" ? "inch" : "cm");

  const handleRangeChange = (values: number[]) => {
    setRangeValues({ min: values[0], max: values[1] });
  };

  const handleInputChange = (type: "min" | "max", value: string) => {
    const numValue = parseInt(value, 10);
    if (!isNaN(numValue)) {
      setRangeValues((prev) => ({ ...prev, [type]: numValue }));
    }
  };

  // New handleApply function to include selected size option in the query
  const handleApply = () => {
    setQuery({
      size: {
        unit,
        min: rangeValues.min,
        max: rangeValues.max,
        option: selectedSizeOption, // Include the selected size option
      },
    });
  };

  const isDesktop = useMediaQuery("(min-width: 1125px)");

  return (
    <div>
      {isDesktop ? (
        <Popover>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="px-5 py-3 text-sm rounded-full shadow-sm"
            >
              Size
              <ChevronDown className="w-4 h-4 ml-2" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-64 p-4 font-normal font-sh5">
            <div className="flex items-center justify-between mb-4">
              <span className="text-sm font-medium">Size</span>
              <div className="flex items-center space-x-2">
                <span
                  className={`text-xs ${unit === "cm" ? "font-medium" : ""}`}
                >
                  cm
                </span>
                <Switch
                  checked={unit === "inch"}
                  onCheckedChange={toggleUnit}
                />
                <span
                  className={`text-xs ${unit === "inch" ? "font-medium" : ""}`}
                >
                  in
                </span>
              </div>
            </div>
            {sizeOptions.map((option) => (
              <div key={option} className="mb-4">
                <button
                  className="flex items-center justify-between w-full py-2 text-sm "
                  onClick={() => {
                    setExpandedOption(
                      expandedOption === option ? null : option,
                    );
                    setSelectedSizeOption(option); // Set selected size option
                  }}
                >
                  {option}
                  {expandedOption === option ? (
                    <ChevronUp className="w-4 h-4" />
                  ) : (
                    <ChevronDown className="w-4 h-4" />
                  )}
                </button>
                {expandedOption === option && (
                  <div className="mt-2 space-y-4">
                    <Slider
                      min={0}
                      max={100}
                      step={1}
                      value={[rangeValues.min, rangeValues.max]}
                      onValueChange={handleRangeChange}
                      className="my-4"
                    />
                    <div className="flex justify-between">
                      <div className="w-[48%]">
                        <p className="text-xs text-gray-60">Min</p>
                        <input
                          type="number"
                          value={rangeValues.min}
                          onChange={(e) =>
                            handleInputChange("min", e.target.value)
                          }
                          className="mt-1 max-w-[84px] border-solid border border-gray-20 p-[10px_12px] text-center"
                        />
                      </div>
                      <div className="w-[48%]">
                        <p className="text-xs text-gray-60">Max</p>
                        <input
                          type="number"
                          value={rangeValues.max}
                          onChange={(e) =>
                            handleInputChange("max", e.target.value)
                          }
                          className="mt-1 max-w-[84px] border-solid border border-gray-20 p-[10px_12px] text-center"
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="flex justify-between mt-4">
              <Button
                variant="outline"
                className="w-[48%]"
                onClick={() =>
                  setQuery({
                    size: undefined,
                  })
                }
              >
                CANCEL
              </Button>
              <Button className="w-[48%]" onClick={handleApply}>
                APPLY
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      ) : (
        <Accordion
          type="single"
          collapsible
          value={openAccordion === "size" ? "item-2" : ""}
          onValueChange={(value) => setOpenAccordion(value ? "size" : null)}
        >
          <AccordionItem value="item-2">
            <AccordionTrigger className="pb-[10px]">
              <div className="flex items-center justify-between gap-x-3">
                <span className="text-lg">Size</span>
                <div className="flex items-center space-x-2">
                  <span
                    className={`text-[13px] ${
                      unit === "cm" ? "font-medium" : ""
                    }`}
                  >
                    cm
                  </span>
                  <Switch
                    checked={unit === "in"}
                    onCheckedChange={toggleUnit}
                  />
                  <span
                    className={`text-[13px] ${
                      unit === "in" ? "font-medium" : ""
                    }`}
                  >
                    in
                  </span>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent className="px-5 pb-[10px]">
              {sizeOptions.map((option) => (
                <div
                  key={option}
                  className="py-5 border-b border-solid border-bdr-10"
                >
                  <button
                    className="flex items-center justify-between w-full text-base "
                    onClick={() => {
                      setExpandedOption(
                        expandedOption === option ? null : option,
                      );
                      setSelectedSizeOption(option); // Set selected size option
                    }}
                  >
                    {option}
                    {expandedOption === option ? (
                      <ChevronUp className="w-5 h-5" />
                    ) : (
                      <ChevronDown className="w-5 h-5" />
                    )}
                  </button>
                  {expandedOption === option && (
                    <div className="mt-2 space-y-4">
                      <Slider
                        min={0}
                        max={100}
                        step={1}
                        value={[rangeValues.min, rangeValues.max]}
                        onValueChange={handleRangeChange}
                        className="my-4"
                      />
                      <div className="flex justify-between">
                        <div className="w-[48%]">
                          <p className="text-xs text-gray-60">Min</p>
                          <input
                            type="number"
                            value={rangeValues.min}
                            onChange={(e) =>
                              handleInputChange("min", e.target.value)
                            }
                            className="mt-1 max-w-[84px] border-solid border border-gray-20 p-[10px_12px] text-center"
                          />
                        </div>
                        <div className="w-[48%]">
                          <p className="text-xs text-gray-60">Max</p>
                          <input
                            type="number"
                            value={rangeValues.max}
                            onChange={(e) =>
                              handleInputChange("max", e.target.value)
                            }
                            className="mt-1 max-w-[84px] border-solid border border-gray-20 p-[10px_12px] text-center"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </div>
  );
};

const ObjectsBy: React.FC<AsignProtectProps> = ({
  openAccordion,
  setOpenAccordion,
}) => {
  const { setQuery } = useObjectContext();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("all");
  const isDesktop = useMediaQuery("(min-width: 1125px)");

  // Function to update the query based on the selected value
  const objectSearch = () => {
    setQuery({
      objectsBy: selectedValue === "all" ? undefined : selectedValue,
    });
  };

  // Debounced version of the objectSearch function
  const debouncedObjectSearch = useDebouncedCallback(objectSearch, 500);

  // Use useEffect to call the debounced search when selectedValue changes
  useEffect(() => {
    debouncedObjectSearch();
  }, [selectedValue, debouncedObjectSearch]);

  return (
    <>
      {isDesktop && (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="px-5 py-3 text-sm rounded-full shadow-sm"
            >
              Objects By
              {isOpen ? (
                <ChevronUp className="w-4 h-4 ml-2" />
              ) : (
                <ChevronDown className="w-4 h-4 ml-2" />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[300px] pt-6 px-6 pb-0 font-sh5 ">
            <RadioGroup
              value={selectedValue}
              onValueChange={setSelectedValue}
              className="gap-0"
            >
              <div className="flex items-center mb-[26px] space-x-3">
                <RadioGroupItem value="all" id="all" className="w-6 h-6" />
                <Label
                  htmlFor="all"
                  className="text-base font-normal cursor-pointer"
                >
                  All artists
                </Label>
              </div>
              <div className="flex items-center mb-[26px] space-x-3">
                <RadioGroupItem value="other" id="other" className="w-6 h-6" />
                <Label
                  htmlFor="other"
                  className="text-base font-normal cursor-pointer"
                >
                  Other artists
                </Label>
              </div>
              <div className="flex items-center mb-[26px] space-x-3">
                <RadioGroupItem value="me" id="me" className="w-6 h-6" />
                <Label
                  htmlFor="me"
                  className="text-base font-normal cursor-pointer"
                >
                  Me
                </Label>
              </div>
            </RadioGroup>
          </PopoverContent>
        </Popover>
      )}
      {!isDesktop && (
        <Accordion
          type="single"
          collapsible
          value={openAccordion === "objectsBy" ? "item-3" : ""}
          onValueChange={(value) =>
            setOpenAccordion(value ? "objectsBy" : null)
          }
        >
          <AccordionItem value="item-3">
            <AccordionTrigger className="text-lg no-underline">
              Object type
            </AccordionTrigger>
            <AccordionContent className="px-[10px] pb-0">
              <RadioGroup
                value={selectedValue}
                onValueChange={setSelectedValue}
                className="gap-0"
              >
                <div className="flex items-center mb-[26px] space-x-3">
                  <RadioGroupItem value="all" id="all" className="w-6 h-6" />
                  <Label
                    htmlFor="all"
                    className="text-base font-normal cursor-pointer"
                  >
                    All artists
                  </Label>
                </div>
                <div className="flex items-center mb-[26px] space-x-3">
                  <RadioGroupItem
                    value="other"
                    id="other"
                    className="w-6 h-6"
                  />
                  <Label
                    htmlFor="other"
                    className="text-base font-normal cursor-pointer"
                  >
                    Other artists
                  </Label>
                </div>
                <div className="flex items-center mb-[26px] space-x-3">
                  <RadioGroupItem value="me" id="me" className="w-6 h-6" />
                  <Label
                    htmlFor="me"
                    className="text-base font-normal cursor-pointer"
                  >
                    Me
                  </Label>
                </div>
              </RadioGroup>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};

type SortOption = {
  label: string;
  value: string;
  key: string;
};

type SortCategory = {
  name: string;
  options: SortOption[];
};

const sortCategories: SortCategory[] = [
  {
    name: "Date added",
    options: [
      { label: "Newest to oldest", value: "-1", key: "createdAt" },
      { label: "Oldest to newest", value: "1", key: "createdAt" },
    ],
  },
  {
    name: "Object name",
    options: [
      { label: "A - Z", value: "1", key: "objectName" },
      { label: "Z - A", value: "-1", key: "objectName" },
    ],
  },
];

const SortBy: React.FC<AsignProtectProps> = ({
  openAccordion,
  setOpenAccordion,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 1125px)");
  const [selectedOptions, setSelectedOptions] = useState<{
    [key: string]: string;
  }>({
    createdAt: "-1", // Default to newest to oldest
    objectName: "1", // Default to A - Z
  });
  const { setFilterOption } = useObjectContext();

  const handleOptionSelect = (category: string, value: string) => {
    setSelectedOptions((prev) => ({ ...prev, [category]: value }));
  };

  useEffect(() => {
    // Update query whenever selected options change
    setFilterOption({
      options: {
        sort: {
          createdAt: Number(selectedOptions.createdAt),
          objectName: Number(selectedOptions.objectName),
        },
      },
    });
  }, [selectedOptions]);

  return (
    <>
      {isDesktop ? (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="px-5 py-3 text-sm rounded-full shadow-sm"
            >
              Sort by
              {isOpen ? (
                <ChevronUp className="w-4 h-4 ml-2" />
              ) : (
                <ChevronDown className="w-4 h-4 ml-2" />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent
            className="w-[296px] px-6 pt-6 pb-[10px] font-normal font-sh5"
            align="start"
          >
            {sortCategories.map((category, index) => (
              <div key={category.name}>
                <h3 className="mb-2 text-sm font-normal">{category.name}</h3>
                <RadioGroup
                  value={selectedOptions[category.options[0].key]} // Accessing key based on options
                  onValueChange={(value) =>
                    handleOptionSelect(category.options[0].key, value)
                  }
                  className="mb-[10px]"
                >
                  {category.options.map((option) => (
                    <div
                      key={option.value}
                      className="flex items-center mb-2 space-x-2"
                    >
                      <RadioGroupItem
                        value={option.value}
                        id={`${category.name}-${option.value}`}
                        className="w-5 h-5"
                      />
                      <Label
                        htmlFor={`${category.name}-${option.value}`}
                        className="text-sm font-normal cursor-pointer text-gray-60"
                      >
                        {option.label}
                      </Label>
                    </div>
                  ))}
                </RadioGroup>
                {index < sortCategories.length - 1 && (
                  <div className="border-b border-solid border-bdr-10 mt-[10px] mb-5" />
                )}
              </div>
            ))}
          </PopoverContent>
        </Popover>
      ) : (
        <Accordion
          type="single"
          collapsible
          value={openAccordion === "sortBy" ? "item-4" : ""}
          onValueChange={(value) => setOpenAccordion(value ? "sortBy" : null)}
        >
          <AccordionItem value="item-4">
            <AccordionTrigger className="text-lg no-underline">
              Sort by
            </AccordionTrigger>
            <AccordionContent className="px-5">
              {sortCategories.map((category, index) => (
                <div key={category.name}>
                  <h3 className="mb-2 text-sm font-normal">{category.name}</h3>
                  <RadioGroup
                    value={selectedOptions[category.options[0].key]} // Accessing key based on options
                    onValueChange={(value) =>
                      handleOptionSelect(category.options[0].key, value)
                    }
                    className="mb-[10px]"
                  >
                    {category.options.map((option) => (
                      <div
                        key={option.value}
                        className="flex items-center mb-2 space-x-2"
                      >
                        <RadioGroupItem
                          value={option.value}
                          id={`${category.name}-${option.value}`}
                          className="w-5 h-5"
                        />
                        <Label
                          htmlFor={`${category.name}-${option.value}`}
                          className="text-xs font-normal cursor-pointer text-gray-60"
                        >
                          {option.label}
                        </Label>
                      </div>
                    ))}
                  </RadioGroup>
                  {index < sortCategories.length - 1 && (
                    <div className="border-b border-solid border-bdr-10 mt-[10px] mb-5" />
                  )}
                </div>
              ))}
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
type ProtectOption = {
  id: string;
  label: string;
};

const protectOptions: ProtectOption[] = [
  { id: "added", label: "Asign Protect+ added" },
  { id: "requested", label: "Asign Protect+ requested" },
  { id: "eligible", label: "Eligible for Asign Protect+" },
  { id: "ineligible", label: "Ineligible for Asign Protect+" },
];

interface AsignProtectProps {
  openAccordion?: any;
  setOpenAccordion?: any;
}

const AsignProtect: React.FC<AsignProtectProps> = ({
  openAccordion,
  setOpenAccordion,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<string[]>(["added"]);
  const isDesktop = useMediaQuery("(min-width: 1125px)");
  const handleOptionToggle = (optionId: string) => {
    setSelectedOptions((prev) =>
      prev.includes(optionId)
        ? prev.filter((id) => id !== optionId)
        : [...prev, optionId],
    );
  };

  return (
    <>
      {isDesktop && (
        <Popover open={isOpen} onOpenChange={setIsOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              className="px-5 py-3 text-sm rounded-full shadow-sm"
            >
              Asign Protect+
              {isOpen ? (
                <ChevronUp className="w-4 h-4 ml-2" />
              ) : (
                <ChevronDown className="w-4 h-4 ml-2" />
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-[281px] p-6 font-normal font-sh5">
            <div className="space-y-[25px]">
              {protectOptions.map((option) => (
                <div
                  key={option.id}
                  className="flex items-center space-x-[12px]"
                >
                  <Checkbox
                    id={option.id}
                    checked={selectedOptions.includes(option.id)}
                    onCheckedChange={() => handleOptionToggle(option.id)}
                    className="w-5 h-5"
                  />
                  <Label
                    htmlFor={option.id}
                    className={cn(
                      "font-normal cursor-pointer text-gray-60",
                      selectedOptions.includes(option.id) && "text-black",
                    )}
                  >
                    {option.label}
                  </Label>
                </div>
              ))}
            </div>
          </PopoverContent>
        </Popover>
      )}
      {!isDesktop && (
        <Accordion
          type="single"
          collapsible
          value={openAccordion === "asignProtect" ? "item-5" : ""}
          onValueChange={(value) =>
            setOpenAccordion(value ? "asignProtect" : null)
          }
        >
          <AccordionItem value="item-5">
            <AccordionTrigger className="text-lg no-underline">
              Asign Protect+
            </AccordionTrigger>
            <AccordionContent className="px-5">
              <div className="space-y-[25px]">
                {protectOptions.map((option) => (
                  <div
                    key={option.id}
                    className="flex items-center space-x-[12px]"
                  >
                    <Checkbox
                      id={option.id}
                      checked={selectedOptions.includes(option.id)}
                      onCheckedChange={() => handleOptionToggle(option.id)}
                      className="w-5 h-5"
                    />
                    <Label
                      htmlFor={option.id}
                      className={cn(
                        "font-normal cursor-pointer text-gray-60",
                        selectedOptions.includes(option.id) && "text-black",
                      )}
                    >
                      {option.label}
                    </Label>
                  </div>
                ))}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
const MobileFilter = () => {
  const [openAccordion, setOpenAccordion] = useState<
    "size" | "category" | null | "objectsBy" | "sortBy" | "asignProtect"
  >(null);
  return (
    <Drawer>
      <DrawerTrigger asChild>
        <Button
          variant="outline"
          className="px-5 py-3 text-sm rounded-full shadow-sm"
        >
          Filters
        </Button>
      </DrawerTrigger>
      <DrawerContent className="flex flex-col p-0 font-normal font-sh5">
        <div className="px-5">
          <div className="flex items-center justify-between  pt-5 border-b border-solid border-bdr-10 pb-[32px]">
            <h2 className="text-5xl font-normal ">Filters</h2>
            <DrawerClose asChild>
              <Button variant="ghost" size="icon" className="w-8 h-8">
                <img src="/close1.svg" alt="" className="w-5 h-5" />
              </Button>
            </DrawerClose>
          </div>
          <Category
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
          />
          <Size
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
          />
          <ObjectsBy
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
          />
          <SortBy
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
          />
          <AsignProtect
            openAccordion={openAccordion}
            setOpenAccordion={setOpenAccordion}
          />
        </div>
        <DialogFooter className="flex flex-row items-center justify-between w-full p-5 bg-[#F2F2F2] ">
          <Button
            className="cursor-pointer h-6 font-medium text-xs font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none px-0 hover:no-underline"
            type="button"
            variant="link"
          >
            CLEAR FILTERS
          </Button>
          <Button
            className="rounded-[50px]   font-medium text-sm text-white font-sh5 p-[14px_44px]"
            variant="default"
            type="button"
          >
            APPLY
          </Button>
        </DialogFooter>
      </DrawerContent>
    </Drawer>
  );
};

const Filter = () => {
  const isDesktop = useMediaQuery("(min-width: 1125px)");
  const { filterOption, setQuery } = useObjectContext();

  /**
   * Debounced search function for projects.
   * @param value - The search value.
   */
  const search = useDebouncedCallback((value: string) => {
    setQuery({
      searchQuery: value === "" ? undefined : value,
    });
  }, 700);
  const navigate = useNavigate();

  // State to manage which filters are enabled/disabled
  const [filters, setFilters] = useState({
    sortBy: false, // Default hidden
    size: true,
    objectsBy: true,
    category: true,
    asignProtect: true,
  });

  const filterOptions = [
    { label: "Sort by", key: "sortBy" },
    { label: "Size", key: "size" },
    { label: "Objects By", key: "objectsBy" },
    { label: "Category", key: "category" },
    { label: "Asign Protect", key: "asignProtect" },
  ];

  // Toggles filter visibility based on checkbox change
  const handleFilterToggle = (key: any) => {
    setFilters((prev) => ({
      ...prev,
      [key]: !prev[key as keyof typeof filters],
    }));
  };

  return (
    <div>
      {isDesktop ? (
        <div className="flex items-center justify-between mb-10">
          <div className="flex gap-x-4">
            {filters.category && <Category />}
            {filters.size && <Size />}
            {filters.objectsBy && <ObjectsBy />}
            {filters.sortBy && <SortBy />}
            {filters.asignProtect && <AsignProtect />}

            <Popover>
              <PopoverTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="w-10 h-10  rounded-full hover:bg-transparent p-[13px_14px] border-[#696969]"
                >
                  <img src="/filter.svg" alt="" className="w-4 h-4" />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                align="start"
                className="p-0 font-normal font-sh5 py-[18px]"
              >
                {filterOptions.map(({ label, key }) => (
                  <label
                    key={key}
                    className="flex items-center cursor-pointer gap-x-2 hover:bg-gray-10 text-gray-60 px-[20px] py-[10px]"
                  >
                    <Checkbox
                      className="w-5 h-5 "
                      checked={filters[key as keyof typeof filters]}
                      onCheckedChange={() => handleFilterToggle(key)}
                    />
                    <span>{label}</span>
                  </label>
                ))}
              </PopoverContent>
            </Popover>
          </div>

          <div className="flex gap-x-[32px]">
            <div className="relative flex items-center">
              <Search className="absolute left-5 top-2.5 h-4 w-4 text-[#1D1D1D]" />
              <input
                type="text"
                placeholder="Search objects"
                className="w-full py-[10px] pl-[43px] pr-4 text-sm border border-solid rounded-full border-gray-20 text-gray-60 min-w-[330px]"
                defaultValue={filterOption?.query?.searchQuery ?? ""}
                onChange={(e) => search(e.target.value)}
              />
            </div>

            <Button
              onClick={() => navigate("/catalog/add/object")}
              className="rounded-full p-[16px_29px]"
            >
              ADD NEW OBJECT
            </Button>
          </div>
        </div>
      ) : (
        <MobileFilter />
      )}
    </div>
  );
};

const DetailCard = ({ data }: { data: any }) => {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 1000px)");
  const queryClient = useQueryClient();
  const { deleteObject } = useObjects();

  const deleteSingleObject = useMutation({
    mutationFn: deleteObject,
    onMutate: () => {
      toast.loading("deleting the object...");
    },
    onSuccess: () => {
      toast.dismiss();
      toast.loading("deleted successfully.");
      queryClient.invalidateQueries({
        queryKey: ["objectsList"],
      });
    },
    onError: () => {
      toast.dismiss();
      toast.error("failed to delete the object.");
    },
  });
  const handleCardClick = () => {
    navigate(`/catalog/add/object?oi=${data.id}`);
  };

  return (
    <div className="w-full mx-auto font-normal font-sh5">
      <div className="relative mb-2 bg-[#EEEEEA] p-3 w-[217px] mq450:w-[151px] h-[217px]  mq450:max-h-[163px] ">
        {data?.image && (
          <img
            src={data?.image}
            alt={data?.objectName}
            className="object-contain w-full h-full max-w-[217px] mq450:max-w-[151px] max-h-[190px] mq450:max-h-[140px]"
          />
        )}
        {isDesktop ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <button
                className="absolute flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md bottom-3 right-3 mq450:w-[32px] mq450:h-[32px]"
                type="button"
              >
                <img
                  src="/setting.svg"
                  className="w-5 h-5 text-gray-600"
                  alt="setting"
                />
              </button>
            </DropdownMenuTrigger>
            <DropdownMenuContent
              className="w-[240px] shadow-lg p-0 rounded-none"
              align="end"
            >
              <DropdownMenuItem
                className="px-6 py-0 my-4 text-base font-normal cursor-pointer hover:bg-bg-5"
                onClick={handleCardClick}
              >
                Edit object details
              </DropdownMenuItem>
              <DropdownMenuItem className="px-6 py-0 my-4 text-base font-normal cursor-pointer hover:bg-bg-5">
                Add to catalogue raisonné
              </DropdownMenuItem>
              <DropdownMenuItem
                className="px-6 py-0 my-4 text-base font-normal cursor-pointer hover:bg-bg-5"
                onSelect={(e) => e.preventDefault()}
              >
                <ShareModal
                  url={`/catalog/detail/artwork?oi=${data?.id}`}
                  className="py-1 text-base text-left text-black hover:text-black"
                >
                  <p>Share</p>
                </ShareModal>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="px-6 py-0 my-4 text-base font-normal cursor-pointer hover:bg-bg-5"
                onSelect={(e) => e.preventDefault()}
              >
                <Global
                  title="Delete object"
                  description="Are you sure you want to permanently delete ‘<input Object name>’? 
This action cannot be undone."
                  actionText="DELETE"
                  cancelText="CANCEL"
                  onConfirm={() => deleteSingleObject.mutate({ id: data.id })}
                >
                  <p>Delete</p>
                </Global>
              </DropdownMenuItem>
              <DropdownMenuSeparator className="-mb-1" />
              <DropdownMenuItem className="px-6 py-0 my-4 text-base font-normal cursor-pointer hover:bg-bg-5">
                Assign Protect+
              </DropdownMenuItem>
              <DropdownMenuItem className="px-6 py-0 my-4 text-base font-normal cursor-pointer hover:bg-bg-5">
                Sell on Asign
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          <Drawer>
            <DrawerTrigger asChild>
              <button
                className="absolute flex items-center justify-center w-10 h-10 bg-white rounded-full shadow-md bottom-3 right-3 mq450:w-[32px] mq450:h-[32px]"
                type="button"
              >
                <img
                  src="/setting.svg"
                  className="w-5 h-5 text-gray-600"
                  alt="setting"
                />
              </button>
            </DrawerTrigger>
            <DrawerContent className="h-auto max-h-[90vh] font-sh5 font-normal">
              <DrawerHeader className="px-5 pt-5 pb-2 ">
                <div className="flex items-center justify-between">
                  <DrawerTitle className="text-5xl font-normal ">
                    Settings
                  </DrawerTitle>
                  <DrawerClose asChild>
                    <Button
                      variant="ghost"
                      size="sm"
                      className="w-auto h-auto p-0"
                    >
                      <img src="/close1.svg" className="w-5 h-5" />
                    </Button>
                  </DrawerClose>
                </div>
              </DrawerHeader>
              <div>
                <div className="px-[17px]">
                  <button
                    className="w-full py-5 text-base text-left text-black"
                    type="button"
                    onClick={handleCardClick}
                  >
                    Edit object details
                  </button>
                  <div className="w-full h-px bg-[#E5E5E5]"></div>
                </div>
                <div className="px-[17px]">
                  <button
                    className="w-full py-5 text-base text-left text-black"
                    type="button"
                  >
                    Add to catalogue raisonné
                  </button>
                  <div className="w-full h-px bg-[#E5E5E5]"></div>
                </div>
                <div className="px-[17px]">
                  <ShareModal
                    url={`/catalog/detail/artwork?oi=${data?.id}`}
                    className="text-base text-left text-black "
                  >
                    <button
                      className="w-full py-5 text-base text-left text-black"
                      type="button"
                    >
                      Share
                    </button>
                  </ShareModal>
                  <div className="w-full h-px bg-[#E5E5E5]"></div>
                </div>
                <div className="px-[17px]">
                  <Global
                    title="Delete object"
                    description="Are you sure you want to permanently delete ‘<input Object name>’? 
This action cannot be undone."
                    actionText="DELETE"
                    cancelText="CANCEL"
                    onConfirm={() => deleteSingleObject.mutate({ id: data.id })}
                  >
                    <button
                      className="w-full py-5 text-base text-left text-black"
                      type="button"
                    >
                      Delete
                    </button>
                  </Global>
                  <div className="w-full h-px bg-[#E5E5E5]"></div>
                </div>
                <div className="px-[17px]">
                  <button
                    className="w-full py-5 text-base text-left text-black"
                    type="button"
                  >
                    Assign Protect+
                  </button>
                  <div className="w-full h-px bg-[#E5E5E5]"></div>
                </div>
                <div className="px-[17px]">
                  <button
                    className="w-full py-5 text-base text-left text-black"
                    type="button"
                  >
                    Sell on Asign
                  </button>
                </div>
              </div>
            </DrawerContent>
          </Drawer>
        )}
      </div>
      <h2 className="mq450:text-xs font-medium mb-0.5 leading-tight text-sm capitalize">
        {data?.objectName ?? "Untitled"}
      </h2>
      <p className="text-sm text-gray-60 mb-0.5 leading-tight mq450:text-[10px]">
        {data?.descriptiveTitle}
      </p>
      <p className="text-sm leading-tight text-[#696969] mq450:text-[10px]">
        {data?.metaData?.creationDate?.year}
      </p>
    </div>
  );
};

import { cn } from "@/lib/utils";
import Sidebar from "@/pages/profile/Sidebar";
import React, { FunctionComponent } from "react";

import { useLocation, useNavigate } from "react-router-dom";

const Header: FunctionComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let currentLocation = location.pathname;

  // Determine if we are on a parent route or a deeper route
  const isCatalogActive = currentLocation.startsWith("/catalog");
  const parentRoutes = [
    "/discover/feed",
    "/business/feed",
    "/objects/feed",
    "/artist/feed",
    "/events/feed",
    "/catalog/detail",
  ];
  const isParentRoute = parentRoutes.includes(currentLocation);
  return (
    <header className="self-stretch mq750:items-center bg-white box-border flex flex-row items-start justify-between py-0 px-12 w-full max-w-full gap-[20px] text-left text-sm text-grey100 font-paragraph-p3 border-b-[1px] border-solid border-gray-20 mq725:pl-6 mq725:pr-6 mq725:box-border font-sh5 font-normal mq450:flex mq450:items-center z-50 ">
      <div className="box-border flex flex-row items-center justify-start max-w-full gap-6 py-0 pl-0 overflow-hidden shrink-0 mq450:justify-between mq450:pr-5 mq450:box-border mq725:box-border">
        {isParentRoute ? (
          <img loading="lazy" alt="Aisgn Logo" src="/logo.svg" />
        ) : (
          <button
            onClick={() => navigate(-1)}
            className="relative block mt-1 cursor-pointer lg:hidden"
          >
            <img loading="lazy" alt="go back" src="/left-arrow.svg" />
          </button>
        )}

        <div className="flex flex-row items-center justify-start mq725:hidden">
          <div
            className={cn(
              "flex flex-row items-center justify-center p-4 gap-[8px] cursor-pointer text-gray-60",
              currentLocation == "/discover/feed" && "text-gray-100",
            )}
            onClick={() => navigate("/discover/feed")}
          >
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/menu-icons.svg"
            />
            <div className="relative text-base leading-[17px] inline-block min-w-[53px]">
              Discover
            </div>
          </div>
          <div className="flex flex-row items-center justify-center p-4 gap-[8px] text-gray-60 cursor-pointer">
            <img
              className="relative w-6 h-6 overflow-hidden shrink-0"
              alt=""
              src="/menu-icons-1.svg"
            />
            <div
              className={cn(
                "relative leading-[17px] inline-block min-w-[53px] text-base",
                isCatalogActive && "text-gray-100",
              )}
              onClick={() => navigate("/catalog/detail")}
            >
              Catalogue
            </div>
          </div>
        </div>
      </div>
      <div className="box-border flex flex-col items-start justify-start max-w-full px-0 pt-2 pb-0 mq750:py-4">
        <div className="self-stretch flex flex-row items-start justify-start mq450:justify-end gap-[24px] mq450:px-[12px]">
          <div className="flex items-center border border-solid border-[#CFCFCF] mq750:hidden mq450:hidden min-w-[232px] justify-center h-10 rounded-full transition-all duration-300 ease-in-out focus-within:justify-start focus-within:gap-2 focus-within:px-3">
            <img
              src="/iconsearch.svg"
              alt="Search Icon"
              className="transition-all duration-300 ease-in-out"
            />
            <input
              type="text"
              className="w-full h-full max-w-[70px] tracking-[0.7px] font-sh5 font-normal outline-none transition-all duration-300 ease-in-out placeholder:text-center focus:placeholder:text-left focus:max-w-full border-transparent bg-transparent"
              placeholder="Search"
            />
          </div>

          <div className="flex flex-row items-center gap-6">
            <img
              className="hidden w-6 h-6 mq450:block mq750:block"
              src="/iconsearch.svg"
            />
            <img
              className="hidden w-6 h-6 mq450:block mq750:block "
              src="/iconinbox.svg"
            />
            <img
              className="hidden w-6 h-6 mq450:block mq750:block"
              src="/button@2x.png"
            />
          </div>
          <div className="flex flex-col items-start justify-start px-0 pt-2 pb-0 mq750:hidden mq450:hidden">
            <div className="flex flex-row items-center justify-end relative gap-[24px]">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                loading="lazy"
                alt=""
                src="/iconinbox.svg"
              />
              <div className="h-[9px] w-[9px] absolute !m-[0] top-[-1.5px] right-[-1px] rounded-[50%] bg-red-100 box-border z-[1] border-[0px] border-solid border-white" />
            </div>
          </div>
          <div className="flex flex-col items-start justify-start px-0 pt-2 pb-0 mq750:hidden mq450:hidden">
            <img
              className="relative object-cover w-6 h-6 overflow-hidden shrink-0"
              loading="lazy"
              alt=""
              src="/button@2x.png"
            />
          </div>
          <Sidebar>
            <div className="flex flex-col items-start justify-start px-0 pt-2 pb-0 cursor-pointer mq450:hidden mq750:hidden">
              <img
                className="relative w-6 h-6 overflow-hidden shrink-0"
                alt=""
                src="/button-1.svg"
              />
            </div>
          </Sidebar>
        </div>
      </div>
    </header>
  );
};

export default Header;

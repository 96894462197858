import useUserAPI from "@/apis/user";
import WorkCard from "@/components/cards/WorkCard";
import Header from "@/components/Global/header/Header";
import { MobileHeader } from "@/components/Global/header/MobileHeader";
import Loader from "@/components/Global/Loader";
import { FollowArtistButton } from "@/components/Templates/Artists/BigBanner";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";
import { Represent } from "@/types/user.types";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
const sideBar = [
  { label: "Biography" },
  { label: "Catalogue rasionné" },
  { label: "Representation" },
  { label: "Education" },
  { label: "Events" },
  { label: "Recognition" },
  { label: "Press" },
  { label: "Notable collections" },
  { label: "Publications" },
];

const Representation: React.FC<{ data: Represent[] }> = ({ data }) => {
  const [verify, setVerify] = useState(true);
  if (!data || data.length === 0) {
    return <div>No data available</div>;
  }

  const verifiedData = data.filter((item) => item.verified);
  const unverifiedData = data.filter((item) => !item.verified);

  return (
    <>
      <ul className="flex gap-5 my-10 list-none border-b border-solid border-gray-20">
        <li
          className={`pb-1 cursor-pointer ${
            verify ? "border-b border-gray-100 border-solid" : "text-gray-60"
          }`}
          onClick={() => setVerify(true)}
        >
          Verified
        </li>
        <li
          className={`cursor-pointer ${
            !verify ? "border-b border-gray-100 border-solid" : "text-gray-60"
          }`}
          onClick={() => setVerify(false)}
        >
          Unverified
        </li>
      </ul>
      <div className="grid grid-cols-1 gap-y-10 md:grid-cols-2">
        {(verify ? verifiedData : unverifiedData).map((item, index) => (
          <div className="flex items-center gap-5" key={index}>
            <Avatar>
              <AvatarImage src={item.image} alt={item.name} />
              <AvatarFallback>{item.name[0]}</AvatarFallback>
            </Avatar>
            <div>
              <p>{item.name}</p>
              <p className="text-sm text-gray-60">{item.location}</p>
            </div>
          </div>
        ))}
      </div>
      <Button variant="outline" className="mt-10 rounded-full">
        VIEW MORE
      </Button>
    </>
  );
};

const ArtistDetail = () => {
  const { id } = useParams() as { id: string };
  const { userById } = useUserAPI();

  const [currentSection, setCurrentSection] = useState<string>("");

  const refs = sideBar.reduce((acc, value) => {
    acc[value.label] = useRef<HTMLDivElement>(null);
    return acc;
  }, {} as { [key: string]: React.RefObject<HTMLDivElement> });

  const scrollToSection = (label: string) => {
    const ref = refs[label]?.current;

    if (ref) {
      setCurrentSection(label);
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-10px",
      threshold: 0.7,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.getAttribute("data-section") || "");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [refs]);

  const { data: artist, isLoading } = useQuery({
    queryKey: ["artist", id],
    queryFn: () => userById({ id }),
    enabled: Boolean(id),
  });

  if (isLoading) return <Loader />;

  return (
    <>
      <Header />
      <div className="container font-normal font-sh5">
        <div className="fixed z-10 w-full top-15 mb-15">
          <MobileHeader
            data={sideBar}
            active={currentSection}
            onClick={scrollToSection}
          />
        </div>
        <div className="bg-[#F2F2F2]">
          <Breadcrumb className="px-5 pt-16 md:p-5">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink href="/">Discover</BreadcrumbLink>
              </BreadcrumbItem>
              <span className="text-black">/</span>
              <BreadcrumbItem>
                <BreadcrumbLink href="/artist/feed">Artist</BreadcrumbLink>
              </BreadcrumbItem>
              <span className="text-black">/</span>
              <BreadcrumbItem>
                <BreadcrumbPage>{artist?.name}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <div className="flex flex-col-reverse gap-5 p-5 lg:mt-8 md:flex-row md:items-center lg:px-[48px]">
            <div className="lg:w-1/3">
              <Avatar className="w-[100px] h-[100px]">
                <AvatarImage src={artist?.profilePic} alt={artist?.name} />
                <AvatarFallback>
                  {artist?.name?.slice(0, 1) ?? "CN"}
                </AvatarFallback>
              </Avatar>
              <h2 className="font-normal font-eb md:text-[48px] text-gray-80 text-[36px]">
                {artist?.name}
              </h2>
              <div className="flex items-stretch gap-4 text-gray-60">
                <span>b. {format(artist?.dob ?? new Date(), "yyyy")}</span>
                <span>New Delhi</span>
              </div>
              <div className="flex items-center gap-4 mt-[26px]">
                <FollowArtistButton
                  id={artist?._id ?? ""}
                  className="bg-other-cta min-w-[100px] h-[40px] uppercase"
                />
                <p className="text-[#696969]">2.3k Followers</p>
              </div>
            </div>
            <div className="lg:w-2/3">
              <img
                src="/demo-image.png"
                alt=""
                className="w-full max-h-[437px] object-cover object-bottom"
              />
            </div>
          </div>
        </div>
        <div className="relative flex p-5 mt-5 lg:px-[48px]">
          <div className="hidden lg:w-1/3 md:block">
            <div className="overflow-y-scroll text-gray-60">
              {sideBar.map((item) => (
                <div
                  role="button"
                  key={item.label}
                  tabIndex={0}
                  className={cn(
                    "mb-[20px] cursor-pointer whitespace-nowrap hover:text-gray-100",
                    currentSection === item.label && "text-gray-100"
                  )}
                  onClick={() => scrollToSection(item.label)}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-2/3 lg:max-h-[500px] overflow-y-scroll">
            <div ref={refs["Biography"]} data-section="Biography">
              <h3 className="font-medium text-[24px]">Biography</h3>
              <p className="my-5">{artist?.biography}</p>
              <Button variant="outline" className="rounded-full">
                READ MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div
              ref={refs["Catalogue rasionné"]}
              data-section="Catalogue rasionné"
            >
              <div className="flex items-center justify-between mb-[32px]">
                <h3 className="font-medium text-[24px]">
                  Catalogue raisonné <sup>{artist?.gallery.length || 0}</sup>
                </h3>
                <p className="flex items-center gap-2 text-sm">
                  <span className="text-xs truncate md:text-sm">
                    Objects for sale
                  </span>{" "}
                  <Switch />
                </p>
              </div>
              <div className="flex flex-wrap items-center justify-between gap-y-10">
                {artist?.gallery.map((item) => (
                  <WorkCard
                    key={item._id}
                    image={item.art_url}
                    description={item.art_desc}
                    label={item.art_name}
                  />
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Representation"]} data-section="Representation">
              <h3 className="font-medium text-[24px]">Representation</h3>
              <div>
                <Representation data={artist?.represents || []} />
              </div>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Education"]} data-section="Education">
              <h3 className="font-medium text-[24px]">Education</h3>
              <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-10">
                {artist?.education.map((item) => (
                  <div key={item.id}>
                    <p>{item.courseName}</p>
                    <p className="mt-1 text-sm text-gray-60">
                      {format(new Date(item.startDate), "yyyy")} -{" "}
                      {format(new Date(item.endDate), "yyyy")}
                    </p>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Events"]} data-section="Events">
              <h3 className="font-medium text-[24px]">Events</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {artist?.events.map((item) => (
                  <div key={item.id} className="">
                    <img
                      src="https://picsum.photos/300/200"
                      alt={item.eventName}
                    />

                    <div className="grid gap-1 mt-5">
                      <h4 className="font-normal capitalize">
                        {item.eventName}
                      </h4>
                      <p className="text-sm text-gray-60">{item.country}</p>
                      <p className="text-sm text-gray-60">
                        {format(new Date(item.startDate), "MMM d, yyyy")} -{" "}
                        {format(new Date(item.endDate), "MMM d, yyyy")}{" "}
                      </p>
                      <p className="text-sm text-gray-60">
                        {" "}
                        {format(new Date(item.startDate), "h:mm a")} -{" "}
                        {format(new Date(item.endDate), "h:mm a")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Recognition"]} data-section="Recognition">
              <h3 className="font-medium text-[24px]">Recognition</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {artist?.recognition.map((item) => (
                  <div key={item.id}>
                    <p>{item.artName}</p>
                    <p className="text-sm text-gray-60">1994 - 1997</p>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Press"]} data-section="Press">
              <h3 className="font-medium text-[24px]">Press</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {artist?.press.map((item) => (
                  <div key={item.id}>
                    <p>{item.articleName}</p>
                    <p className="mt-2 mb-5 text-sm text-gray-60">
                      {format(new Date(item.articleDate), "yyyy")}
                    </p>
                    <a
                      className="font-medium underline "
                      href={item.articleLink}
                      target="blank"
                    >
                      VIEW IMAGE
                    </a>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div
              ref={refs["Notable collections"]}
              data-section="Notable collections"
            >
              <h3 className="font-medium text-[24px]">Notable collections</h3>
              <div className="grid grid-cols-2 mt-10 gap-y-10">
                {artist?.notableCollections.map((item) => (
                  <div key={item.id}>
                    <p>{item.collectionName}</p>
                    <p className="mt-1 text-sm text-gray-60">
                      {item.state}, {item.country}
                    </p>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
            <Separator className="my-[42px]" />
            <div ref={refs["Publications"]} data-section="Publications">
              <h3 className="font-medium text-[24px]">Publications</h3>
              <div className="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-10">
                {artist?.publications.map((item) => (
                  <div key={item.id}>
                    <p>{item.publicationName}</p>
                    <p className="mt-1 text-sm text-gray-60">
                      {item.publicationBusiness} -{" "}
                      {format(new Date(item.publicationDate), "MMM dd, yyyy")}
                    </p>
                  </div>
                ))}
              </div>
              <Button variant="outline" className="mt-10 rounded-full">
                VIEW MORE
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArtistDetail;

import { baseUrl } from "@/apis";
import updateProfile from "@/apis/updateProfile";
import { Button } from "@/components/ui/button";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

interface OTPVerificationProps {
  phoneNumber: string;
  onVerify: (otp: string) => void;
  onEditNumber: () => void;
  onResendOTP: () => void;
  stepNumber: number;
  totalSteps: number;
  setType: (type: string) => void;
  otpVal: string;
  userId: string;
}

const OTPVerification: React.FC<OTPVerificationProps> = ({
  phoneNumber,
  onVerify,
  onEditNumber,
  onResendOTP,
  stepNumber,
  totalSteps,
  setType,
  otpVal,
  userId,
}) => {
  const [timer, setTimer] = useState(30);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(
    null,
  );
  const [canResend, setCanResend] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    setError,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const enteredOTP = watch("otp");

  useEffect(() => {
    if (timer <= 0) {
      setCanResend(true);
      return;
    }

    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  // const onSubmit = (data: { otp: string }) => {
  //   onVerify(data.otp);
  // };

  const handleResendOTP = async () => {
    if (!canResend) return; // Prevent resending if not allowed

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: userId }),
      redirect: "follow" as RequestRedirect,
    };

    try {
      const response = await fetch(
        `${baseUrl}/client/auth/send-otp`,
        requestOptions,
      );
      if (response.ok) {
        console.log("OTP resent successfully");
        setTimer(30); // Reset timer to 30 seconds
        setCanResend(false); // Disable resend button until timer expires
      } else {
        console.error("Failed to resend OTP:", response.statusText);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    }
  };

  // const handleVerify = () => {
  //   if (inputOTP === otp) {
  //     setType("agreement")
  //     // onVerify();
  //   } else {
  //     setError('Invalid OTP. Please try again.');
  //   }
  // };

  // const handleVerifyAndSetup = () => {
  //   console.log(typeof(enteredOTP), typeof(otpVal))
  //   if (parseInt(enteredOTP) === parseInt(otpVal)) {
  //     onVerify(enteredOTP);
  //     setType("agreement");
  //   } else {
  //     setError("otp", {
  //       type: "manual",
  //       message: "Invalid OTP. Please try again."
  //     });
  //   }
  // };

  const handleVerifyAndSetup = async (data: { otp: string }) => {
    setIsVerifying(true);
    setVerificationError(null);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      otp: enteredOTP,
      id: userId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
    };

    try {
      const response = await fetch(
        "https://ab.host.levitation.co.in/client/auth/verify-otp",
        requestOptions,
      );
      const result = await response.json();
      if (result.status === "BAD_REQUEST") {
        setVerificationError("Verification failed. Please check your OTP.");
      }
      if (result.status !== "BAD_REQUEST") {
        console.log("OTP verification successful:", result);
        onVerify(data.otp);
        const dataToUpdate = {
          phoneVerified: true,
        };

        try {
          const updateResult = await updateProfile(userId, dataToUpdate);
          console.log("Profile updated successfully:", updateResult);
        } catch (updateError) {
          console.error("Error updating profile:", updateError);
        }
        setType("agreement");
      } else {
        throw new Error(result.message || "OTP verification failed");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      setVerificationError(
        error instanceof Error
          ? error.message
          : "Incorrect OTP. Please try again.",
      );
    } finally {
      setIsVerifying(false);
    }
  };

  // const handleVerifyAndSetup = () => {
  //   setType("agreement");
  // }

  return (
    <div className="w-full md:w-1/2 flex flex-col p-8 md:p-16 overflow-y-auto">
      <div className="w-full max-w-md mx-auto">
        <p className="text-gray-500 mb-2 text-sm font-normal">
          Step {stepNumber} of {totalSteps}
        </p>
        <h1 className="text-[32px] font-normal mb-4">Verify OTP</h1>
        <p className="mb-8">
          <span className="text-[16px] font-normal text-[#696969]">
            An OTP has been sent to +91-
          </span>
          {phoneNumber}{" "}
          <Button
            variant="link"
            className="text-black underline font-medium text-[12px]"
            onClick={() => console.log("edit number")}
          >
            EDIT NUMBER
          </Button>
        </p>
        <form className="space-y-6">
          <div>
            <label
              htmlFor="otp"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              OTP
            </label>
            <input
              type="text"
              id="otp"
              {...register("otp", {
                required: "OTP is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "OTP must be 6 digits",
                },
              })}
              className="mt-1 h-[59px] text-[16px] block w-full px-3 py-2 border border-gray-300 shadow-sm focus:outline-none focus:ring-black focus:border-black"
            />
            {errors.otp && (
              <p className="mt-1 text-xs text-red-500">
                {errors.otp.message as string}
              </p>
            )}
            {verificationError && (
              <div className="text-red-500">{verificationError}</div>
            )}
          </div>
          <p className="text-sm text-gray-500">
            {canResend ? (
              <Button
                variant="link"
                className="text-black underline p-0 m-0"
                onClick={handleResendOTP}
              >
                Resend OTP
              </Button>
            ) : (
              `Resend OTP in ${timer} seconds`
            )}
          </p>

          <Button
            className={`w-full py-3 px-4 bg-black text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
              !isValid ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
            }`}
            disabled={!isValid}
            onClick={() => handleVerifyAndSetup({ otp: enteredOTP })}
          >
            SET UP ACCOUNT
          </Button>
        </form>
      </div>
    </div>
  );
};

export default OTPVerification;

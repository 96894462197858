import React, { useState, useCallback, useRef } from "react";
import GlobalDialog from "@/modal/GlobalDialog";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { X } from "lucide-react";
import { useObjectContext } from "@/context/objects";
import toast from "react-hot-toast";

interface AddMediaProps {
  children: React.ReactElement;
  type: "image" | "video";
  schemaKey: string;
}
const MAX_FILE_SIZE = 20; // 20 MB in bytes

const AddMedia: React.FC<AddMediaProps> = ({ children, type, schemaKey }) => {
  const [title, setTitle] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [preview, setPreview] = useState<string | null>(null);
  const [base64String, setBase64String] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { updateOrAddItem } = useObjectContext();

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedFile = event.target.files?.[0];

      if (selectedFile) {
        if (selectedFile.size > MAX_FILE_SIZE * 1024 * 1024) {
          toast.error("File size exceeds the limit.");
          return;
        }
        setFile(selectedFile);

        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          setPreview(result); // Set the preview for the image/video
          setBase64String(result); // Store the entire Base64 string including the MIME type
        };
        reader.readAsDataURL(selectedFile); // Converts file to Base64 string
      }
    },
    [],
  );

  const handleDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile) {
      if (droppedFile.size > MAX_FILE_SIZE * 1024 * 1024) {
        toast.error("File size exceeds the limit.");
        return;
      }
      setFile(droppedFile);

      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setPreview(result); // Set the preview for the image/video
        setBase64String(result); // Store the entire Base64 string including the MIME type
      };
      reader.readAsDataURL(droppedFile); // Converts file to Base64 string
    }
  }, []);

  const handleDelete = useCallback(() => {
    setFile(null);
    setPreview(null);
    setBase64String(null);
  }, []);

  const handleSave = useCallback(() => {
    if (!title || !file || !base64String) {
      toast.error("Please fill all the fields.");
      return;
    }
    if (file && title && base64String) {
      updateOrAddItem({
        document: {
          media: {
            [schemaKey]: [
              {
                title,
                [type]: base64String, // Send the full Base64 string here
              },
            ],
          },
        },
      });
    }
  }, [file, title, base64String, updateOrAddItem]);

  const openFileDialog = (event: React.MouseEvent) => {
    event.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleCancel = () => {
    setTitle("");
    setFile(null);
    setPreview(null);
    setBase64String(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <GlobalDialog
      title=""
      TriggerButton={children}
      onCancel={handleCancel}
      onConfirm={handleSave}
    >
      <div className="space-y-4">
        <div>
          <Label htmlFor="title">
            {type === "image" ? "Image title" : "Video title"}
          </Label>
          <Input
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder={`Enter ${type} title`}
          />
        </div>

        <div
          className="p-4 text-center border-2 border-gray-300 border-dashed rounded-lg cursor-pointer"
          onClick={openFileDialog}
          onDragOver={(e) => e.preventDefault()}
          onDrop={handleDrop}
        >
          {preview ? (
            <div className="relative">
              {type === "image" ? (
                <img
                  src={preview}
                  alt="Preview"
                  className="h-auto max-w-full"
                />
              ) : (
                <video src={preview} controls className="h-auto max-w-full" />
              )}
              <button
                onClick={handleDelete}
                className="absolute p-1 bg-white rounded-full top-2 right-2"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          ) : (
            <>
              <p>Drag and drop or browse files</p>
              <p className="text-sm text-gray-500">
                {type === "image"
                  ? "Up to 5 MB, in JPG or PNG format"
                  : "Up to 20 MB, in MP4 format"}
              </p>
              <Button variant="link" onClick={openFileDialog}>
                Browse Files
              </Button>
              <Input
                ref={fileInputRef}
                type="file"
                accept={type === "image" ? "image/*" : "video/*"}
                onChange={handleFileChange}
                className="hidden"
              />
            </>
          )}
        </div>
      </div>
    </GlobalDialog>
  );
};

export default AddMedia;

import React from "react";

const Loader = () => {
  return (
    <div className="loading-screen">
      <img className="animate-spin" src="/loading.svg" alt="" />
    </div>
  );
};

export default Loader;

import GlobalDialog from "@/modal/GlobalDialog";
import React, { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { z } from "zod"; // Import Zod for validation
import { useForm } from "react-hook-form"; // Import React Hook Form
import { zodResolver } from "@hookform/resolvers/zod"; // Zod resolver for form validation
import { useUserContext } from "@/context/user";

const formSchema = z.object({
  displayName: z.string().min(2, "Display name must be at least 2 characters"),
  dob: z.string().regex(/^\d{4}$/, "Must be a valid year"),
  registerPlace: z.string().min(2, "City name must be at least 2 characters"),
});

type FormValues = z.infer<typeof formSchema>;

interface EditProfileProps {
  children: React.ReactNode;
}

// Assuming you want to generate a list of years dynamically:
const years = Array.from(
  { length: 100 },
  (_, i) => new Date().getFullYear() - i,
);

const EditProfile = ({ children }: EditProfileProps) => {
  const { updateUser, me } = useUserContext();

  // Initialize form with react-hook-form and zod resolver
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      displayName: me?.displayName || "",
      dob: me?.dob || "",
      registerPlace: me?.registerPlace || "",
    },
  });

  // Handle form submission
  const onSubmit = (data: FormValues) => {
    updateUser.mutate(data);
  };

  return (
    <GlobalDialog
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
      title="Edit Profile"
      isError={Object.keys(form.formState.errors).length > 0}
    >
      <Form {...form}>
        <FormField
          control={form.control}
          name="displayName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Display name</FormLabel>
              <FormControl>
                <Input
                  placeholder="Mrinalini Mukherjee"
                  {...field}
                  className=""
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="dob"
          render={({ field }) => (
            <FormItem className="mt-[24px]">
              <FormLabel>Birth year</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                    <SelectValue placeholder="Select a year" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {years.map((year) => (
                    <SelectItem key={year} value={year.toString()}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="registerPlace"
          render={({ field }) => (
            <FormItem className="mt-[24px]">
              <FormLabel>Location</FormLabel>
              <FormControl>
                <Input placeholder="New Delhi" {...field} />
              </FormControl>
              <FormDescription>
                Add the city you currently practice in.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
      </Form>
    </GlobalDialog>
  );
};

export default EditProfile;

import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { DialogClose } from "@radix-ui/react-dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import useBoard from "@/apis/board";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { CreateBoardParams } from "@/hooks/useFilter";
import toast from "react-hot-toast";
import { useMediaQuery } from "@mui/material";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { CreateBoardResponse } from "@/types/boards.type";
import { Link } from "react-router-dom";

type NewBoardProps = {
  children: ReactNode;
};

const NewBoard: React.FC<NewBoardProps> = ({ children }) => {
  const [open, setOpen] = useState(false); // Manage state here
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger className="text-lg text-white bg-transparent">
          {children}
        </DialogTrigger>
        <DialogContent className="max-w-[640px] z-[100]">
          <DialogHeader className="px-8">
            <DialogTitle className="pb-5 border-[#E5E5E5] border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
              <div className="flex flex-row justify-between">
                <p>New Board</p>
                <div className="flex flex-row items-center gap-4">
                  <DialogClose asChild>
                    <img
                      className="cursor-pointer"
                      src="/close1.svg"
                      alt="Close"
                    />
                  </DialogClose>
                </div>
              </div>
            </DialogTitle>
          </DialogHeader>
          {/* Pass setOpen to ProfileForm */}
          <ProfileForm setOpen={setOpen} />
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={() => setOpen(true)}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none h-4/5 ">
        <DrawerHeader className="p-5 text-left">
          <div className="flex flex-row items-center justify-between pb-2  border-b-[1px]  border-bdr-10 border-solid">
            <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
              New Board
            </DrawerTitle>
            <DialogClose asChild>
              <img className="cursor-pointer" src="/close1.svg" alt="Close" />
            </DialogClose>
          </div>
        </DrawerHeader>
        {/* Pass setOpen to ProfileForm */}
        <ProfileForm setOpen={setOpen} />
      </DrawerContent>
    </Drawer>
  );
};

// ProfileForm now receives setOpen as a prop
function ProfileForm({
  className,
  setOpen,
}: React.ComponentProps<"form"> & {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [selectedImageId, setSelectedImageId] = useState<number>(0);
  const charLimit = 140;
  const imageSources = [
    { id: 0, src: "/starLogoAlt.svg", alt: "Star Logo" },
    { id: 1, src: "/caseAlt.svg", alt: "Case Icon" },
    { id: 2, src: "/eyeAlt.svg", alt: "Eye Icon" },
    { id: 3, src: "/framingAlt.svg", alt: "Framing Icon" },
    { id: 4, src: "/paintAlt.svg", alt: "Paint Icon" },
    { id: 5, src: "/playAlt.svg", alt: "Play Icon" },
    { id: 6, src: "/scholarAlt.svg", alt: "Scholar Icon" },
    { id: 7, src: "/tvAlt.svg", alt: "TV Icon" },
    { id: 8, src: "/starAlt.svg", alt: "Star Alt Icon" },
    { id: 9, src: "/personAlt.svg", alt: "Person Icon" },
    { id: 10, src: "/writingAlt.svg", alt: "Writing Icon" },
    { id: 11, src: "/heart.svg", alt: "heart Icon" },
  ];
  const { createBoard } = useBoard();
  const queryClient = useQueryClient();

  const BoardCreationToast = ({
    boardId,
    name,
  }: {
    boardId: string;
    name: string;
  }) => (
    <div className="flex justify-between w-full space-x-4">
      <p>Board created successfully: {name}</p>
      <Link to={`/boards/${boardId}`} className="view-link">
        View
      </Link>
    </div>
  );

  const mutation: UseMutationResult<
    CreateBoardResponse,
    Error,
    CreateBoardParams
  > = useMutation({
    mutationFn: createBoard,

    onSuccess: (response) => {
      setName("");
      setDescription("");
      setSelectedImageId(0);

      toast.success(
        <BoardCreationToast
          name={response.data.name}
          boardId={response.data.id}
        />,
        {
          id: "create-BOARD",
        }
      );
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },

    onMutate: () => {
      setOpen(false);
      toast.loading("Adding the board...", {
        id: "create-BOARD",
      });
    },

    onError: (error) => {
      console.error("Error creating board", error);
      toast.error("Failed to create board. Please try again.", {
        id: "create-BOARD",
      });
    },
  });

  const handleCreateBoard = () => {
    if (name.trim() === "") {
      toast.error("Enter all details");
      return;
    }
    if (description.length >= charLimit) {
      toast.error("Description character has been exceed.");
      return;
    }

    const newBoard: CreateBoardParams = {
      name,
      description,
      icon: selectedImageId,
    };

    mutation.mutate(newBoard);
  };

  // Determine if the button should be disabled
  const isButtonDisabled = !name;

  return (
    <form className={cn("grid items-start gap-4 overflow-auto", className)}>
      <div className="flex flex-col gap-[20px] mq450:px-5 px-8">
        <div className="flex flex-col gap-3">
          <div className="text-base font-normal text-gray-100 font-sh5 mq450:hidden">
            Select Board Icon
          </div>
          <div className="flex flex-row items-center gap-6 mq450:flex-col">
            <div className="flex items-center justify-center min-w-[120px] h-[120px] rounded-full border-[1px] border-solid border-bdr-10">
              <img
                className="w-[72px] h-[72px] "
                src={imageSources[selectedImageId].src}
                alt={imageSources[selectedImageId].alt}
              />
            </div>
            <div>
              <div className="hidden pb-3 text-base font-normal text-gray-100 font-sh5 mq450:block">
                Select Board Icon
              </div>
              <div className="flex flex-wrap w-full gap-2 py-2 mq450:justify-between">
                {imageSources.map((image, index) => (
                  <img
                    key={image.id}
                    className={`w-10 h-10 border-solid p-2 transition-transform duration-200 ease-in-out cursor-pointer border-[1px] ${
                      selectedImageId === image.id
                        ? "bg-[#F6F6F5] border-[#7AC51C]"
                        : "border-transparent"
                    } hover:border-[#7AC51C]`}
                    src={image.src}
                    alt={image.alt}
                    onClick={() => setSelectedImageId(image.id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-base font-normal text-gray-100 mq450:text-left font-sh5">
            Name of the Board
          </div>

          <input
            value={name}
            onChange={(e) => {
              e.persist();
              setName(e.target.value);
              console.log("Input Name:", e.target.value);
            }}
            type="text"
            aria-placeholder="Enter Name"
            placeholder="Enter Name"
            className="placeholder:text-gray-40 font-normal text-base font-sh5 w-full h-[50px] border border-solid border-bdr-10 p-5"
          />
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-base font-normal text-gray-100 mq450:text-left font-sh5">
            Description (Optional)
          </div>
          <input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
            type="text"
            aria-placeholder="Enter Description"
            className="placeholder:text-gray-40 font-normal text-base font-sh5 w-full h-[50px] border border-solid border-bdr-10 p-5"
          />

          <div className="mt-2 text-left text-gray-500">
            {description.length}/{charLimit}
          </div>
        </div>
      </div>
      <div className="flex mt-2 flex-row items-center justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="cursor-pointer h-6 min-w-12 font-medium text-xs relative font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none "
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <button
          onClick={handleCreateBoard}
          className={cn(
            "cursor-pointer rounded-[50px] h-[42px] min-w-[130px] font-sh5 text-xs  px-4 font-medium",
            isButtonDisabled
              ? "bg-gray-20 text-gray-60"
              : "bg-gray-100 text-white"
          )}
          type="button"
          disabled={isButtonDisabled} // Add the disabled attribute
        >
          CREATE
        </button>
      </div>
    </form>
  );
}

export default NewBoard;

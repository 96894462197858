import React from "react";
import { ObjectItem } from "@/types/boards.type";
import AddItemDropDown from "../AddItemDropDown";

interface ObjectsProps {
  items: ObjectItem[];
}

const Objects: React.FC<ObjectsProps> = ({ items }) => {
  return (
    <div>
      {items.length === 0 ? (
        <p>No objects found.</p>
      ) : (
        items.map((item) => (
          <div
            key={item._id}
            className="flex w-full min-w-[297px] h-fit  flex-col relative items-center justify-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 group"
          >
            <div className="flex flex-col items-center flex-1 w-full">
              {item.image ? (
                <img
                  src={item.image}
                  alt={item.objectName}
                  className="object-cover w-full h-full"
                />
              ) : (
                <div className="min-w-full rounded-sm min-h-[20vh] bg-[#d3d3d3]"></div>
              )}

              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
              <AddItemDropDown
                itemID={item._id}
                boardID={""}
                itemType={"object"}
              >
                <span className="absolute top-4 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-sm flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center">
                  <div className="flex flex-row items-center gap-1">
                    <img className="w-6 h-6" src="/label.svg" alt="label" />
                  </div>
                </span>
              </AddItemDropDown>
              <div className="absolute bottom-0 left-0 flex flex-col w-full gap-1 p-4">
                <div className="flex flex-row items-center gap-[2px]">
                  <img
                    src="/icon-protect-1.svg"
                    alt="Protect Icon"
                    className="w-5 h-5"
                  />
                  {item.assignProtect && (
                    <p className="text-xs font-normal text-white font-sh5">
                      Asign Protect+
                    </p>
                  )}
                </div>
                <h2 className="ml-1 text-sm font-normal text-white font-sh5">
                  {item.objectName}
                </h2>
                {item.publishedYear && (
                  <p className="font-sh5 ml-1 font-normal text-sm text-[#CCCCCC]">
                    c. {item.publishedYear}
                  </p>
                )}
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Objects;

import Header from "@/components/Global/header/Header";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import React from "react";
import { EventView, ObjectView } from "../view";
import useEvent from "@/apis/event";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import Loader from "@/components/Global/Loader";
import { format } from "date-fns";
import Slider from "react-slick";
import Image from "@/components/Global/Image";

const EventDetail = () => {
  const { id } = useParams() as { id: string };
  const { getEventById } = useEvent();
  const { data: event, isLoading } = useQuery({
    queryKey: ["event", id],
    queryFn: () => getEventById({ id }),
    enabled: !!id,
  });
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  if (isLoading) return <Loader />;
  console.log(event);
  return (
    <div className="container font-normal font-sh5 max-w-[1110px] pb-10  md:m-auto ml-3">
      <Header />
      <Breadcrumb className="py-5">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Discover</BreadcrumbLink>
          </BreadcrumbItem>
          <span className="text-black">/</span>
          <BreadcrumbItem>
            <BreadcrumbLink href="/events/feed">events</BreadcrumbLink>
          </BreadcrumbItem>
          <span className="text-black">/</span>
          <BreadcrumbItem>
            <BreadcrumbPage>{event?.eventName}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      {/*slider  */}

      {Number(event?.eventGallery.length) > 1 ? (
        <Slider {...settings}>
          {event?.eventGallery.map((item) => (
            <div className="w-full h-[500px]" key={item._id}>
              <Image
                src={item.image}
                alt={item.objectName}
                className="object-cover object-center w-full h-full"
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="w-full h-[500px]">
          <Image
            src={event?.image}
            alt={event?.eventName}
            className="object-cover object-center w-full h-full"
          />
        </div>
      )}
      {/* other details */}
      <div>
        <h3 className="my-5 text-6xl font-normal capitalize font-eb">
          {event?.eventName}
        </h3>
        <p>
          {format(event?.startDate ?? new Date(), "MMM d, yyyy")} - Sep 25, 2023
        </p>
        <Button variant="link" className="p-0 text-gray-900 uppercase">
          Bharti Kher
        </Button>

        {/*loaction detail  */}
        <div className="mt-5">
          <h6 className="text-lg font-normal text-gray-60">Location</h6>
          <p className="capitalize">{event?.metaData.location.join(", ")}</p>
        </div>
        {/* In collaboration with */}
        <div className="mt-5">
          <h6 className="mb-1 text-lg font-normal text-gray-60">
            In collaboration with
          </h6>
          <ul className="list-none">
            {event?.collaboration.map((item) => (
              <li key={item.name}>{item.name}</li>
            ))}
          </ul>
        </div>
        {/* desc */}
        <p className="mt-10 text-gray-60">{event?.description}</p>
        <Separator className="my-10" />
        {/* works */}
        <h3 className="font-medium text-[24px]">
          Works <sup>{event?.eventGallery.length ?? 0}</sup>
        </h3>
        <div className="flex flex-wrap gap-5 mt-10">
          {event?.eventGallery.map((item) => (
            <ObjectView key={item._id} item={item} />
          ))}
        </div>
        {/* more events like this */}
        <Separator className="my-10" />
        <h3 className="font-medium text-[24px]">More events like this</h3>
        <div className="flex flex-wrap gap-5 mt-10">
          {event?.eventGallery.map((item) => (
            <EventView key={item} item={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default EventDetail;

export const steps = {
  information: [
    "about",
    "images",
    "details",
    "location",
    "provenance",
    "components",
  ],
  documentation: [
    "objectIdentification",
    "secondaryMeasurements",
    "valuation",
    "records",
    "media",
  ],
};

import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import GlobalDialog from "@/modal/GlobalDialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useObjectContext } from "@/context/objects";

const formSchema = z
  .object({
    auctionHouse: z.string().min(1, "Auction house is required"),
    name: z.string().min(1, "Auction name is required"),
    type: z.array(z.string()).min(1, "Please select at least one auction type"),
    startDate: z.coerce
      .date()
      .min(new Date("1900-01-01"), "Date cannot be before 1900")
      .max(new Date(), "Date cannot be in the future"),
    endDate: z.coerce
      .date()
      .min(new Date("1900-01-01"), "Date cannot be before 1900")
      .max(new Date(), "Date cannot be in the future"),
    saleNumber: z.string(),
    lotNumber: z.string(),
  })
  .refine((data) => data.endDate >= data.startDate, {
    message: "End date cannot be before start date",
    path: ["endDate"], // Shows error on endDate field
  });

type FormValues = z.infer<typeof formSchema>;

const AddAuction = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { updateOrAddItem, currentObject } = useObjectContext();
  const defaultAuction = currentObject?.provenance?.auctionHistory?.find(
    (item: any) => item._id === defaultId,
  ) || {
    auctionHouse: "",
    name: "",
    type: [],
    saleNumber: "",
    lotNumber: "",
  };
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultAuction,
    mode: "onTouched",
  });

  const onSubmit = (data: FormValues) => {
    if (Object.keys(form.formState.errors).length > 0) {
      return false;
    }
    if (defaultId) {
      updateOrAddItem({
        provenance: {
          auctionHistory: [
            {
              ...data,
              _id: defaultId,
            },
          ],
        },
      });
      return;
    }
    updateOrAddItem({
      provenance: {
        auctionHistory: [data],
      },
    });
  };

  return (
    <GlobalDialog
      title="Add Auction"
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
      isError={Object.keys(form.formState.errors).length > 0}
      onCancel={() => {
        form.reset();
      }}
    >
      <Form {...form}>
        <form className="space-y-6">
          <FormField
            control={form.control}
            name="auctionHouse"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Auction house</FormLabel>
                <FormControl>
                  <Input placeholder="Enter auction house" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Auction name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter auction name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="type"
            render={() => (
              <FormItem>
                <FormLabel>Auction type</FormLabel>
                <div className="space-y-2">
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes("live")}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, "live"])
                                : field.onChange(
                                    field.value?.filter(
                                      (value) => value !== "live",
                                    ),
                                  );
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Live auction
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name="type"
                    render={({ field }) => (
                      <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                        <FormControl>
                          <Checkbox
                            checked={field.value?.includes("online")}
                            onCheckedChange={(checked) => {
                              return checked
                                ? field.onChange([...field.value, "online"])
                                : field.onChange(
                                    field.value?.filter(
                                      (value) => value !== "online",
                                    ),
                                  );
                            }}
                          />
                        </FormControl>
                        <FormLabel className="font-normal">
                          Online auction
                        </FormLabel>
                      </FormItem>
                    )}
                  />
                </div>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="startDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Start date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={`w-full pl-3 text-left font-normal ${
                            !field.value && "text-muted-foreground"
                          }`}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>DD-MM-YYYY</span>
                          )}
                          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date > new Date() || date < new Date("1900-01-01")
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="endDate"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>End date</FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={`w-full pl-3 text-left font-normal ${
                            !field.value && "text-muted-foreground"
                          }`}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>DD-MM-YYYY</span>
                          )}
                          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date > new Date() || date < new Date("1900-01-01")
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="saleNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Sale number</FormLabel>
                <FormControl>
                  <Input placeholder="Enter sale number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lotNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Lot number</FormLabel>
                <FormControl>
                  <Input placeholder="Enter lot number" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddAuction;

import React, { CSSProperties, useEffect, useState } from "react";
import ObjectType from "@/components/Boards/Object";
import EventType from "@/components/Boards/Event";
import TextType from "@/components/Boards/Text";
import LinkType from "@/components/Boards/Link";
import ArtistType from "@/components/Boards/Artist";
import BusinessType from "@/components/Boards/Business";
import FileType from "@/components/Boards/File";
import VideoType from "@/components/Boards/Video";
import WritingType from "@/components/Boards/Writing";
import { BoardItemType, FileItem, VideoItem } from "@/types/boards.type";
import ViewImgVid from "../ViewImgVid";
import CarouselDrawer from "./CaourselDrawer";
import { ItemType } from "@/hooks/useFilter";

type BoardItem = BoardItemType;

interface BoardItemsPageProps {
  boardItems: BoardItem[];
  boardId: string;
  searchQuery: string;
  selectedItemType: string;
}
interface SortableItemProps {
  item: BoardItem;
  boardId: string;
  onItemClick: (e: React.MouseEvent) => void;
}

const SortableItem: React.FC<SortableItemProps> = ({
  item,
  boardId,
  onItemClick,
}) => {
  const style: CSSProperties = {
    cursor: "pointer",
    position: "relative",
  };

  const commonProps = {
    style,
    className: "masonry-item",
    onClick: onItemClick,
  };

  switch (item.itemType) {
    case "object":
      return (
        <div {...commonProps}>
          <ObjectType
            imgSrc={item.image}
            objectName={item.objectName}
            publishedYear={item.publishedYear ?? 0}
            asignProtect={item.assignProtect}
            boardId={boardId}
            coverId={item._id}
            className="flex-shrink-0"
            itemId={item._id}
            caption={item.caption ?? ""}
            showEllipsis
            itemType={item.itemType}
            tagsList={item.tags}
          />
        </div>
      );
    case "text":
      return (
        <div {...commonProps}>
          <TextType
            title={item.title}
            summary={item.summary}
            className="flex-shrink-0"
            boardId={boardId}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            tagList={item.tags}
          />
        </div>
      );
    case "events":
      return (
        <div {...commonProps}>
          <EventType
            eventName={item.eventName}
            image={item.image}
            description={item.description}
            state={item.state}
            country={item.country}
            startDate={item.startDate}
            endDate={item.endDate}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption}
            className={""}
            coverId={item._id}
            tagList={item.tags}
          />
        </div>
      );
    case "link":
      return (
        <div {...commonProps}>
          <LinkType
            hyperLink={item.link}
            className=""
            boardId={boardId}
            itemId={item._id}
            caption={item.caption}
            tagList={item.tags}
          />
        </div>
      );
    case "artist":
      return (
        <div {...commonProps}>
          <ArtistType
            coverId={item._id}
            name={item.name}
            img={item.profilePic}
            dob={item.dob}
            className=""
            boardId={boardId}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            tagList={item.tags}
          />
        </div>
      );
    case "business":
      return (
        <div {...commonProps}>
          <BusinessType
            coverId={item._id}
            businessName={item.businessName}
            image={item.coverImage}
            description={item.description}
            businessDate={item.businessDate}
            businessLocation={item.businessLocation}
            businessLogo={item.image}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            boardId={boardId}
            tagList={item.tags}
          />
        </div>
      );
    case "video":
      return (
        <div {...commonProps}>
          <VideoType
            coverId={item._id}
            videoUrl={item.content.url}
            coverphoto={item.content.coverImage}
            title={item.content.title}
            className=""
            itemId={item._id}
            caption={item.caption ? item.caption : ""}
            boardId={boardId}
            tagList={item.tags}
            showCaption
            showEllipsis
          />
        </div>
      );
    case "writing":
      return (
        <div {...commonProps}>
          <WritingType
            title={item.content.title}
            image={item.content.coverImage}
            description={item.content.description}
            authorName={item.content.authorName}
            className={""}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption ? item.caption : ""}
            coverId={item._id}
            imgSrc={item.content.coverImage}
            tagList={item.tags}
          />
        </div>
      );
    case "file":
      return (
        <div {...commonProps}>
          <FileType
            image={item.image}
            className=""
            fileName={item.name}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption}
            coverId={item._id}
            imgSrc={item.image}
            tagList={item.tags}
          />
        </div>
      );
    default:
      return null;
  }
};

const BoardItemsSm: React.FC<BoardItemsPageProps> = ({
  boardItems,
  boardId,
  searchQuery,
  selectedItemType,
}) => {
  const [items, setItems] = useState(boardItems);
  const [videoItems, setVideoItems] = useState<VideoItem[]>([]);
  const [fileItems, setFileItems] = useState<FileItem[]>([]);
  const [activeItemIndex, setActiveItemIndex] = useState<string | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    setItems(boardItems);
  }, [boardItems]);

  useEffect(() => {
    const filteredVideos = items.filter(
      (item): item is VideoItem => item.itemType === "video"
    );
    const filteredFiles = items.filter(
      (item): item is FileItem => item.itemType === "file"
    );
    setVideoItems(filteredVideos);
    setFileItems(filteredFiles);
  }, [items]);

  const matchesSearchQuery = (item: BoardItem, query: string): boolean => {
    query = query.toLowerCase();

    switch (item.itemType) {
      case "object":
        return item.objectName?.toLowerCase().includes(query) ?? false;
      case "text":
        return item.title?.toLowerCase().includes(query) ?? false;
      case "events":
        return item.eventName?.toLowerCase().includes(query) ?? false;
      case "link":
        return item.link?.toLowerCase().includes(query) ?? false;
      case "artist":
        return item.name?.toLowerCase().includes(query) ?? false;
      case "business":
        return item.businessName?.toLowerCase().includes(query) ?? false;
      case "video":
      case "writing":
        return item.content?.title?.toLowerCase().includes(query) ?? false;
      case "file":
        return item.name?.toLowerCase().includes(query) ?? false;
      default:
        return false;
    }
  };

  const filteredItems = items.filter((item) => {
    const query = searchQuery.toLowerCase();
    const matchesQuery = matchesSearchQuery(item, query);
    const matchesItemType = selectedItemType
      ? item.itemType === selectedItemType
      : true;

    return matchesQuery && matchesItemType;
  });

  const handleItemClick = (itemId: string, itemType: ItemType) => {
    let initialIndex = -1; // Use a number for index

    if (itemType === "video") {
      initialIndex = videoItems.findIndex((item) => item._id === itemId);
    } else if (itemType === "file") {
      initialIndex = fileItems.findIndex((item) => item._id === itemId);
    }

    const initialId = itemId.toString();
    setActiveItemIndex(initialId);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <>
      <CarouselDrawer
        isOpen={isDialogOpen}
        onClose={closeDialog}
        videoItems={videoItems}
        fileItems={fileItems}
        initialId={activeItemIndex ?? ""}
      />
      <div className="masonry-layout py-5 min-h-[59vh]">
        {filteredItems.map((item, index) => (
          <SortableItem
            key={item._id}
            item={item}
            boardId={boardId}
            onItemClick={() => {
              if (item.itemType === "video" || item.itemType === "file") {
                handleItemClick(item._id, item.itemType);
              }
            }}
          />
        ))}
      </div>
    </>
  );
};

export default BoardItemsSm;

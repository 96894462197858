import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { CreateBoardParams } from "@/hooks/useFilter";

type RemoveCoverProps = {
  children: ReactNode;
  boardId: string;
};

const RemoveCover: React.FC<RemoveCoverProps> = ({ children, boardId }) => {
  const [open, setOpen] = useState(false);
  const [removing, setRemoving] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { editSingleBoard } = useBoard();
  const queryClient = useQueryClient();

  // Define the mutation
  const coverMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }),
    onSuccess: () => {
      toast.success("Cover removed successfully!", {
        id: "remove-cover",
      });
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setRemoving(false); // Stop loading
      setOpen(false); // Close dialog or drawer on success
    },
    onError: (error: Error) => {
      toast.error("Failed to remove cover. Please try again.", {
        id: "remove-cover",
      });
    },
    onMutate: () => {
      toast.loading("Removing cover...", {
        id: "remove-cover",
      });
    },
  });

  // Function to handle cover removal
  const handleCover = (coverId: string) => {
    const params: CreateBoardParams = {
      coverId,
    };

    coverMutation.mutate({
      boardId,
      params,
    });
  };

  // Handle delete action
  const handleDelete = () => {
    handleCover("");
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>
      <DialogContent className="min-w-[640px]">
        <DialogHeader className="px-5 border-[#E5E5E5] border-b-[1px] border-solid pb-5">
          <div className="flex flex-row items-center justify-between">
            <DialogTitle className=" text-gray-100 font-normal font-sh5 text-[28px]">
              Remove Cover
            </DialogTitle>
            <DialogClose asChild className="bg-transparent">
              <img className="cursor-pointer" src="/close1.svg" />
            </DialogClose>
          </div>
        </DialogHeader>
        <ProfileForm onRemove={handleDelete} removing={removing} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={() => setOpen(true)}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="text-left">
          <div className="flex flex-row items-center justify-between">
            <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
              Remove Cover
            </DrawerTitle>
            <DialogClose className="bg-transparent" asChild>
              <img className="bg-transparent" src="/close1.svg" alt="close" />
            </DialogClose>
          </div>
        </DrawerHeader>
        <ProfileForm onRemove={handleDelete} removing={removing} />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onRemove: () => void;
  removing: boolean;
};

function ProfileForm({ onRemove, removing }: ProfileFormProps) {
  return (
    <form className="grid items-start gap-4">
      <div className="px-5 font-normal text-center font-sh5 font-base text-gray-60">
        <div className="flex flex-col w-full mq450:gap-4 mq750:gap-4">
          <p>Are you sure you want to remove the Cover from this Board?</p>
          <p>This action cannot be undone.</p>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
            type="button"
            disabled={removing}
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] px-4 font-bold text-sm text-white"
          variant="default"
          type="button"
          onClick={onRemove}
          disabled={removing}
        >
          {removing ? "REMOVING..." : "REMOVE"}
        </Button>
      </div>
    </form>
  );
}

export default RemoveCover;

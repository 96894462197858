import { capitalizeWords } from "@/lib/helper";
import { cn } from "@/lib/utils";
import React from "react";
import { useLocation } from "react-router-dom";

interface PinnedBoardListProps {
  pinnedBoards: {
    id: string;
    name: string;
    icon: number;
  }[];
  imageSources: { id: number; src: string }[];
  handleBoards: (url: string) => void;
}

const PinnedBoardList: React.FC<PinnedBoardListProps> = ({
  pinnedBoards,
  imageSources,
  handleBoards,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  return (
    <>
      {pinnedBoards.map((board) => {
        const imageSource = imageSources.find(
          (image) => image.id === board.icon
        );

        return (
          <div
            onClick={() => handleBoards(`/boards/${board.id}`)}
            key={board.id}
            className="flex flex-row items-center justify-center gap-2 px-0 py-0"
          >
            <img
              className="w-6 h-6"
              src={imageSource ? imageSource.src : ""}
              alt={`Icon for ${board.name}`}
            />
            <div
              className={cn(
                "text-gray-60 max-w-[150px] truncate leading-[140%] shrink-0 whitespace-nowrap font-normal text-lg font-sh5",
                currentPath == `/boards/${board.id}` &&
                  "text-gray-900 font-medium"
              )}
            >
              {capitalizeWords(board.name)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default PinnedBoardList;

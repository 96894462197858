import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import GlobalDialog from "@/modal/GlobalDialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { X } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useObjectContext } from "@/context/objects";

const formSchema = z.object({
  image: z.string().optional(),
  name: z.string().min(1, "Exhibition name is required"),
  hostedBy: z.string().min(1, "Host is required"),
  venue: z.string().min(1, "Venue is required"),
  location: z.object({
    city: z.string().min(1, "City is required"),
    country: z.string().min(1, "Country is required"),
  }),
  startDate: z.object({
    day: z.string().min(1, "Day is required"),
    month: z.string().min(1, "Month is required"),
    year: z.string().min(1, "Year is required"),
  }),
  endDate: z.object({
    day: z.string().min(1, "Day is required"),
    month: z.string().min(1, "Month is required"),
    year: z.string().min(1, "Year is required"),
  }),
});

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
type FormValues = z.infer<typeof formSchema>;

const AddExhibition = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { updateOrAddItem, currentObject } = useObjectContext();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const defaultExhibition = currentObject?.provenance?.exhibitionHistory?.find(
    (item: any) => item._id === defaultId,
  );
  const defaultStartDate = defaultExhibition?.startDate
    ? new Date(defaultExhibition.startDate)
    : null;
  const defaultEndDate = defaultExhibition?.endDate
    ? new Date(defaultExhibition.endDate)
    : null;

  const defaultValues = defaultExhibition
    ? {
        name: defaultExhibition.name || "",
        hostedBy: defaultExhibition.hostedBy || "",
        venue: defaultExhibition.venue || "",
        location: {
          city: defaultExhibition.location.city || "",
          country: defaultExhibition.location.country || "",
        },
        startDate: {
          day: defaultStartDate
            ? String(defaultStartDate.getDate()).padStart(2, "0")
            : "",
          month: defaultStartDate ? months[defaultStartDate.getMonth()] : "",
          year: defaultStartDate ? String(defaultStartDate.getFullYear()) : "",
        },
        endDate: {
          day: defaultEndDate
            ? String(defaultEndDate.getDate()).padStart(2, "0")
            : "",
          month: defaultEndDate ? months[defaultEndDate.getMonth()] : "",
          year: defaultEndDate ? String(defaultEndDate.getFullYear()) : "",
        },
      }
    : {
        name: "",
        hostedBy: "",
        venue: "",
        location: {
          city: "",
          country: "",
        },
        startDate: { day: "", month: "", year: "" },
        endDate: { day: "", month: "", year: "" },
      };
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    defaultExhibition?.image || null,
  );
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const convertFileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const base64 = await convertFileToBase64(file);
      form.setValue("image", base64);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const base64 = await convertFileToBase64(file);
      form.setValue("image", base64);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleDeleteImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    setPreviewUrl(null);
    form.setValue("image", undefined);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onSubmit = (data: FormValues) => {
    const formattedData = {
      ...data,
      startDate: new Date(
        `${data.startDate.year}-${months.indexOf(data.startDate.month) + 1}-${
          data.startDate.day
        }`,
      ).toISOString(),
      endDate: new Date(
        `${data.endDate.year}-${months.indexOf(data.endDate.month) + 1}-${
          data.endDate.day
        }`,
      ).toISOString(),
    };
    if (defaultId) {
      updateOrAddItem({
        provenance: {
          exhibitionHistory: [
            {
              ...formattedData,
              _id: defaultId,
            },
          ],
        },
      });
      return;
    }
    updateOrAddItem({
      provenance: {
        exhibitionHistory: [formattedData],
      },
    });
    // Handle form submission
  };

  return (
    <GlobalDialog
      title="Add exhibition"
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form className="space-y-6">
          {/* Image Upload */}
          <FormField
            control={form.control}
            name="image"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Upload image</FormLabel>
                <FormControl>
                  <div
                    className="relative flex items-center justify-center w-full h-48 overflow-hidden border-2 border-gray-300 border-dashed rounded-md cursor-pointer bg-[#F6F6F6]"
                    onClick={() => fileInputRef.current?.click()}
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {previewUrl ? (
                      <>
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="object-contain w-full h-full"
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="absolute p-1 rounded-full top-2 right-2 hover:bg-red-100"
                          onClick={handleDeleteImage}
                        >
                          <X className="w-4 h-4" />
                        </Button>
                      </>
                    ) : (
                      <div className="text-center">
                        <img
                          src="/image-icon.svg"
                          alt="Upload"
                          className="w-6 h-6 mx-auto mb-2"
                        />
                        <p className="text-sm font-normal text-gray-600">
                          Drag and drop or browse files
                        </p>
                        <p className="text-xs text-gray-400">
                          Up to 50 MB, in .jpg or .png format
                        </p>
                      </div>
                    )}
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Exhibition Name */}
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Exhibition name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter exhibition name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Hosted By */}
          <FormField
            control={form.control}
            name="hostedBy"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Hosted by</FormLabel>
                <FormControl>
                  <Input placeholder="Enter host name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Venue */}
          <FormField
            control={form.control}
            name="venue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Venue</FormLabel>
                <FormControl>
                  <Input placeholder="Enter venue" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* City */}
          <FormField
            control={form.control}
            name="location.city"
            render={({ field }) => (
              <FormItem>
                <FormLabel>City</FormLabel>
                <FormControl>
                  <Input placeholder="Enter city" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Country */}
          <FormField
            control={form.control}
            name="location.country"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Country</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a country" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="us">United States</SelectItem>
                    <SelectItem value="uk">United Kingdom</SelectItem>
                    <SelectItem value="fr">France</SelectItem>
                    {/* Add more countries as needed */}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Start Date */}
          <div className="space-y-4">
            <FormLabel>Exhibition Start Date</FormLabel>
            <div className="grid grid-cols-3 gap-4">
              <FormField
                control={form.control}
                name="startDate.day"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-base font-normal">Day</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full p-5 rounded-none  h-[59px] text-base">
                          <SelectValue placeholder="Day" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(
                          (day) => (
                            <SelectItem
                              key={day}
                              value={day.toString().padStart(2, "0")}
                            >
                              {day}
                            </SelectItem>
                          ),
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="startDate.month"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-base font-normal">
                      Month
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                          <SelectValue placeholder="Month" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {months.map((month) => (
                          <SelectItem key={month} value={month}>
                            {month}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="startDate.year"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="w-full p-5 rounded-none h-[59px] text-base">
                      Year
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                          <SelectValue placeholder="Year" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Array.from(
                          { length: 20 },
                          (_, i) => new Date().getFullYear() - i,
                        ).map((year) => (
                          <SelectItem key={year} value={year.toString()}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>

          {/* End Date */}
          <div className="space-y-4">
            <FormLabel>Exhibition End Date</FormLabel>
            <div className="grid grid-cols-3 gap-4">
              <FormField
                control={form.control}
                name="endDate.day"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-base font-normal">Day</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full p-5 rounded-none  h-[59px] text-base">
                          <SelectValue placeholder="Day" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Array.from({ length: 31 }, (_, i) => i + 1).map(
                          (day) => (
                            <SelectItem
                              key={day}
                              value={day.toString().padStart(2, "0")}
                            >
                              {day}
                            </SelectItem>
                          ),
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endDate.month"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="text-base font-normal">
                      Month
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                          <SelectValue placeholder="Month" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {months.map((month) => (
                          <SelectItem key={month} value={month}>
                            {month}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="endDate.year"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className="w-full p-5 rounded-none h-[59px] text-base">
                      Year
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                          <SelectValue placeholder="Year" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {Array.from(
                          { length: 20 },
                          (_, i) => new Date().getFullYear() - i,
                        ).map((year) => (
                          <SelectItem key={year} value={year.toString()}>
                            {year}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddExhibition;

import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { UpdateItemParams } from "@/hooks/useFilter";
import { Textarea } from "@/components/ui/textarea";

const { editItem } = useBoard();

type AddCaptionLinkProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
  defalutCaption?: string;
};

const AddCaptionLink: React.FC<AddCaptionLinkProps> = ({
  children,
  boardId,
  itemId,
  defalutCaption,
}) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [caption, setCaption] = useState(defalutCaption || "");
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);
  const updateCaptionMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    UpdateItemParams
  > = useMutation({
    mutationFn: (params: UpdateItemParams) => editItem({ params }),
    onMutate: () => {
      setSaving(true);
    },
    onSuccess: (data) => {
      setSaving(false);
      toast.success(`Item updated successfully: ${data.message}`);
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setOpen(false);
    },
    onError: (error: Error) => {
      setSaving(false);
      toast.error(`Failed to update item: ${error.message}`);
    },
  });

  const handleSave = async () => {
    if (caption.trim() === "") {
      toast.error("Caption is required.");
      return;
    }
    if (caption.length >= 500) {
      toast.error("Caption must be less than 500 characters.");
      return;
    }

    const updateRequest: UpdateItemParams = {
      boardId,
      itemId,
      updatedFields: { caption },
    };

    try {
      await updateCaptionMutation.mutateAsync(updateRequest);
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error occurred while saving.");
    }
  };
  
  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>

      <DialogContent className="min-w-[969px]">
        <DialogHeader className="px-8">
          <DialogTitle className="border-[#E5E5E5] pb-4 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>Add Caption</p>
              <DialogClose asChild>
                <img src="/close1.svg" className="cursor-pointer" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>

        <ProfileForm
          className="h-full"
          onSave={handleSave}
          caption={caption}
          setCaption={setCaption}
          saving={saving}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={() => setOpen(true)}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="text-left">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            Add Caption
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm
          onSave={handleSave}
          caption={caption}
          setCaption={setCaption}
          saving={saving}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onSave: () => void;
  className?: string;
  caption: string;
  setCaption: React.Dispatch<React.SetStateAction<string>>;
  saving: boolean;
};

function ProfileForm({
  onSave,
  className,
  caption,
  setCaption,
  saving,
}: ProfileFormProps) {
  return (
    <form className={` grid items-start ${className}`}>
      <div className="font-normal text-center font-sh5 font-base text-gray-60">
        <div className="w-full px-8 pb-4">
          <Textarea
            className="w-full h-fit px-4 py-5 resize-none border-solid border-[1px] border-bdr-10 rounded-none"
            placeholder="Add caption"
            value={caption}
            onChange={(e) => setCaption(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] px-4 font-bold text-sm text-white bg-other-cta"
          variant="default"
          type="button"
          onClick={onSave}
        >
          {saving ? "SAVING..." : "SAVE"}
        </Button>
      </div>
    </form>
  );
}

export default AddCaptionLink;

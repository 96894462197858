import GlobalDialog from "@/modal/GlobalDialog";
import React from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Switch } from "@/components/ui/switch";
import { useUserContext } from "@/context/user";

// Define the form schema using Zod
const formSchema = z.object({
  institution: z
    .string()
    .min(2, "Institution name must be at least 2 characters"),
  degree: z.string().min(2, "Degree/Diploma must be at least 2 characters"),
  fieldOfStudy: z
    .string()
    .min(2, "Field of study must be at least 2 characters"),
  startDate: z.string().regex(/^\d{4}$/, "Must be a valid year"),
  endDate: z.string().regex(/^\d{4}$/, "Must be a valid year"),
  isPublic: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

const AddEducation = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { updateUser, me } = useUserContext();
  const defaultValue = me?.education?.find(
    (item: any) => item._id === defaultId,
  ) || {
    institution: "",
    degree: "",
    fieldOfStudy: "",
    startDate: "",
    endDate: "",
    isPublic: false,
  };

  // Convert startDate and endDate from ISO string to human-readable format (if they exist)
  const formattedDefaultValue = {
    ...defaultValue,
    startDate: defaultValue.startDate
      ? new Date(defaultValue.startDate).getFullYear().toString()
      : "",
    endDate: defaultValue.endDate
      ? new Date(defaultValue.endDate).getFullYear().toString()
      : "",
  };

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: formattedDefaultValue,
  });

  const onSubmit = (data: FormValues) => {
    if (defaultId) {
      updateUser.mutate({
        education: [
          {
            ...data,
            _id: defaultId,
          },
        ],
      });
      return;
    }

    updateUser.mutate({
      education: [data],
    });
  };

  return (
    <GlobalDialog
      title="Add Education"
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="max-h-[80vh] overflow-y-scroll w-full"
        >
          {/* Institution Field */}
          <FormField
            control={form.control}
            name="institution"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Name of institution
                </FormLabel>
                <FormControl>
                  <Input placeholder="KC College" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Degree Field */}
          <FormField
            control={form.control}
            name="degree"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Degree/Diploma
                </FormLabel>
                <FormControl>
                  <Input placeholder="Degree in Finance" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Field of Study Field */}
          <FormField
            control={form.control}
            name="fieldOfStudy"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Field of study
                </FormLabel>
                <FormControl>
                  <Input placeholder="Accounting" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* Start Year Field */}
          <FormField
            control={form.control}
            name="startDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Start year
                </FormLabel>
                <FormControl>
                  <Input placeholder="YYYY" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="endDate"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  End year
                </FormLabel>
                <FormControl>
                  <Input placeholder="YYYY" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Public Visibility Switch */}
          <FormField
            control={form.control}
            name="isPublic"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row items-center justify-between -mx-[25px] border-solid border-bdr-10 border-t pt-[22px] px-[25px]">
                  <div className="space-y-0.5 ">
                    <FormLabel className="text-base font-normal">
                      Publicly visible
                    </FormLabel>
                  </div>
                  <div className="">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </div>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddEducation;

import useAsignProtect from "@/apis/protect";
import Header from "@/components/Global/header/Header";
import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useObjectContext } from "@/context/objects";
import { useUserContext } from "@/context/user";
import useMediaQuery from "@/hooks/use-media-query";
import { useQuery } from "@tanstack/react-query";
import { Loader } from "lucide-react";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const RequestSucces = () => {
  const { currentObject } = useObjectContext();
  const { me } = useUserContext();
  const { getAsignRequestByObjectId } = useAsignProtect();
  const [searchParams] = useSearchParams();
  const objectId = searchParams.get("oi");
  const { data: apData, isLoading } = useQuery({
    queryKey: ["AP", objectId],
    queryFn: () => getAsignRequestByObjectId(objectId as string),
  });
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const images = [
    {
      src: "https://picsum.photos/300/200",
      title: "Moon (Quiet Yellow Water)",
    },
    {
      src: "https://picsum.photos/200/200",
      title: "The lips told a thousand l...",
    },
    {
      src: "https://picsum.photos/200/200",
      title: "Moon (Quiet Yellow Water)",
    },
  ];
  if (isLoading) {
    return <Loader />;
  }
  // If the screen is mobile, we render only 2 images
  const visibleImages = isDesktop ? images : images.slice(0, 2);
  return (
    <>
      <Header />
      <div className="flex flex-col items-center justify-center min-h-screen  py-8 bg-[#F6F6F6] font-sh5 font-normal">
        <div className="mb-10 text-center mq750:mb-6">
          <h1 className="mb-3 text-[28px] font-normal mq750:text-5xl">
            Thank you, {me?.name}
          </h1>
          <p className="text-lg mq750:text-sm">
            We have successfully received your request for
            <br />
            Asign Protect+.
          </p>
        </div>

        <div className="w-full max-w-3xl overflow-hidden bg-white rounded-lg shadow-lg">
          <div className="p-10 mq750:py-8 mq750:px-5">
            <div className="flex flex-col items-center mq750:mb-6 mb-[32px]">
              <p className="mq750:mb-2 mq750:text-[15px] text-base mb-4">
                <span>Request ID</span> <span># {apData?.reqId}</span>
              </p>
              <p className="mq750:text-sm text-gray-60 text-[20px]">
                <span className="text-black">Date of request</span>{" "}
                <span className="text-base mq750:text-xs">
                  {new Date(apData?.createdAt).toLocaleDateString()}
                </span>
              </p>
              <p className="mq750:mt-2 mq750:text-sm text-gray-60 text-[20px] mt-4">
                <span className="text-black ">Objects to be labelled</span>{" "}
                <span className="text-base mq750:text-xs">1</span>
              </p>
            </div>

            <div className="grid grid-cols-4 gap-x-4 mq750:grid-cols-2">
              <div className="flex flex-col items-center">
                <div className="bg-[#EEEEEA] w-full h-full mb-3">
                  <img
                    src={currentObject.image}
                    alt={currentObject.objectName}
                    className="object-contain w-full aspect-square"
                  />
                </div>
                <p className={`text-sm text-center truncate`}>
                  {currentObject.objectName}
                </p>
              </div>

              {/* <div className="flex flex-col items-center justify-center w-full aspect-square bg-[#EEEEEA] mq750:hidden">
                <span className="underline">+37</span>
              </div> */}
            </div>
            <Separator className="mq750:mt-6 mb-[29px] mt-10" />
            <div className="flex justify-center">
              <Button
                variant="link"
                className="h-auto p-0 text-[#303030] underline hover:text-gray-900 text-sm font-medium"
                onClick={() => navigate("/catalog/detail")}
              >
                BACK TO CATALOGUE
              </Button>
            </div>
          </div>
        </div>

        <div className="mt-10 text-center">
          <p className="mb-6 text-sm mq750:mb-4 text-gray-60">
            Have questions?{" "}
            <a href="#" className="ml-2 text-black underline">
              SEE KNOWLEDGE CENTRE
            </a>
          </p>
          <p className="w-full text-sm text-center text-gray-60">
            <a href="#" className="hover:underline">
              Terms & Conditions
            </a>{" "}
            |
            <a href="#" className="hover:underline">
              {" "}
              Privacy Policy
            </a>{" "}
            |
            <a href="#" className="hover:underline">
              {" "}
              Contact us
            </a>{" "}
            | © 2024 Democraft Technologies Private Limited
          </p>
        </div>
      </div>
    </>
  );
};

export default RequestSucces;

import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import GlobalDialog from "@/modal/GlobalDialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useObjectContext } from "@/context/objects";

const formSchema = z.object({
  measurementType: z.string().min(1, "Measurement type is required"),
  shape: z.string().min(1, "Shape is required"),
  dimensionsType: z.enum(["cm", "in"]),
  height: z.coerce.number(),
  width: z.coerce.number(),
  depth: z.coerce.number(),
  diameter: z.coerce.number(),
  weightType: z.enum(["kg", "lbs"]),
  weight: z.coerce.number(),
});

type FormValues = z.infer<typeof formSchema>;

export default function AddSecondaryMeasurement({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) {
  const { updateOrAddItem, currentObject } = useObjectContext();
  const defaultSecondaryMeasurement =
    currentObject?.document?.secondaryMeasurements?.find(
      (item: any) => item._id === defaultId,
    ) || {
      measurementType: "",
      shape: "",
      dimensionsType: "cm",
      weightType: "kg",
    };

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultSecondaryMeasurement,
  });

  function onSubmit(data: FormValues) {
    if (defaultId) {
      updateOrAddItem({
        document: {
          secondaryMeasurements: [
            {
              ...data,
              _id: defaultId,
            },
          ],
        },
      });
      return;
    }
    updateOrAddItem({
      document: {
        secondaryMeasurements: [data],
      },
    });
  }

  return (
    <GlobalDialog
      title="Add Secondary Measurement"
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
      onCancel={form.reset}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            <FormField
              control={form.control}
              name="measurementType"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Measurement type</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select measurement type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="overall">Overall</SelectItem>
                      <SelectItem value="frame">Frame</SelectItem>
                      <SelectItem value="base">Base</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="shape"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Shape</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a shape" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="rectangular">Rectangular</SelectItem>
                      <SelectItem value="circular">Circular</SelectItem>
                      <SelectItem value="irregular">Irregular</SelectItem>
                    </SelectContent>
                  </Select>
                </FormItem>
              )}
            />
          </div>

          <div className="flex items-center space-x-2">
            <FormLabel>Dimensions</FormLabel>
            <FormField
              control={form.control}
              name="dimensionsType"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      checked={field.value === "in"}
                      onCheckedChange={(checked) =>
                        field.onChange(checked ? "in" : "cm")
                      }
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <span>{form.watch("dimensionsType") === "cm" ? "cm" : "in"}</span>
          </div>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
            {["height", "width", "depth", "diameter"].map((dimension) => (
              <FormField
                key={dimension}
                control={form.control}
                name={dimension as "height" | "width" | "depth" | "diameter"}
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {dimension.charAt(0).toUpperCase() + dimension.slice(1)}
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder={`Add ${dimension} in ${form.watch(
                          "dimensionsType",
                        )}`}
                        type="number"
                        {...field}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            ))}
          </div>

          <div className="flex items-center space-x-2">
            <FormLabel>Weight</FormLabel>
            <FormField
              control={form.control}
              name="weightType"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Switch
                      checked={field.value === "lbs"}
                      onCheckedChange={(checked) =>
                        field.onChange(checked ? "lbs" : "kg")
                      }
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <span>{form.watch("weightType") === "kg" ? "kg" : "lbs"}</span>
          </div>

          <FormField
            control={form.control}
            name="weight"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    placeholder={`Add weight in ${form.watch("weightType")}`}
                    type="number"
                    {...field}
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
}

import React, { ReactNode, useEffect, useRef, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { UpdateItemParams } from "@/hooks/useFilter";

type AddCaptionProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
  captionImg: string;
  defalutCaption?: string;
};

const AddCaption: React.FC<AddCaptionProps> = ({
  children,
  boardId,
  itemId,
  captionImg,
  defalutCaption,
}) => {
  const [open, setOpen] = useState(false);
  const { editItem } = useBoard();
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [caption, setCaption] = useState(defalutCaption || ""); // Initialize with defaultCaption
  const [saving, setSaving] = useState(false);
  const queryClient = useQueryClient();

  // Mutation setup for updating the caption
  const updateCaptionMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    UpdateItemParams
  > = useMutation({
    mutationFn: (params: UpdateItemParams) => editItem({ params }),
    onMutate: () => {
      setSaving(true);
    },
    onSuccess: (data) => {
      setSaving(false);
      toast.success(`Item updated successfully: ${data.message}`);
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setOpen(false);
    },
    onError: (error: Error) => {
      setSaving(false);
      toast.error(`Failed to update item: ${error.message}`);
    },
  });

  const handleSave = async () => {
    if (caption.trim() === "") {
      toast.error("Caption is required.");
      return;
    }
    if (caption.length >= 500) {
      toast.error("Caption must be less than 500 characters.");
      return;
    }

    const updateRequest: UpdateItemParams = {
      boardId,
      itemId,
      updatedFields: { caption },
    };

    try {
      await updateCaptionMutation.mutateAsync(updateRequest);
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error occurred while saving.");
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(false);
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger onClick={handleClick} className="bg-transparent" asChild>
        {children}
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="sm:max-w-[900px] p-0 z-[100] font-sh5 font-normal gap-y-0 w-full"
      >
        <div className="flex">
          <div className="relative w-1/3 px-[32px]">
            <img
              src={captionImg}
              alt="Artwork"
              className="inset-0 object-contain w-full h-full "
            />
          </div>
          <div className="flex flex-col w-2/3 border-l border-solid border-bdr-10">
            <DialogHeader className="px-[32px] border-b border-solid border-bdr-10 py-5">
              <div className="flex items-center justify-between">
                <DialogTitle className="text-[28px] font-normal">
                  Add Caption
                </DialogTitle>
                <button onClick={handleClick}>
                  <img
                    className="cursor-pointer w-[24px] h-[24px]"
                    src="/close1.svg"
                    alt="close"
                  />
                </button>
              </div>
            </DialogHeader>
            <div className="flex-grow px-[32px] py-5  overflow-y-scroll">
              <textarea
                value={caption}
                onChange={(e) => {
                  setCaption(e.target.value);
                  e.stopPropagation();
                }}
                placeholder="Michelangelo - France - early 1900s: Acrylic liner and enamel on birch on an acrylic"
                className="w-full  text-sm font-normal border border-solid resize-none font-sh5 border-bdr-10 font-base h-[261px] p-[16px_20px] text-black "
                maxLength={500}
              />
              <p className="text-sm text-gray-500">
                {500 - caption.length} characters left.
              </p>
            </div>
            <div className="flex flex-row items-center justify-between w-full p-[20px_32px] bg-[#F2F2F2]">
              <Button
                className="cursor-pointer h-6 font-medium text-xs font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none px-0 hover:no-underline"
                type="button"
                variant="link"
                onClick={handleClick}
              >
                CANCEL
              </Button>
              <Button
                onClick={handleSave}
                className="rounded-[50px]  h-[42PX] mq750:px-5 font-medium text-sm text-white font-sh5 px-[36px] py-2"
                variant="default"
                type="button"
              >
                SAVE
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={handleClick}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent
        onClick={(e) => e.stopPropagation()}
        className="w-full rounded-none h-2/3"
      >
        <DrawerHeader className="px-5 text-left">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            <div className="flex flex-row items-center justify-between">
              {defalutCaption ? "Edit Caption" : "Add Caption"}
              <DialogClose className="bg-transparent">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>{" "}
        <ProfileForm
          key={defalutCaption} // Ensure the form resets when defaultCaption changes
          onSave={handleSave}
          caption={caption}
          setCaption={setCaption}
          captionImg={captionImg}
          saving={saving}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onSave: () => void; // Accepts a MouseEvent parameter
  className?: string;
  caption: string; // Caption state
  captionImg: string; // Caption state
  saving: boolean;
  setCaption: React.Dispatch<React.SetStateAction<string>>; // Setter for caption
};

function ProfileForm({
  onSave,
  caption,
  setCaption,
  captionImg,
  saving,
}: ProfileFormProps) {
  const maxCharacters = 500;

  const charactersLeft = maxCharacters - caption.length;
  const textAreaRef = useRef<HTMLTextAreaElement>(null); // Ref for the textarea
  const handleCaptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newCaption = e.target.value;
    if (newCaption.length <= maxCharacters) {
      setCaption(newCaption);
    }
  };
  // Ensure cursor stays at the end of the text
  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.selectionStart = textAreaRef.current.value.length;
      textAreaRef.current.selectionEnd = textAreaRef.current.value.length;
    }
  }, [caption]); // Run the effect whenever `caption` changes
  return (
    <form
      className={`mq450:overflow-auto mq450:w-full mq750:w-full mq750:overflow-auto h-full flex w-full flex-col justify-between`}
    >
      <div className="flex flex-col ">
        <div className="flex flex-row items-center justify-between h-full mq750:flex-col mq450:flex-col">
          <div className="w-1/3 pb-4 mq450:w-full mq750:scale px-11">
            <img className="flex w-full" src={captionImg} alt={captionImg} />
          </div>
          <div className="flex flex-col items-start justify-start w-2/3 h-full mq450:w-full mq750:w-full">
            <div className="w-full h-full px-8 mq450:pb-3 mq750:pb-3 mq450:px-5 mq750:px-5 mq450:flex mq450:flex-grow mq750:flex mq750:flex-grow mq750:justify-center">
              <textarea
                className="font-sh5 resize-none w-full text-sm font-normal h-full font-base text-gray-60 px-10 mq750:py-5 mq750:w-[70vw] mq750:px-5 mq750:min-h-[20px] mq450:py-5 mq450:px-5 mq450:min-h-[20px] border-solid border-[1px] border-bdr-10 rounded-none py-5"
                placeholder="add caption"
                value={caption}
                onChange={handleCaptionChange}
                ref={textAreaRef}
              />
            </div>
            <div className="w-full px-8 pt-2 pb-5 text-sm font-normal text-left text-gray-500 mq450:px-5 mq750:px-5 font-sh5">
              {charactersLeft} characters left
            </div>
          </div>
        </div>

        <div className="flex justify-end ">
          <div className="flex flex-row justify-between w-2/3 mq450:py-4 mq450:w-full mq750:w-full items-center px-5 py-8 bg-[#F2F2F2]">
            <DialogClose asChild>
              <button
                disabled={saving}
                className="cursor-pointer h-6 min-w-12 font-medium text-sm relative font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none"
                type="button"
              >
                CANCEL
              </button>
            </DialogClose>
            <Button
              disabled={saving}
              className="bg-other-cta rounded-[50px] min-w-[130px] h-[42px] px-4 font-medium text-sm text-white font-sh5"
              variant="default"
              type="button"
              onClick={onSave}
            >
              {saving ? "SAVING..." : "SAVE"}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default AddCaption;

import React from "react";
import { WritingItem } from "@/types/boards.type";
import { Button } from "@/components/ui/button";
import AddItemDropDown from "../AddItemDropDown";
import Image from "@/components/Global/Image";

interface WritingProps {
  items: WritingItem[];
}

const Writing: React.FC<WritingProps> = ({ items }) => {
  console.log("Objects Component Items:", items);

  return (
    <div>
      {items.length === 0 ? (
        <p>No Events found.</p>
      ) : (
        items.map((item) => (
          <div
            key={item._id}
            className={` h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 group `}
          >
            <Image
              className="object-cover w-full h-full"
              src={item.content.coverImage}
            />
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
            <AddItemDropDown
              itemID={item._id}
              boardID={""}
              itemType={"writing"}
            >
              <span className="absolute flex invisible gap-1 p-2 text-sm transition-all duration-300 ease-in-out scale-95 bg-white rounded-full opacity-0 cursor-pointer top-4 text-other-cta right-4 group-hover:opacity-100 group-hover:scale-100 group-hover:visible">
                <div className="flex flex-row items-center gap-1">
                  <img className="w-6 h-6" src="/label.svg" alt="label" />
                </div>
              </span>
            </AddItemDropDown>
            <div>
              {" "}
              <div className="absolute bottom-0 left-0 p-4">
                <p className="text-xs font-normal text-white fobt-sh5">
                  {item.content.authorName}
                </p>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Writing;

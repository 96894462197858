import React, {
  ReactNode,
  useState,
  KeyboardEvent,
  useRef,
  useEffect,
} from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { cn } from "@/lib/utils";
import { debounce, useMediaQuery } from "@mui/material";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import toast from "react-hot-toast";
import { UpdateItemParams } from "@/hooks/useFilter";
import { GetSingleBoardResponse } from "@/types/boards.type";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";

type AddTagProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
  itemType: string;
  tagList: string[];
};

const AddTag: React.FC<AddTagProps> = ({
  children,
  boardId,
  itemType,
  itemId,
  tagList = [],
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState<string[]>(tagList); // Initialize with unique tags
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const inputRef = useRef<HTMLDivElement>(null);
  const { getTag, editItem } = useBoard();

  const fetchTags = async (query: string) => {
    if (!query.trim()) {
      setSuggestions([]);
      return;
    }
    setLoading(true);
    try {
      const response = await getTag({ sectionType: itemType });
      const filteredTags = response.data.data
        .filter((tag) => tag.tag.toLowerCase().includes(query.toLowerCase()))
        .map((tag) => tag.tag);
      setSuggestions(filteredTags);
    } catch (error) {
      console.error("Error fetching tags:", error);
    } finally {
      setLoading(false);
    }
  };

  const debouncedFetchTags = debounce(fetchTags, 300);

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newTag = tagInput.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags((prevTags) => [...prevTags, newTag]);
        setTagInput("");
        setSuggestions([]);
      }
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLDivElement>) => {
    const value = e.currentTarget.textContent || "";
    setTagInput(value);
    debouncedFetchTags(value);
  };

  const removeTag = (index: number) => {
    setTags((prevTags) => prevTags.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const queryClient = useQueryClient();

  const updateCaptionMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    UpdateItemParams
  > = useMutation({
    mutationFn: (params: UpdateItemParams) => editItem({ params }),
    onSuccess: (data) => {
      toast.success("Item updated successfully", {
        id: "updating-item",
      });
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
      setOpen(false);
    },
    onError: (error: Error) => {
      toast.error(`Failed to update item: ${error.message}`, {
        id: "updating-item",
      });
    },
    onMutate: () => {
      toast.loading("updating the item", {
        id: "updating-item",
      });
    },
  });

  const handleSave = async () => {
    const updateRequest: UpdateItemParams = {
      boardId,
      itemId,
      updatedFields: {
        tags,
      },
    };

    try {
      await updateCaptionMutation.mutateAsync(updateRequest);
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error occurred while saving.");
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={handleClick}
      >
        {children}
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="min-w-[640px] "
      >
        <DialogHeader>
          <DialogTitle className="px-8 border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Add Tags</p>
              <div className="flex flex-row items-center ">
                <DialogClose asChild>
                  <img
                    src="/close1.svg"
                    alt="Close"
                    className="cursor-pointer"
                  />
                </DialogClose>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm
          saving={saving}
          handleSave={handleSave}
          setSuggestions={setSuggestions}
          title=""
          setTitle={() => {}}
          text={tagInput}
          setText={setTagInput}
          boardId={boardId}
          tags={tags} // Only render the unique tags, not tagList
          setTags={setTags}
          suggestions={suggestions}
          loading={loading}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          removeTag={removeTag}
          fetchTags={debouncedFetchTags}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={handleClick}
      >
        {children}
      </DialogTrigger>
      <DrawerContent
        onClick={(e) => e.stopPropagation()}
        className="rounded-none"
      >
        <DrawerHeader>
          <DrawerTitle className="border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Add Tags</p>
              <div className="flex flex-row items-center gap-4">
                <DialogClose asChild>
                  <img src="/close1.svg" alt="Close" />
                </DialogClose>
              </div>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm
          saving={saving}
          handleSave={handleSave}
          text={tagInput}
          setText={setTagInput}
          tags={tags} // Only render the unique tags
          setTags={setTags}
          suggestions={suggestions}
          setSuggestions={setSuggestions}
          loading={loading}
          handleInputChange={handleInputChange}
          handleKeyDown={handleKeyDown}
          removeTag={removeTag}
          fetchTags={debouncedFetchTags}
          boardId={boardId}
          title=""
          setTitle={() => {}}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  title: string;
  setTitle: (title: string) => void;
  text: string;
  setText: (text: string) => void;
  tags: string[];
  setTags: (tags: string[]) => void;
  suggestions: string[];
  setSuggestions: (suggestions: string[]) => void;
  loading: boolean;
  handleInputChange: (e: React.FormEvent<HTMLDivElement>) => void;
  handleKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  removeTag: (index: number) => void;
  fetchTags: (query: string) => void;
  handleSave: () => void;
  saving: boolean;
};

function ProfileForm({
  className,
  text,
  setText,
  tags,
  setTags,
  suggestions,
  setSuggestions,
  loading,
  handleInputChange,
  removeTag,
  handleSave,
  saving,
}: ProfileFormProps) {
  const inputRef = useRef<HTMLDivElement>(null);

  const setCaretToEnd = (element: HTMLElement) => {
    const range = document.createRange();
    const selection = window.getSelection();
    range.selectNodeContents(element);
    range.collapse(false);
    selection?.removeAllRanges();
    selection?.addRange(range);
    element.focus();
  };

  const handleKeyDownEvent = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      const newTag = text.trim();
      if (newTag && !tags.includes(newTag)) {
        setTags([...tags, newTag]);
        setText("");
        setSuggestions([]);
      }
    } else if (event.key === "Backspace" && text === "") {
      event.preventDefault();
      if (tags.length > 0) {
        const updatedTags = [...tags];
        updatedTags.pop();
        setTags(updatedTags);
      }
    }
  };

  const focusInput = () => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus to the contentEditable div
    }
  };
  useEffect(() => {
    if (inputRef.current) {
      setCaretToEnd(inputRef.current);
    }
  }, [text]);

  return (
    <form className={cn("grid items-center gap-5 mq450:gap-8")}>
      <div className="px-8 mq450:px-4">
        <div
          className="relative flex flex-row border-solid border-[1px] py-4 h-[156px] items-start"
          onClick={focusInput}
        >
          {/* tag list */}
          <div className="flex flex-wrap items-center gap-2 pl-3">
            {/* Display newly added tags */}
            {tags.map((tag: string, index) => (
              <span
                key={index}
                className="inline-flex items-center px-2 py-1 text-sm font-normal text-black bg-bg-5 font-sh5"
              >
                {tag}
                <button
                  className="w-4 h-4 ml-1 bg-transparent hover:text-gray-700 focus:outline-none"
                  onClick={() => removeTag(index)}
                >
                  <img src="/close1.svg" className="w-4 h-4" />
                </button>
              </span>
            ))}

            <div className="relative">
              <div
                ref={inputRef}
                className="px-8 rounded-none border-none font-sh5 font-medium placeholder:text-[#979797] text-base overflow-y-auto bg-white border border-gray-200 focus:outline-none"
                contentEditable
                onInput={handleInputChange}
                onKeyDown={handleKeyDownEvent}
                dangerouslySetInnerHTML={{ __html: text }} // Use innerHTML to set contentEditable div's content
              ></div>
              {suggestions.length > 0 && (
                <div className="absolute z-50 min-w-[200px] border-gainsboro bg-white border p-2 text-left shadow-lg mt-1">
                  {suggestions
                    .filter((suggestion) => !tags.includes(suggestion.trim())) // Filter out already selected tags
                    .map((suggestion, index) => (
                      <div
                        key={index}
                        className="px-4 py-2 text-base font-normal text-left cursor-pointer hover:bg-gray-200 text-gray-60 font-sh5"
                        onClick={() => {
                          const trimmedSuggestion = suggestion.trim();
                          if (!tags.includes(trimmedSuggestion)) {
                            setTags([...tags, trimmedSuggestion]);
                          }
                          setText("");
                          setSuggestions([]);
                          if (inputRef.current) {
                            inputRef.current.innerText = "";
                            setCaretToEnd(inputRef.current);
                          }
                        }}
                      >
                        {suggestion}
                      </div>
                    ))}
                  {loading && (
                    <div className="px-4 py-2 text-gray-500">Loading...</div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          disabled={saving}
          className="rounded-[50px] px-4 font-bold text-sm text-white bg-other-cta"
          variant="default"
          type="button"
          onClick={handleSave}
        >
          {saving ? "SAVING..." : "SAVE"}
        </Button>
      </div>
    </form>
  );
}

export default AddTag;

import React, { useState } from "react";
import {
  CalendarIcon,
  FileText,
  MoreVertical,
  Plus,
  Upload,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import AddDocument from "./modal/AddDocument";
import { ActionDropDown } from ".";
import { useObjectContext } from "@/context/objects";
import { useFormContext } from "react-hook-form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Input } from "@/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";

const Records = () => {
  const { currentObject } = useObjectContext();
  const [uploadedFileName, setUploadedFileName] = useState<string>("");
  const form = useFormContext();
  const handleTypeChange = (value: "loan" | "borrowed" | "no") => {
    form.setValue("document.records.loans.type", value);
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        form.setValue("document.records.loans.file", base64);
        setUploadedFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="font-normal font-sh5">
      {" "}
      <div className="w-full mx-auto">
        <h2 className="mb-4 text-xl font-normal">Documents</h2>
        <div className="space-y-4">
          {currentObject?.document?.records?.document?.length === 0 ? (
            <p className="text-sm text-gray-500">
              You have not added any document yet.
            </p>
          ) : (
            <ul className="space-y-2">
              {currentObject?.document?.records?.document?.map(
                (component: any, index: number) => (
                  <li
                    key={component.id}
                    className="flex items-center justify-between p-2 bg-white rounded-lg shadow"
                  >
                    <div className="flex items-center space-x-3">
                      <FileText className="w-5 h-5 text-gray-500" />
                      <div className="">
                        <p className="font-medium">{component.name}</p>
                        <p className="text-sm text-gray-500">
                          {component.type}
                        </p>
                      </div>
                    </div>
                    <ActionDropDown
                      id={component._id}
                      dataKey="document.records.document"
                    />
                  </li>
                ),
              )}
            </ul>
          )}
          <AddDocument>
            <Button variant="link" className="h-auto p-0 mt-2">
              + ADD DOCUMENT
            </Button>
          </AddDocument>
        </div>
      </div>
      <div className="mt-5 space-y-4">
        <h2 className="text-lg font-medium">Loans and borrowings</h2>
        <FormField
          control={form.control}
          name="document.records.loans.type"
          defaultValue="no"
          render={({ field }) => (
            <FormItem className="">
              <FormLabel className="text-base font-normal">
                Is this object loaned or borrowed?
              </FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={(value) =>
                    handleTypeChange(value as "loan" | "borrowed" | "no")
                  }
                  value={field.value}
                  className="flex flex-col "
                >
                  <FormItem className="flex items-center p-4 space-x-3 space-y-0 border border-solid border-bdr-10">
                    <FormControl>
                      <RadioGroupItem
                        value="loan"
                        className="border border-gray-300 "
                      />
                    </FormControl>
                    <FormLabel className="font-normal">
                      THIS OBJECT IS CURRENTLY ON LOAN
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center p-4 space-x-3 space-y-0 border border-solid border-bdr-10">
                    <FormControl>
                      <RadioGroupItem
                        value="borrowed"
                        className="border border-gray-300"
                      />
                    </FormControl>
                    <FormLabel className="font-normal">
                      THIS OBJECT HAS BEEN BORROWED
                    </FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center p-4 space-x-3 space-y-0 border border-solid border-bdr-10">
                    <FormControl>
                      <RadioGroupItem
                        value="no"
                        className="border border-gray-300"
                      />
                    </FormControl>
                    <FormLabel className="font-normal">NO</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
            </FormItem>
          )}
        />
      </div>
      {form.watch("document.records.loans.type") !== "no" && (
        <>
          <div className="my-5 space-y-4">
            <FormLabel className="text-base font-normal">
              Loan contract document
            </FormLabel>
            <div className="flex items-center justify-center w-full">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
              >
                {!uploadedFileName && (
                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                    <Upload className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      Up to 50 MB, in docx or pdf format
                    </p>
                  </div>
                )}
                {uploadedFileName && (
                  <div>
                    <p>{uploadedFileName}</p>
                  </div>
                )}
                <input
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                  accept=".docx,.pdf"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>

          <FormField
            control={form.control}
            name="document.records.loans.name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal ">
                  Borrower's name
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Enter borrower's name"
                    {...field}
                    className="border-gray-300"
                  />
                </FormControl>
              </FormItem>
            )}
          />

          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="document.records.loans.startDate"
              render={({ field }) => (
                <FormItem className="flex flex-col mt-5">
                  <FormLabel className="text-base font-normal">
                    Loan start date
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full pl-3 text-left font-normal h-[54px] rounded-none",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>DD/MM/YYYY</span>
                          )}
                          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) =>
                          date > new Date() || date < new Date("1900-01-01")
                        }
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="document.records.loans.endDate"
              render={({ field }) => (
                <FormItem className="flex flex-col mt-5">
                  <FormLabel className="text-base font-normal">
                    Loan end date
                  </FormLabel>
                  <Popover>
                    <PopoverTrigger asChild>
                      <FormControl>
                        <Button
                          variant={"outline"}
                          className={cn(
                            "w-full pl-3 text-left font-normal h-[54px] rounded-none",
                            !field.value && "text-muted-foreground",
                          )}
                        >
                          {field.value ? (
                            format(field.value, "PPP")
                          ) : (
                            <span>DD/MM/YYYY</span>
                          )}
                          <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                        </Button>
                      </FormControl>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0" align="start">
                      <Calendar
                        mode="single"
                        selected={field.value}
                        onSelect={field.onChange}
                        disabled={(date) => date < new Date("1900-01-01")}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </FormItem>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Records;

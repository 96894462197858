import artist from "@/apis/artist";
import Header from "@/components/Global/header/Header";
import { FollowArtistButton } from "@/components/Templates/Artists/BigBanner";
import { Avatar, AvatarImage, AvatarFallback } from "@radix-ui/react-avatar";
import { Camera, Edit2, MoreHorizontal } from "lucide-react";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import UploadImage from "./UploadImage";
import { MobileHeader } from "@/components/Global/header/MobileHeader";
import { Separator } from "@/components/ui/separator";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";
import { Switch } from "@/components/ui/switch";
import AddBio from "./AddBio";
import EditProfile from "./EditProfile";
import AddRepresentation from "./AddRepresentation";
import AddEducation from "./AddEducation";
import AddEvent from "./AddEvent";
import AddRecognition from "./AddRecognition";
import AddPress from "./AddPress";
import AddPublication from "./AddPublication";
import AddNotableCollection from "./AddNotableCollection";
import { useUserContext } from "@/context/user";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import Global from "@/modal/Global";
const sideBar = [
  { label: "Biography" },
  { label: "Catalogue rasionné" },
  { label: "Representation" },
  { label: "Education" },
  { label: "Events" },
  { label: "Recognition" },
  { label: "Press" },
  { label: "Notable collections" },
  { label: "Publications" },
];
const Profile = () => {
  const [currentSection, setCurrentSection] = useState<string>("");
  const { me, isLoadingMe } = useUserContext();
  const refs = sideBar.reduce(
    (acc, value) => {
      acc[value.label] = useRef<HTMLDivElement>(null);
      return acc;
    },
    {} as { [key: string]: React.RefObject<HTMLDivElement> },
  );

  const scrollToSection = (label: string) => {
    const ref = refs[label]?.current;

    if (ref) {
      setCurrentSection(label);
      ref.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: "-10px",
      threshold: 0.7,
    };

    const observerCallback = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setCurrentSection(entry.target.getAttribute("data-section") || "");
        }
      });
    };

    const observer = new IntersectionObserver(
      observerCallback,
      observerOptions,
    );

    Object.values(refs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      observer.disconnect();
    };
  }, [refs]);

  if (isLoadingMe) {
    return <div>Loading...</div>;
  }
  return (
    <div className="container font-normal font-sh5">
      <Header />
      <div className="fixed z-10 w-full top-15 mb-15">
        <MobileHeader
          data={sideBar}
          active={currentSection}
          onClick={scrollToSection}
        />
      </div>
      <TopSection />
      <div className="relative flex p-5 mt-5 lg:px-[48px]">
        <div className="hidden lg:w-1/3 md:block">
          <div className="overflow-y-scroll text-gray-60">
            {sideBar.map((item) => (
              <div
                role="button"
                key={item.label}
                tabIndex={0}
                className={cn(
                  "mb-[20px] cursor-pointer whitespace-nowrap hover:text-gray-100",
                  currentSection === item.label && "text-gray-100",
                )}
                onClick={() => scrollToSection(item.label)}
              >
                {item.label}
              </div>
            ))}
          </div>
        </div>
        <div className="lg:w-2/3 lg:max-h-[500px] bottom-[100px] sm:bottom-0 overflow-y-scroll">
          <div ref={refs["Biography"]} data-section="Biography">
            <h3 className="font-medium text-[24px] flex justify-between items-center">
              Biography{" "}
              <AddBio>
                <Button variant="link" className="h-auto p-0 mt-5 gap-x-1">
                  <img src="/edit.svg" alt="" />
                  EDIT
                </Button>
              </AddBio>
            </h3>
            {me?.biography && (
              <>
                {" "}
                <p className="my-5">{me?.biography}</p>
                <Button variant="outline" className="rounded-full">
                  READ MORE
                </Button>
              </>
            )}
            {me?.bigraphy === "" && (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Tell us about the person behind the art.
                </p>
                <AddBio>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddBio>
              </div>
            )}
          </div>
          <div
            ref={refs["Representation"]}
            data-section="Representation"
            className="mt-[48px]"
          >
            <h3 className="font-medium text-[24px]">Representation</h3>
            <div
              className={cn(
                "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
              )}
            >
              <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
              <p className="mb-5 text-center text-gray-60">
                Galleries that represent you.
              </p>
              <AddRepresentation>
                <Button
                  variant="link"
                  className="h-auto p-0 text-sm font-medium"
                >
                  + ADD
                </Button>
              </AddRepresentation>
            </div>
            {/* <div>
              <Representation data={artist?.represents || []} />
            </div> */}
          </div>
          <div
            ref={refs["Education"]}
            data-section="Education"
            className="mt-[48px]"
          >
            <h3 className="font-medium text-[24px] flex justify-between items-center ">
              Education{" "}
              <AddEducation>
                <Button variant="link" className="h-auto p-0 mt-5 gap-x-1">
                  <img src="/edit.svg" alt="" />
                  Add Education
                </Button>
              </AddEducation>
            </h3>
            {me?.education.length === 0 ? (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Your academic qualifications.
                </p>
                <AddEducation>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddEducation>
              </div>
            ) : (
              <div className="grid grid-cols-1 gap-10 mt-10 md:grid-cols-2">
                {me?.education.map((item: any, index: number) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between"
                  >
                    <div>
                      <p>{item.institution}</p>
                      <p className="mt-1 text-sm text-gray-60">
                        {format(new Date(item.startDate), "yyyy")}-{" "}
                        {item?.endDate
                          ? format(new Date(item.endDate), "yyyy")
                          : "Present"}
                      </p>
                    </div>
                    <ActionDropDown
                      id={item.id}
                      dataKey="education"
                      key={index}
                    />
                  </div>
                ))}
              </div>
            )}

            <Button variant="outline" className="mt-10 rounded-full">
              VIEW MORE
            </Button>
          </div>
          <div ref={refs["Events"]} data-section="Events" className="mt-[48px]">
            <h3 className="font-medium text-[24px] flex items-center justify-between">
              Events{" "}
              <AddEvent>
                <Button variant="link" className="h-auto p-0 mt-5 gap-x-1">
                  <img src="/edit.svg" alt="" />
                  Add Event
                </Button>
              </AddEvent>
            </h3>
            {me.events.length === 0 ? (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Your academic qualifications.
                </p>
                <AddEvent>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddEvent>
              </div>
            ) : (
              <div className="grid grid-cols-2 gap-10 mt-10">
                {me?.events.map((item: any) => (
                  <div key={item.id} className="">
                    <img
                      src={item.image}
                      alt={item.eventName}
                      className="w-full h-full max-h-[245px] "
                    />

                    <div className="grid gap-1 mt-5">
                      <h4 className="flex items-center justify-between font-normal capitalize">
                        {item.eventName}
                        <ActionDropDown id={item._id} dataKey="events" />
                      </h4>
                      <p className="text-sm text-gray-60">{item.country}</p>
                      <p className="text-sm text-gray-60">
                        {format(new Date(item.startDate), "MMM d, yyyy")} -{" "}
                        {format(new Date(item.endDate), "MMM d, yyyy")}{" "}
                      </p>
                      <p className="text-sm text-gray-60">
                        {" "}
                        {format(new Date(item.startDate), "h:mm a")} -{" "}
                        {format(new Date(item.endDate), "h:mm a")}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div
            ref={refs["Recognition"]}
            data-section="Recognition"
            className="mt-[48px]"
          >
            <h3 className="font-medium text-[24px] items-center justify-between flex">
              Recognition{" "}
              <AddRecognition>
                <Button
                  variant="link"
                  className="h-auto p-0 text-sm font-medium"
                >
                  + Add Recognition
                </Button>
              </AddRecognition>
            </h3>
            {me?.recognition.length === 0 ? (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Your honours and awards.
                </p>
                <AddRecognition>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddRecognition>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-10 mt-10 capitalize">
                  {me?.recognition.map((item: any) => (
                    <div key={item.id}>
                      <p className="flex items-center justify-between">
                        {item.name}{" "}
                        <ActionDropDown id={item._id} dataKey="recognition" />
                      </p>
                      <p className="text-sm text-gray-60">
                        {item.awardedBy} . {format(new Date(item.date), "yyyy")}
                      </p>
                    </div>
                  ))}
                </div>
                <Button variant="outline" className="mt-10 rounded-full">
                  VIEW MORE
                </Button>
              </>
            )}
          </div>
          <div ref={refs["Press"]} data-section="Press" className="mt-[48px]">
            <h3 className="font-medium text-[24px] items-center justify-between flex">
              Press{" "}
              <AddPress>
                <Button
                  variant="link"
                  className="h-auto p-0 text-sm font-medium"
                >
                  + ADD PRESS
                </Button>
              </AddPress>
            </h3>
            {me?.press.length === 0 ? (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Your media coverage.
                </p>
                <AddPress>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddPress>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-10 mt-10">
                  {me?.press.map((item: any) => (
                    <div key={item.id}>
                      <p className="flex items-center justify-between">
                        {item.title}{" "}
                        <ActionDropDown id={item._id} dataKey="press" />
                      </p>
                      <p className="mt-2 mb-5 text-sm text-gray-60">
                        {item.publishedBy}
                      </p>
                      <a
                        className="font-medium underline "
                        href={item.image}
                        target="blank"
                      >
                        VIEW IMAGE
                      </a>
                    </div>
                  ))}
                </div>
                <Button variant="outline" className="mt-10 rounded-full">
                  VIEW MORE
                </Button>{" "}
              </>
            )}

            {/* */}
          </div>
          <div
            ref={refs["Notable collections"]}
            data-section="Notable collections"
            className="mt-[48px]"
          >
            <h3 className="font-medium text-[24px] items-center flex justify-between">
              Notable collections{" "}
              <AddNotableCollection>
                <Button
                  variant="link"
                  className="h-auto p-0 text-sm font-medium uppercase"
                >
                  + ADD Notable collections
                </Button>
              </AddNotableCollection>
            </h3>
            {me.notableCollections.length == 0 ? (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Prominent collections that include your work.
                </p>
                <AddNotableCollection>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddNotableCollection>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-2 gap-10 mt-10">
                  {me?.notableCollections.map((item: any) => (
                    <div key={item.id}>
                      <p className="flex items-center justify-between">
                        {item.collectorName}{" "}
                        <ActionDropDown
                          id={item._id}
                          dataKey="notableCollections"
                        />
                      </p>
                      <p className="mt-1 text-sm text-gray-60">
                        {item.location}
                      </p>
                    </div>
                  ))}
                </div>
                <Button variant="outline" className="mt-10 rounded-full">
                  VIEW MORE
                </Button>{" "}
              </>
            )}
          </div>
          <div
            ref={refs["Publications"]}
            data-section="Publications"
            className="mt-[48px]"
          >
            <h3 className="font-medium text-[24px] flex items-center justify-between ">
              Publications{" "}
              <AddPublication>
                <Button
                  variant="link"
                  className="h-auto p-0 text-sm font-medium uppercase"
                >
                  + ADD Publications
                </Button>
              </AddPublication>
            </h3>
            {me?.publications.length == 0 ? (
              <div
                className={cn(
                  "flex flex-col items-center w-full p-8 mx-auto bg-white border border-solid border-bdr-10 mt-10",
                )}
              >
                <img src="/writing.svg" alt="" className="w-10 h-10 mb-3" />
                <p className="mb-5 text-center text-gray-60">
                  Exhibition catalogues and any other publications about your
                  work.
                </p>
                <AddPublication>
                  <Button
                    variant="link"
                    className="h-auto p-0 text-sm font-medium"
                  >
                    + ADD
                  </Button>
                </AddPublication>
              </div>
            ) : (
              <>
                <div className="grid grid-cols-1 gap-10 mt-10 md:grid-cols-2">
                  {me?.publications.map((item: any) => (
                    <div key={item.id}>
                      <img
                        src={item.image}
                        alt={item.author}
                        className="w-full h-full max-h-[245px] "
                      />
                      <p className="flex items-center justify-between">
                        {item.author}{" "}
                        <ActionDropDown dataKey="publications" id={item._id} />
                      </p>
                    </div>
                  ))}
                </div>
                <Button variant="outline" className="mt-10 rounded-full">
                  VIEW MORE
                </Button>{" "}
              </>
            )}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default Profile;

const TopSection = () => {
  const { me, isLoadingMe } = useUserContext();
  if (isLoadingMe) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <div className="flex mb-[48px] mt-[60px]  lg:px-[48px]">
        <h1 className="text-[40px]  leading-tight mb-4 font-normal lg:w-1/3">
          Build your artist profile
        </h1>
        <div className="lg:w-2/3">
          <div className="max-w-[600px]">
            <p className="mb-4 text-gray-100">
              Get discovered by art lovers, businesses, and other artists.
            </p>
            <p className="text-gray-100">
              Your Asign page tells the story of your unique creative journey.
              Share details about upcoming shows, gallery representation,
              recognition, and more.
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse  p-5 lg:mt-8 md:flex-row md:items-center lg:px-[48px]">
        <div className="lg:w-1/3">
          <UploadImage imageToUpdate="profilePic">
            <Avatar className="">
              <AvatarImage
                src={me?.profilePic ?? "/frame-1.jpg"}
                className="w-[100px] h-[100px] rounded-full object-cover"
              />
              <AvatarFallback>{"CN"}</AvatarFallback>
            </Avatar>
          </UploadImage>
          <h2 className="font-normal font-eb md:text-[48px] text-gray-80 text-[36px] max-w-[100px] leading-[57px] ">
            {me?.displayName}
          </h2>

          <EditProfile>
            <Button variant="link" className="h-auto p-0 mt-5 gap-x-1">
              <img src="/edit.svg" alt="" />
              EDIT
            </Button>
          </EditProfile>
        </div>
        <div className="relative lg:w-2/3">
          <img
            src={me?.coverImage ?? "/frame-1.jpg"}
            alt=""
            className="w-full max-h-[437px] object-cover object-bottom"
          />
          {!me?.coverImage && (
            <div className="absolute w-[100px] h-[100px] rounded-full bg-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] flex items-center justify-center">
              <img src="/image-icon.svg" alt="" className="w-[36px] h-[36px]" />
            </div>
          )}
          {/* uplaod icons */}
          <UploadImage imageToUpdate="coverImage">
            <div className="absolute w-[48px] h-[48px] bg-white bottom-6 right-6 rounded-full flex justify-center items-center cursor-pointer">
              <img src="/camera.svg" alt="" className="w-[24px] h-[24px]" />
            </div>
          </UploadImage>
        </div>
      </div>
    </>
  );
};

interface ActionDropDownProps {
  id: string;
  dataKey: string;
}

const ActionDropDown: React.FC<ActionDropDownProps> = ({ id, dataKey }) => {
  const { updateUser, me } = useUserContext();
  const popoverRef = useRef<HTMLDivElement>(null);

  const defaultValue = me?.[dataKey]?.find((item: any) => item._id === id) || {
    isPublic: false,
  };

  const onTogglePublic = (checked: boolean) => {
    updateUser.mutate({
      [dataKey]: [
        {
          ...defaultValue,
          isPublic: checked,
          _id: id,
        },
      ],
    });
  };

  const onDelete = (id: string) => {
    updateUser.mutate({
      [dataKey]: [
        {
          _id: id,
          delete: true,
        },
      ],
    });
  };

  useEffect(() => {
    if (popoverRef.current) {
      popoverRef.current.focus();
    }
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="focus:outline-none" type="button">
          <MoreHorizontal className="w-5 h-5 text-gray-500" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="w-56 p-0 font-normal border border-gray-200 rounded-md shadow-lg font-sh5"
        align="end"
        ref={popoverRef}
        tabIndex={0}
      >
        <div className="py-2">
          <div className="flex items-center justify-between w-full px-4 py-2">
            <span className="text-sm font-normal text-gray-700">
              Publicly visible
            </span>
            <Switch
              checked={defaultValue.isPublic}
              onCheckedChange={onTogglePublic}
              className="data-[state=checked]:bg-gray-900"
            />
          </div>
          {/* Conditionally render the Edit button based on dataKey */}
          {dataKey === "education" && (
            <AddEducation defaultId={id}>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddEducation>
          )}
          {dataKey === "events" && (
            <AddEvent defaultId={id}>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddEvent>
          )}
          {dataKey === "representation" && (
            <AddRepresentation>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddRepresentation>
          )}
          {dataKey === "recognition" && (
            <AddRecognition defaultId={id}>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddRecognition>
          )}
          {dataKey === "press" && (
            <AddPress defaultId={id}>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddPress>
          )}
          {dataKey === "notableCollections" && (
            <AddNotableCollection defaultId={id}>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddNotableCollection>
          )}
          {dataKey === "publications" && (
            <AddPublication defaultId={id}>
              <button className="w-full px-4 py-2 text-sm font-normal text-left">
                Edit
              </button>
            </AddPublication>
          )}
          {/* You can add more conditions for other dataKeys as needed */}
          <Global
            title="Delete"
            description="Are you sure you want to permanently delete?"
            actionText="DELETE"
            cancelText="CANCEL"
            onConfirm={() => onDelete(id)}
          >
            <button
              className="w-full px-4 py-2 text-sm font-normal text-left "
              type="button"
            >
              Delete
            </button>
          </Global>
        </div>
      </PopoverContent>
    </Popover>
  );
};

import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import AddComponents from "./modal/AddComponents";
import { MoreHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useObjectContext } from "@/context/objects";
import { ActionDropDown } from ".";

export default function Components() {
  const handleDelete = (id: string) => {};
  const { currentObject } = useObjectContext();

  return (
    <div className="space-y-4">
      {currentObject?.components?.length === 0 ? (
        <p className="text-sm text-gray-500">
          You have not added any components yet.
        </p>
      ) : (
        <ul className="space-y-2">
          {currentObject?.components?.map((component: any, index: number) => (
            <li
              key={component.id}
              className="flex items-center justify-between p-2 bg-white rounded-lg shadow"
            >
              <div className="flex items-center space-x-3">
                <img src={component.image} className="w-10 h-10 rounded" />
                <span> component {index + 1}</span>
              </div>
              <ActionDropDown id={component._id} dataKey="components" />
            </li>
          ))}
        </ul>
      )}
      <AddComponents>
        <Button variant="link" className="h-auto p-0 mt-2">
          + ADD COMPONENT
        </Button>
      </AddComponents>
    </div>
  );
}

import React, { FunctionComponent, useState } from "react";
import Layout from "../layout/DefaultLayout";
import Headline from "../components/Headline";
import { Button } from "../components/ui/button";
import Card from "../components/Templates/Boards/card";
import useBoard from "@/apis/board";
import { Board, GetAllBoardResponse } from "@/types/boards.type";
import { useQuery } from "@tanstack/react-query";
import NewBoard from "@/modal/NewBoard";
import useFilter from "@/hooks/useFilter";
import SortingOptionsDropdown from "@/components/Boards/SortingOptionsDropDown";
import TextFooter from "@/components/Global/TermsCondn";
import { useDebouncedCallback } from "use-debounce";
import PageLoader from "@/components/loader/PageLoader";

const Boards: FunctionComponent = () => {
  const { getAllBoard } = useBoard();
  const [position, setPosition] = useState("recently");
  const { filterOption, setQuery } = useFilter({
    options: {
      pagination: false,
      sort: { updatedAt: -1 },
    },
  });

  const { data, isLoading } = useQuery<GetAllBoardResponse>({
    queryKey: ["boards", filterOption],
    queryFn: () =>
      getAllBoard({
        params: filterOption,
      }),
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    retry: 3,
  });

  // Debounced search query function
  const search = useDebouncedCallback((value: string) => {
    setQuery({
      searchQuery: value === "" ? undefined : value,
    });
  }, 1000);

  const boardsData = data?.data?.data || [];
  const pinnedBoards = boardsData.filter((board) => board.pinned);
  const otherBoards = boardsData.filter((board) => !board.pinned);

  const imageSources = [
    { id: 0, src: "/starLogoWhite.svg", alt: "Star Logo" },
    { id: 1, src: "/caseWhite.svg", alt: "Case Icon" },
    { id: 2, src: "/eyeWhite.svg", alt: "Eye Icon" },
    { id: 3, src: "/frameWhite.svg", alt: "Framing Icon" },
    { id: 4, src: "/paintingAlt.svg", alt: "Paint Icon" },
    { id: 5, src: "/playWhite.svg", alt: "Play Icon" },
    { id: 6, src: "/scholarWhite.svg", alt: "Scholar Icon" },
    { id: 7, src: "/tvWhite.svg", alt: "TV Icon" },
    { id: 8, src: "/starWhite.svg", alt: "Star Alt Icon" },
    { id: 9, src: "/personWhite.svg", alt: "Person Icon" },
    { id: 10, src: "/writing.svg", alt: "Writing Icon" },
    { id: 11, src: "/heartWhite.svg", alt: "Heart Icon" },
  ];

  const sortBoards = (boards: Board[], position: string) => {
    switch (position) {
      case "recently": // Recently added (2 days)
        return boards.sort((a, b) => {
          const twoDaysAgo = new Date();
          twoDaysAgo.setDate(twoDaysAgo.getDate() - 2);
          const aDate = new Date(a.createdAt);
          const bDate = new Date(b.createdAt);
          if (aDate > twoDaysAgo && bDate <= twoDaysAgo) return -1;
          if (aDate <= twoDaysAgo && bDate > twoDaysAgo) return 1;
          return bDate.getTime() - aDate.getTime();
        });
      case "oldtonew": // old to new
        return boards.sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
        );
      case "AtoZ": // A to Z
        return boards.sort((a, b) => a.name.localeCompare(b.name));
      case "ZtoA": // Z to A
        return boards.sort((a, b) => b.name.localeCompare(a.name));
      default:
        return boards;
    }
  };

  const sortedOtherBoards = sortBoards(otherBoards, position);

  return (
    <Layout viewMobTopNav={true}>
      <div className="w-full px-12 pt-12 overflow-y-visible mq450:pt-16 mq750:pt-16 mq750:px-3 mq450:px-5">
        <div className="flex flex-col">
          <NewBoard>
            <div>
              <Button
                size="icon"
                variant="default"
                className="hidden mq750:block mq450:block w-12 h-12 mq750:fixed mq750:bottom-[110px] mq750:z-[50] mq750:left-[45%] mq450:fixed mq450:bottom-[110px] mq450:z-[50] mq450:left-[45%] rounded-full font-sh5 font-normal text-sm bg-other-cta"
              >
                <img className="w-8 h-8" alt="addSign" src="/addSign.svg" />
              </Button>
            </div>
          </NewBoard>

          <div className="flex flex-row mq450:flex-col mq750:flex-col mq450:gap-[15px] mq750:gap-[15px] justify-between">
            <div className="flex flex-row flex-wrap items-center justify-between">
              <Headline text="Boards" />
              <NewBoard>
                <Button
                  size="icon"
                  variant="default"
                  className="rounded-[50px] mq450:block mq750:block hidden"
                >
                  <img className="w-8 h-8 p-1" src="/addSign.svg" alt="add" />
                </Button>
              </NewBoard>
            </div>

            <div className="flex flex-row items-center gap-5 mq450:gap-0 mq750:gap-0">
              <div className="relative w-full">
                <input
                  onChange={(e) => search(e.target.value)}
                  className="w-full border-[1px] min-w-[232px] pl-12 border-solid border-gray-20 focus:border-black focus:ring-0 h-10 rounded-[50px] transition-colors duration-300 ease-in-out focus:outline-none focus:ring-5 focus:ring-focus-ring focus:ring-opacity-50"
                  placeholder="Search"
                  type="text"
                />
                <img
                  className="absolute transform -translate-y-1/2 left-4 top-1/2"
                  src="/iconsearch.svg"
                  alt="Search Icon"
                />
              </div>

              <NewBoard>
                <Button
                  size="icon"
                  variant="default"
                  className="w-[156px] h-[49px] rounded-[50px] mq750:hidden mq450:hidden "
                >
                  <span className="flex flex-row">
                    <img src="/plus-icon.svg" alt="add" />
                  </span>
                  <p className="ml-3 font-medium"> NEW BOARD</p>
                </Button>
              </NewBoard>
            </div>
          </div>
          {isLoading && <PageLoader />}

          {!isLoading && (
            <>
              {/* Pinned boards */}
              <div className="flex flex-col pt-12 ">
                <p className="text-base font-normal mq450:pb-4 text-gray-60">
                  Pinned Boards
                </p>

                {pinnedBoards.length === 0 ? (
                  <div className="grid place-items-center h-[300px] bg-[#FCFCFC] my-5">
                    <div className="flex flex-col items-center gap-1 font-normal font-sh5">
                      <img src="/database.svg" alt="" />
                      <p className="text-lg">No Data Found</p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row flex-wrap justify-start gap-5 mq750:items-center mq450:gap-4 mq450:justify-center">
                    {pinnedBoards.map((board) => {
                      const imageSource = imageSources.find(
                        (image) => image.id === board.icon,
                      );

                      return (
                        <Card
                          pin={board.pinned ? board.pinned : false}
                          key={board.id}
                          cardImg={board?.cover}
                          logoimageSrc={imageSource ? imageSource.src : ""}
                          mainText={board.name}
                          subText={`${board.boardItems.length} items`}
                          boardId={board.id}
                        />
                      );
                    })}
                  </div>
                )}
              </div>

              {/* Other boards */}
              <div className="flex flex-col pt-12">
                <div className="flex flex-row items-center justify-between mq450:pb-4">
                  <p className="text-base font-normal text-gray-60">
                    Other Boards
                  </p>
                  {sortedOtherBoards.length > 0 && (
                    <div>
                      <SortingOptionsDropdown
                        position={position}
                        setPosition={setPosition}
                      />
                    </div>
                  )}
                </div>
                {sortedOtherBoards.length === 0 ? (
                  <div className="grid place-items-center h-[300px] bg-[#FCFCFC] my-5">
                    <div className="flex flex-col items-center gap-1 font-normal font-sh5">
                      <img src="/database.svg" alt="" />
                      <p className="text-lg">No Data Found</p>
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-row flex-wrap justify-start gap-5 mq450:justify-center">
                    {sortedOtherBoards.map((board) => {
                      const imageSource = imageSources.find(
                        (image) => image.id === board.icon,
                      );

                      return (
                        <Card
                          key={board.id}
                          cardImg={board?.cover}
                          logoimageSrc={imageSource ? imageSource.src : ""}
                          mainText={board.name}
                          subText={`${board.boardItems.length} items`}
                          boardId={board.id}
                        />
                      );
                    })}
                  </div>
                )}
              </div>
            </>
          )}
        </div>

        {(pinnedBoards.length > 0 || sortedOtherBoards.length > 0) && (
          <div className="relative bottom-0 mt-12">
            <TextFooter />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default Boards;

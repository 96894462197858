import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import GlobalDialog from "@/modal/GlobalDialog";
import { useUserContext } from "@/context/user";

// Zod schema for validation
const formSchema = z.object({
  image: z.string().nonempty("Please upload a file"), // Expect base64 string
  author: z.string().min(2, "Published by must be at least 2 characters"),
  isPublic: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

const AddPublication = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { me, updateUser } = useUserContext();
  const defaultValue = me?.publications?.find(
    (item: any) => item._id === defaultId,
  ) || {
    author: "",
    isPublic: false,
  };
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    defaultValue?.image ?? null,
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValue,
  });

  // Convert file to base64 and set in form
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        form.setValue("image", base64String);
        setPreviewUrl(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result as string;
        form.setValue("image", base64String);
        setPreviewUrl(URL.createObjectURL(file));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    form.setValue("image", "");
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const onSubmit = (data: FormValues) => {
    if (defaultId) {
      updateUser.mutate({
        publications: [
          {
            ...data,
            _id: defaultId,
          },
        ],
      });
      return;
    }

    updateUser.mutate({
      publications: [data],
    });
  };

  const title = "Add Publication";

  return (
    <GlobalDialog
      title={title}
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6 ">
          <FormField
            control={form.control}
            name="image"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div
                    className="relative flex items-center justify-center w-full h-48 overflow-hidden border-2 border-gray-300 border-dashed rounded-md cursor-pointer bg-[#F6F6F6]"
                    onClick={() => fileInputRef.current?.click()}
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {previewUrl ? (
                      <>
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="object-contain w-full h-full"
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="absolute p-1 bg-white rounded-full top-2 right-2 hover:bg-red-100"
                          onClick={handleDeleteImage}
                        >
                          <img
                            src="/delete.svg"
                            alt="Delete"
                            className="w-4 h-4"
                          />
                        </Button>
                      </>
                    ) : (
                      <div className="text-center">
                        <img
                          src="/image-icon.svg"
                          alt="Upload"
                          className="w-6 h-6 mx-auto mb-2"
                        />
                        <p className="text-lg font-normal text-gray-100 font-sh5">
                          Drag and drop or browse files
                        </p>
                        <p className="text-sm font-normal font-sh5 mq450:text-center text-gray-60">
                          Up to 20 MB, in .jpg, .png, or .webp format
                        </p>
                      </div>
                    )}
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.webp"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="author"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">Author</FormLabel>
                <FormControl>
                  <Input placeholder="DAG" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isPublic"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row items-center justify-between -mx-[25px] border-solid border-bdr-10 border-t pt-[22px] px-5">
                  <div className="space-y-0.5 ">
                    <FormLabel className="text-base font-normal">
                      Publicly visible
                    </FormLabel>
                  </div>
                  <Switch
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddPublication;

import { Textarea } from "@/components/ui/textarea";
import { useUserContext } from "@/context/user";
import GlobalDialog from "@/modal/GlobalDialog";
import React, { useState } from "react";

const AddBio = ({ children }: { children: React.ReactNode }) => {
  const { updateUser, me } = useUserContext();
  const [bio, setBio] = useState(me?.biography || "");
  const maxLength = 2774;

  const handleSave = () => {
    updateUser.mutate({
      biography: bio,
    });
  };
  const title = me?.biography ? "Edit a biography" : "Add a biography";

  return (
    <GlobalDialog TriggerButton={children} onConfirm={handleSave} title={title}>
      <Textarea
        value={bio}
        onChange={(e) => setBio(e.target.value)}
        placeholder="Enter your biography"
        className="w-full h-fit p-[16px_20px] resize-none border-solid border-[1px] border-bdr-10 rounded-none min-h-[140px] text-base font-sh5 font-normal leading-[19.2px]"
        maxLength={maxLength}
      />
      <p className="mt-2 text-sm text-gray-500">
        {maxLength - bio.length} characters left
      </p>
    </GlobalDialog>
  );
};

export default AddBio;

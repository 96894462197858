import React, { useState, useEffect } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Checkbox } from "@/components/ui/checkbox";
import { Textarea } from "@/components/ui/textarea";
import { Calendar } from "@/components/ui/calendar";
import { format } from "date-fns";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "lucide-react";
import { cn } from "@/lib/utils";
import AddValuation from "./modal/AddValuation";
import { useObjectContext } from "@/context/objects";
import { formatInRuppes } from "@/lib/helper";
import { ActionDropDown } from ".";

export default function Valuation() {
  const [acquisitionValue, setAcquisitionValue] = useState(0);
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const { currentObject } = useObjectContext();
  const form = useFormContext();

  // Set default values to avoid NaN initially
  const watchPrice =
    form.watch("document.valuation.acquisitionDetails.price") || 0;
  const watchDiscount =
    form.watch("document.valuation.acquisitionDetails.discount") || 0;
  const watchTax = form.watch("document.valuation.acquisitionDetails.tax") || 0;
  const watchValuationAmount = form.watch("valuationAmount") || 0;
  const watchValuationMargin = form.watch("valuationMargin") || 0;
  const watchValuationDiscount = form.watch("valuationDiscount") || 0;

  useEffect(() => {
    const calculatedValue =
      watchPrice * (1 - watchDiscount / 100) * (1 + watchTax / 100);
    console.log(calculatedValue);
    setAcquisitionValue(calculatedValue || 0); // Set 0 if calculation is NaN or undefined
  }, [watchPrice, watchDiscount, watchTax]);

  useEffect(() => {
    const calculatedPrice =
      watchValuationAmount *
      (1 + watchValuationMargin / 100) *
      (1 - watchValuationDiscount / 100);
    setEstimatedPrice(calculatedPrice || 0); // Set 0 if calculation is NaN or undefined
  }, [watchValuationAmount, watchValuationMargin, watchValuationDiscount]);

  function onSubmit(data: any) {
    console.log(data);
    // Handle form submission
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="space-y-4">
          <h3 className="text-xl font-medium">Acquisition details</h3>
          <div className="space-y-4">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="document.valuation.acquisitionDetails.type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Acquisition type</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select acquisition type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="Direct purchase">
                          Direct purchase
                        </SelectItem>
                        <SelectItem value="Auction">Auction</SelectItem>
                        <SelectItem value="Gift">Gift</SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="document.valuation.acquisitionDetails.currency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Currency</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select currency" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="USD">
                          USD (United States Dollar)
                        </SelectItem>
                        <SelectItem value="EUR">EUR (Euro)</SelectItem>
                        <SelectItem value="GBP">
                          GBP (British Pound Sterling)
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="document.valuation.acquisitionDetails.price"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Price</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="document.valuation.acquisitionDetails.discount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Discount %</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="document.valuation.acquisitionDetails.tax"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tax %</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder="Add tax"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="document.valuation.acquisitionDetails.paidInFullDate"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>Acquisition date</FormLabel>
                    <Popover>
                      <PopoverTrigger asChild>
                        <FormControl>
                          <Button
                            variant={"outline"}
                            className={cn(
                              "  pl-3 text-left font-normal w-full rounded-none h-[58px] text-base",
                              !field.value && "text-muted-foreground",
                            )}
                          >
                            {field.value ? (
                              format(field.value, "PPP")
                            ) : (
                              <span>Pick a date</span>
                            )}
                            <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                          </Button>
                        </FormControl>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          mode="single"
                          selected={field.value}
                          onSelect={field.onChange}
                          disabled={(date) =>
                            date > new Date() || date < new Date("1900-01-01")
                          }
                          initialFocus
                        />
                      </PopoverContent>
                    </Popover>
                  </FormItem>
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="document.valuation.acquisitionDetails.notes"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Acquisition notes</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Add any additional notes about the acquisition"
                      className="resize-none"
                      {...field}
                    />
                  </FormControl>
                </FormItem>
              )}
            />
            <div>
              <FormLabel>Acquisition value</FormLabel>
              <p className="text-lg font-semibold">
                {form.watch("document.valuation.acquisitionDetails.currency")}{" "}
                {acquisitionValue.toFixed(2)}
              </p>
              <FormDescription>
                This value is auto-calculated based on the information entered
                above.
              </FormDescription>
            </div>
          </div>
        </div>

        <div className="space-y-4">
          <h3 className="text-xl font-medium">Valuations</h3>
          <div>
            {currentObject?.document?.valuation?.valuationDetail?.map(
              (valuation: any) => (
                <div
                  key={valuation.id}
                  className="flex items-center justify-between py-4 border-b border-solid border-bdr-10"
                >
                  <div>
                    <p className="text-sm text-gray-500">
                      Valuation as on {format(valuation.date, "PPP")}
                    </p>
                    <p className="font-medium">
                      Estimate {formatInRuppes.format(valuation.lowValue)}-
                      {formatInRuppes.format(valuation.highValue)}
                    </p>
                  </div>
                  <ActionDropDown
                    id={valuation.id}
                    dataKey="document.valuation.valuationDetail"
                  />
                </div>
              ),
            )}
          </div>
          <AddValuation>
            <Button variant="link" className="h-auto p-0">
              + ADD VALUATION
            </Button>
          </AddValuation>
          <div className="space-y-4">
            <h4 className="text-lg font-medium">Pricing</h4>
            <FormDescription>
              Calculate the estimated price for your object. This is visible
              only to you.
            </FormDescription>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="valuationCurrency"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Currency</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select currency" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="USD">
                          USD (United States Dollar)
                        </SelectItem>
                        <SelectItem value="EUR">EUR (Euro)</SelectItem>
                        <SelectItem value="GBP">
                          GBP (British Pound Sterling)
                        </SelectItem>
                      </SelectContent>
                    </Select>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="valuationAmount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Amount</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
              <FormField
                control={form.control}
                name="valuationMargin"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Margin %</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="valuationDiscount"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Discount %</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        {...field}
                        onChange={(e) =>
                          field.onChange(parseFloat(e.target.value))
                        }
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            </div>
            <div>
              <FormLabel>Estimated price</FormLabel>
              <p className="text-lg font-semibold">
                {form.watch("valuationCurrency")} {estimatedPrice.toFixed(2)}
              </p>
              <FormDescription>
                This value is auto-calculated based on the information entered
                above.
              </FormDescription>
            </div>
          </div>
        </div>
      </form>
    </Form>
  );
}

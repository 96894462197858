import React from "react";
import { FunctionComponent } from "react";

const Footer: FunctionComponent = () => {
  return (
    <div className="w-full bg-grey100 max-w-full flex flex-row flex-wrap items-start justify-start py-[60px] px-12 box-border gap-[10px] leading-[normal] tracking-[normal] mq725:pl-6 mq725:pr-6 mq725:box-border bg-gray-100 text-white mt-[56px]">
      <img
        className="h-[100px] w-[25.1px] relative"
        loading="lazy"
        alt=""
        src="/logo-Footer.svg"
      />
      <section className="text-white-80 flex-1 flex flex-row items-start justify-end gap-[10px] min-w-[747px] max-w-full text-left text-base text-other-white font-paragraph-p3 mq975:min-w-full mq1025:flex-wrap">
        <div className="flex-1 flex flex-row items-start justify-center py-0 pr-[301.4px] pl-[301.5px] box-border gap-[40px] min-w-[328px] max-w-full mq975:flex-wrap mq975:pl-[150px] mq975:pr-[150px] mq975:box-border mq725:gap-[20px] mq725:pl-[75px] mq725:pr-[75px] mq725:box-border">
          <div className="flex-1 flex flex-col items-start justify-start gap-[24px] min-w-[107px]">
            <div className="self-stretch relative leading-[120%]">Artists</div>
            <div className="self-stretch relative leading-[120%]">Business</div>
            <div className="self-stretch relative leading-[120%]">
              Collectors
            </div>
            <div className="self-stretch relative leading-[120%]">Everyone</div>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[24px] min-w-[107px] text-other-white-80">
            <a
              className="self-stretch relative leading-[120%]"
              href="https://www.asign.art/contact/"
            >
              Contact Us
            </a>
            <a
              className="self-stretch relative leading-[120%]"
              href="https://www.asign.art/privacy-policy/"
            >
              Privacy Policy
            </a>
            <a
              className="self-stretch relative leading-[120%]"
              href="https://www.asign.art/terms-and-conditions/"
            >{`Terms & Conditions`}</a>
          </div>
        </div>
        <div className="relative leading-[120%] text-lightgray-200">
          <p className="m-0">{`© 2024 `}</p>
          <p className="m-0">Democrart Technologies</p>
          <p className="m-0">Private Limited</p>
        </div>
      </section>
    </div>
  );
};

export default Footer;

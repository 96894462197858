import { Business } from "@/types/business.type";
import { apis } from ".";

const useBusiness = () => {
  const getBusinessById = async ({ id }: { id: string }): Promise<Business> => {
    try {
      const { data } = await apis.get(`client/api/v1/business/${id}`, {});
      return data.data;
    } catch (error) {
      throw error;
    }
  };
  return { getBusinessById };
};

export default useBusiness;

import AddBoardDropDown from "@/components/Boards/AddItemDropDown";
import Image from "@/components/Global/Image";

import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Artist } from "@/types/atrists.type";
import { format } from "date-fns";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { FollowArtistButton } from "./BigBanner";
import { getId } from "@/lib/helper";

interface Props {
  data: Artist[];
  title: string;
  type?: "3x" | "big";
  sectionId?: string;
  heading?: string;
}

const Big = ({ data, title, type = "big", sectionId, heading }: Props) => {
  const sliderRef = useRef<Slider | null>(null);

  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [slidesToShow, setSlidesToShow] = useState(2);
  const navigate = useNavigate();
  const updateSlidesToShow = (width: number) => {
    if (width >= 1024) {
      setSlidesToShow(type === "3x" ? 3 : 4);
    } else if (width >= 400) {
      setSlidesToShow(3);
    } else {
      setSlidesToShow(1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateSlidesToShow(entry.contentRect.width);
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [type]);
  let dragging = false;
  const settings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      slidesToShow,
      slidesToScroll: slidesToShow,
      arrows: false,
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
    }),
    [slidesToShow]
  );

  const renderArtistCard = (item: Artist | number, index: number) => {
    const isPlaceholder = typeof item === "number";
    const artist = isPlaceholder ? null : item;

    return (
      <div
        key={isPlaceholder ? index : artist?._id}
        className="relative px-2 group"
        // onClick={(e) => }
      >
        <div className="relative">
          <div className="relative overflow-hidden aspect-square">
            {isPlaceholder || !artist?.profilePic ? (
              <div className="w-full h-full bg-gradient-to-b from-gray-200 to-black" />
            ) : (
              <>
                <Image
                  src={artist.profilePic}
                  alt="profile pic"
                  className="object-cover w-full h-full cursor-pointer"
                  loading="lazy"
                />
                <div
                  className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% cursor-pointer"
                  onClick={() => {
                    if (dragging) return;
                    navigate(`/artist/detail/${artist._id}`);
                  }}
                ></div>
              </>
            )}
          </div>
          <AddBoardDropDown
            itemID={getId(artist)}
            boardID={""}
            itemType={"artist"}
          >
            <span className="absolute top-4 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-sm flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center">
              <img src="/label.svg" alt="label" /> Board
            </span>
          </AddBoardDropDown>
          <div className="absolute left-0 flex items-center justify-between w-full px-[12px] text-white bottom-[15%] lg:bottom-3">
            <div>
              <h1 className="text-5xl font-normal tracking-wide capitalize font-eb">
                {isPlaceholder ? "Artist Name" : artist?.name}
              </h1>
              <p className="text-xs">
                {isPlaceholder
                  ? "b. date"
                  : artist?.dob && "b. " + format(artist.dob, "yyyy")}
              </p>
            </div>
            <FollowArtistButton id={artist?._id ?? ""} />
          </div>
        </div>
        <div className="bg-bg-5 p-[12px_20px]">
          <p className="mt-2 text-sm text-gray-60 line-clamp-3">
            {/* {isPlaceholder ? "Artist Description" : artist} */}
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nisi
            aliquam quas facilis a. Expedita, quod quam! Excepturi, dolor iure
            temporibus, ex atque exercitationem ipsum possimus sit ullam, ea
            provident necessitatibus.
          </p>
          <div className="flex mt-[24px] gap-1 justify-between">
            {(isPlaceholder
              ? [1, 2, 3]
              : artist?.gallery?.slice(0, 3) || []
            ).map((galleryItem, galleryIndex) => (
              <div
                key={
                  isPlaceholder
                    ? galleryIndex
                    : typeof galleryItem !== "number"
                    ? galleryItem._id
                    : undefined
                }
                className="relative overflow-hidden bg-white aspect-square"
              >
                {!isPlaceholder && typeof galleryItem !== "number" && (
                  <Image
                    src={galleryItem.art_url}
                    alt={galleryItem.art_name}
                    className="object-cover w-full h-full max-h-[276px] cursor-pointer max-w-[276px]"
                    onClick={() => navigate(`/artist/view/${sectionId}`)}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="font-sh5" ref={containerRef}>
      <div className="flex items-center justify-between mb-8 mq750:mb-6">
        <ToolTipText title={title} heading={heading} />
        <div className="flex flex-row items-center">
          <Button
            className="text-sm font-medium underline bg-transparent mq750:text-xs"
            variant="link"
            size="sm"
            onClick={() => navigate(`/artist/view/${sectionId}`)}
          >
            VIEW ALL
          </Button>
          <div className="lg:flex flex-row items-center gap-[5px] ">
            {[true, false].map((flip, index) => (
              <img
                key={index}
                className="p-[3px] bg-[#EEEEEA] rounded-[100%] h-[26px] w-[26px] cursor-pointer"
                style={flip ? { transform: "scaleX(-1)" } : {}}
                src="/arrow-right.svg"
                alt={flip ? "Previous" : "Next"}
                onClick={flip ? previous : next}
              />
            ))}
          </div>
        </div>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {(data.length <= 1 ? [1, 2, 3, 4, 5] : data).map((item, index) =>
          renderArtistCard(item, index)
        )}
      </Slider>
    </div>
  );
};

export default Big;

export const ToolTipText = ({
  title,
  heading,
}: {
  title: string;
  heading?: string;
}) => {
  return (
    <TooltipProvider>
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <h3 className="lg:text-[28px] text-[24px] font-normal capitalize truncate max-w-[60%]">
            {title || "Section Title"}
            <p className="mt-1 text-sm truncate text-gray-60 max-w-[70%]">
              {heading}
            </p>
          </h3>
        </TooltipTrigger>
        <TooltipContent className="w-full p-1 text-sm text-white bg-gray-100 rounded-md max-w-[300px] truncate">
          <p>{title}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

import React from "react";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";

interface AgreementProps {
  title: string;
  introduction: string;
  content: string;
  checkboxLabel: string;
  buttonText: string;
  isChecked: boolean;
  onCheckChange: (checked: boolean) => void;
  isEstate: boolean;
  nextStep: () => void;
  setType: (type: string) => void;
  userId: string;
}

const Agreement: React.FC<AgreementProps> = ({
  title,
  introduction,
  content,
  checkboxLabel,
  buttonText,
  isChecked,
  onCheckChange,
  isEstate,
  setType,
  nextStep,
  userId,
}) => {
  // const handleSubmit = () => {
  //   if (!isEstate) {
  //     nextStep();
  //     setType("businessform");
  //   } else {
  //     nextStep();
  //     setType("upload");
  //   }
  // };

  const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
  };

  const handleSubmit = async () => {
    if (!isChecked) {
      return; // Don't proceed if checkbox is not checked
    }
    if (!isEstate) {
      nextStep();
      setType("businessform");
    } else {
      nextStep();
      setType("upload");
    }

    try {
      const raw = JSON.stringify({
        contract: true,
        id: userId,
      });

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: raw,
        redirect: "follow" as RequestRedirect,
      };

      const response = await fetch(
        "https://ab.host.levitation.co.in/client/auth/update-profile",
        requestOptions,
      );
      const result = await response.text();

      // If the API call is successful, proceed with the next step
      if (response.ok) {
        if (!isEstate) {
          nextStep();
          setType("businessform");
        } else {
          nextStep();
          setType("upload");
        }
      } else {
        // Handle API error
        console.error("API call failed:", result);
        // You might want to show an error message to the user here
      }
    } catch (error) {
      console.error("Error:", error);

      // Handle any network errors or exceptions
      // You might want to show an error message to the user here
    }
  };
  return (
    <div className="w-full md:w-1/2 flex flex-col h-screen font-sh5">
      <div className="mt-20   px-8 md:px-16 xl:px-40">
        <h1 className="text-[32px] font-normal mb-6">
          {capitalizeFirstLetter(title)}
        </h1>
      </div>

      <div className="flex-grow overflow-y-auto px-8 md:px-16">
        <div className="max-w-md mx-auto">
          <h2 className="text-[24px] font-normal mb-4">
            Introduction and Background
          </h2>
          <div className="space-y-4 mb-8">
            <p className="text-[#696969] font-normal text-[14px]">
              {introduction}
            </p>
          </div>
          <h2 className="text-[24px] font-normal mb-4">Miscellaneous</h2>
          <div className="space-y-4 mb-8">
            <p className="text-[#696969] font-normal text-[14px]">{content}</p>
            <div className="flex items-center space-x-2 mb-8">
              <Checkbox
                id="terms"
                checked={isChecked}
                onCheckedChange={onCheckChange}
              />
              <label
                htmlFor="terms"
                className="text-sm font-normal text-[#696969] leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              >
                {checkboxLabel}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="p-4 flex-shrink-0">
        <div className="max-w-md mx-auto">
          <Button
            className="w-full py-3 px-4 bg-black text-white rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            disabled={!isChecked}
            onClick={handleSubmit}
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Agreement;

import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import { useMutation, UseMutationResult } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { useNavigate } from "react-router-dom";

type DeleteBoardProps = {
  children: ReactNode;
  boardId: string;
  boardName: string;
};

const DeleteBoard: React.FC<DeleteBoardProps> = ({
  children,
  boardId,
  boardName,
}) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const { deleteSingleBoard } = useBoard();
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);

  const mutation: UseMutationResult<GetSingleBoardResponse, Error, string> =
    useMutation({
      mutationFn: (boardId: string) => deleteSingleBoard(boardId),
      onSuccess: (data) => {
        toast.success(`Board deleted successfully: ${data.message}`, {
          id: "delete",
        });
        setOpen(false);
        navigate("/boards");
      },
      onError: (error: Error) => {
        toast.error(`Failed to delete board: ${error.message}`, {
          id: "delete",
        });
      },
      onMutate: () => {
        toast.loading("Deleting board...", {
          id: "delete",
        });
      },
    });

  const handleDelete = () => {
    mutation.mutate(boardId);
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent className="min-w-[640px] z-[100]">
        <DialogHeader className="px-8">
          <DialogTitle className="border-[#E5E5E5] pb-4 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>Delete Board</p>
              <DialogClose asChild className="bg-transparent cursor-pointer">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm
          onDelete={handleDelete}
          boardName={boardName}
          deleting={deleting}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger onClick={() => setOpen(true)}>{children}</DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="mb-5 text-left border-b border-solid border-gray-10">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            <div className="flex flex-row items-center justify-between">
              Delete Board
              <DialogClose asChild className="bg-transparent cursor-pointer">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm
          onDelete={handleDelete}
          boardName={boardName}
          deleting={deleting}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onDelete: () => void; // Prop for delete action
  boardName: string; // Board name for display
  deleting: boolean;
};

function ProfileForm({ onDelete, boardName, deleting }: ProfileFormProps) {
  return (
    <form className="grid items-start gap-4">
      <div className="px-8 font-normal text-center font-sh5 font-base text-gray-60">
        <p className="">
          Are you sure you want to permanently delete <span>{boardName}</span> ?{" "}
          <br />
          This action cannot be undone.
        </p>
      </div>
      <div className="flex flex-row items-center justify-between w-full p-5 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            disabled={deleting}
            className="cursor-pointer h-6 min-w-12 font-medium text-xs font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none"
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px]  h-[42PX] mq750:px-5 font-medium text-sm text-white font-sh5 px-[36px] py-2"
          variant="default"
          type="button"
          disabled={deleting}
          onClick={onDelete} // Call onDelete on button click
        >
          {deleting ? "DELETING..." : "DELETE"}
        </Button>
      </div>
    </form>
  );
}

export default DeleteBoard;

import React, { createContext, useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import useObjects from "@/apis/objects";
import { useSearchParams, useNavigate } from "react-router-dom";
import useFilter, { QueryData } from "@/hooks/useFilter";
import useAsignProtect from "@/apis/protect";

// Define the context type
interface ObjectContextType {
  currentObject: any;
  isLoading: boolean;
  updateOrAddItem: (data: any) => void;
  setFilterOption: React.Dispatch<React.SetStateAction<any>>;
  setQuery: React.Dispatch<React.SetStateAction<any>>;
  filterOption: QueryData;
  objectsList: any;
  eligibility: boolean;
  missingFields: string[];
  falseBooleanFields: string[];
  category: any;
}

const ObjectContext = createContext<ObjectContextType | undefined>(undefined);

const ObjectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { addObjects, updateObject, getObjectById, objectList } = useObjects();
  const { checkEligiblity } = useAsignProtect();
  const { filterOption, setQuery, setFilterOption } = useFilter();
  const [eligibility, setEligibility] = useState(false);
  const [missingFields, setMissingFields] = useState<string[]>([]);
  const [falseBooleanFields, setFalseBooleanFields] = useState<string[]>([]);
  const [category, setCategory] = useState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const objectId = searchParams.get("oi");

  // Check object eligibility
  const eligibilityHandler = useMutation({
    mutationFn: checkEligiblity,
    onSuccess: ({ data }) => {
      const { missingFields, falseBooleanFields } = data[0]; // Assuming data structure
      setMissingFields(missingFields);
      setFalseBooleanFields(falseBooleanFields);
      setEligibility(
        missingFields.length === 0 && falseBooleanFields.length === 0,
      );
      setCategory(data[0].missingFieldCategory);
    },
    onError: () => {},
  });

  // Fetch current object if objectId is present
  const currentObject = useQuery({
    queryKey: ["object", objectId],
    queryFn: () => getObjectById({ id: objectId as string }),
    enabled: !!objectId,
  });

  const objectsList = useQuery({
    queryKey: ["objectsList", filterOption],
    queryFn: () => objectList(filterOption),
  });

  // Add Object mutation
  const addObjectHandler = useMutation({
    mutationFn: addObjects,
    onMutate: () => {
      toast.loading("Adding object...");
    },
    onSuccess: (data) => {
      toast.dismiss();
      toast.success("Object added successfully");
      navigate(`?oi=${data.id}`); // Update URL with new object ID
      queryClient.invalidateQueries({
        queryKey: ["object", data.id],
      }); // Refetch new object
      queryClient.invalidateQueries({
        queryKey: ["objectsList"],
      }); // Refetch updated object
      eligibilityHandler.mutate({ ids: [data.id] }); // Check eligibility after adding object
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
  });

  // Update Object mutation
  const updateObjectHandler = useMutation({
    mutationFn: updateObject,
    onMutate: () => {
      toast.loading("Updating object...");
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Object updated successfully");

      queryClient.invalidateQueries({
        queryKey: ["object", objectId],
      }); // Refetch updated object
      queryClient.invalidateQueries({
        queryKey: ["objectsList"],
      }); // Refetch updated object
      eligibilityHandler.mutate({ ids: [objectId as string] });
    },

    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
  });

  // Helper function to get differences between two objects
  const getObjectDiff = (oldObj: any, newObj: any) => {
    const diff: any = {};
    for (const key in newObj) {
      if (JSON.stringify(oldObj[key]) !== JSON.stringify(newObj[key])) {
        diff[key] = newObj[key];
      }
    }
    return diff;
  };

  // Either add or update object
  const updateOrAddItem = (data: any) => {
    if (objectId && currentObject.data) {
      const updatedFields = getObjectDiff(currentObject.data, data);
      if (Object.keys(updatedFields).length > 0) {
        updateObjectHandler.mutate({ id: objectId, data: updatedFields });
      }
    } else {
      const isAnyFieldFilled = Object.values(data).some(
        (field) => field !== "" && field !== null && field !== undefined,
      );
      if (isAnyFieldFilled) {
        addObjectHandler.mutate(data);
      }
    }
  };

  useEffect(() => {
    if (objectId) {
      eligibilityHandler.mutate({ ids: [objectId] });
    }
  }, []);

  const contextValue: ObjectContextType = {
    currentObject: currentObject.data,
    isLoading: currentObject.isLoading,
    updateOrAddItem,
    setFilterOption,
    setQuery,
    filterOption,
    objectsList,
    eligibility,
    missingFields,
    falseBooleanFields,
    category,
  };

  return (
    <ObjectContext.Provider value={contextValue}>
      {children}
    </ObjectContext.Provider>
  );
};

// Custom hook to use ObjectContext
export const useObjectContext = () => {
  const context = useContext(ObjectContext);
  if (!context) {
    throw new Error(
      "useObjectContext must be used within ObjectContextProvider",
    );
  }
  return context;
};

export default ObjectContextProvider;

import React, { useState } from "react";
import SideBar from "../add-object/SideBar";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { AlertTriangle, ShieldPlus } from "lucide-react";
import { Button } from "@/components/ui/button";
import Header from "@/components/Global/header/Header";
import GlobalDialog from "@/modal/GlobalDialog";
import useAsignProtect from "@/apis/protect";
import { useObjectContext } from "@/context/objects";
import { cn } from "@/lib/utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import AsignTimeline from "./AsignTimeline";

const AsignRequest = () => {
  const [currentStep, setCurrentStep] = useState("1");
  const { eligibility, category, currentObject } = useObjectContext();
  const { applyAsignProtect } = useAsignProtect();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const objectId = searchParams.get("oi");
  const queryClient = useQueryClient();
  const applyAsignProtectHandler = useMutation({
    mutationFn: applyAsignProtect,
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Request sent successfully");
      queryClient.invalidateQueries({
        queryKey: ["object", objectId],
      }); // Refetch new object
      navigate(`/catalog/request/recived?oi=${objectId}`);
    },
    onMutate: () => {
      toast.loading("Requesting...");
    },
  });

  return (
    <div className="container flex-col h-screen font-normal font-sh5">
      <Header />
      <div className="flex-col sm:flex sm:w-full sm:h-full sm:overflow-hidden sm:flex-row">
        <div className="sm:w-1/4 sm:h-full sm:pr-4">
          <SideBar currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
        <div className="border-t border-solid border-gray-300 sm:w-3/4 sm:bg-[#F6F6F6] sm:py-10 sm:px-12 sm:h-full sm:overflow-hidden">
          <main className="">
            {currentObject?.status !== "request" && (
              <Card className="sm:py-10 sm:px-[120px]">
                <CardHeader className="space-y-4">
                  <div className="flex items-center space-x-2">
                    <img src="/Layer1.svg" alt="layerText" />
                    <h1 className="text-[32px] font-normal">Asign Protect+</h1>
                  </div>
                  <p className="text-[#696969] text-[16px] font-normal">
                    Add our proprietary Asign Protect+ labels to your object and{" "}
                    <br />
                    unlock a variety of services on Asign.
                  </p>
                  <Button
                    variant="link"
                    className="p-0 h-auto justify-start font-medium text-xs text-[#1D1D1D] underline hover:no-underline"
                  >
                    LEARN MORE
                  </Button>
                </CardHeader>
                <CardContent className="space-y-4  sm:mb-[124px]">
                  {eligibility ? (
                    <>
                      {" "}
                      <div className="flex items-center p-4 space-x-2 text-black rounded-md bg-green-50 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="w-5 h-5"
                        >
                          <circle cx="12" cy="12" r="10" />
                          <path d="M8 14s1.5 2 4 2 4-2 4-2" />
                          <line x1="9" y1="9" x2="9.01" y2="9" />
                          <line x1="15" y1="9" x2="15.01" y2="9" />
                        </svg>
                        <span className="font-normal text-[14px] text-[#1D1D1D]">
                          You are eligible to add Asign Protect+ to this object.
                        </span>
                      </div>
                      <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
                        {/* <GlobalDialog
                        TriggerButton={}
                        title="Inventory and Authentication label"
                        onConfirm={handleConfirm}
                        onCancel={handleCancel}
                        confirmText="SAVE"
                        isError={isError}
                      >
                        <div className="space-y-4">
                          <ul className="space-y-2 list-disc list-inside">
                            <li>
                              Store your object details securely on a
                              blockchain.
                            </li>
                            <li>
                              Get your object verified and authenticated by
                              experts.
                            </li>
                            <li>
                              Build trust and increase revenue safely and
                              confidently.
                            </li>
                            <li>Avail expert Advisory Services.</li>
                            <li>
                              Track your object’s provenance throughout its
                              lifetime.
                            </li>
                          </ul>
                        </div>
                      </GlobalDialog> */}
                        <Button
                          className="bg-gray-900 text-medium text-[12px] text-white  hover:bg-gray-800 rounded-full py-[14px] px-7 h-[42px]"
                          onClick={() =>
                            applyAsignProtectHandler.mutate({
                              ids: [objectId as string],
                            })
                          }
                          disabled={
                            currentObject?.isLoading ||
                            !eligibility ||
                            currentObject?.status == "request"
                          }
                        >
                          REQUEST ASIGN PROTECT+
                        </Button>
                        <Button
                          variant="outline"
                          className="w-1/4 sm:w-auto  rounded-full px-[28px] py-[14px] h-[42px] border-[#CFCFCF] "
                        >
                          <p className="text-[12px] font-medium ">
                            View Details
                          </p>
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <div className="flex items-center p-4 space-x-2 text-black rounded-md bg-red-50">
                        <AlertTriangle className="w-5 h-5 text-red-500" />
                        <span className="font-normal text-[14px] text-[#1D1D1D]">
                          This object is not eligible for Asign Protect+.
                        </span>
                      </div>
                      <p className="text-[#696969] text-[16px] font-normal">
                        Add or edit details in the following sections to enable
                        Asign Protect+:
                      </p>
                      <ul className="space-y-4">
                        {category &&
                          Object.keys(category).map((section: any, index) => (
                            <li
                              key={index}
                              className="flex items-center space-x-2"
                            >
                              <span
                                className={cn(
                                  "flex items-center justify-center w-6 h-6 text-white bg-red-400 rounded-full",
                                  category[section].length == 0 &&
                                    "bg-green-500",
                                )}
                              >
                                {index + 1}
                              </span>
                              <span className="text-[16px] font-normal capitalize">
                                {section}
                              </span>
                            </li>
                          ))}
                      </ul>
                      <Button className="bg-gray-900 text-medium text-[12px] text-white hover:bg-gray-800 rounded-full py-[14px] px-7 h-[42px]">
                        COMPLETE NOW
                      </Button>
                    </>
                  )}
                </CardContent>
                <CardFooter>
                  <div className="flex flex-col space-y-4 sm:space-y-0 sm:flex-row sm:items-center sm:space-x-2">
                    <span className="text-sm font-normal text-[#696969]">
                      Need help with Asign Protect+?
                    </span>
                    <Button
                      variant="link"
                      className="justify-start h-auto p-0 underline sm:justify-center hover:no-underline"
                    >
                      <p className="font-medium text-sm text-[#1D1D1D]">
                        REQUEST A CALLBACK
                      </p>
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            )}
            {currentObject?.status === "request" && <AsignTimeline />}
          </main>
          <footer className="p-4 mt-10 space-y-2 text-sm text-center text-gray-500">
            <div className="flex items-center justify-start ml-2 space-x-2 sm:ml-0 sm:justify-center sm:mb-6">
              <span className="text-sm font-normal text-[#696969]">
                Have questions?
              </span>
              <Button
                variant="link"
                className="p-0 h-auto text-sm font-medium text-[#1D1D1D]"
              >
                SEE KNOWLEDGE CENTRE
              </Button>
            </div>
            <div className="flex items-center justify-start ml-2 space-x-2 sm:ml-0 sm:justify-center">
              <Button
                variant="link"
                className="p-0 h-auto text-sm font-normal text-[#696969]"
              >
                Terms & Conditions
              </Button>
              <span>|</span>
              <Button
                variant="link"
                className="p-0 h-auto text-sm font-normal text-[#696969]"
              >
                Privacy Policy
              </Button>
              <span>|</span>
              <span className="sm:hidden font-normal text-[#696969]">
                Contact us
              </span>
              <span className="hidden sm:inline font-normal text-[#696969]">
                © 2024 Democrart Technologies Private Limited
              </span>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default AsignRequest;

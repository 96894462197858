import React from "react";
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import ViewImgVid from "../ViewImgVid";
import { FileItem, VideoItem } from "@/types/boards.type";
interface CarouselDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  videoItems?: VideoItem[];
  fileItems?: FileItem[];
  initialId: string;
}

const CarouselDrawer: React.FC<CarouselDrawerProps> = ({
  isOpen,
  onClose,
  videoItems = [],
  fileItems = [],
  initialId,
}) => {
  if (!isOpen) return null; // Ensure this logic is correct

  return (
    <Drawer open={isOpen} onClose={onClose}>
      {" "}
      <DrawerContent className="h-[92%] rounded-none">
        <DrawerHeader>
          <DrawerTitle />
        </DrawerHeader>
        <ViewImgVid
          onClose={onClose}
          videoItems={videoItems}
          fileItems={fileItems}
          initialId={initialId}
        />
      </DrawerContent>
    </Drawer>
  );
};

export default CarouselDrawer;

import React, { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import GlobalDialog from "@/modal/GlobalDialog";
import { useUserContext } from "@/context/user";
import { useObjectContext } from "@/context/objects";

const formSchema = z.object({
  addressType: z.string().min(2, {
    message: "Location name must be at least 2 characters.",
  }),
  subAddressType: z.string().optional(),
  lineOne: z.string().min(1, {
    message: "Address line 1 is required.",
  }),
  lineTwo: z.string().optional(),
  pinCode: z.string().regex(/^\d{6}$/, {
    message: "PIN code must be 6 digits.",
  }),
  city: z.string().min(1, {
    message: "City is required.",
  }),
  state: z.string().min(1, {
    message: "Please select a state.",
  }),
  country: z.string().min(1, {
    message: "Please select a country.",
  }),
});

const AddLocation = ({
  children,
  defaultId,
  addressType,
}: {
  children: React.ReactNode;
  defaultId?: string;
  addressType?: string;
}) => {
  const { updateUser, me } = useUserContext();
  const { updateOrAddItem, currentObject } = useObjectContext();
  const defaultLocation = currentObject?.pastLocations.find(
    (item: any) => item._id === defaultId,
  ) || {
    addressType: "",
    subAddressType: "",
    lineOne: "",
    lineTwo: "",
    pinCode: "",
    city: "",
    state: "",
    country: "India",
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultLocation,
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    if (defaultId) {
      updateOrAddItem({
        pastLocations: [
          {
            ...values,
            _id: defaultId,
          },
        ],
      });
      return;
    }
    updateUser.mutate({
      address: [values],
    });
  }

  return (
    <GlobalDialog
      TriggerButton={children}
      title="Add Location"
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form className="space-y-8">
          <FormField
            control={form.control}
            name="addressType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Location name</FormLabel>
                <FormControl>
                  <Input placeholder="Location name" {...field} />
                </FormControl>
                <FormDescription>
                  Add a unique name for this location. Eg: Studio in Mumbai,
                  Warehouse in Delhi, Office in Chennai, Home, etc.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="subAddressType"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Sub-location</FormLabel>
                <FormControl>
                  <Input placeholder="Sub-location" {...field} />
                </FormControl>
                <FormDescription>
                  Eg: First floor, Bedroom, Lobby, Corridor, etc.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lineOne"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address line 1</FormLabel>
                <FormControl>
                  <Input placeholder="Address line 1" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lineTwo"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Address line 2</FormLabel>
                <FormControl>
                  <Input placeholder="Address line 2" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="pinCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>PIN code</FormLabel>
                  <FormControl>
                    <Input placeholder="PIN code" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="city"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>City</FormLabel>
                  <FormControl>
                    <Input placeholder="City" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="state"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>State</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a state" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="maharashtra">Maharashtra</SelectItem>
                      <SelectItem value="delhi">Delhi</SelectItem>
                      <SelectItem value="tamil-nadu">Tamil Nadu</SelectItem>
                      {/* Add more states as needed */}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="country"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Country</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={String(field.value)}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select a country" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      <SelectItem value="india">India</SelectItem>
                      {/* Add more countries as needed */}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddLocation;

import React from "react";

interface WorkCardProps {
  className?: string;
  label: string;
  description?: string;
  price?: string;
  image?: string;
}

const WorkCard = (props: WorkCardProps) => {
  return (
    <div>
      <div className="w-[290px] aspect-square bg-bg-5 grid place-items-center p-10">
        <img
          src={props.image ?? "https://picsum.photos/200"}
          alt={props.label}
          className="object-cover w-full h-full "
        />
      </div>
      <h5 className="mt-5 text-lg font-normal capitalize truncate">
        {props.label}
      </h5>
      <h6 className="text-[16px] font-normal text-gray-60 truncate capitalize">
        {props.description}
      </h6>
      <p className="text-[16px] font-normal text-gray-60">2021</p>
      <p className="text-[16px]  text-gray-100 font-medium mt-2">₹7,500</p>
    </div>
  );
};

export default WorkCard;

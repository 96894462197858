import React, { useState } from "react";
import LeftFormImage from "../RegistrationForm/LeftFormImage";
import ForgetPasswordStep1 from "./ForgetPasswordStep1";
import { useNavigate } from "react-router-dom";
import VerifyOTPStep2 from "./ForgetPasswordStep2";
import ResetPasswordStep3 from "./ResetPasswordStep3";
import { Button } from "@/components/ui/button";
import useUserAPI from "@/apis/user";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Menu } from "lucide-react";

interface UserData {
  id?: string;
  phone?: string;
  keycloakId?: string;
}

export default function ForgotPassword() {
  const { sendOtp, verifyOtp, resetPassword } = useUserAPI();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [userData, setUserData] = useState<UserData>({});
  const [resendDisabled, setResendDisabled] = useState(false);
  const [resendTimer, setResendTimer] = useState(0);

  // Handle OTP resend cooldown
  const startResendTimer = () => {
    setResendDisabled(true);
    setResendTimer(30);
    const timer = setInterval(() => {
      setResendTimer((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          setResendDisabled(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const sendOtpHandler = useMutation({
    mutationFn: sendOtp,
    onSuccess: (data) => {
      toast.success("OTP sent successfully");
      startResendTimer();
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Failed to send OTP");
      setCurrentStep(1);
    },
  });

  const verifyOtpHandler = useMutation({
    mutationFn: verifyOtp,
    onSuccess: (data) => {
      toast.success("OTP verified successfully");
      setUserData(data); // Store user data for future use
      setOtpVerified(true);
      setCurrentStep(3);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Invalid OTP");
      setOtpVerified(false);
    },
  });

  const resetPasswordHandler = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      toast.success("Password reset successfully");
      setCurrentStep(4);
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message || "Password reset failed");
    },
  });

  const handleContinueStepOne = async (mobileNumber: string) => {
    if (!mobileNumber || mobileNumber.trim().length < 10) {
      toast.error("Please enter a valid phone number");
      return;
    }

    try {
      await sendOtpHandler.mutateAsync({ phone: mobileNumber });
      setPhoneNumber(mobileNumber);
      setCurrentStep(2);
    } catch (error) {
      // Error already handled in mutation
    }
  };

  const handleContinueStepTwo = async (otp: string) => {
    if (!otp || otp.length !== 6) {
      toast.error("Please enter a valid 6-digit OTP");
      return;
    }

    try {
      await verifyOtpHandler.mutateAsync({
        otp,
        phone: phoneNumber,
      });
    } catch (error) {
      // Error already handled in mutation
    }
  };

  const handleContinueStep3 = async (password: string) => {
    if (!password || password.length < 8) {
      toast.error("Password must be at least 8 characters long");
      return;
    }

    try {
      await resetPasswordHandler.mutateAsync({
        password,
        id: userData.id ?? "",
      });
    } catch (error) {
      // Error already handled in mutation
    }
  };

  const handleEditNumber = () => {
    setCurrentStep(1);
    setPhoneNumber("");
    setOtpVerified(false);
    setUserData({});
  };

  const handleResendOTP = async () => {
    if (resendDisabled) {
      toast.error(
        `Please wait ${resendTimer} seconds before requesting a new OTP`,
      );
      return;
    }

    try {
      await sendOtpHandler.mutateAsync({ phone: phoneNumber });
    } catch (error) {
      // Error already handled in mutation
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate("/login");
    }
  };

  const handleCancel = () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to cancel? All progress will be lost.",
    );
    if (confirmCancel) {
      navigate("/login");
    }
  };

  // Loading states
  const isLoading =
    sendOtpHandler.isPending ||
    verifyOtpHandler.isPending ||
    resetPasswordHandler.isPending;

  return (
    <div className="flex h-screen bg-white font-sh5">
      <LeftFormImage />
      <div className="absolute right-14 top-5 cursor-pointer">
        <Menu strokeWidth={0.7} size={35} />
      </div>

      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="w-12 h-12 border-b-2 border-white rounded-full animate-spin"></div>
        </div>
      )}

      {currentStep === 1 && (
        <ForgetPasswordStep1
          onContinue={handleContinueStepOne}
          onBack={handleBack}
          isLoading={isLoading}
        />
      )}

      {currentStep === 2 && (
        <VerifyOTPStep2
          phoneNumber={phoneNumber}
          onContinue={handleContinueStepTwo}
          onEditNumber={handleEditNumber}
          onResendOTP={handleResendOTP}
          resendDisabled={resendDisabled}
          resendTimer={resendTimer}
          isLoading={isLoading}
        />
      )}

      {currentStep === 3 && (
        <ResetPasswordStep3
          onContinue={handleContinueStep3}
          onCancel={handleCancel}
          onBack={handleBack}
          isLoading={isLoading}
        />
      )}

      {currentStep === 4 && (
        <div className="flex flex-col justify-center w-full max-w-md min-h-screen p-6 mx-auto">
          <div className="flex flex-col gap-12 items-start">
            <svg
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="1"
              className="w-16 h-16  text-[#696969]"
            >
              <polyline points="20 6 9 17 4 12" strokeWidth="0.5" />
            </svg>
            <div>
              <h1 className="mb-2 text-3xl font-normal ">Password updated!</h1>
              <p className="text-base  text-gray-500">
                You have successfully changed your password
              </p>
            </div>
            <Button
              onClick={() => navigate("/login")}
              className="w-[150px] rounded-full py-6 text-sm bg-[#303030] hover:bg-[#262626] disabled:text-[#696969] disabled:bg-[#CFCFCF] disabled:opacity-100"
            >
              LOG IN AGAIN
            </Button>
          </div>
        </div>
      )}
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from "react";
export type BoardItemType =
  | ArtistItem
  | ObjectItem
  | EventItem
  | BusinessItem
  | LinkItem
  | FileItem
  | TextItem
  | WritingItem
  | VideoItem;

export interface WritingItem {
  _id: string;
  contentType: "writing";
  content: WritingContent;
  itemType: "writing";
}
interface WritingContent {
  title: string;
  coverImage: string;
  authorName: string;
  description: string;
  _id: string;
}
interface FileItem {
  _id: string;
  name: string;
  image: string;
  itemType: "file";
  createdAt: string;
}
interface TextItem {
  _id: string; // e.g., "ab7a2c05-c295-44ec-9fa0-429cc14df6ec"
  title: string; // e.g., "Sample Title"
  summary: string; // e.g., "This is a summary of the text item."
  createdAt: string; // e.g., "2024-07-29T05:51:11.039Z"
  itemType: "text"; // Explicitly set as 'text'
  image: string;
}
interface VideoItem {
  _id: string;
  contentType: "video";
  content: VideoContent;
  itemType: "video";
  image: string;
}

interface VideoContent {
  title: string; // e.g., "Futrist arts"
  coverImage: string; // e.g., "https://i0.wp.com/designsketch.in/wp-content/uploads/2023/01/37-min.jpg?resize=740%2C685&ssl=1"
  url: string; // e.g., "https://www.youtube.com/watch?v=d95PPykB2vE"
  image: string;
}

interface BusinessItem {
  _id: string;
  businessName: string;
  image: string;
  description: string;
  businessDate: string;
  itemType: "business";
}

interface EventItem {
  _id: string;
  eventName: string;
  image: string;
  description: string;
  state: string;
  startDate: string;
  endDate: string;
  itemType: "events";
}

interface ArtistItem {
  _id: string;
  name: string;
  profilePic: string;
  gallery: string[];
  dob: string;
  itemType: "artist";
}

interface ObjectItem {
  _id: string;
  objectName: string;
  image: string;
  price: number;
  assignProtect: boolean;
  itemType: "object";
}

interface LinkItem {
  _id: string;
  link: string;
  createdAt: string;
  itemType: "link";
  caption: string;
}

export interface GetAllBoardParams {
  query: {
    searchQuery: string;
  };
  options: QueryOptions;
}

export interface QueryOptions {
  collation?: string;
  select?: string[];
  sort?: object;
  populate?: string;
  projection?: string;
  lean?: boolean;
  leanWithId?: boolean;
  offset?: number;
  page?: number;
  limit?: number;
  pageCount?: number;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  pagination?: boolean;
  useEstimatedCount?: boolean;
  useCustomCountFn?: boolean;
  forceCountFn?: boolean;
  read?: Record<string, any>;
  options?: Record<string, any>;
}

export interface searchQueryOption {
  search?: string;
  itemType?: string;
}
export type ItemType =
  | "artist"
  | "object"
  | "events"
  | "business"
  | "video"
  | "writing"
  | "link"
  | "file"
  | "text"
  | "all";
export interface CreateBoardParams {
  name?: string;
  description?: string;
  icon?: number;
  coverId?: string;
  pinned?: boolean;
  boardItems?: BoardItemType[];
}

export interface duplicateBoardParams {
  boardId: string;
}
export interface UpdateItemParams {
  boardId: string;
  itemId: string;
  updatedFields?: {
    tags?: string[];
    caption?: string;
    link?: string;
    title?: string;
    summary?: string;
  };
}
export interface TagParams {
  sectionType: string;
}

export interface AddItemParams {
  boardId: string;
  itemType: string;
  title?: string;
  summary?: string;
  link?: string;
  base64Image?: string;
  itemId?: string;
  name?: string;
}
export interface DeleteItemParam {
  boardId: string;
  itemId: string;
}

export interface similarItemParams {
  query: similarItemQuery;
  options?: QueryOptions;
}

interface similarItemQuery {
  boardId: string;
  itemType: string;
}

export interface Paginator {
  itemCount: number;
  offset: number;
  perPage: number;
  pageCount: number;
  currentPage: number;
  slNo: number;
  hasPrevPage: boolean;
  hasNextPage: boolean;
  prev: number | null;
  next: number | null;
}

export interface QueryData {
  query?: {
    [key: string]: string | number | [] | undefined;
  };
  options?: QueryOptions;
  isCountOnly?: boolean;
}

const useFilter = (initialState?: QueryData) => {
  const [filterOption, setFilterOption] = useState<QueryData>({
    ...initialState,
    options: {
      pageCount: 0,
      hasNextPage: false,
      hasPrevPage: false,
      page: 1,
      offset: 0,
      sort: {
        position: 1,
      },
      populate: "addedBy",
      pagination: false,
      ...initialState?.options,
    },
    query: {
      ...initialState?.query,
    },
  });

  const setPaginatorData = ({
    pageCount,
    hasNextPage,
    hasPrevPage,
    offset,
  }: {
    pageCount: number;
    hasNextPage: boolean;
    hasPrevPage: boolean;
    offset: number;
  }) => {
    setFilterOption((prev) => ({
      ...prev,
      options: {
        ...prev.options,
        pageCount,
        hasNextPage,
        hasPrevPage,
        offset,
      },
    }));
  };

  const refetchPaginationData = (next: number) => {
    setFilterOption((prev) => {
      const updatedOptions = {
        ...prev.options,
        page: next,
        offset: (next - 1) * 10,
      };
      return {
        ...prev,
        options: updatedOptions,
      };
    });
  };

  const setQuery = (query: object) => {
    setFilterOption((prev) => {
      return {
        ...prev,
        query: {
          ...prev.query,
          ...query,
        },
      };
    });
  };

  const removeQueryKey = (key: string) => {
    setFilterOption((prev) => {
      const { [key]: _, ...rest } = prev.query || {};
      return {
        ...prev,
        query: rest,
      };
    });
  };

  return {
    filterOption,
    setFilterOption,
    setPaginatorData,
    refetchPaginationData,
    setQuery,
    removeQueryKey,
    useFilter,
  };
};

export default useFilter;

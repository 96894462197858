import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from "react-hot-toast";
import View from "./pages/view";
import ArtistDetail from "./pages/detail/ArtistDetail";
import BusinessDetail from "./pages/detail/BusinessDetail";
import BottomNavBar from "./components/Global/BottomBarNav";
import EventDetail from "./pages/detail/EventDetail";
import WritingDetail from "./pages/detail/WritingDetail";
import ObjectDetail from "./pages/detail/ObjectDetail";
import Locationdetail from "./pages/view/Locationdetail";
import useToasterPosition from "./hooks/toastPosition";
import ProtectedLayout from "./layout/ProtectedLayout";
import Discover from "./pages/Discover";
import Objects from "./pages/Objects";
import Artists from "./pages/Artists";
import Businesses from "./pages/Businesses";
import Events from "./pages/Events";
import Boards from "./pages/Boards";
import SingleBoardPage from "./pages/SingleBoardPage";
import Detail from "./pages/catalog/Detail";
import ArtworkDisplay from "./pages/catalog/ArtworkDisplay";
import RequestSucces from "./pages/catalog/RequestSucces";
import MultiStepForm from "./pages/RegistrationForm/MultiStepForm";
import Profile from "./pages/profile";
import AddObjectForm from "./pages/add-object";
import ObjectContextProvider from "./context/objects";
import AsignRequest from "./pages/catalog/AsignRequest";
import AsignTimePage from "./pages/catalog/AsignTimePage";
import LoginForm from "./pages/LoginForm/LoginForm";
import ForgotPassword from "./pages/LoginForm/ForgetPassword";

function App() {
  const toasterPosition = useToasterPosition();
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    // Define titles and meta descriptions for each route
    const routesMeta: {
      [key: string]: { title: string; description: string };
    } = {
      "/discover/feed": {
        title: "Discover | Feed",
        description: "Welcome to our home page",
      },
      "/objects/feed": {
        title: "Objects | Feed",
        description: "Explore our objects",
      },
      "/artist/feed": {
        title: "Artists | Feed",
        description: "Discover artists",
      },
      "/businesses": {
        title: "Businesses | Feed",
        description: "Find businesses",
      },
      "/events/feed": {
        title: "Events | Feed",
        description: "Upcoming events",
      },
      "/boards": {
        title: "Boards",
        description: "Explore Boards",
      },
    };

    let title = "Default Title";
    let metaDescription = "Default description";

    // Handle dynamic routes like "/boards/:boardId" or "/artist/detail/:id"
    if (/^\/boards\/\w+$/.test(pathname)) {
      title = "Board | Explore";
      metaDescription = "Explore this board";
    } else if (/^\/artist\/detail\/\w+$/.test(pathname)) {
      title = "Artist | Detail";
      metaDescription = "Learn more about this artist";
    } else if (/^\/business\/\w+$/.test(pathname)) {
      title = "Business | Detail";
      metaDescription = "Learn more about this business";
    } else if (/^\/events\/detail\/\w+$/.test(pathname)) {
      title = "Event | Detail";
      metaDescription = "Learn more about this event";
    } else if (/^\/objects\/detail\/\w+$/.test(pathname)) {
      title = "Objects | Detail";
      metaDescription = "Learn more about this object";
    } else if (routesMeta[pathname]) {
      // Check if the path is an exact match in the static map
      title = routesMeta[pathname].title;
      metaDescription = routesMeta[pathname].description;
    }

    // Update the document's title and meta description
    document.title = title;

    const metaDescriptionTag = document.querySelector<HTMLMetaElement>(
      'head > meta[name="description"]',
    );
    if (metaDescriptionTag) {
      metaDescriptionTag.content = metaDescription;
    } else {
      // Create meta description tag if it doesn't exist
      const metaTag = document.createElement("meta");
      metaTag.name = "description";
      metaTag.content = metaDescription;
      document.head.appendChild(metaTag);
    }
  }, [pathname]);

  useEffect(() => {
    if (pathname === "/") {
      navigate("/discover/feed");
    }
  }, [pathname, navigate]);
  useEffect(() => {
    const handlePageLeave = (event: BeforeUnloadEvent) => {
      if (pathname === "/register") {
        console.log("inside");
        const message =
          "Are you sure you want to leave? Your changes may not be saved.";
        event.preventDefault();
        event.returnValue = message;
        return message;
      }
    };

    // For modern browsers
    window.addEventListener("beforeunload", handlePageLeave);
    // For older browsers and different scenarios
    window.addEventListener("unload", handlePageLeave);

    // Additional protection for single page applications
    const handlePopState = () => {
      if (pathname === "/register") {
        if (
          window.confirm(
            "Are you sure you want to leave? Your changes may not be saved.",
          )
        ) {
          // Handle navigation if confirmed
        } else {
          // Prevent navigation
          window.history.pushState(null, "", window.location.href);
        }
      }
    };

    window.addEventListener("popstate", handlePopState);

    // Cleanup
    return () => {
      window.removeEventListener("beforeunload", handlePageLeave);
      window.removeEventListener("unload", handlePageLeave);
      window.removeEventListener("popstate", handlePopState);
    };
  }, [pathname]);
  return (
    <>
      <Routes>
        <Route
          path="/discover/feed"
          element={
            <ProtectedLayout>
              <Discover />
            </ProtectedLayout>
          }
        />
        <Route path="/register" element={<MultiStepForm />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/reset" element={<ForgotPassword />} />
        <Route
          path="/profile"
          element={
            <ProtectedLayout>
              <Profile />
            </ProtectedLayout>
          }
        />
        <Route
          path="/boards"
          element={
            <ProtectedLayout>
              <Boards />
            </ProtectedLayout>
          }
        />
        <Route
          path="/business/feed"
          element={
            <ProtectedLayout>
              <Businesses />
            </ProtectedLayout>
          }
        />
        <Route
          path="/events/feed"
          element={
            <ProtectedLayout>
              <Events />
            </ProtectedLayout>
          }
        />
        <Route
          path="/boards/:boardId"
          element={
            <ProtectedLayout>
              <SingleBoardPage />
            </ProtectedLayout>
          }
        />
        <Route
          path="/:type/view/:id"
          element={
            <ProtectedLayout>
              <View />
            </ProtectedLayout>
          }
        />
        <Route
          path="/location/view"
          element={
            <ProtectedLayout>
              <Locationdetail />
            </ProtectedLayout>
          }
        />
        <Route
          path="/artist/detail/:id"
          element={
            <ProtectedLayout>
              <ArtistDetail />
            </ProtectedLayout>
          }
        />
        <Route
          path="/objects/detail/:id"
          element={
            <ProtectedLayout>
              <ObjectDetail />
            </ProtectedLayout>
          }
        />
        <Route
          path="/business/detail/:id"
          element={
            <ProtectedLayout>
              <BusinessDetail />
            </ProtectedLayout>
          }
        />
        <Route
          path="/events/detail/:id"
          element={
            <ProtectedLayout>
              <EventDetail />
            </ProtectedLayout>
          }
        />
        <Route
          path="/writing/detail/:id"
          element={
            <ProtectedLayout>
              <WritingDetail />
            </ProtectedLayout>
          }
        />
        <Route
          path="/objects/feed"
          element={
            <ProtectedLayout>
              <Objects />
            </ProtectedLayout>
          }
        />
        <Route
          path="/artist/feed"
          element={
            <ProtectedLayout>
              <Artists />
            </ProtectedLayout>
          }
        />
        <Route
          path="/catalog/detail"
          element={
            <ProtectedLayout>
              <ObjectContextProvider>
                <Detail />
              </ObjectContextProvider>
            </ProtectedLayout>
          }
        />
        <Route
          path="/catalog/detail/:id"
          element={
            <ProtectedLayout>
              <div>Hello</div>
            </ProtectedLayout>
          }
        />
        <Route
          path="/catalog/detail/artwork"
          element={
            <ObjectContextProvider>
              <ArtworkDisplay />
            </ObjectContextProvider>
          }
        />
        <Route
          path="/catalog/add/object"
          element={
            <ProtectedLayout>
              <ObjectContextProvider>
                <AddObjectForm />
              </ObjectContextProvider>
            </ProtectedLayout>
          }
        />
        <Route
          path="/catalog/asign/request"
          element={
            <ObjectContextProvider>
              <AsignRequest />
            </ObjectContextProvider>
          }
        />
        <Route
          path="/catalog/asign/timeline"
          element={
            <ObjectContextProvider>
              <AsignTimePage />
            </ObjectContextProvider>
          }
        />
        <Route
          path="/catalog/request/recived"
          element={
            <ProtectedLayout>
              <ObjectContextProvider>
                <RequestSucces />
              </ObjectContextProvider>
            </ProtectedLayout>
          }
        />
        <Route
          path="/user/profile"
          element={
            <ProtectedLayout>
              <Profile />
            </ProtectedLayout>
          }
        />
      </Routes>
      <BottomNavBar />
      <Toaster
        position={toasterPosition}
        toastOptions={{
          duration: 3000,
          icon: null,
          style: {
            background: "#1D1D1D",
            color: "#ffffff",
            borderRadius: "0px",
            padding: "20px",
            fontSize: "14px",
            fontWeight: "400px",
          },
        }}
      />
    </>
  );
}

export default App;

import React from "react";

interface HeadlineProps {
  text: string;
}

const Headline: React.FC<HeadlineProps> = ({ text }) => {
  return (
    <h1 className="m-0 relative text-inherit tracking-[-0.02em] leading-[100%] font-normal font-inherit inline-block min-w-[105px] mq450:text-13xl mq450:leading-[19px] mq1050:text-7xl mq1050:leading-[26px]">
      {text}
    </h1>
  );
};

export default Headline;

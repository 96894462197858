import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import GlobalDialog from "@/modal/GlobalDialog";
import { FileText, Upload } from "lucide-react";
import { useObjectContext } from "@/context/objects";

const formSchema = z.object({
  type: z.string().min(1, "Document type is required"),
  name: z.string().min(1, "Document name is required"),
  file: z.string().min(1, "Document file is required"),
});

const AddDocument = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { currentObject, updateOrAddItem } = useObjectContext();
  const defaultVaule = currentObject?.document?.records?.document?.find(
    (item: any) => item._id === defaultId,
  ) || {
    type: "",
    name: "",
    file: "",
  };
  const [base64String, setBase64String] = useState<string>("");
  const [uploadedFileName, setUploadedFileName] = useState<string>(
    defaultVaule.name,
  );

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultVaule,
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    if (defaultId) {
      updateOrAddItem({
        document: {
          records: {
            document: [
              {
                ...values,
                _id: defaultId,
              },
            ],
          },
        },
      });
      return;
    }
    updateOrAddItem({
      document: {
        records: {
          document: [values],
        },
      },
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        setBase64String(base64);
        form.setValue("file", base64);
        setUploadedFileName(file.name);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <GlobalDialog
      TriggerButton={children}
      title="Add Document"
      onCancel={() => form.reset()}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Document type</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select document type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="invoice">Invoice</SelectItem>
                    <SelectItem value="report">Report</SelectItem>
                    <SelectItem value="contract">Contract</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Document name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter document name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="file"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Upload document</FormLabel>
                <FormControl>
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      {!uploadedFileName && (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <Upload className="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400" />
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            Up to 50 MB, in docx or pdf format
                          </p>
                        </div>
                      )}
                      {uploadedFileName && (
                        <div>
                          <p>{uploadedFileName}</p>
                        </div>
                      )}
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        accept=".docx,.pdf"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddDocument;

import React, { createContext, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import useUserAPI, { UserInteraction } from "@/apis/user";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";

// Define the shape of the context value
interface UserContextValue {
  interactions: UserInteraction | undefined;
  isLoading: boolean;
  isError: boolean;
  me: any;
  isLoadingMe: boolean;
  isErrorMe: boolean;
  updateUser: any;
  // Add more properties as needed
}

// Create the UserContext with a default value
const UserContext = createContext<UserContextValue>({
  interactions: undefined,
  isLoading: true,
  isError: false,
  me: undefined,
  isLoadingMe: true,
  isErrorMe: false,
  updateUser: undefined,
});

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { getUserIntreaction, myInfo, updateMe } = useUserAPI();
  const location = useLocation();
  const { isLogin } = useAuth();
  let currentLocation = location.pathname;
  // Use useQuery to fetch user interactionsconst
  const {
    data: interactions,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["user-interactions"],
    queryFn: () => getUserIntreaction(),
    enabled: isLogin,
  });

  const {
    data: me,
    isLoading: isLoadingMe,
    isError: isErrorMe,
  } = useQuery({
    queryKey: ["me"],
    queryFn: () => myInfo(),
    enabled: isLogin,
  });

  const updateUser = useMutation({
    mutationFn: updateMe,
    onSuccess: () => {
      toast.dismiss();
      toast.success("Profile updated successfully");
    },
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
    onMutate: () => {
      toast.loading("Updating profile...");
    },
  });

  // Provide the fetched data (or undefined if still loading or errored) to the context
  return (
    <UserContext.Provider
      value={{
        interactions,
        isLoading,
        isError,
        me,
        isLoadingMe,
        isErrorMe,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Export the context provider and the custom hook for consuming the context
export default UserContextProvider;
export const useUserContext = () => useContext(UserContext);

import React from "react";
import { Skeleton } from "../ui/skeleton";

const PageLoader = () => {
  return (
    <>
      <div className="flex flex-wrap items-center gap-4 mt-5 justify-center">
        {[1, 2, 3, 4].map((item) => (
          <Skeleton className="w-[297px] h-[220px]" key={item} />
        ))}
      </div>
      <Skeleton className="w-[200px] h-5 mt-5" />
      <div className="flex flex-wrap gap-4 mt-5 items-center justify-center">
        {[1, 2, 3, 4].map((item) => (
          <Skeleton className="w-[297px] h-[220px]" key={item} />
        ))}
      </div>
      <Skeleton className="w-[200px] h-5 mt-5" />
      <div className="flex flex-wrap items-center gap-4 mt-5 justify-center">
        {[1, 2, 3, 4].map((item) => (
          <Skeleton className="w-[297px] h-[220px]" key={item} />
        ))}
      </div>
      <Skeleton className="w-[200px] h-5 mt-5" />
      <div className="flex flex-wrap gap-4 mt-5 items-center justify-center">
        {[1, 2, 3, 4].map((item) => (
          <Skeleton className="w-[297px] h-[220px]" key={item} />
        ))}
      </div>
    </>
  );
};

export default PageLoader;

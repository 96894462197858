import React, { ChangeEvent, ReactNode, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import { Textarea } from "@/components/ui/textarea";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import useBoard from "@/apis/board";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { UpdateItemParams } from "@/hooks/useFilter";

const { editItem } = useBoard();
type AddLinkProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
  defaultLink: string;
};

const EditLink: React.FC<AddLinkProps> = ({
  children,
  boardId,
  itemId,
  defaultLink,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [link, setLink] = useState(defaultLink);
  const queryClient = useQueryClient();
  const updateCaptionMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    UpdateItemParams
  > = useMutation({
    mutationFn: (params: UpdateItemParams) => editItem({ params }), // Call editItem with parameters
    onSuccess: (data) => {
      toast.success(`Item updated successfully: ${data.message}`, {
        id: "updating-item",
      }); // Notify on success
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
    },
    onError: (error: Error) => {
      toast.error(`Failed to update item: ${error.message}`, {
        id: "updating-item",
      }); // Notify on error
    },
    onMutate: () => {
      toast.loading("updating the item", {
        id: "updating-item",
      });
    },
  });
  const handleSave = async () => {
    // Prepare the UpdateItemRequest object
    const URL_REGEX = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w-./?%&=]*)?$/i;
    if (link.trim() === "") {
      toast.error("Link is required.");
      return;
    }

    if (!URL_REGEX.test(link)) {
      toast.error("Please enter a valid URL"); // Set error message for invalid URL
      return;
    }

    const updateRequest: UpdateItemParams = {
      boardId,
      itemId,
      updatedFields: {
        link, // Pass the current caption state
      },
    };

    try {
      // Trigger the mutation with updateRequest
      await updateCaptionMutation.mutateAsync(updateRequest); // Use mutateAsync for async handling
    } catch (error) {
      console.error("Error saving data:", error);
      toast.error("Error occurred while saving.");
    }
  };
  return isDesktop ? (
    <Dialog>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>
      <DialogContent className="max-w-[754px]">
        <DialogHeader className="mx-[32px]">
          <DialogTitle className="border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Edit Link</p>
              <div className="flex flex-row items-center gap-4 cursor-pointer">
                <DialogClose asChild>
                  <img src="/close1.svg" alt="Close" />
                </DialogClose>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm
          handleSave={handleSave}
          setLink={setLink}
          boardId={boardId}
          link={link}
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none ">
        <DrawerHeader>
          <DrawerTitle className=" border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Edit Link</p>
              <div className="flex flex-row items-center gap-4">
                <DialogClose asChild>
                  <img src="/close1.svg" alt="Close" />
                </DialogClose>
              </div>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm
          handleSave={handleSave}
          setLink={setLink}
          boardId={boardId}
          link={link}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string; // Add boardId prop
  link: string;
  setLink: (link: string) => void;
  handleSave: () => void;
};

function ProfileForm({
  className,
  boardId,
  link,
  setLink,
  handleSave,
}: ProfileFormProps) {
  return (
    <form className={cn("grid items-center gap-5 mq450:gap-8", className)}>
      <div className="flex flex-col gap-3 justify-center px-[32px] items-start">
        <p className="mq450:pb-3">Url</p>
        <input
          placeholder="Enter Url"
          value={link}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLink(e.target.value)
          }
          className={cn(
            "w-full p-5 rounded-none  font-sh5 font-medium placeholder:text-[#979797] text-base border-solid border-gray-10 border placeholder:font-normal"
          )}
        />
      </div>

      <div className="flex  flex-row items-center justify-between w-full p-5 px-[32px] bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="cursor-pointer h-6 min-w-12 font-medium text-xs relative font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none"
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] min-w-[83px] h-[42px] px-4 font-medium text-sm text-white font-sh5"
          variant="default"
          type="button"
          onClick={handleSave}
        >
          SAVE
        </Button>
      </div>
    </form>
  );
}
export default EditLink;

import GlobalDialog from "@/modal/GlobalDialog";
import React from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useUserContext } from "@/context/user";

const formSchema = z.object({
  collectorName: z
    .string()
    .min(2, "Collector's name must be at least 2 characters"),
  location: z.string().min(2, "Location must be at least 2 characters"),
  isPublic: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

const AddNotableCollection = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { me, updateUser } = useUserContext();
  const defaultValue = me?.notableCollections?.find(
    (item: any) => item._id === defaultId,
  ) || {
    collectorName: "",
    location: "",
    isPublic: false,
  };
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValue,
  });

  const onSubmit = (data: FormValues) => {
    if (defaultId) {
      updateUser.mutate({
        notableCollections: [
          {
            ...data,
            _id: defaultId,
          },
        ],
      });
      return;
    }

    updateUser.mutate({
      notableCollections: [data],
    });
  };

  const title = "Add Notable Collection";
  return (
    <GlobalDialog
      title={title}
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="collectorName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Collector's name
                </FormLabel>
                <FormControl>
                  <Input placeholder="Ashvin Rajagopalan" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Location
                </FormLabel>
                <FormControl>
                  <Input placeholder="Mumbai" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="isPublic"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row items-center justify-between -mx-[25px] border-solid border-bdr-10 border-t pt-[22px] px-5">
                  <div className="space-y-0.5 ">
                    <FormLabel className="text-base font-normal">
                      Publicly visible
                    </FormLabel>
                  </div>
                  <div className="">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </div>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddNotableCollection;

import { sectionParams } from "@/apis/artist";
import useGlobalApis from "@/apis/global";
import Header from "@/components/Global/header/Header";
import Loader from "@/components/Global/Loader";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import useFilter from "@/hooks/useFilter";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { BussinessView, EventView } from ".";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const Locationdetail = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get("t");

  const { getFilters, getList } = useGlobalApis();
  const { filterOption, setQuery } = useFilter();

  const search = useDebouncedCallback((value: string) => {
    setQuery({
      searchQuery: value,
    });
  }, 700);

  const { data: view, isLoading } = useQuery({
    queryKey: ["view-list", filterOption],
    queryFn: () =>
      getList({
        type: type as any,
        params: filterOption,
      }),
  });

  const { data: filters } = useQuery({
    queryKey: ["filters", type],
    queryFn: () => getFilters(type as any),
    enabled: Boolean(type),
  });

  return (
    <>
      {isLoading && <Loader />}
      <Header />
      <div className="container font-normal font-sh5 lg:px-[48px] px-5">
        <Breadcrumb className="lg:mt-[64px] mt-5">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink href="/">Discover</BreadcrumbLink>
            </BreadcrumbItem>
            <span className="text-black">/</span>
            <BreadcrumbItem>
              <BreadcrumbLink
                href={`
                /${type}/feed
              `}
              >
                {type}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <div className="flex items-center justify-between flex-1 gap-2 my-10">
          <div className="hidden gap-2 lg:flex">
            {filters?.map((item: any) => (
              <Select
                key={item.name}
                onValueChange={(value) => setQuery({ [item.name]: value })}
                // defaultValue={filterOption?.query?.filters?.[item.name]}
              >
                <SelectTrigger className="w-[116px] rounded-full">
                  <SelectValue
                    placeholder={item.label}
                    className="capitalize placeholder:capitalize"
                  />
                </SelectTrigger>
                <SelectContent>
                  {item.options.map((item: any) => (
                    <SelectItem
                      value={item.value}
                      key={item.value}
                      className="capitalize"
                    >
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            ))}
          </div>
          <div className="flex items-center w-full gap-1 px-2 border border-solid border-gray-20 max-w-[340px] rounded-full">
            <img src="/search.svg" alt="search" />
            <input
              className="w-full font-normal bg-transparent border-none outline-none h-9 font-sh5"
              placeholder="Search"
              type="text"
              defaultValue={filterOption.query?.searchQuery ?? ""}
              onChange={(e) => search(e.target.value)}
            />
          </div>
        </div>
        <div>
          {type === "business" && (
            <div className="flex flex-wrap gap-5">
              {view?.data.map((item: any) => (
                <BussinessView key={item._id} item={item} />
              ))}
            </div>
          )}
          {type === "events" && (
            <div className="flex flex-wrap gap-5">
              {view?.data.map((item: any) => (
                <EventView key={item._id} item={item} />
              ))}
            </div>
          )}
          {view?.data.length === 0 && !isLoading && (
            <div className="grid place-items-center h-[500px] bg-[#FCFCFC] my-5">
              <div className="flex flex-col items-center gap-1 font-normal font-sh5">
                <img src="/database.svg" alt="" />
                <p>No Data Found</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Locationdetail;

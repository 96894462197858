import React from "react";
import { apis } from ".";

const useContentLib = () => {
  const getContentById = async (id: string) => {
    try {
      const { data } = await apis.get(
        `/client/api/v1/contentlibrary/${id}`,
        {}
      );
      return data.data;
    } catch (error) {
      console.error("Error creating event:", error);
      throw error;
    }
  };

  return { getContentById };
};

export default useContentLib;

import React, { useState, useRef } from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Copy } from "lucide-react";
import GlobalDialog from "@/modal/GlobalDialog";
import { useUserContext } from "@/context/user";

// Schema validation with Zod
const formSchema = z.object({
  image: z.string().optional(), // Base64 string after conversion
  type: z.string().min(1, "Event type is required"),
  eventName: z.string().min(2, "Event name must be at least 2 characters"),
  isPublic: z.boolean(),
});

// Define the form's shape based on the schema
type FormValues = z.infer<typeof formSchema>;

const types = [
  "Exhibition",
  "Art Fair",
  "Gallery Opening",
  "Artist Talk",
  "Workshop",
];

const AddEvent = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { me, updateUser } = useUserContext();
  const defaultValue = me?.events?.find(
    (item: any) => item._id === defaultId,
  ) || {
    type: "",
    eventName: "",
    isPublic: false,
  };
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    defaultValue?.image ?? null,
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValue,
  });

  const title = "Add Event";

  // Function to convert file to base64 string
  const convertFileToBase64 = (file: File) => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handle file selection and conversion to base64
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const base64String = await convertFileToBase64(file);
        form.setValue("image", base64String); // Store base64 string in the form
        setPreviewUrl(URL.createObjectURL(file)); // Optional: Set preview URL
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  // Handle file drop and conversion to base64
  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      try {
        const base64String = await convertFileToBase64(file);
        form.setValue("image", base64String);
        setPreviewUrl(URL.createObjectURL(file));
      } catch (error) {
        console.error("Error converting file to base64:", error);
      }
    }
  };

  const handleDeleteImage = (e: React.MouseEvent) => {
    e.stopPropagation();
    form.setValue("image", "");
    setPreviewUrl(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };
  // Handle form submission
  const onSubmit = (data: FormValues) => {
    if (defaultId) {
      updateUser.mutate({
        events: [
          {
            ...data,
            _id: defaultId,
          },
        ],
      });
      return;
    }

    updateUser.mutate({
      events: [data],
    });
  };

  return (
    <GlobalDialog
      title={title}
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form className="space-y-6">
          <FormField
            control={form.control}
            name="image"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div
                    className="relative flex items-center justify-center w-full h-48 overflow-hidden border-2 border-gray-300 border-dashed rounded-md cursor-pointer"
                    onClick={() => fileInputRef.current?.click()}
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {previewUrl ? (
                      <>
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="object-contain w-full h-full"
                        />
                        <Button
                          type="button"
                          variant="ghost"
                          size="icon"
                          className="absolute p-1 bg-white rounded-full top-2 right-2 hover:bg-red-100"
                          onClick={handleDeleteImage}
                        >
                          <img src="/delete.svg" alt="" />
                        </Button>
                      </>
                    ) : (
                      <div className="text-center">
                        <p className="text-sm text-gray-500">
                          Drag and drop or click to upload
                        </p>
                        <p className="mt-1 text-xs text-gray-400">
                          Up to 20 MB, in .jpg, .png, or .webp format
                        </p>
                      </div>
                    )}
                    <input
                      type="file"
                      accept=".jpg,.jpeg,.png,.webp"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Event type
                </FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger className="h-12">
                      <SelectValue placeholder="Select event type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {types.map((type) => (
                      <SelectItem key={type} value={type}>
                        {type}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="eventName"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Event name
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="Girish Agarwal Arts Gallery"
                    {...field}
                    className="h-12"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Public Visibility Switch */}
          <FormField
            control={form.control}
            name="isPublic"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row items-center justify-between -mx-[25px] border-solid border-bdr-10 border-t pt-[22px] px-5">
                  <div className="space-y-0.5 ">
                    <FormLabel className="text-base font-normal">
                      Publicly visible
                    </FormLabel>
                  </div>
                  <div className="">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </div>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddEvent;

import React from "react";
import { BusinessItem } from "@/types/boards.type";
import { Button } from "@/components/ui/button";
import AddItemDropDown from "../AddItemDropDown";

interface BusinessProps {
  items: BusinessItem[];
}

const Objects: React.FC<BusinessProps> = ({ items }) => {
  return (
    <div>
      {items.length === 0 ? (
        <p>No Business found.</p>
      ) : (
        items.map((item) => (
          <div
            className={`flex min-h-[297px] min-w-[297px] w-full h-full flex-col relative items-center justify-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 group`}
            key={item._id}
          >
            <div className="flex flex-col items-center flex-1 w-full">
              <img
                src={item.coverImage}
                className="object-cover w-full h-full bg-gradient-to-t from-zinc-900 to-neutral-50"
              />
              <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
              <AddItemDropDown
                itemID={item._id}
                boardID={""}
                itemType={"business"}
              >
                <span className="absolute flex invisible gap-1 p-2 text-sm transition-all duration-300 ease-in-out scale-95 bg-white rounded-full opacity-0 cursor-pointer top-4 text-other-cta right-4 group-hover:opacity-100 group-hover:scale-100 group-hover:visible">
                  <div className="flex flex-row items-center gap-1">
                    <img className="w-6 h-6" src="/label.svg" alt="label" />
                  </div>
                </span>
              </AddItemDropDown>
              <div className="absolute bottom-0 left-0 flex flex-row w-full gap-2 p-4 item-center">
                <img className="w-14 h-14 rounded-[100px]" src={item.image} />
                <div className="flex flex-col justify-center gap-2">
                  <h2 className="text-sm font-normal text-white font-sh5">
                    {item.businessName}
                  </h2>
                  <h2 className="font-sh5 font-normal text-sm text-[#CCCCCC]">
                    {item.businessLocation}
                  </h2>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Objects;

import GlobalDialog from "@/modal/GlobalDialog";
import React, { useEffect, useState } from "react";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Button } from "@/components/ui/button";
import { ChevronsUpDown, Check } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { cn } from "@/lib/utils"; // Ensure this utility is available
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Checkbox } from "@/components/ui/checkbox";
import { useUserContext } from "@/context/user";

// Define the form schema using Zod
const formSchema = z.object({
  galleryName: z.string().min(2, "Gallery name must be at least 2 characters"),
  representationType: z.enum(["exclusive", "non-exclusive"], {
    required_error: "You need to select a representation type",
  }),
  agreement: z.boolean().refine((val) => val === true, {
    message: "You must agree to the terms and conditions",
  }),
});

type FormValues = z.infer<typeof formSchema>;

const galleries = [
  {
    value: "devi-art-foundation-1",
    label: "Devi Art Foundation",
    location: "Kolkata, India",
    avatar: "/path-to-avatar-1.jpg",
  },
  {
    value: "devi-art-foundation-2",
    label: "Devi Art Foundation",
    location: "Kolkata, India",
    avatar: "/path-to-avatar-2.jpg",
  },
  {
    value: "devi-art-foundation-3",
    label: "Devi Art Foundation",
    location: "Kolkata, India",
    initials: "DA",
    avatar: "/path-to-avatar-2.jpg",
  },
  {
    value: "threee",
    label: "ansh",
    location: "Kolkata, India",
    initials: "DA",
    avatar: "/path-to-avatar-2.jpg",
  },
];

interface AddRepresentationProps {
  children: React.ReactNode;
}

const AddRepresentation = ({ children }: AddRepresentationProps) => {
  const { updateUser, me } = useUserContext();
  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      galleryName: "",
      representationType: undefined,
      agreement: false,
    },
  });

  const title = "Add representation";

  // State to control popover open state
  const [open, setOpen] = useState(false);

  // Handle form submission
  const handleSubmit = (data: FormValues) => {
    console.log(data);
  };

  useEffect(() => {
    console.log(form.watch("galleryName"));
  }, [form.watch("galleryName")]);

  return (
    <GlobalDialog title={title} TriggerButton={children}>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
          <FormDescription className="text-sm text-gray-500">
            The gallery will be contacted to approve your request. Once
            accepted, they will be added as a verified representation on your
            public profile.
          </FormDescription>
          {/* Gallery Name Field */}
          <FormField
            control={form.control}
            name="galleryName"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Gallery name</FormLabel>
                <Popover open={open} onOpenChange={setOpen}>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant="outline"
                        role="combobox"
                        aria-expanded={open}
                        className={cn(
                          "w-full justify-between",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value
                          ? galleries.find(
                              (gallery) => gallery.value === field.value,
                            )?.label
                          : "Select gallery"}
                        <ChevronsUpDown className="w-4 h-4 ml-2 opacity-50 shrink-0" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="p-0 z-[100]">
                    <Command>
                      <CommandInput placeholder="Search gallery..." />
                      <CommandList>
                        <CommandEmpty>No gallery found.</CommandEmpty>

                        {galleries.map((gallery) => (
                          <CommandItem
                            key={gallery.value}
                            onSelect={() => {
                              console.log("sss");
                              // Ensure the form value is updated here
                              form.setValue("galleryName", gallery.value);
                              setOpen(false); // Close popover after selection
                            }}
                          >
                            {gallery.label}
                          </CommandItem>
                        ))}
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Representation Type Field */}
          <FormField
            control={form.control}
            name="representationType"
            render={({ field }) => (
              <FormItem className="space-y-3">
                <FormLabel>Representation type</FormLabel>
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    className="flex flex-col space-y-1"
                  >
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="exclusive" />
                      </FormControl>
                      <FormLabel className="font-normal">Exclusive</FormLabel>
                    </FormItem>
                    <FormItem className="flex items-center space-x-3 space-y-0">
                      <FormControl>
                        <RadioGroupItem value="non-exclusive" />
                      </FormControl>
                      <FormLabel className="font-normal">
                        Non-exclusive
                      </FormLabel>
                    </FormItem>
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {/* Agreement Field */}
          <FormField
            control={form.control}
            name="agreement"
            render={({ field }) => (
              <FormItem className="flex flex-row items-start space-x-3 space-y-0">
                <FormControl>
                  <Checkbox
                    checked={field.value}
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <div className="space-y-1 leading-none">
                  <FormLabel>
                    I agree to the{" "}
                    <a href="#" className="underline">
                      Terms and Conditions
                    </a>{" "}
                    of Asign's representation agreement.
                  </FormLabel>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddRepresentation;

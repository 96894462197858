import React, { useState } from "react";
import {
  DndContext,
  closestCenter,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  useSortable,
  horizontalListSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ObjectType from "@/components/Boards/Object";
import EventType from "@/components/Boards/Event";
import TextType from "@/components/Boards/Text";
import LinkType from "@/components/Boards/Link";
import ArtistType from "@/components/Boards/Artist";
import BusinessType from "@/components/Boards/Business";
import FileType from "@/components/Boards/File";
import WritingType from "@/components/Boards/Writing";
import VideoType from "@/components/Boards/Video";
import { BoardItemType, GetSingleBoardResponse } from "@/types/boards.type";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import { CreateBoardParams } from "@/hooks/useFilter";
import toast from "react-hot-toast";
import Spinner from "../Global/Spinner";

type BoardItem = BoardItemType;

interface BoardItemsMobProps {
  boardItems: BoardItem[];
  boardId: string;
}

const BoardItemsMob: React.FC<BoardItemsMobProps> = ({
  boardItems,
  boardId,
}) => {
  const [rearangeLoading, setRearrangeLoading] = useState(false);
  const [items, setItems] = useState(boardItems);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor),
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over === null || active.id === over.id) return;

    const oldIndex = boardItems.findIndex((item) => item._id === active.id);
    const newIndex = boardItems.findIndex((item) => item._id === over.id);

    if (oldIndex === -1 || newIndex === -1) return;

    const newItems = arrayMove(boardItems, oldIndex, newIndex);
    setItems(newItems);
    setRearrangeLoading(true);
    handleIndexChange(newItems);
  };
  const { editSingleBoard } = useBoard();
  const queryClient = useQueryClient();

  const coverMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    { boardId: string; params: CreateBoardParams }
  > = useMutation({
    mutationFn: ({ boardId, params }) => editSingleBoard({ boardId, params }),
    onSettled: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setRearrangeLoading(false);
      toast.success("Rearranged successfully!");
    },
    onError: (error: Error) => {
      toast.error("Failed to rearrange. Please try again.");
    },
  });

  const handleIndexChange = (newItems: BoardItem[]) => {
    const params: CreateBoardParams = {
      boardItems: newItems,
    };

    coverMutation.mutate({
      boardId,
      params,
    });
  };

  return (
    <>
      {rearangeLoading && <Spinner />}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext
          items={boardItems.map((item) => item._id)}
          strategy={verticalListSortingStrategy}
        >
          <div className="masonry-layout-mob w-full overflow-x-hidden masonry-layout ">
            {boardItems.map((item) => (
              <SortableItem key={item._id} item={item} boardId={boardId} />
            ))}
          </div>
        </SortableContext>
      </DndContext>
    </>
  );
};

interface SortableItemProps {
  item: BoardItem;
  boardId: string;
}

const SortableItem: React.FC<SortableItemProps> = ({ item, boardId }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: item._id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
    opacity: isDragging ? 0.8 : 1,
    zIndex: isDragging ? 1 : 0,
    cursor: isDragging ? "grabbing" : "grab",
    position: isDragging ? ("relative" as const) : ("static" as const),
    width: isDragging ? "initial" : undefined,
    height: isDragging ? "initial" : undefined,
  };

  const commonProps = {
    ref: setNodeRef,
    style,
    ...attributes,
    ...listeners,
    className: "masonry-item-mob",
  };

  switch (item.itemType) {
    case "object":
      return (
        <div {...commonProps}>
          <ObjectType
            imgSrc={item.image}
            objectName={item.objectName}
            publishedYear={item.publishedYear ?? 0}
            asignProtect={item.assignProtect}
            boardId={boardId}
            coverId={item._id}
            className=" mq750:h-[213px] mq750:min-w-[156px] mq450:h-[213px] mq450:min-w-[156px] "
            itemId={item._id}
            caption={item.caption ?? ""}
            showEllipsis={false}
            itemType={item.itemType}
            tagsList={item.tags}
            showCaption={false}
          />
        </div>
      );
    case "text":
      return (
        <div {...commonProps}>
          <TextType
            tagList={item.tags}
            showEllipsis={false}
            title={item.title}
            summary={item.summary}
            className="mq450:min-w-[154px] mq750:min-w-[154px] "
            boardId={boardId}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            showCaption={false}
          />
        </div>
      );
    case "events":
      return (
        <div {...commonProps}>
          <EventType
            tagList={item.tags}
            showEllipsis={false}
            showDetail={false}
            eventName={item.eventName}
            image={item.image}
            description={item.description}
            state={item.state}
            country={item.country}
            startDate={item.startDate}
            endDate={item.endDate}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            showCaption={false}
            caption={item.caption}
            className="mq450:min-w-[156px] mq450:h-[156px] mq750:min-w-[156px] mq750:h-[156px]  "
            coverId={item._id}
          />
        </div>
      );
    case "link":
      return (
        <div {...commonProps}>
          <LinkType
            tagList={item.tags}
            hyperLink={item.link}
            className="mq450:min-w-[154px] mq750:min-w-[154px]"
            boardId={boardId}
            itemId={item._id}
            caption={item.caption}
            showEllipsis={false}
            showCaption={false}
          />
        </div>
      );
    case "artist":
      return (
        <div {...commonProps}>
          <ArtistType
            tagList={item.tags}
            showEllipsis={false}
            showDetail={false}
            name={item.name}
            img={item.profilePic}
            dob={item.dob}
            className="mq450:min-w-[156px] mq450:h-[156px] mq750:min-w-[156px] mq750:h-[156px]  "
            boardId={boardId}
            itemId={item._id}
            itemType={item.itemType}
            caption={item.caption}
            coverId={item._id}
            showCaption={false}
          />
        </div>
      );
    case "business":
      return (
        <div {...commonProps}>
          <BusinessType
            tagList={item.tags}
            businessName={item.businessName}
            image={item.coverImage}
            description={item.description}
            businessDate={item.businessDate}
            businessLocation={item.businessLocation}
            businessLogo={item.image}
            itemId={item._id}
            itemType={item.itemType}
            showEllipsis={false}
            showCaption={false}
            caption={item.caption}
            boardId={boardId}
            className="mq450:min-w-[156px] mq450:h-[156px] mq750:min-w-[156px] mq750:h-[156px]"
            coverId={item._id}
            showDetail={false}
          />
        </div>
      );
    case "writing":
      return (
        <div {...commonProps}>
          <WritingType
            showCaption={false}
            title={item.content.title}
            image={item.content.coverImage}
            description={item.content.description}
            authorName={item.content.authorName}
            className="mq450:min-w-[156px] mq450:h-[156px] mq750:min-w-[156px] mq750:h-[156px]  "
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            caption={item.caption ? item.caption : ""}
            tagList={item.tags}
            showEllipsis={false}
            coverId={item._id}
            imgSrc={item.content.coverImage}
          />
        </div>
      );
    case "video":
      return (
        <div {...commonProps}>
          <VideoType
            videoUrl={item.content.url}
            coverphoto={item.content.coverImage}
            title={item.content.title}
            className="mq450:min-w-[156px] mq450:h-[156px] mq750:min-w-[156px] mq750:h-[156px] rounded-none"
            tagList={item.tags}
            itemId={item._id}
            caption={item.caption ? item.caption : ""}
            boardId={boardId}
            coverId={item._id}
            showCaption={false}
            showEllipsis={false}
          />
        </div>
      );
    case "file":
      return (
        <div {...commonProps}>
          <FileType
            showEllipsis={false}
            image={item.image}
            className="mq450:min-w-[156px] mq450:h-[156px] mq750:min-w-[156px] mq750:h-[156px]"
            fileName={item.name}
            itemId={item._id}
            boardId={boardId}
            itemType={item.itemType}
            tagList={item.tags}
            caption={item.caption}
            coverId={item._id}
            imgSrc={item.image}
            showCaption={false}
          />
        </div>
      );
    default:
      return null;
  }
};

export default BoardItemsMob;

import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { Check, AlertCircle } from "lucide-react";
import GlobalDialog from "@/modal/GlobalDialog";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useAsignProtect from "@/apis/protect";
import { useSearchParams } from "react-router-dom";
import Loader from "@/components/Global/Loader";
import { useObjectContext } from "@/context/objects";
import LabelManagementComponent from "@/components/label-management";
import LabellingError from "@/components/labelling-error";
import toast from "react-hot-toast";
import { getId } from "@/lib/helper";

// Stage flow constant
const STAGE_FLOW = [
  "authentication",
  "inspection",
  "asignProtect+",
  "approved",
];

type TimelineStatus = "completed" | "current" | "pending" | "failed" | "review";

interface TimelineItem {
  status: TimelineStatus;
  title: string;
  description?: string;
  failureReason?: string;
  rejectionDate?: string;
}

// Helper function to get stage index
const getStageIndex = (stage: string): number => {
  return STAGE_FLOW.findIndex((s) => s.toLowerCase() === stage.toLowerCase());
};

const TimelineItemComponent = ({
  item,
  onRequestAgain,
  currentStage,
}: {
  item: TimelineItem;
  onRequestAgain: () => void;
  currentStage: string;
}) => {
  const getIcon = () => {
    switch (item.status) {
      case "completed":
        return <Check className="w-4 h-4 text-white" />;
      case "failed":
        return <AlertCircle className="w-4 h-4 text-white" />;
      case "current":
        return <div className="w-2 h-2 bg-black rounded-full" />;
      default:
        return <div className="w-2 h-2 bg-gray-300 rounded-full" />;
    }
  };

  const getStageMessage = (stage: string) => {
    switch (stage) {
      case "authentication":
        return "Authentication process is in progress";
      case "inspection":
        return "inspection process is in progress";
      case "asignProtect+":
        return "asignProtect process is in progress";
      case "approved":
        return "Process completed successfully";
      default:
        return "";
    }
  };

  const getStatusStyles = () => {
    if (item.status === "pending" || item.status === "review") {
      return "text-gray-400";
    }
    if (item.status === "failed") {
      return "text-red-500";
    }
    if (item.status === "current") {
      return "text-black font-medium";
    }
    return "text-black";
  };

  const getIconContainerStyles = () => {
    if (item.status === "completed") {
      return "bg-black";
    }
    if (item.status === "failed") {
      return "bg-red-500";
    }
    if (item.status === "current" || item.status === "review") {
      return "bg-white border-2 border-black";
    }
    return "bg-white border-2 border-gray-300";
  };

  return (
    <div className="relative pb-8">
      {item.status !== "pending" && (
        <div
          className="absolute left-3 top-3 -ml-px h-full w-0.5 bg-gray-200"
          aria-hidden="true"
        />
      )}
      <div className="relative flex items-start space-x-3">
        <div>
          <div
            className={`h-6 w-6 rounded-full flex items-center justify-center ${getIconContainerStyles()}`}
          >
            {getIcon()}
          </div>
        </div>
        <div className="flex-1 min-w-0">
          <h3 className={`text-[16px] font-normal ${getStatusStyles()}`}>
            {item.title}
          </h3>

          {item.status === "failed" || item.status === "review"
            ? item.failureReason && (
                <div className="mt-2 text-gray-500">
                  <p className="text-sm font-normal text-[#696969]">
                    We cannot process your request to label this object due to
                    the following reason:
                  </p>
                  <p className="text-sm text-[#696969] font-normal">
                    {item.failureReason}
                  </p>
                  {item.rejectionDate && (
                    <p className="mt-2 text-sm text-[#696969] font-normal">
                      Date of rejection: {item.rejectionDate}
                    </p>
                  )}

                  <GlobalDialog
                    TriggerButton={
                      <Button
                        variant="link"
                        className="p-0 h-auto text-[12px] font-medium mt-2 text-black underline"
                      >
                        REQUEST AGAIN
                      </Button>
                    }
                    title="Request Again"
                    onConfirm={onRequestAgain}
                    onCancel={() => {}}
                    confirmText="SUBMIT"
                  >
                    <div className="space-y-4 text-center">
                      <p>
                        Your request has been rejected for the following
                        reason(s):
                      </p>
                      <p>{item.failureReason}</p>
                      <p>
                        Please make the required changes and submit the request.
                      </p>
                    </div>
                  </GlobalDialog>
                </div>
              )
            : // Only show the stage message if this item represents the current stage
              item.status === "current" && (
                <p className="text-sm text-[#696969] font-normal">
                  {getStageMessage(currentStage)}
                </p>
              )}
        </div>
      </div>
    </div>
  );
};

export default function AsignTimeline() {
  const { getAsignRequestByObjectId, requestAgain } = useAsignProtect();
  const { currentObject } = useObjectContext();
  const [searchParams] = useSearchParams();
  const objectId = searchParams.get("oi");
  const queryClient = useQueryClient();
  const { data: apData, isLoading } = useQuery({
    queryKey: ["AP", objectId],
    queryFn: () => getAsignRequestByObjectId(objectId as string),
  });

  // Process timeline data based on currentStage and status
  const determineTimelineStatus = (stepTitle: string): TimelineStatus => {
    if (!apData) return "pending";

    const currentStageIndex = getStageIndex(apData.currentStage);
    const stepIndex = getStageIndex(stepTitle);

    // Special case for first step (Request raised)
    if (stepTitle === "Request raised") {
      return currentStageIndex >= 0 ? "completed" : "current";
    }

    if (stepIndex < 0) return "pending";

    // Handle different cases
    if (apData.status === "rejected" && stepIndex === currentStageIndex) {
      return "failed";
    }
    if (apData.status === "review" && stepIndex === currentStageIndex) {
      return "review";
    }
    if (stepIndex < currentStageIndex) {
      return "completed";
    }
    if (stepIndex === currentStageIndex) {
      return "current";
    }
    return "pending";
  };

  const timeline: TimelineItem[] = [
    {
      title: "Request raised",
      status: determineTimelineStatus("Request raised"),
      failureReason: apData?.rejectReason,
      rejectionDate: apData?.rejectionDate,
    },
    {
      title: "Authentication",
      status: determineTimelineStatus("authentication"),
      failureReason: apData?.rejectReason,
      rejectionDate: apData?.rejectionDate,
    },
    {
      title: "Inspection",
      status: determineTimelineStatus("inspection"),
      failureReason: apData?.rejectReason,
      rejectionDate: apData?.rejectionDate,
    },
    {
      title: "Asign Protect+",
      status: determineTimelineStatus("asignProtect+"),
      failureReason: apData?.rejectReason,
      rejectionDate: apData?.rejectionDate,
    },
  ];

  const againRequestHandler = useMutation({
    mutationFn: requestAgain,
    onError: () => {
      toast.dismiss();
      toast.error("Something went wrong");
    },
    onSuccess: () => {
      toast.dismiss();
      toast.success("Request sent successfully");
      queryClient.invalidateQueries({
        queryKey: ["object", objectId],
      }); // Refetch new object
      queryClient.invalidateQueries({
        queryKey: ["AP"],
      }); // Refetch new object
    },
    onMutate: () => {
      toast.loading("Requesting...");
    },
  });

  const handleRequestAgain = () => {
    againRequestHandler.mutate(getId(apData));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <div className="flex flex-col bg-white font-sh5">
        <main className="flex items-center justify-center flex-grow sm:p-4">
          <Card className="w-full max-w-2xl shadow-none">
            <CardHeader className="flex flex-row justify-between items-start border-t sm:border-t-0 border-b border-solid border-[#E5E5E5] sm:p-0 sm:pt-10 sm:pb-[20px]">
              <div>
                <h2 className="text-sm font-normal text-[#1D1D1D]">
                  Date of request
                </h2>
                <p className="text-[#696969] text-sm font-normal">
                  {apData?.requestDate}
                </p>
              </div>
              <div className="text-right">
                <h2 className="text-sm font-medium text-[#1D1D1D]">
                  Request ID # {apData?.reqId}
                </h2>
              </div>
            </CardHeader>
            <CardContent className="flex flex-col md:flex-row md:space-x-8 mt-5 border-b border-solid border-[#E5E5E5] sm:p-0 sm:pb-8">
              <div className="flex flex-row w-full mb-6 space-x-4 sm:flex-col sm:space-x-0 md:w-1/3 md:mb-0">
                <img
                  src={currentObject?.image}
                  alt={currentObject?.objectName}
                  className="w-[100px] h-[105px] sm:w-[180px] sm:h-[189px] border border-gray-200 object-contain"
                />
                <p className="mt-2 font-normal text-[#1D1D1D]">
                  {currentObject?.objectName}
                </p>
              </div>
              {apData.currentStage !== "approved" && (
                <div className="w-full md:w-2/3">
                  <h2 className="mb-6 text-[18px] font-normal">Timeline</h2>
                  <div className="flow-root">
                    <ul className="-mb-8">
                      {timeline.map((item, index) => (
                        <li
                          key={index}
                          className="font-normal list-none decoration-none"
                        >
                          <TimelineItemComponent
                            item={item}
                            onRequestAgain={handleRequestAgain}
                            currentStage={apData?.currentStage}
                          />
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
              {/* {apData.currentStage === "approved" && (
                <LabelManagementComponent
                  childLabel={apData?.childLabel ?? []}
                  parentLabel={apData?.parentLabel}
                />
              )} */}
              {apData.currentStage === "asignProtect+" &&
                apData.currentStage === "rejected" && <LabellingError />}
            </CardContent>
            <CardFooter className="justify-center mt-6">
              <Button
                variant="secondary"
                className="px-6 py-4 h-[46px] text-white bg-gray-900 rounded-full hover:bg-gray-800"
              >
                BACK TO CATALOGUE
              </Button>
            </CardFooter>
          </Card>
        </main>
      </div>
      <div className="p-4 mt-12 space-y-4 text-sm text-center text-gray-500">
        <div className="flex items-center justify-center space-x-2">
          <span>Have questions?</span>
          <Button variant="link" className="h-auto p-0 text-sm font-semibold">
            SEE KNOWLEDGE CENTRE
          </Button>
        </div>
        <div className="flex items-center justify-center space-x-2">
          <Button variant="link" className="h-auto p-0 text-sm">
            Terms & Conditions
          </Button>
          <span>|</span>
          <Button variant="link" className="h-auto p-0 text-sm">
            Privacy Policy
          </Button>
          <span>|</span>
          <span>© 2024 Democrart Technologies Private Limited</span>
        </div>
      </div>
    </>
  );
}

import React from "react";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Switch } from "@/components/ui/switch";
import GlobalDialog from "@/modal/GlobalDialog";
import { useUserContext } from "@/context/user";

const formSchema = z.object({
  name: z.string().min(2, "Name of recognition must be at least 2 characters"),
  awardedBy: z.string().min(2, "Awarded by must be at least 2 characters"),
  date: z.object({
    day: z.string().nonempty("Day is required"),
    month: z.string().nonempty("Month is required"),
    year: z.string().nonempty("Year is required"),
  }),
  isPublic: z.boolean(),
});

type FormValues = z.infer<typeof formSchema>;

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const AddRecognition = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { me, updateUser } = useUserContext();

  const defaultRecognition = me?.recognition?.find(
    (item: any) => item._id === defaultId,
  );

  const defaultDate = defaultRecognition?.date
    ? new Date(defaultRecognition.date)
    : null;

  const defaultValues = defaultRecognition
    ? {
        name: defaultRecognition.name || "",
        awardedBy: defaultRecognition.awardedBy || "",
        date: {
          day: defaultDate
            ? String(defaultDate.getDate()).padStart(2, "0")
            : "",
          month: defaultDate ? months[defaultDate.getMonth()] : "",
          year: defaultDate ? String(defaultDate.getFullYear()) : "",
        },
        isPublic: defaultRecognition.isPublic || false,
      }
    : {
        name: "",
        awardedBy: "",
        date: {
          day: "",
          month: "",
          year: "",
        },
        isPublic: false,
      };

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const onSubmit = (data: FormValues) => {
    const { date } = data;
    const isoDate = new Date(
      `${date.year}-${months.indexOf(date.month) + 1}-${date.day}`,
    ).toISOString();
    data.date = isoDate as any;
    if (defaultId) {
      updateUser.mutate({
        recognition: [
          {
            ...data,
            _id: defaultId,
          },
        ],
      });
      return;
    }
    updateUser.mutate({
      recognition: [data],
    });
  };

  const title = "Add Recognition";
  return (
    <GlobalDialog
      title={title}
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Name of recognition
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="FICCI YFLO Achiever's Award, India"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="awardedBy"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">
                  Awarded by
                </FormLabel>
                <FormControl>
                  <Input placeholder="Mojo Art" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="grid grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="date.day"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-base font-normal">Day</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full p-5 rounded-none  h-[59px] text-base">
                        <SelectValue placeholder="Day" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Array.from({ length: 31 }, (_, i) => i + 1).map(
                        (day) => (
                          <SelectItem
                            key={day}
                            value={day.toString().padStart(2, "0")}
                          >
                            {day}
                          </SelectItem>
                        ),
                      )}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date.month"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-base font-normal">Month</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                        <SelectValue placeholder="Month" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {months.map((month) => (
                        <SelectItem key={month} value={month}>
                          {month}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date.year"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="w-full p-5 rounded-none h-[59px] text-base">
                    Year
                  </FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger className="w-full p-5 rounded-none h-[59px] text-base">
                        <SelectValue placeholder="Year" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Array.from(
                        { length: 100 },
                        (_, i) => new Date().getFullYear() - i,
                      ).map((year) => (
                        <SelectItem key={year} value={year.toString()}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          {/* Public Visibility Switch */}
          <FormField
            control={form.control}
            name="isPublic"
            render={({ field }) => (
              <FormItem>
                <div className="flex flex-row items-center justify-between -mx-[25px] border-solid border-bdr-10 border-t pt-[22px] px-5">
                  <div className="space-y-0.5 ">
                    <FormLabel className="text-base font-normal">
                      Publicly visible
                    </FormLabel>
                  </div>
                  <div className="">
                    <Switch
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </div>
                </div>
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddRecognition;

import React, { useState, useRef } from "react";
import { VideoItem } from "@/types/boards.type";
import { Button } from "@/components/ui/button";
import AddItemDropDown from "../AddItemDropDown";

interface VideoProps {
  items: VideoItem[];
}

const Videos: React.FC<VideoProps> = ({ items }) => {
  const [playingVideoId, setPlayingVideoId] = useState<string | null>(null);
  const videoRefs = useRef<Map<string, HTMLVideoElement>>(new Map());

  const handlePlay = (videoId: string) => {
    if (playingVideoId === videoId) {
      // If the clicked video is already playing, pause it
      setPlayingVideoId(null);
    } else {
      // Pause the currently playing video (if any)
      if (playingVideoId) {
        videoRefs.current.get(playingVideoId)?.pause();
      }
      // Play the clicked video
      setPlayingVideoId(videoId);
    }
  };

  return (
    <div>
      {items.length === 0 ? (
        <p>No videos found.</p>
      ) : (
        items.map((item) => {
          const { _id, content } = item;
          const isPlaying = playingVideoId === _id;

          return (
            <div
              key={_id}
              className="min-h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 group"
            >
              <p className="absolute p-1 text-white bg-black bg-opacity-50 rounded bottom-2 left-2">
                {content.title}
              </p>
              <div
                className={`absolute inset-0 flex justify-center items-center cursor-pointer ${
                  isPlaying ? "hidden" : "block"
                }`}
                onClick={() => handlePlay(_id)}
              >
                <img
                  src={content.coverImage}
                  alt={content.title}
                  className="object-full  w-full h-full"
                />
                <button
                  className="absolute p-3 transition-opacity bg-white rounded-full opacity-75 hover:opacity-100"
                  aria-label="Play"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-10 h-10 text-gray-800"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M8 5v14l11-7z" />
                  </svg>
                </button>
              </div>
              <video
                ref={(el) => videoRefs.current.set(_id, el!)}
                src={content.url}
                controls
                className={`absolute inset-0 w-full h-full ${
                  isPlaying ? "block" : "hidden"
                }`}
                onClick={() => handlePlay(_id)}
              >
                Your browser does not support the video tag.
              </video>
              <AddItemDropDown
                itemID={item._id}
                boardID={""}
                itemType={"video"}
              >
                <span className="absolute flex invisible gap-1 p-2 text-sm transition-all duration-300 ease-in-out scale-95 bg-white rounded-full opacity-0 cursor-pointer top-4 text-other-cta right-4 group-hover:opacity-100 group-hover:scale-100 group-hover:visible">
                  <div className="flex flex-row items-center gap-1">
                    <img className="w-6 h-6" src="/label.svg" alt="label" />
                  </div>
                </span>
              </AddItemDropDown>
            </div>
          );
        })
      )}
    </div>
  );
};

export default Videos;

import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { CheckCircle2, LoaderCircle } from "lucide-react";
import { getOverlappingDaysInIntervals } from "date-fns";
import updateProfile from "@/apis/updateProfile";
import { cn } from "@/lib/utils";

interface InputField {
  id: string;
  label: string;
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  isVerified?: boolean;
  verifiedName?: string;
}

interface IdentityVerificationFormProps {
  stepNumber: number;
  totalSteps: number;
  title: string;
  description: string;
  inputFields: InputField[];
  isAgreed: boolean;
  setIsAgreed: (value: boolean) => void;
  termsLink: string;
  isFormValid: boolean;
  onBack: () => void;
  setType: (type: string) => void;
  gstVal: boolean;
  nextStep: () => void;
  selectedAccountType: string;
  setUUID: (uuid: string) => void;
  userId: string;
  selectedOption: string;
}

const IdentityVerificationForm: React.FC<IdentityVerificationFormProps> = ({
  stepNumber,
  totalSteps,
  title,
  description,
  inputFields,
  isAgreed,
  setIsAgreed,
  termsLink,
  isFormValid,
  onBack,
  setType,
  gstVal,
  nextStep,
  selectedAccountType,
  setUUID,
  userId,
  selectedOption,
}) => {
  const [panVerificationStatus, setPanVerificationStatus] = useState<{
    isVerified: boolean;
    name: string;
  }>({ isVerified: false, name: "" });
  const [isAadhaarVerified, setIsAadhaarVerified] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isChecked, setIsChecked] = useState(false);
  const [isPanLoading, setIsPanLoading] = useState(false);

  const verifyPan = async (panNumber: string) => {
    if (panNumber.length !== 10) return;
    setIsPanLoading(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        panNumber: panNumber,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow" as RequestRedirect,
      };

      const response = await fetch(
        "https://ab.host.levitation.co.in/client/auth/verify-pan",
        requestOptions,
      );
      const result = await response.json();

      if (result?.data?.full_name) {
        setIsPanLoading(false);
        setPanVerificationStatus({
          isVerified: true,
          name: result.data.full_name,
        });
        const dataToUpdate = {
          additionalInfo: {
            nameAsPerPan: result.data.full_name,
            pancard: panNumber,
          },
        };

        try {
          const updateResult = await updateProfile(userId, dataToUpdate);
          console.log("Profile updated successfully:", updateResult);
        } catch (updateError) {
          console.error("Error updating profile:", updateError);
        }
      } else {
        setPanVerificationStatus({ isVerified: false, name: "" });
        setError(result.message || "PAN verification failed");
      }
    } catch (error) {
      console.error("Error verifying PAN:", error);
      setError("An error occurred while verifying PAN. Please try again.");
      setPanVerificationStatus({ isVerified: false, name: "" });
    }
  };

  const verifyAadhaar = async (aadhaar: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      aadhaar: aadhaar,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
    };

    const response = await fetch(
      "https://ab.host.levitation.co.in/client/auth/enter-aadhar",
      requestOptions,
    );
    const result = await response.json();
    console.log("here is the result", result);

    setUUID(result.data.response_data.uuid);

    if (
      result.error ||
      result.message === "Verification failed" ||
      !result.success
    ) {
      setError(result.message || "Aadhaar verification failed");
      setIsAadhaarVerified(false);
      return {
        success: false,
        message: result.message || "Aadhaar verification failed",
      };
    }
    setError(null);
    setUUID(result.data.response_data.uuid);
    return { success: true, data: result };
  };

  const verifyGST = async (gst: string) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      gst: gst,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
    };

    const response = await fetch(
      "https://ab.host.levitation.co.in/client/auth/gst-verification",
      requestOptions,
    );
    return await response.json();
  };

  const handleType = async () => {
    setError(null);
    setIsLoading(true);

    try {
      const aadhaarField = inputFields.find((field) => field.id === "aadhaar");
      const gstField = inputFields.find((field) => field.id === "gst");
      const cinField = inputFields.find((field) => field.id === "cin");
      const llpinField = inputFields.find((field) => field.id === "llp");
      console.log(aadhaarField);

      if (aadhaarField && !aadhaarField.value) {
        setError("Aadhaar field is empty");
        throw new Error("Aadhaar field is empty");
      }

      if (aadhaarField && aadhaarField.value) {
        const aadhaarResult = await verifyAadhaar(aadhaarField.value);
        console.log("Aadhaar verification result1:", aadhaarResult);
        //Here add adhaar validation
        if (aadhaarResult.message === "Failed") {
          setIsLoading(false);
          setError("Aadhaar verification failed");
          return {
            success: false,
            message: "Aadhaar verification failed",
          };
        }
      }

      if (gstField && gstField.value) {
        const gstResult = await verifyGST(gstField.value);
        console.log("GST verification result:", gstResult);
        if (!gstResult.success) {
          throw new Error(gstResult.message);
        }
      }

      // If all verifications are successful, proceed with the original logic
      if (gstVal) {
        if (
          selectedAccountType === "business" ||
          selectedOption === "company"
        ) {
          setType("addresspage");
        } else {
          setType("address");
        }
        nextStep();
      } else {
        setType("otp2");
      }
    } catch (error) {
      console.error("Verification error:", error);
      setError(
        error instanceof Error ? error.message : "An unknown error occurred",
      );
    }
    setIsLoading(false);
  };

  // const handleType = () => {
  //   if (gstVal) {
  //     if (selectedAccountType === "business") {
  //       setType("addresspage");
  //     } else {
  //       setType("address");
  //     }
  //     nextStep();
  //   } else {
  //     setType("otp2");
  //   }
  // };
  return (
    <div className="flex flex-col w-full p-8 md:w-1/2 md:p-16">
      <div className="flex-grow w-full max-w-md mx-auto">
        <p className="mb-2 text-sm text-gray-500">
          Step {stepNumber} of {totalSteps}
        </p>
        <h1 className="mb-4 text-4xl font-bold">{title}</h1>
        <p className="mb-8 text-gray-600">{description}</p>

        <div className="space-y-6">
          {inputFields.map((field) => (
            <div key={field.id}>
              <Label htmlFor={field.id} className="block mb-2 font-medium">
                {field.label}
              </Label>
              <div className="relative">
                <Input
                  id={field.id}
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    if (field.id === "pan") {
                      verifyPan(e.target.value);
                    }
                  }}
                  className={cn(
                    "pr-10",
                    field.id == "pan" && "uppercase placeholder:capitalize",
                  )}
                  placeholder={field.placeholder}
                />
                {field.id === "pan" && isPanLoading && (
                  <LoaderCircle
                    className="absolute text-green-500 transform -translate-y-1/2 right-3 top-1/2"
                    size={20}
                  />
                )}
                {field.id === "pan" && panVerificationStatus.isVerified && (
                  <CheckCircle2
                    className="absolute text-green-500 transform -translate-y-1/2 right-3 top-1/2"
                    size={20}
                  />
                )}
              </div>
              {field.id === "pan" && panVerificationStatus.isVerified && (
                <div className="mt-2">
                  <p className="font-medium">{panVerificationStatus.name}</p>
                  <p className="text-sm text-gray-500">Name as per PAN</p>
                </div>
              )}
            </div>
          ))}

          <div className="flex items-center space-x-2">
            <Checkbox
              id="terms"
              checked={isChecked}
              onCheckedChange={(checked) => setIsChecked(checked as boolean)}
            />
            <label
              htmlFor="terms"
              className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
            >
              I agree to the{" "}
              <a href={termsLink} className="underline">
                Terms and Conditions
              </a>{" "}
              for the provided information.
            </label>
          </div>
        </div>
      </div>
      {error && <p className="mt-4 text-red-500">{error}</p>}
      <div className="flex items-center justify-between mt-8">
        <Button variant="ghost" className="text-gray-800" onClick={onBack}>
          BACK
        </Button>
        <Button
          className="text-white bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={handleType}
          disabled={
            !isChecked || !panVerificationStatus.isVerified || isLoading
          } // Disable button when loading
        >
          {isLoading ? (
            <span>Loading...</span> // Replace with your loading spinner or message
          ) : (
            "CONTINUE"
          )}
        </Button>
      </div>
    </div>
  );
};

export default IdentityVerificationForm;

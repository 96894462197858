import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { MultiSelect } from "@components/ui/multi-select";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Textarea } from "@/components/ui/textarea";

const DetailStep: React.FC = () => {
  const form = useFormContext();
  const [hasSignatures, setHasSignatures] = useState("no");
  const [hasInscriptions, setHasInscriptions] = useState("no");

  useEffect(() => {
    // Check if there are any values in `metaData.signature` and set `hasSignatures` accordingly
    if (form.getValues("metaData.signature")) {
      setHasSignatures("yes");
    }

    // Get the `inscriptions` object and check if any value is present (non-empty)
    const inscriptions = form.getValues("metaData.inscriptions");
    const hasAnyInscriptions = inscriptions
      ? Object.values(inscriptions).some(
          (value) => typeof value === "string" && value.trim() !== "",
        )
      : false;

    // If any value in `inscriptions` is present, set `hasInscriptions` to "yes"
    if (hasAnyInscriptions) {
      setHasInscriptions("yes");
    }
  }, [form.watch(["metaData.signature", "metaData.inscriptions"])]);

  return (
    <Accordion type="single" collapsible className="w-full">
      <AccordionItem value="medium-measurements">
        <AccordionTrigger>Medium and measurements</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-4">
            {/* Mediums */}
            <FormField
              control={form.control}
              name="metaData.medium"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Medium(s)*</FormLabel>
                  <MultiSelect
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    placeholder="Enter medium(s)"
                    options={[
                      { value: "oil", label: "Oil" },
                      { value: "acrylic", label: "Acrylic" },
                      { value: "watercolor", label: "Watercolor" },
                    ]}
                    variant="inverted"
                    animation={2}
                    maxCount={3}
                  />
                  <FormDescription>
                    You can add multiple mediums by selecting from the dropdown.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            {/* Surface */}
            <FormField
              control={form.control}
              name="metaData.surface"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Surface(s)*</FormLabel>
                  <MultiSelect
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                    value={field.value}
                    placeholder="Enter surface(s)"
                    options={[
                      { value: "oil", label: "Oil" },
                      { value: "acrylic", label: "Acrylic" },
                      { value: "watercolor", label: "Watercolor" },
                    ]}
                    variant="inverted"
                    animation={2}
                    maxCount={3}
                  />
                  <FormDescription>Choose a surface type.</FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />

            <div className="grid grid-cols-2 gap-4">
              {/* Measurement Type */}
              <FormField
                control={form.control}
                name="metaData.measurementType"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Measurement Type*</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a measurement type" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="2d">2D</SelectItem>
                        <SelectItem value="3d">3D</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Shape */}
              <FormField
                control={form.control}
                name="metaData.shape"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Shape*</FormLabel>
                    <Select onValueChange={field.onChange} value={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select a shape" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="rectangle">Rectangle</SelectItem>
                        <SelectItem value="square">Square</SelectItem>
                        <SelectItem value="circle">Circle</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Dimensions */}
            <div className="flex items-center space-x-2">
              <FormLabel>Dimensions</FormLabel>
              <Switch
                checked={form.watch("metaData.dimensionsType") === "cm"}
                onCheckedChange={(checked) =>
                  form.setValue(
                    "metaData.dimensionsType",
                    checked ? "cm" : "inch",
                  )
                }
              />
              <span>{form.watch("metaData.dimensionsType") || "cm"}</span>
            </div>

            <div className="grid grid-cols-2 gap-4">
              {/* Height */}
              <FormField
                control={form.control}
                name="metaData.height"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Height*</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder={`Enter height in ${form.watch(
                          "metaData.dimensionsType",
                        )}`}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Width */}
              <FormField
                control={form.control}
                name="metaData.width"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Width*</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder={`Enter width in ${form.watch(
                          "metaData.dimensionsType",
                        )}`}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="grid grid-cols-2 gap-4">
              {/* Depth */}
              <FormField
                control={form.control}
                name="metaData.depth"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Depth</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder={`Enter depth in ${form.watch(
                          "metaData.dimensionsType",
                        )}`}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Diameter */}
              <FormField
                control={form.control}
                name="metaData.diameter"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Diameter</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        placeholder={`Enter diameter in ${form.watch(
                          "metaData.dimensionsType",
                        )}`}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            {/* Weight */}
            <div className="flex items-center space-x-2">
              <FormLabel>Weight</FormLabel>
              <Switch
                checked={form.watch("metaData.weightType") === "kg"}
                onCheckedChange={(checked) =>
                  form.setValue("metaData.weightType", checked ? "kg" : "lbs")
                }
              />
              <span>{form.watch("metaData.weightType") || "kg"}</span>
            </div>

            <FormField
              control={form.control}
              name="metaData.weight"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <Input
                      type="number"
                      placeholder={`Enter weight in ${form.watch(
                        "metaData.weightType",
                      )}`}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="characteristics">
        <AccordionTrigger>Characteristics</AccordionTrigger>
        <AccordionContent>
          <FormField
            control={form.control}
            name="metaData.technique"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Technique(s)</FormLabel>
                <MultiSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                  placeholder="Enter technique(s)"
                  options={[
                    { value: "oil", label: "Oil" },
                    { value: "acrylic", label: "Acrylic" },
                    { value: "watercolor", label: "Watercolor" },
                  ]}
                  variant="inverted"
                  animation={2}
                  maxCount={3}
                />
                <FormDescription>
                  You can add multiple techniques separated by a comma or by
                  selecting from the dropdown.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="metaData.style"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Style</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a style" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="m@example.com">m@example.com</SelectItem>
                    <SelectItem value="m@google.com">m@google.com</SelectItem>
                    <SelectItem value="m@support.com">m@support.com</SelectItem>
                  </SelectContent>
                </Select>

                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="metaData.subject"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Subject(s)</FormLabel>
                <MultiSelect
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                  value={field.value}
                  placeholder="Enter subject(s)"
                  options={[
                    { value: "oil", label: "Oil" },
                    { value: "acrylic", label: "Acrylic" },
                    { value: "watercolor", label: "Watercolor" },
                  ]}
                  variant="inverted"
                  animation={2}
                  maxCount={3}
                />
                <FormDescription>
                  You can add multiple subjects separated by a comma or by
                  selecting from the dropdown.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="metaData.movement"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Movement</FormLabel>
                <Select onValueChange={field.onChange} value={field.value}>
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select a movement" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="m@example.com">m@example.com</SelectItem>
                    <SelectItem value="m@google.com">m@google.com</SelectItem>
                    <SelectItem value="m@support.com">m@support.com</SelectItem>
                  </SelectContent>
                </Select>

                <FormMessage />
              </FormItem>
            )}
          />
        </AccordionContent>
      </AccordionItem>

      <AccordionItem value="signature-inscriptions">
        <AccordionTrigger>Signature and inscriptions</AccordionTrigger>
        <AccordionContent>
          <div className="space-y-6">
            <FormItem>
              <FormLabel className="text-base font-semibold">
                Are there any signatures on this object?*
              </FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={(value) => {
                    setHasSignatures(value);
                    form.setValue("metaData.signature", "");
                  }}
                  className="flex flex-col space-y-1"
                  value={hasSignatures}
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="yes" />
                    </FormControl>
                    <FormLabel className="font-normal">YES</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="no" />
                    </FormControl>
                    <FormLabel className="font-normal">NO</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
            </FormItem>

            {hasSignatures == "yes" && (
              <FormField
                control={form.control}
                name="metaData.signature"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Describe the signature(s)*</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Describe the signature(s)"
                        className="resize-none"
                        {...field}
                      />
                    </FormControl>
                    <FormDescription>
                      {250 - (field.value?.length || 0)} characters left
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}

            <FormItem>
              <FormLabel className="text-base font-semibold">
                Are there any inscriptions on this object?
              </FormLabel>
              <FormControl>
                <RadioGroup
                  onValueChange={(value) => {
                    setHasInscriptions(value);
                    form.setValue("metaData.inscriptions.verso", "");
                    form.setValue("metaData.inscriptions.recto", "");
                    form.setValue("metaData.inscriptions.base", "");
                  }}
                  className="flex flex-col space-y-1"
                  value={hasInscriptions}
                >
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="yes" />
                    </FormControl>
                    <FormLabel className="font-normal">YES</FormLabel>
                  </FormItem>
                  <FormItem className="flex items-center space-x-3 space-y-0">
                    <FormControl>
                      <RadioGroupItem value="no" />
                    </FormControl>
                    <FormLabel className="font-normal">NO</FormLabel>
                  </FormItem>
                </RadioGroup>
              </FormControl>
            </FormItem>

            {hasInscriptions === "yes" && (
              <>
                <FormField
                  control={form.control}
                  name="metaData.inscriptions.verso"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Verso</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Any visible markings on the back"
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>
                        {500 - (field.value?.length || 0)} characters left
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="metaData.inscriptions.recto"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Recto</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Any visible markings on the front"
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>
                        {500 - (field.value?.length || 0)} characters left
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                <FormField
                  control={form.control}
                  name="metaData.inscriptions.base"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Base</FormLabel>
                      <FormControl>
                        <Textarea
                          placeholder="Any visible markings on the bottom"
                          className="resize-none"
                          {...field}
                        />
                      </FormControl>
                      <FormDescription>
                        {500 - (field.value?.length || 0)} characters left
                      </FormDescription>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </>
            )}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default DetailStep;

import React, { useState, useEffect } from "react";
import { FileUploader, FileInput } from "@/components/ui/file-upload";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";

import { CloudUpload } from "lucide-react";
import { useFormContext } from "react-hook-form";

const ImagesStep: React.FC = () => {
  const dropZoneConfig = {
    maxFiles: 1,
    maxSize: 1024 * 1024 * 4,
    multiple: false,
  };
  const form = useFormContext();

  // Function to convert file to base64
  const convertToBase64 = (
    file: File,
  ): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  // Handler to update the form with base64 string
  const handleFileChange = async (
    file: File | null,
    fieldOnChange: (value: string | null) => void,
  ) => {
    if (file) {
      const base64String = await convertToBase64(file);
      fieldOnChange(base64String as string);
    } else {
      fieldOnChange(null);
    }
  };

  // Component to render image preview if present
  const ImagePreview: React.FC<{ image: string | undefined }> = ({ image }) => {
    if (!image) return null;

    return (
      <div className="">
        <img
          src={image}
          alt="Preview"
          className="object-contain max-w-[300px]  "
        />
      </div>
    );
  };

  return (
    <>
      {/* Featured Image Field */}
      <FormField
        control={form.control}
        name="image"
        render={({ field }) => (
          <FormItem>
            <FormLabel className="text-[20px] font-normal required_field">
              Featured Image
            </FormLabel>
            <p className="text-sm font-normal text-[#696969]">
              This will be your object's primary image.
            </p>
            <FormControl>
              <div className="flex items-start justify-start">
                <FileUploader
                  value={field.value || []}
                  onValueChange={async (files) => {
                    if (files) {
                      await handleFileChange(files[0], field.onChange);
                    }
                  }}
                  dropzoneOptions={dropZoneConfig}
                  className="relative border-dashed border-[1px] rounded-none border-slate-500 p-0 bg-background"
                >
                  <FileInput className=" bg-[#f6f6f6] rounded-none grid place-items-center">
                    {field.value ? (
                      <ImagePreview image={field.value} />
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full p-8 ">
                        <img alt="" src="/images/uplaodImg.svg" />
                        <p className="mb-1 text-lg font-normal text-[#696969] dark:text-gray-400">
                          Drag and drop or browse files
                        </p>
                        <p className="text-sm font-normal text-[#696969] dark:text-gray-400">
                          Up to 20 MB, in .jpg, .webp, or .png format
                        </p>
                      </div>
                    )}
                  </FileInput>
                </FileUploader>
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Front Image Field */}
      <FormField
        control={form.control}
        name="frontImage"
        render={({ field }) => (
          <FormItem className="mt-8">
            <FormLabel className="text-[20px] font-normal">
              Front Image
            </FormLabel>
            <FormControl>
              <>
                <FileUploader
                  value={field.value || []}
                  onValueChange={async (files) => {
                    if (files) {
                      await handleFileChange(files[0], field.onChange);
                    }
                  }}
                  dropzoneOptions={dropZoneConfig}
                  className="relative border-dashed border-[1px] rounded-none border-slate-500 p-0 bg-background"
                >
                  <FileInput className="bg-[#f6f6f6] grid place-items-center">
                    {field.value ? (
                      <ImagePreview image={field.value} />
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full p-8 ">
                        <img alt="" src="/images/uplaodImg.svg" />
                        <p className="mb-1 text-lg font-normal text-[#696969] dark:text-gray-400">
                          Drag and drop or browse files
                        </p>
                        <p className="text-sm font-normal text-[#696969] dark:text-gray-400">
                          Up to 20 MB, in .jpg, .webp, or .png format
                        </p>
                      </div>
                    )}
                  </FileInput>
                </FileUploader>
              </>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {/* Back Image Field */}
      <FormField
        control={form.control}
        name="backImage"
        render={({ field }) => (
          <FormItem className="mt-8">
            <FormLabel className="text-[20px] font-normal">
              Back Image
            </FormLabel>
            <FormControl>
              <>
                <FileUploader
                  value={field.value || []}
                  onValueChange={async (files) => {
                    if (files) {
                      await handleFileChange(files[0], field.onChange);
                    }
                  }}
                  dropzoneOptions={dropZoneConfig}
                  className="relative border-dashed border-[1px] rounded-none border-slate-500 p-0 bg-background"
                >
                  <FileInput className="bg-[#f6f6f6] rounded-none grid place-items-center">
                    {field.value ? (
                      <ImagePreview image={field.value} />
                    ) : (
                      <div className="flex flex-col items-center justify-center w-full p-8 ">
                        <img alt="" src="/images/uplaodImg.svg" />
                        <p className="mb-1 text-lg font-normal text-[#696969] dark:text-gray-400">
                          Drag and drop or browse files
                        </p>
                        <p className="text-sm font-normal text-[#696969] dark:text-gray-400">
                          Up to 20 MB, in .jpg, .webp, or .png format
                        </p>
                      </div>
                    )}
                  </FileInput>
                </FileUploader>
              </>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <div className="mt-10 flex items-center justify-center text-sm font-normal text-[#696969] font-sh5">
        Have questions?
        <span className="text-xs font-medium ml-1 underline-offset-2 underline text-[#1d1d1d]">
          SEE KNOWLEDGE CENTER
        </span>
      </div>
    </>
  );
};

export default ImagesStep;

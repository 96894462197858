import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";

const ProtectedLayout = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLogin, isLoading } = useAuth();

  useEffect(() => {
    if (!isLoading && !isLogin) {
      // Preserve the attempted URL to redirect back after login
      navigate("/login", {
        replace: true,
        state: { from: location.pathname },
      });
    }
  }, [isLogin, isLoading, navigate, location]);

  // Show nothing while checking authentication
  if (isLoading) {
    return null; // Or return a loading spinner component
  }

  // If not authenticated, render nothing (redirect happens in useEffect)
  if (!isLogin) {
    return null;
  }

  // If authenticated, render children
  return <>{children}</>;
};

export default ProtectedLayout;

import React from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarIcon } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import GlobalDialog from "@/modal/GlobalDialog";
import { useObjectContext } from "@/context/objects";

const formSchema = z.object({
  date: z.date({
    required_error: "A date of valuation is required.",
  }),
  doneBy: z.string().min(1, "Done by is required."),
  status: z.string({
    required_error: "Please select a status.",
  }),
  type: z.string({
    required_error: "Please select a value type.",
  }),
  basis: z.string({
    required_error: "Please select a value basis.",
  }),
  approach: z.string({
    required_error: "Please select a value approach.",
  }),
  currency: z.string({
    required_error: "Please select a currency.",
  }),
  lowValue: z.coerce.number().min(1, "Low value is required."),
  highValue: z.coerce.number().min(1, "High value is required."),
  notes: z.string().optional(),
});
const AddValuation = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { updateOrAddItem, currentObject } = useObjectContext();
  const defaultValues =
    currentObject?.document?.valuation?.valuationDetail?.find(
      (item: any) => item.id === defaultId,
    );
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });
  function onSubmit(data: z.infer<typeof formSchema>) {
    if (defaultId) {
      updateOrAddItem({
        document: {
          valuation: {
            valuationDetail: [
              {
                ...data,
                _id: defaultValues,
              },
            ],
          },
        },
      });
    }
    updateOrAddItem({
      document: {
        valuation: {
          valuationDetail: [data],
        },
      },
    });
  }
  return (
    <GlobalDialog
      title="Add Valuation"
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form className="space-y-8">
          <FormField
            control={form.control}
            name="date"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Date of valuation</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full pl-3 text-left font-normal rounded-none h-[59px]",
                          !field.value && "text-muted-foreground",
                        )}
                      >
                        {field.value ? (
                          format(field.value, "PPP")
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={field.value}
                      onSelect={field.onChange}
                      disabled={(date) =>
                        date > new Date() || date < new Date("1900-01-01")
                      }
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="doneBy"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Done by</FormLabel>
                <FormControl>
                  <Input placeholder="Enter name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="status"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Status</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select status" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="pending">Pending</SelectItem>
                    <SelectItem value="completed">Completed</SelectItem>
                    <SelectItem value="review">Under Review</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Value type</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select value type" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="market">Market Value</SelectItem>
                    <SelectItem value="book">Book Value</SelectItem>
                    <SelectItem value="fair">Fair Value</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="basis"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Value basis</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select value basis" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="as-is">As Is</SelectItem>
                    <SelectItem value="as-complete">As Complete</SelectItem>
                    <SelectItem value="as-stabilized">As Stabilized</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="approach"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Value approach</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select value approach" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="income">Income Approach</SelectItem>
                    <SelectItem value="market">Market Approach</SelectItem>
                    <SelectItem value="cost">Cost Approach</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Currency</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select currency" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="usd">USD</SelectItem>
                    <SelectItem value="eur">EUR</SelectItem>
                    <SelectItem value="gbp">GBP</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lowValue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Low value</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Enter low value"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="highValue"
            render={({ field }) => (
              <FormItem>
                <FormLabel>High value</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Enter high value"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Comparable notes</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Enter comparable notes"
                    className="resize-none"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddValuation;

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import Header from "@/components/Global/header/Header";
import { steps } from "./utils";
import SideBar from "./SideBar";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import ImagesStep from "./ImagesStep";
import DetailsStep from "./DetailStep";
import Location from "./Location";
import Provenance from "./Provenance";
import { useObjectContext } from "@/context/objects";
import Components from "./Components";
import ObjectIdentification from "./ObjectIdentification";
import SecondaryMeasurement from "./SecondaryMesaurement";
import Valuation from "./Valuation";
import Media from "./Media";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ChevronLeft, ChevronRight, MoreHorizontal } from "lucide-react";
import Global from "@/modal/Global";
import AddLocation from "./modal/AddLocation";
import Spinner from "@/components/Global/Spinner";
import AddProvenance from "./modal/Addprovenance";
import AddAuction from "./modal/AddAuction";
import AddExhibition from "./modal/Addexhibition";
import AddPublication from "./modal/AddPublication";
import AddComponents from "./modal/AddComponents";
import AddSecondaryMeasurement from "./modal/AddSecondaryMeasurement";
import { cn } from "@/lib/utils";
import { useUserContext } from "@/context/user";
import useUserAPI from "@/apis/user";
import useFilter from "@/hooks/useFilter";
import { useDebouncedCallback } from "use-debounce";
import { useQuery } from "@tanstack/react-query";
import { Skeleton } from "@/components/ui/skeleton";
import Records from "./Records";
import AddDocument from "./modal/AddDocument";
import AddValuation from "./modal/AddValuation";

const MultiStepForm: React.FC = () => {
  const [currentStep, setCurrentStep] = useState("about");
  const [searchParams] = useSearchParams();
  const objectId = searchParams.get("oi");
  const { updateOrAddItem, currentObject, isLoading } = useObjectContext();
  const location = useLocation();
  const navigate = useNavigate();
  const form = useForm({
    defaultValues: currentObject,
  });

  const onSubmit = (data: any) => {
    updateOrAddItem(data);
    // Handle form submission
  };

  const combinedSteps = [...steps.information, ...steps.documentation]; // Combine all steps

  useEffect(() => {
    // Get the step from URL query parameter
    const searchParams = new URLSearchParams(location.search);
    const step = searchParams.get("step");
    if (step && combinedSteps.includes(step)) {
      setCurrentStep(step);
    }
  }, [location]);

  const updateQueryParams = (newStep: string) => {
    const searchParams = new URLSearchParams(location.search);

    // Update or add the "step" parameter
    searchParams.set("step", newStep);

    // Navigate while keeping the other parameters intact
    navigate(`${location.pathname}?${searchParams.toString()}`, {
      replace: true,
    });
  };

  const navigateSteps = (direction: "back" | "next") => {
    const currentIndex = combinedSteps.indexOf(currentStep);
    let newStep;

    if (direction === "back" && currentIndex > 0) {
      newStep = combinedSteps[currentIndex - 1];
    } else if (
      direction === "next" &&
      currentIndex < combinedSteps.length - 1
    ) {
      newStep = combinedSteps[currentIndex + 1];
    }

    if (newStep) {
      setCurrentStep(newStep);
      updateQueryParams(newStep);
    } else if (
      direction === "next" &&
      currentIndex === combinedSteps.length - 1 &&
      objectId
    ) {
      // No next step available, redirect to /catalog/detail/request if objectId is available
      navigate(`/catalog/asign/request?oi=${objectId}`);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case "about":
        return <AboutStep />;
      case "images":
        return <ImagesStep />;
      case "details":
        return <DetailsStep />;
      case "location":
        return <Location />;
      case "provenance":
        return <Provenance />;
      case "components":
        return <Components />;
      case "objectIdentification":
        return <ObjectIdentification />;
      case "secondaryMeasurements":
        return <SecondaryMeasurement />;
      case "valuation":
        return <Valuation />;
      case "records":
        return <Records />;
      case "media":
        return <Media />;
      default:
        return null;
    }
  };
  useEffect(() => {
    form.reset(currentObject);
  }, [currentObject]);
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="container flex-col h-screen font-normal font-sh5">
      <Header />
      <div className="flex flex-col w-full sm:w-full sm:h-full sm:flex-row">
        <div className="h-full pr-4 overflow-y-scroll sm:w-1/4">
          <SideBar
            currentStep={currentStep}
            setCurrentStep={(step) => {
              setCurrentStep(step);
              updateQueryParams(step);
            }}
          />
        </div>
        <div className="sm:w-3/4 bg-[#F6F6F6] sm:p-10 h-full">
          <Form {...form}>
            <form className="flex flex-col h-full pb-16 bg-white rounded-lg shadow-sm sm:pb-0">
              <div className="flex justify-between px-5 py-4 border-b border-[#F2F2F2] border-solid">
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => navigateSteps("back")}
                  className={cn(
                    "flex items-center gap-2 text-sm font-medium",
                    currentStep == "about" && "invisible",
                  )}
                >
                  <ChevronLeft className="w-5 h-5" />
                  BACK
                </Button>
                <Button
                  type="button"
                  variant="ghost"
                  onClick={() => navigateSteps("next")}
                  className="flex items-center gap-2 text-sm font-medium"
                >
                  NEXT
                  <ChevronRight className="w-5 h-5" />
                </Button>
              </div>

              <div className="flex-grow px-5 py-8 sm:px-[120px] overflow-y-auto">
                <h2 className="mb-6  text-[28px] font-normal capitalize">
                  {currentStep}
                </h2>
                {renderStep()}
              </div>

              <div className="flex items-center justify-between w-full px-5 py-4 border-t border-[#F2F2F2] mb-2 border-solid">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    form.reset();
                    navigate("/catalog/detail");
                  }}
                  className="px-8 py-2 text-sm font-medium rounded-full"
                >
                  EXIT
                </Button>
                <Button
                  onClick={form.handleSubmit(onSubmit)}
                  type="button"
                  className="px-8 py-2 text-sm font-medium text-white bg-gray-900 rounded-full hover:bg-gray-800"
                >
                  SAVE
                </Button>
              </div>
            </form>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default MultiStepForm;
const AboutStep: React.FC = () => {
  const form = useFormContext();
  const { missingFields } = useObjectContext();
  const { me } = useUserContext();

  // State initialization
  const [showCompletionYear, setShowCompletionYear] = useState(
    Boolean(form.watch("metaData.compeletionDate.year")),
  );

  // Constants
  const categories = [
    { name: "Painting", value: "painting" },
    { name: "Sculpture", value: "sculpture" },
    { name: "Photograph", value: "photograph" },
    { name: "Textile", value: "textile" },
    { name: "Ceramic", value: "ceramic" },
    { name: "Metal", value: "metal" },
    { name: "Glass", value: "glass" },
    { name: "Wood", value: "wood" },
    { name: "Other", value: "other" },
  ];

  // API and search setup
  const { searchUsers } = useUserAPI();
  const { filterOption, setQuery } = useFilter({
    options: { select: ["name", "id", "artistId"], pagination: false },
    query: {
      searchQuery: undefined,
      searchType: "regexSearch",
      userRole: "artist",
    },
  });

  // Memoized callbacks
  const querySearch = useDebouncedCallback((value) => {
    setQuery({ searchQuery: value === "" ? undefined : value });
  }, 1000);

  const handleArtistCheckbox = useCallback(
    (checked: boolean) => {
      form.setValue("artist", checked ? me?.id || "" : "");
      form.setValue("artistName", checked ? me?.name || "" : "");
    },
    [form, me],
  );

  const handleCircaChange = useCallback(
    (checked: boolean) => form.setValue("circa", checked),
    [form],
  );

  const handleUnknownDateChange = useCallback(
    (checked: boolean) => form.setValue("unknownCreationDate", checked),
    [form],
  );

  const toggleCompletionYear = useCallback(
    () => setShowCompletionYear(true),
    [],
  );

  // Query setup
  const { data: artists, isLoading } = useQuery({
    queryKey: ["artist", filterOption],
    queryFn: () => searchUsers(filterOption),
    enabled: !!filterOption && filterOption.query?.searchQuery !== undefined,
  });

  // Character count calculation
  const getCharacterCount = useCallback(
    (value: string = "") => 3000 - value.length,
    [],
  );

  return (
    <div className="flex flex-col gap-6">
      <FormField
        control={form.control}
        name="artist"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Artist's name*</FormLabel>
            <FormControl>
              <Popover>
                <PopoverTrigger asChild>
                  <div className="flex items-center justify-between w-full text-gray-40">
                    <span className="capitalize">
                      {form.watch("artistName") || "Select artist"}
                    </span>
                    <img src="/arrow-down.svg" alt="" className="mr-5" />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="w-full" align="start">
                  <input
                    className="w-full min-w-[411px] border border-solid px-3 rounded-lg border-gray-20 h-[48px] outline-none font-sh5 font-normal"
                    placeholder="Enter artist's name"
                    type="text"
                    onChange={(e) => querySearch(e.target.value)}
                  />
                  <div className="max-h-[200px] overflow-y-scroll">
                    {isLoading
                      ? [...Array(8)].map((_, index) => (
                          <Skeleton key={index} className="w-full h-5 mt-4" />
                        ))
                      : artists?.data.map((item) => (
                          <p
                            className="flex justify-between mt-4 mb-2 font-normal cursor-pointer"
                            key={item.id}
                            onClick={() => {
                              field.onChange(item.id);
                              form.setValue("artistName", item.name);
                            }}
                          >
                            <span className="capitalize">{item.name}</span>
                          </p>
                        ))}
                  </div>
                </PopoverContent>
              </Popover>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="flex items-center gap-4">
        <Checkbox
          checked={form.watch("artist") === me?.id}
          onCheckedChange={handleArtistCheckbox}
          className="space-y-1 leading-none"
        />
        <p> I am the artist</p>
      </div>

      <FormField
        control={form.control}
        name="objectName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Object title*</FormLabel>
            <FormControl>
              <Input
                placeholder="Enter object title"
                {...field}
                className={
                  missingFields.includes("objectName") ? "border-red-500" : ""
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="descriptiveTitle"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Descriptive title</FormLabel>
            <FormControl>
              <Input placeholder="Enter descriptive title" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="type"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Object type*</FormLabel>
            <Select onValueChange={field.onChange} value={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select object type" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {categories.map((category) => (
                  <SelectItem key={category.value} value={category.value}>
                    {category.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className="grid grid-cols-2 gap-4">
        <FormField
          control={form.control}
          name="metaData.creationDate.year"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Creation year*</FormLabel>
              <FormControl>
                <Input type="number" placeholder="Enter year" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="metaData.creationDate.era"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Era</FormLabel>
              <Select onValueChange={field.onChange} defaultValue={field.value}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select era" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="CE">CE</SelectItem>
                  <SelectItem value="BCE">BCE</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className="flex gap-x-5">
        <div className="flex items-center gap-x-2">
          <Checkbox
            id="circa"
            checked={form.watch("circa")}
            onCheckedChange={handleCircaChange}
          />
          <label htmlFor="circa" className="text-sm font-medium">
            Circa
          </label>
        </div>
        <div className="flex items-center gap-x-2">
          <Checkbox
            id="unknownCreationDate"
            checked={form.watch("unknownCreationDate")}
            onCheckedChange={handleUnknownDateChange}
          />
          <label htmlFor="unknownCreationDate" className="text-sm font-medium">
            Object creation date is unknown
          </label>
        </div>
      </div>

      {!showCompletionYear ? (
        <Button
          type="button"
          variant="link"
          className="justify-start w-auto h-auto p-0 border-b border-solid rounded-none max-w-[170px]"
          onClick={toggleCompletionYear}
        >
          + ADD COMPLETION YEAR
        </Button>
      ) : (
        <div className="grid grid-cols-2 gap-4">
          <FormField
            control={form.control}
            name="metaData.compeletionDate.year"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Completion year</FormLabel>
                <FormControl>
                  <Input type="number" placeholder="Enter year" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="metaData.compeletionDate.era"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Era</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select era" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    <SelectItem value="CE">CE</SelectItem>
                    <SelectItem value="BCE">BCE</SelectItem>
                  </SelectContent>
                </Select>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      )}

      <FormField
        control={form.control}
        name="objectInpossession"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Is the object in your possession?*</FormLabel>
            <FormControl>
              <RadioGroup
                onValueChange={field.onChange}
                value={String(field.value)}
                className="flex flex-col space-y-1"
              >
                <FormItem className="flex items-center space-x-3">
                  <FormControl>
                    <RadioGroupItem value="true" />
                  </FormControl>
                  <FormLabel>YES</FormLabel>
                </FormItem>
                <FormItem className="flex items-center space-x-3">
                  <FormControl>
                    <RadioGroupItem value="false" />
                  </FormControl>
                  <FormLabel>NO</FormLabel>
                </FormItem>
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="description"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Object description</FormLabel>
            <FormControl>
              <Textarea
                placeholder="Enter object description"
                className="rounded-none resize-none"
                {...field}
              />
            </FormControl>
            <FormDescription>
              {getCharacterCount(field.value)} characters left
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

interface ActionDropDownProps {
  id: string;
  dataKey: string;
}

export const createNestedObject = (key: string, value: any) => {
  return key.split(".").reduceRight((acc, part) => ({ [part]: acc }), value);
};

export const ActionDropDown: React.FC<ActionDropDownProps> = ({
  id,
  dataKey,
}) => {
  const { updateOrAddItem } = useObjectContext();
  const popoverRef = useRef<HTMLDivElement>(null);

  const onDelete = (id: string) => {
    // Use the helper function to create a nested object
    const payload = createNestedObject(dataKey, [
      {
        _id: id,
        delete: true,
      },
    ]);

    // Update the object using the payload
    updateOrAddItem(payload);
  };

  useEffect(() => {
    if (popoverRef.current) {
      popoverRef.current.focus();
    }
  }, []);

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="focus:outline-none" type="button">
          <MoreHorizontal className="w-5 h-5 text-gray-500" />
        </button>
      </PopoverTrigger>
      <PopoverContent
        className="w-56 p-0 font-normal border border-gray-200 rounded-md shadow-lg font-sh5"
        align="end"
        ref={popoverRef}
        tabIndex={0}
      >
        <div className="py-2">
          {/* Conditionally render the Edit button based on dataKey */}
          {dataKey === "provenance.overview" && (
            <AddProvenance defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddProvenance>
          )}
          {dataKey === "provenance.auctionHistory" && (
            <AddAuction defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddAuction>
          )}
          {dataKey === "provenance.exhibitionHistory" && (
            <AddExhibition defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddExhibition>
          )}
          {dataKey === "provenance.publicationHistory" && (
            <AddPublication defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddPublication>
          )}
          {dataKey === "components" && (
            <AddComponents defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddComponents>
          )}
          {dataKey === "document.secondaryMeasurements" && (
            <AddSecondaryMeasurement defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddSecondaryMeasurement>
          )}
          {dataKey === "document.records.document" && (
            <AddDocument defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddDocument>
          )}
          {dataKey === "document.valuation.valuationDetail" && (
            <AddValuation defaultId={id}>
              <button
                className="w-full px-4 py-2 text-sm font-normal text-left"
                type="button"
              >
                Edit
              </button>
            </AddValuation>
          )}
          {/* You can add more conditions for other dataKeys as needed */}
          <Global
            title="Delete"
            description="Are you sure you want to permanently delete?"
            actionText="DELETE"
            cancelText="CANCEL"
            onConfirm={() => onDelete(id)}
          >
            <button
              className="w-full px-4 py-2 text-sm font-normal text-left "
              type="button"
            >
              Delete
            </button>
          </Global>
        </div>
      </PopoverContent>
    </Popover>
  );
};

import React from "react";
import ArtistDropDown from "./ArtistDropDown";
import CaptionItem from "./CaptionItem";

interface BusinessProp {
  businessName: string;
  image: string;
  description: string;
  businessDate: string;
  businessLocation: string;
  className?: string;
  businessLogo: string;
  coverId: string;
  itemId: string;
  boardId: string;
  itemType: string;
  caption: string;
  tagList: string[];
  showEllipsis?: boolean;
  showCaption?: boolean;
  showDetail?: boolean;
}

const BusinessType: React.FC<BusinessProp> = ({
  businessName,
  image,
  businessLocation,
  className = "",
  businessLogo,
  itemId,
  boardId,
  caption,
  itemType,
  coverId,
  tagList,
  showEllipsis = true,
  showCaption = true,
  showDetail = true,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={`flex h-fit min-w-[297px] w-full flex-col relative items-center justify-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
      >
        <div className="flex-1 w-full flex flex-col items-center">
          <img
            src={image}
            className="object-cover w-full h-full bg-gradient-to-t from-zinc-900 to-neutral-50"
            alt={businessName}
          />
          <div className="absolute inset-0 bg-gradient-to-t from-zinc-900 to-transparent rounded-t-lg"></div>
          {showEllipsis && (
            <ArtistDropDown
              boardId={boardId}
              itemId={itemId}
              itemType={itemType}
              caption={caption}
              coverId={coverId}
              imgSrc={image}
              tagList={tagList}
            >
              <img
                className="absolute top-0 right-0 p-2"
                src="/ellipsis.svg"
                alt="options"
              />
            </ArtistDropDown>
          )}

          <div className="w-full absolute flex flex-row gap-2 item-center left-0 bottom-0 p-4">
            <img
              className="w-14 h-14 mq450:w-6 mq450:h-6 rounded-[100px]"
              src={businessLogo}
            />
            {showDetail && (
              <div className="flex flex-col justify-center gap-2">
                <h2 className="font-sh5 font-normal text-sm text-white">
                  {businessName}
                </h2>
                <h2 className="font-sh5 font-normal text-sm text-[#CCCCCC]">
                  {businessLocation}
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </div>
  );
};

export default BusinessType;

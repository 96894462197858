import React, { ReactNode } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import RemoveItem from "@/modal/RemoveItem";
import EditLink from "@/modal/EditLink";
import AddCaptionLink from "@/modal/AddCaptionLink";
import RemoveCaption from "@/modal/RemoveCaption";
import AddTag from "@/modal/AddTag";

interface ObjectDropDownProp {
  children: ReactNode;
  boardId: string;
  coverId: string;
  itemId: string;
  imgSrc: string;
  tagList: string[];
  objectName: string;
  price: number;
  assignProtect: boolean;
  caption?: string;
  defaultLink: string;
}

const LinkDropDown: React.FC<ObjectDropDownProp> = ({
  children,
  boardId,
  itemId,
  caption,
  tagList,
  defaultLink,
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  console.log(caption);
  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent
        side="bottom"
        alignOffset={8}
        align="end"
        className="border-[1px]  border-bdr-10 border-solid p-2 min-w-[200px] rounded-none"
      >
        <DropdownMenuGroup className="flex flex-col gap-2 text-base text-gray-60 font-sh5">
          <ProfileForm
            caption={caption}
            boardId={boardId}
            itemId={itemId}
            tagList={tagList}
            defaultLink={defaultLink}
          />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader className="p-0"></DrawerHeader>
        <ProfileForm
          boardId={boardId}
          itemId={itemId}
          caption={caption}
          tagList={tagList}
          defaultLink={defaultLink}
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  itemId: string;
  caption?: string;
  tagList: string[];
  defaultLink: string;
};

function ProfileForm({
  className,
  boardId,
  itemId,
  caption,
  tagList,
  defaultLink,
}: ProfileFormProps) {
  return (
    <form className={cn("grid items-center gap-6  p-8", className)}>
      <EditLink boardId={boardId} itemId={itemId} defaultLink={defaultLink}>
        <div className="text-base font-normal text-left text-gray-60 mq450:text-left font-sh5">
          Edit Link
        </div>
      </EditLink>
      {caption && (
        <RemoveCaption boardId={boardId} itemId={itemId}>
          <div className="text-base font-normal text-left cursor-pointer text-gray-60 font-sh5">
            Remove Caption
          </div>
        </RemoveCaption>
      )}
      <AddCaptionLink
        boardId={boardId}
        itemId={itemId}
        defalutCaption={caption}
      >
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 font-sh5">
          {caption ? "Edit Caption" : "Add Caption"}
        </div>
      </AddCaptionLink>
      <AddTag
        boardId={boardId}
        itemId={itemId}
        itemType={"link"}
        tagList={tagList}
      >
        <div className="text-base font-normal text-left text-gray-60 mq450:text-left font-sh5">
          Add tags
        </div>
      </AddTag>
      <RemoveItem boardId={boardId} itemId={itemId}>
        <div className="text-base font-normal text-left text-gray-60 mq450:text-left font-sh5">
          Remove from Board
        </div>{" "}
      </RemoveItem>
    </form>
  );
}

export default LinkDropDown;

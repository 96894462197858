import React, { ReactNode } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import { BoardItemType } from "@/types/boards.type";
import { DialogClose } from "@radix-ui/react-dialog";
import BoardItemsMob from "./BoardItemMob";
import { ScrollArea } from "@radix-ui/react-scroll-area";

type BoardItem = BoardItemType;
interface RearrangeDrawerProps {
  children: ReactNode;
  boardId: string;
  boardItems: BoardItem[];
}

const RearrangeDrawer: React.FC<RearrangeDrawerProps> = ({
  children,
  boardId,
  boardItems,
}) => {
  return (
    <Drawer>
      <DrawerTrigger asChild>{children}</DrawerTrigger>
      <DrawerContent className="rounded-none absolute top-5  pt-5 px-5 min-h-[100%]">
        <DrawerHeader className="p-0">
          <DrawerTitle>
            <div className="flex border-b-[1px] border-solid border-bdr-10 pb-5 flex-row items-center justify-between">
              Reorder
              <DialogClose asChild>
                <img className="w-6 h-6" src="/close1.svg" alt="Close Icon" />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ScrollArea className="max-h-[70vh] overflow-y-auto ">
          {boardItems.length === 0 ? (
            <div className="flex items-center justify-center h-[50vh]">
              <p className="text-gray-60 text-lg font-sh5">No items to show</p>
            </div>
          ) : (
            <BoardItemsMob boardId={boardId} boardItems={boardItems} />
          )}
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  );
};

export default RearrangeDrawer;

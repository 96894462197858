import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Eye, EyeOff, Menu, WineOff } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import LeftFormImage from "../RegistrationForm/LeftFormImage";
import { Link, useNavigate } from "react-router-dom";
import useUserAPI from "@/apis/user";
import { useMutation } from "@tanstack/react-query";
import toast from "react-hot-toast";

const formSchema = z.object({
  phone: z
    .string()
    .min(10, "Invalid phone number")
    .max(10, "Invalid phone number"),
  password: z.string().min(8, "Password must be at least 8 characters long"),
  rememberMe: z.boolean().optional(),
});

export default function LoginForm() {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useUserAPI();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      phone: "",
      password: "",
      rememberMe: false,
    },
  });

  const loginHandler = useMutation({
    mutationFn: login,
    onSuccess: (data) => {
      toast.success(`Hello ${data.name}, Welcome back!`, { id: "login" });
      window.location.href = "/discover/feed";
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message ?? "Something went wrong", {
        id: "login",
      });
    },
    onMutate: () => {
      toast.loading("Logging in...", { id: "login" });
    },
  });

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    loginHandler.mutate(values);
  };

  return (
    <div className="flex h-screen bg-white font-sh5">
      <LeftFormImage />
      <div className="absolute right-14 top-5 cursor-pointer">
        <Menu strokeWidth={0.7} size={35} />
      </div>
      <div className="w-full max-w-md p-6 m-auto space-y-6">
        <h1 className="text-[32px] font-normal">Log in</h1>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-base font-normal">
                    Mobile number
                  </FormLabel>
                  <FormControl>
                    <div className="relative w-full  font-sh5">
                      <div className="absolute inset-y-0 top-[-1px] left-0 flex items-center pl-3 pointer-events-none">
                        <span className="">+91</span>
                      </div>
                      <Input
                        type="number"
                        className="pl-12 pr-4 h-[59px] focus:border-[#696969]"
                        placeholder="Enter phone number"
                        {...field}
                      />
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel className="text-base font-normal">
                    Password
                  </FormLabel>
                  <FormControl>
                    <div className="relative">
                      <div>
                        <Input
                          {...field}
                          type={showPassword ? "text" : "password"}
                          className="h-[59px] pr-10 focus:border-[#696969]"
                        />
                      </div>
                      <Button
                        variant="link"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 mt-2"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? (
                          <EyeOff strokeWidth={0.5} />
                        ) : (
                          <Eye strokeWidth={0.5} />
                        )}
                      </Button>
                    </div>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex items-center justify-between">
              <FormField
                control={form.control}
                name="rememberMe"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center space-x-2 space-y-0">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <FormLabel className="text-sm font-normal text-[#696969]">
                      Remember me
                    </FormLabel>
                  </FormItem>
                )}
              />
              <Link
                to="/reset"
                className="text-sm font-medium underline underline-offset-4"
              >
                FORGOT PASSWORD?
              </Link>
            </div>
            <div className="text-center text-sm font-normal text-[#696969]">
              Don&apos;t you have an account yet?{" "}
              <a
                href="/register"
                className="ml-5 text-sm font-medium text-black underline underline-offset-4"
              >
                SIGN UP
              </a>
            </div>
            <Button
              variant="default"
              type="submit"
              className="w-full h-12 rounded-full bg-[#303030] hover:bg-[#262626]  disabled:opacity-100 disabled:bg-[#dcdcdc] disabled:text-[#000000] disabled:font-medium"
              disabled={!form.formState.isValid}
            >
              LOG IN
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}

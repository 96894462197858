import React from "react";
import { Button } from "../ui/button";

const FallbackScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen px-4 font-normal bg-white font-sh5">
      <div className="w-full max-w-md text-center">
        <img
          className="h-[45px] mx-auto mb-8 text-gray-400 w-[45px]"
          src="/open-mail.svg"
          alt="Open Mail"
        />
        <h1 className="mb-3 text-5xl font-semibold text-gray-900">
          Email verification pending
        </h1>
        <p className="text-gray-500 mb-9">
          Click on the verification link sent to you
          <br />
          by email to continue.
        </p>
        <Button className="px-[41px] py-4 text-xs font-medium text-white transition-colors rounded-full ">
          RESEND VERIFICATION EMAIL
        </Button>
      </div>
      <footer className="absolute left-0 right-0 w-full text-sm text-center text-gray-400 bottom-4 ">
        <a href="#" className="hover:underline">
          Terms & Conditions
        </a>
        {" | "}
        <a href="#" className="hover:underline">
          Privacy Policy
        </a>
        {" | "}
        <a href="#" className="hover:underline">
          Contact us
        </a>
        <span className="mt-1 ">
          &nbsp; | © 2024 Democraft Technologies Private Limited
        </span>
      </footer>
    </div>
  );
};

export default FallbackScreen;

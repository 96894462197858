import React from "react";
import ObjecDropDown from "./ObjectDropDown";
import CaptionItem from "./CaptionItem";

interface ObjectProp {
  imgSrc: string;
  objectName: string;
  publishedYear: number;
  asignProtect: boolean;
  className?: string;
  boardId: string;
  itemType: string;
  coverId: string;
  itemId: string;
  showEllipsis: boolean;
  caption?: string;
  tagsList: string[];
  showCaption?: boolean;
}

const Object: React.FC<ObjectProp> = ({
  imgSrc,
  objectName,
  publishedYear,
  asignProtect,
  className = "",
  boardId,
  coverId,
  itemId,
  showEllipsis,
  caption,
  itemType,
  tagsList,
  showCaption = true,
}) => {
  return (
    <>
      <div
        className={`flex min-w-[297px] h-fit  w-full  flex-col  relative items-center justify-center bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
      >
        <div className="flex flex-col items-center flex-1 w-full">
          <img
            src={imgSrc}
            alt={objectName}
            className="object-cover w-full h-full"
          />

          <div className="absolute inset-0 rounded-t-lg bg-gradient-to-t from-zinc-900 to-transparent"></div>

          <div className="absolute bottom-0 left-0 flex flex-col w-full gap-1 p-4 ">
            <div className="flex flex-row items-center gap-[2px]">
              <img
                src="/icon-protect-1.svg"
                alt="Protect Icon"
                className="w-5 h-5"
              />
              {asignProtect && (
                <p className="text-xs font-normal text-white font-sh5">
                  Asign Protect+
                </p>
              )}
            </div>
            <h2 className="ml-1 text-sm font-normal text-white font-sh5">
              {objectName}
            </h2>

            <p className="font-sh5 ml-1 font-normal text-sm text-[#CCCCCC]">
              c. {publishedYear ? publishedYear : "NA"}
            </p>
          </div>
          {showEllipsis && (
            <ObjecDropDown
              boardId={boardId}
              coverId={coverId}
              itemId={itemId}
              imgSrc={imgSrc}
              objectName={objectName}
              publishedYear={publishedYear}
              assignProtect={asignProtect}
              caption={caption ? caption : ""}
              itemType={itemType}
              tagsList={tagsList}
            >
              <img
                className="absolute top-0 right-0 p-2 cursor-pointer"
                src="/ellipsis.svg"
              />
            </ObjecDropDown>
          )}
        </div>
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </>
  );
};

export default Object;

"use client";

import React from "react";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area";
import { LockIcon, PlusIcon } from "lucide-react";
import AddMedia from "./modal/AddMedia";
import { useObjectContext } from "@/context/objects";
import ReactPlayer from "react-player";
interface MediaSectionProps {
  title: string;
  addButtonText: string;
  items?: Array<{ image?: string; video?: string; title?: string }>; // Updated type to handle both image and video
  isLocked?: boolean;
  isVideo?: boolean;
  scehmaKey?: string;
}
const MediaSection: React.FC<MediaSectionProps> = ({
  title,
  addButtonText,
  items = [],
  isLocked = false,
  isVideo = false,
  scehmaKey,
}) => {
  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-2">
        <h2 className="text-lg font-semibold">{title}</h2>
        {isLocked ? (
          <LockIcon className="w-4 h-4 text-gray-400" />
        ) : (
          <AddMedia
            type={isVideo ? "video" : "image"}
            schemaKey={scehmaKey ?? ""}
          >
            <Button variant="link" size="sm">
              <PlusIcon className="w-4 h-4 mr-2" />
              {addButtonText}
            </Button>
          </AddMedia>
        )}
      </div>

      {items.length === 0 ? (
        <p className="text-sm text-gray-500">
          You have not added any {title.toLowerCase()} yet.
        </p>
      ) : (
        <ScrollArea className="w-full border rounded-md whitespace-nowrap">
          <div className="flex p-4 space-x-4 w-max">
            {items.map((item, index) => (
              <Card key={index} className="w-[150px]">
                <CardContent className="p-0">
                  {isVideo && item.video ? (
                    <ReactPlayer
                      url={item.video}
                      controls
                      width="100%"
                      height="150px"
                      className="rounded-md"
                    />
                  ) : (
                    <img
                      src={item?.image}
                      alt={`${item?.title || "Media"} ${index + 1}`}
                      className="w-full h-[150px] object-cover rounded-md"
                    />
                  )}
                </CardContent>
              </Card>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      )}
    </div>
  );
};

const Media = () => {
  const asignProtectImages = [
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-TECvErw8vHOslzH9wKWzjNYcR6tJIZ.png",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-1niag1MT55U46Z5kolRMMz83FpLx42.png",
    "https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image-J6mI0aCZ1gTI4YWBYa6fISptS6U0oQ.png",
  ];
  const { currentObject } = useObjectContext();
  return (
    <div className="max-w-4xl p-4 mx-auto md:p-6">
      <h1 className="mb-6 text-2xl font-bold">Media</h1>

      <MediaSection
        title="Additional object images"
        addButtonText="ADD IMAGE"
        items={currentObject?.document?.media?.addtionImages}
        scehmaKey="addtionImages"
      />
      <MediaSection
        title="Object videos"
        addButtonText="ADD VIDEO"
        isVideo
        scehmaKey="videos"
        items={currentObject?.document?.media?.videos}
      />
      <MediaSection addButtonText="" title="Object archives" isLocked={true} />
      <MediaSection
        title="Archival images"
        addButtonText="ADD IMAGE"
        scehmaKey="archivedImage"
        items={currentObject?.document?.media?.archivedImage}
      />
      <MediaSection
        title="Archival videos"
        addButtonText="ADD VIDEO"
        isVideo
        scehmaKey="archivedVideos"
        items={currentObject?.document?.media?.archivedVideos}
      />

      <div className="mb-8">
        <h2 className="mb-2 text-lg font-semibold">Asign Protect+ images</h2>
        <p className="mb-4 text-sm text-gray-500">
          These images will be uploaded to the Asign Connect database and be for
          recreation return purposes only.
        </p>
        <ScrollArea className="w-full border rounded-md whitespace-nowrap">
          <div className="flex p-4 space-x-4 w-max">
            {asignProtectImages.map((image, index) => (
              <Card key={index} className="w-[150px]">
                <CardContent className="p-0">
                  <img
                    src={image}
                    alt={`Asign Protect+ image ${index + 1}`}
                    className="w-full h-[150px] object-cover rounded-md"
                  />
                </CardContent>
              </Card>
            ))}
          </div>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
      </div>
    </div>
  );
};

export default Media;

import React, { useEffect, useRef, useState } from "react";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { MoreHorizontal, MoreVertical, Plus } from "lucide-react";
import AddLocation from "./modal/AddLocation";
import { useUserContext } from "@/context/user";
import { useObjectContext } from "@/context/objects";
import { ActionDropDown } from ".";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import Global from "@/modal/Global";

const Location = () => {
  const { me } = useUserContext(); // User data including current addresses
  const { updateOrAddItem, currentObject } = useObjectContext(); // Function to update the current object in your context
  const [selectedLocation, setSelectedLocation] = useState(
    currentObject?.currentLocation?.locationId ?? "",
  );
  const popoverRef = useRef<HTMLDivElement>(null);

  const onDelete = (id: string) => {
    updateOrAddItem({
      pastLocations: [
        {
          _id: id,
          delete: true,
        },
      ],
    });
  };

  // Function to update the current location and move the previous one to past locations
  const updateCurrentAddress = (newLocationId: string) => {
    if (!newLocationId) return;

    // Find the selected location object from the list of addresses
    const newLocation = me.address.find(
      (item: any) => item._id === newLocationId,
    );

    if (!newLocation) return;

    // Ensure that the locationId is correctly preserved for both current and past locations
    const cleanLocation = ({ _id, ...rest }: any) => ({
      ...rest,
      locationId: _id, // This ensures locationId is correctly set
    });

    // Prepare the payload to update the currentLocation and pastLocations

    const payload = {
      // Set the cleaned new location as the current location (object, not array)
      currentLocation: cleanLocation(newLocation),

      // Move the previous currentLocation to pastLocations if it exists
      pastLocations: [
        ...(currentObject?.currentLocation
          ? [
              {
                ...cleanLocation(currentObject.currentLocation),
                locationId: currentObject.currentLocation.locationId,
              },
            ]
          : []),
        ...(currentObject?.pastLocations || []),
      ],
    };

    // Update the object using the updateOrAddItem function
    updateOrAddItem(payload);
  };

  // Handle location change and trigger the update
  const handleLocationChange = (newLocationId: string) => {
    setSelectedLocation(newLocationId); // Update local state
    updateCurrentAddress(newLocationId); // Trigger API call
  };

  // Wait until data is available, then set the initial location
  useEffect(() => {
    if (currentObject?.currentLocation?.locationId) {
      setSelectedLocation(currentObject.currentLocation.locationId);
    }
  }, [currentObject?.currentLocation]);

  useEffect(() => {
    if (popoverRef.current) {
      popoverRef.current.focus();
    }
  }, []);
  return (
    <>
      <p className="mb-5 text-[#696969]">
        Record the current and past locations of the object.
      </p>
      <div className="space-y-4">
        <div className="space-y-2">
          <Label htmlFor="location" className="text-sm font-medium">
            Current location <span className="text-red-500">*</span>
          </Label>
          <Select
            onValueChange={handleLocationChange}
            value={String(selectedLocation)}
          >
            <SelectTrigger
              id="location"
              className="w-full text-left border border-gray-300 h-[59px] px-5 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            >
              <SelectValue placeholder="Select a location" />
            </SelectTrigger>
            <SelectContent>
              <SelectGroup>
                {me?.address?.map((item: any) => (
                  <SelectItem
                    value={item._id}
                    className="px-4 py-2 pl-7"
                    key={item._id}
                  >
                    <div className="space-y-1">
                      <div className="flex items-center justify-between">
                        <span>
                          {item.addressType} - {item.subAddressType}
                        </span>
                      </div>
                      <p className="text-sm text-gray-500">
                        {item.lineOne}, {item.lineTwo}, {item.city},{" "}
                        {item.state}, {item.pinCode}
                      </p>
                    </div>
                  </SelectItem>
                ))}
              </SelectGroup>
              <AddLocation>
                <Button
                  variant="ghost"
                  className="w-full mt-2  border-none bg-[#F2F2F2]  rounded-none justify-start px-5"
                  type="button"
                >
                  <Plus className="w-4 h-4 mr-2" />
                  ADD LOCATION
                </Button>
              </AddLocation>
            </SelectContent>
          </Select>
        </div>
        <div className="space-y-2">
          <h3 className="text-sm font-medium">Past location(s)</h3>
          <div className="border border-solid rounded-md border-bdr-10">
            {currentObject?.pastLocations?.map((location: any) => (
              <div
                key={location.id}
                className="flex items-center justify-between p-4 border-b border-solid last:border-b-0 border-bdr-10"
              >
                <span>
                  {location.addressType} - {location.subAddressType}{" "}
                </span>
                <Popover>
                  <PopoverTrigger asChild>
                    <button className="focus:outline-none" type="button">
                      <MoreHorizontal className="w-5 h-5 text-gray-500" />
                    </button>
                  </PopoverTrigger>
                  <PopoverContent
                    className="w-56 p-0 font-normal border border-gray-200 rounded-md shadow-lg font-sh5"
                    align="end"
                    ref={popoverRef}
                    tabIndex={0}
                  >
                    <div className="py-2">
                      <AddLocation
                        defaultId={location.id}
                        addressType="pastLocations"
                      >
                        <button
                          className="w-full px-4 py-2 text-sm font-normal text-left"
                          type="button"
                        >
                          Edit
                        </button>
                      </AddLocation>

                      {/* You can add more conditions for other dataKeys as needed */}
                      <Global
                        title="REMOVE"
                        description={`Are you sure you want to remove ‘${location.addressType} - ${location.subAddressType}’  as a past location for this object?`}
                        actionText="Remove"
                        cancelText="CANCEL"
                        onConfirm={() => onDelete(location.id)}
                      >
                        <button
                          className="w-full px-4 py-2 text-sm font-normal text-left "
                          type="button"
                        >
                          Remove
                        </button>
                      </Global>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Location;

import React from "react";

const TextFooter = () => {
  return (
    <footer className="flex items-center justify-center text-sm font-normal mq450:hidden mq750:hidden font-sh5 text-gray-60">
      <footer className="absolute left-0 right-0 w-full pt-1 text-sm text-center text-gray-400 bottom-4">
        <a
          href="https://www.asign.art/terms-and-conditions/"
          className="hover:underline"
        >
          Terms & Conditions
        </a>
        {" | "}
        <a
          href="https://www.asign.art/privacy-policy/"
          className="hover:underline"
        >
          Privacy Policy
        </a>
        {" | "}
        <a href="https://www.asign.art/contact/" className="hover:underline">
          Contact us
        </a>
        <span className="mt-1 ">
          &nbsp; | © 2024 Democraft Technologies Private Limited
        </span>
      </footer>
    </footer>
  );
};

export default TextFooter;

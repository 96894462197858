import React, { FunctionComponent, lazy, Suspense } from "react";
import Layout from "@/layout/DefaultLayout";
import useFilter from "@/hooks/useFilter";
import useGlobalApis from "@/apis/global";
import { useQuery } from "@tanstack/react-query";
import LazyLoader from "@/components/LazyLoader";
const Preview = lazy(() => import("@/components/Preview/Preview"));

const Discover: FunctionComponent = () => {
  const { filterOption } = useFilter({
    query: {
      sectionStatus: "publish",
    },
  });
  const { getFeed } = useGlobalApis();
  const feed = useQuery({
    queryKey: ["discover-feed", filterOption],
    queryFn: () =>
      getFeed({
        params: filterOption,
        type: "discover",
      }),
  });

  return (
    <Layout viewMobTopNav>
      <Suspense fallback={<LazyLoader />}>
        <Preview
          data={feed.data?.data}
          type="discover"
          parentclass="gap-0 overflow-x-hidden lg:gap-y-[0px] gap-y-[0px] px-5 lg:px-[48px]"
          isLoading={feed.isLoading}
        />
      </Suspense>
    </Layout>
  );
};

export default Discover;

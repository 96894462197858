import React, { ReactNode, useState, useCallback, useRef } from "react";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMediaQuery } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import useBoard from "@/apis/board";
import { cn } from "@/lib/utils";
import toast from "react-hot-toast";

type UploadMediaProps = {
  children: ReactNode;
  boardId: string;
};

const UploadMedia: React.FC<UploadMediaProps> = ({ children, boardId }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return isDesktop ? (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="min-w-[640px]">
        <DialogHeader className="px-8">
          <DialogTitle className="pb-5 border-[#E5E5E5] border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>Upload File</p>
              <DialogClose asChild>
                <img src="/close1.svg" alt="Close" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm boardId={boardId} onClose={handleClose} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={() => setIsOpen(true)}
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader>
          <div className="flex flex-row items-center justify-between">
            <p>Upload File</p>
            <DialogClose asChild>
              <img src="/close1.svg" alt="Close" />
            </DialogClose>
          </div>
        </DrawerHeader>
        <ProfileForm boardId={boardId} onClose={handleClose} />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  onClose: () => void;
};

function ProfileForm({ className, boardId, onClose }: ProfileFormProps) {
  const [loading, setLoading] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [base64Image, setBase64Image] = useState<string | null>(null);
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const { addItem } = useBoard();
  const queryClient = useQueryClient();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const mutation = useMutation({
    mutationFn: addItem,
    onSuccess: (response) => {
      resetForm();
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
      toast.success("File uploaded successfully", { id: "upload-file" });
      onClose();
    },
    onError: (error: Error) => {
      toast.error("Error uploading file", { id: "upload-file" });
      console.error("Error uploading file:", error);
    },
    onMutate: () => {
      toast.loading("Uploading file...", { id: "upload-file" });
    },
  });

  const resetForm = () => {
    setUploadComplete(true);
    setFile(null);
    setBase64Image(null);
    setPreviewUrl(null);
    setLoading(false);
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  const handleDrop = useCallback(async (event: React.DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      await processFile(droppedFiles[0]);
    }
  }, []);

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files?.length) {
      await processFile(event.target.files[0]);
    }
  };

  const processFile = async (selectedFile: File) => {
    if (!validateFile(selectedFile)) return;
    setFile(selectedFile);
    setLoading(true);
    setUploadComplete(false);
    const base64 = await convertToBase64(selectedFile);
    setBase64Image(base64);
    setPreviewUrl(URL.createObjectURL(selectedFile));
    setLoading(false);
  };

  const validateFile = (file: File) => {
    const maxFileSize = 20 * 1024 * 1024; // 20MB
    const validTypes = [
      "image/jpeg",
      "image/png",
      "image/webp",
      "application/pdf",
      "video/avi",
    ];
    if (!validTypes.includes(file.type)) {
      toast.error("Invalid file type.");
      return false;
    }
    if (file.size > maxFileSize) {
      toast.error("File size exceeds the 20MB limit.");
      return false;
    }
    return true;
  };

  const handleClickUploadArea = () => {
    fileInputRef.current?.click();
  };

  const handleSave = () => {
    if (base64Image && file) {
      const base64Data = base64Image.split(",")[1];
      mutation.mutate({
        boardId,
        itemType: "file",
        base64Image: base64Data,
        name: file.name, // Send file name
      });
    } else {
      toast.error("No file selected.");
    }
  };

  return (
    <form className={cn("grid items-center gap-5 mq450:gap-8", className)}>
      <div className="px-8">
        <div
          onClick={handleClickUploadArea}
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          className={`w-full cursor-pointer bg-[#F6F6F6] border-[1px] border-dotted border-gray-40 flex flex-col items-center justify-center gap-3 py-8 ${
            loading ? "bg-[#e0e0e0]" : ""
          }`}
        >
          <input
            ref={fileInputRef}
            type="file"
            accept=".jpg,.jpeg,.png,.webp,.pdf,.avi"
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          {!previewUrl && !loading && (
            <>
              <img
                className="w-[24px] h-[24px]"
                src="/add_image.svg"
                alt="Add"
              />
              <p className="text-lg font-normal text-gray-100 font-sh5">
                Drag and drop or browse files
              </p>
              <p className="text-sm font-normal font-sh5 mq450:text-center text-gray-60">
                Up to 20 MB, in .jpg, .png, .webp, .pdf, or .avi format
              </p>
            </>
          )}
          {loading && (
            <div className="flex items-center justify-center w-full mt-4">
              <div className="inline-block px-2 py-1 text-xs font-semibold text-teal-600 bg-teal-200 rounded-full">
                Uploading...
              </div>
            </div>
          )}
          {uploadComplete && !loading && (
            <p className="mt-4 font-semibold text-other-cta">
              File uploaded, hit on save
            </p>
          )}
          {previewUrl && !loading && (
            <div className="px-8 py-5">
              {file?.type.startsWith("image/") && (
                <img src={previewUrl} alt="Preview" className="w-[200px]" />
              )}
              {file?.type.startsWith("video/") && (
                <video
                  src={previewUrl}
                  controls
                  className="h-auto max-w-full"
                />
              )}
              {file?.type === "application/pdf" && (
                <iframe
                  src={previewUrl}
                  title="PDF Preview"
                  className="w-full h-60"
                />
              )}
            </div>
          )}
        </div>
        <p className="pt-5 text-sm font-normal font-sh5 text-gray-60">
          Upload an image, video, or PDF to add to your Boards.
        </p>
      </div>
      <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
            type="button"
            onClick={onClose}
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] px-4 font-medium text-sm text-white"
          variant="default"
          type="button"
          onClick={handleSave}
          disabled={loading || !file}
        >
          UPLOAD
        </Button>
      </div>
    </form>
  );
}

export default UploadMedia;

import React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { cn } from "@/lib/utils";

interface ItemTypeFilterProps {
  onSelect: (type: string) => void;
  selectedType: string;
}

const ItemTypeFilter: React.FC<ItemTypeFilterProps> = ({
  onSelect,
  selectedType,
}) => {
  // Default value for the dropdown trigger
  const displayText = selectedType || "Show All";

  const itemStyle =
    "font-normal text-sm font-sh5 leading-[19.2px] text-[#696969]";

  const displayLabelByValue = {
    object: "Object",
    artist: "Artists",
    business: "Business",
    events: "Events",
    writing: "Writing",
    text: "Texts",
    file: "Images",
    video: "Videos",
    link: "Link",
  };

  return (
    <div>
      <DropdownMenu>
        <DropdownMenuTrigger className="cursor-pointer text-sm font-sh5 nsh  bg-transparent border-[#E8E8E8] px-5 py-3 rounded-[50px] border-[1px] border-solid capitalize w-[120px] flex items-center justify-between">
          <span className="truncate">
            {displayText == ""
              ? "Show All"
              : displayLabelByValue[
                  selectedType as keyof typeof displayLabelByValue
                ] || "Show All"}
          </span>
          <img className="ml-2" src="/arrow.svg" alt="arrow" />
        </DropdownMenuTrigger>
        <DropdownMenuContent
          side="bottom"
          sideOffset={10}
          align="start"
          className="p-8 border border-[#D9D9D9] max-h-[400px] overflow-auto w-[232px]"
        >
          <DropdownMenuGroup className="flex flex-col gap-6 w-full overflow-auto text-base text-gray-60 font-sh5">
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("")}
            >
              Show All
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("object")}
            >
              Object
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("artist")}
            >
              Artists
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("business")}
            >
              Business
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("events")}
            >
              Events
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("writing")}
            >
              Writing
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("text")}
            >
              Texts
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("file")}
            >
              Images
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("video")}
            >
              Videos
            </DropdownMenuItem>
            <DropdownMenuItem
              className={cn(itemStyle)}
              onClick={() => onSelect("link")}
            >
              Link
            </DropdownMenuItem>
          </DropdownMenuGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default ItemTypeFilter;

import React from "react";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { cn } from "@/lib/utils";

interface BusinessInfoFormProps {
  stepNumber: number;
  totalSteps: number;
  isRegistered: string;
  setIsRegistered: (value: string) => void;
  companyType: string;
  setCompanyType: (value: string) => void;
  contactPerson: string;
  setContactPerson: (value: string) => void;
  businessWebsite: string;
  setBusinessWebsite: (value: string) => void;
  setType: (type: string) => void;
  nextStep: () => void;
  selectedOption: string;
  userId: string;
  selectedAccountType: string;
}

const BusinessInfoForm: React.FC<BusinessInfoFormProps> = ({
  stepNumber,
  totalSteps,
  isRegistered,
  setIsRegistered,
  companyType,
  setCompanyType,
  contactPerson,
  setContactPerson,
  businessWebsite,
  setBusinessWebsite,
  setType,
  nextStep,
  selectedOption,
  userId,
  selectedAccountType,
}) => {
  console.log(companyType, contactPerson, businessWebsite);
  // const handleContinue = () => {
  //   if (selectedOption === "company") {
  //     setType("verifypan");
  //   } else {
  //     nextStep();
  //     setType("verifypan");
  //   }
  // };
  const handleContinue = async () => {
    try {
      await updateProfile({
        id: userId,
        companyType,
        contactPerson,
        website: businessWebsite,
      });

      if (selectedOption === "company" && selectedAccountType !== "collector") {
        setType("verifypan");
      } else {
        nextStep();
        setType("verifypan");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  const updateProfile = async (profileData: {
    id: string;
    companyType: string;
    contactPerson: string;
    website: string;
  }) => {
    try {
      const updateHeaders = new Headers();
      updateHeaders.append("Content-Type", "application/json");

      const updateRaw = JSON.stringify(profileData);

      const updateRequestOptions = {
        method: "POST",
        headers: updateHeaders,
        body: updateRaw,
        redirect: "follow" as RequestRedirect,
      };

      const updateResponse = await fetch(
        "https://ab.host.levitation.co.in/client/auth/update-profile",
        updateRequestOptions,
      );

      const updateResult = await updateResponse.json();

      if (updateResponse.ok) {
        console.log("Profile updated successfully");
        // You might want to update some state or UI here to reflect the successful update
      } else {
        console.error("Failed to update profile:", updateResult.message);
        // Handle the error case in the UI
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle the error case in the UI
    }
  };

  return (
    <div
      className={cn(
        "w-full flex flex-col p-8 ",
        selectedAccountType !== "collector" ? "md:w-1/2 md:p-16" : "",
      )}
    >
      <div className="flex-grow max-w-md mx-auto w-full">
        <p className="text-gray-500 mb-2 text-sm">
          Step {stepNumber} of {totalSteps}
        </p>
        <h1 className="text-4xl font-bold mb-8">Tell us about your business</h1>

        <form className="space-y-6">
          <div>
            <p className="mb-4 font-medium">
              Is your company registered in India?
            </p>
            <RadioGroup value={isRegistered} onValueChange={setIsRegistered}>
              <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-4 mb-2">
                <RadioGroupItem value="yes" id="yes" />
                <Label htmlFor="yes">YES</Label>
              </div>
              <div className="flex items-center space-x-2 border border-gray-300 rounded-md p-4">
                <RadioGroupItem value="no" id="no" />
                <Label htmlFor="no">NO</Label>
              </div>
            </RadioGroup>
          </div>

          {isRegistered === "yes" && (
            <>
              <div>
                <label
                  htmlFor="companyType"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Type of company
                </label>
                <Select value={companyType} onValueChange={setCompanyType}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder="Proprietorship" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="proprietorship">
                      Proprietorship
                    </SelectItem>
                    <SelectItem value="partnership">Partnership</SelectItem>
                    <SelectItem value="llp">
                      Limited Liability Partnership
                    </SelectItem>
                    <SelectItem value="privateLimited">
                      Private Limited
                    </SelectItem>
                    <SelectItem value="publicLimited">
                      Public Limited
                    </SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div>
                <label
                  htmlFor="contactPerson"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Contact person
                </label>
                <Input
                  id="contactPerson"
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  className="w-full"
                />
              </div>

              <div>
                <label
                  htmlFor="businessWebsite"
                  className="block mb-2 text-sm font-medium text-gray-700"
                >
                  Business website
                </label>
                <Input
                  id="businessWebsite"
                  value={businessWebsite}
                  onChange={(e) => setBusinessWebsite(e.target.value)}
                  className="w-full"
                />
              </div>
            </>
          )}
        </form>
      </div>

      <div className="mt-8 px-24">
        <Button
          className="w-full bg-gray-200 text-gray-800 hover:bg-gray-300"
          onClick={handleContinue}
        >
          CONTINUE
        </Button>
      </div>
    </div>
  );
};

export default BusinessInfoForm;

interface UpdateProfileData {
  [key: string]: any;
}

const updateProfile = async (userId: string, data: UpdateProfileData) => {
  try {
    const response = await fetch(
      "https://ab.host.levitation.co.in/client/auth/update-profile",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: userId, ...data }),
        redirect: "follow" as RequestRedirect,
      },
    );

    const result = await response.json();

    if (response.ok) {
      console.log("Profile updated successfully");
      return result;
    } else {
      console.error("Failed to update profile:", result.message);
      throw new Error(result.message);
    }
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export default updateProfile;

// Usage example:
// updateProfile(userId, { panNumber: "ABCDE1234F" })
//   .then(result => {
//     // Handle success
//   })
//   .catch(error => {
//     // Handle error
//   });

import React from "react";
import { Button } from "@/components/ui/button";
import AddSecondaryMeasurement from "./modal/AddSecondaryMeasurement";
import { MoreHorizontal } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useObjectContext } from "@/context/objects";
import { ActionDropDown } from ".";

export default function SecondaryMeasurement() {
  const { currentObject } = useObjectContext();

  const handleDelete = (id: string) => {
    // Implement delete logic here
  };

  return (
    <div className="space-y-4">
      {currentObject?.document?.secondaryMeasurements?.length === 0 ? (
        <p className="text-sm text-gray-500">
          You have not added any secondary measurements yet.
        </p>
      ) : (
        <ul className="space-y-2">
          {currentObject?.document?.secondaryMeasurements?.map(
            (measurement: any, index: number) => (
              <li
                key={measurement.id}
                className="flex items-center justify-between p-2 bg-white rounded-lg"
              >
                <span>Secondary measurement {index + 1}</span>
                <ActionDropDown
                  id={measurement._id}
                  dataKey="document.secondaryMeasurements"
                />
              </li>
            ),
          )}
        </ul>
      )}
      <AddSecondaryMeasurement>
        <Button variant="link" className="h-auto p-0 mt-2">
          + ADD SECONDARY MEASUREMENT
        </Button>
      </AddSecondaryMeasurement>
    </div>
  );
}

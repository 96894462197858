import React from "react";
import { apis } from ".";
import { Event } from "@/types/event.type";

const useEvent = () => {
  const getEventById = async ({ id }: { id: string }): Promise<Event> => {
    try {
      const { data } = await apis.get(`client/api/v1/events/${id}`, {});
      return data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  return { getEventById };
};

export default useEvent;

import updateProfile from "@/apis/updateProfile";
import { Button } from "@/components/ui/button";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";

interface OTPVerificationProps {
  phoneNumber: string;
  onVerify: (otp: string) => void;
  onEditNumber: () => void;
  onResendOTP: () => void;
  stepNumber: number;
  totalSteps: number;
  setType: (type: string) => void;
  nextStep: () => void;
  uuid?: string;
  setAddress: (address: any) => void;
  userId: string;
  aadhaar: string;
}

function formatAddress(addressString: string) {
  // Split the address string by commas
  const addressParts = addressString.split(",");

  // Ensure that the address parts have the expected components
  if (addressParts.length < 8) {
    throw new Error("Incomplete address data");
  }

  return {
    address: [
      {
        lineOne: addressParts[0]?.trim() || "",
        lineTwo: `${addressParts[1]?.trim() || ""}, ${
          addressParts[2]?.trim() || ""
        }`,
        country: addressParts[6]?.trim() || "",
        state: addressParts[5]?.trim() || "",
        city: addressParts[3]?.trim() || "",
        pinCode: addressParts[7]?.trim() || "",
      },
    ],
  };
}

const AadharOTPVerification: React.FC<OTPVerificationProps> = ({
  onVerify,
  onEditNumber,
  onResendOTP,
  stepNumber,
  totalSteps,
  setType,
  nextStep,
  uuid,
  setAddress,
  userId,
  aadhaar,
}) => {
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [verificationError, setVerificationError] = useState<string | null>(
    null,
  );

  const {
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  const otp = watch("otp");

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setCanResend(true);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const verifyOTP = async (data: { otp: string }) => {
    setIsVerifying(true);
    setVerificationError(null);

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      uuid: uuid,
      otp: otp,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow" as RequestRedirect,
    };

    try {
      const response = await fetch(
        "https://ab.host.levitation.co.in/client/auth/enter-aadhar-otp",
        requestOptions,
      );
      const result = await response.json();

      if (result.status !== "VALIDATION_ERROR") {
        console.log("OTP verification successful:", result);
        setAddress(result.data.response_data.address);
        onVerify(data.otp);

        const newAddress = result.data.response_data.address;
        const addressToSend = formatAddress(newAddress);

        const dataToUpdate = {
          address: addressToSend.address,
          additionalInfo: {
            aadhar: aadhaar,
          },
        };

        try {
          const updateResult = await updateProfile(userId, dataToUpdate);
          console.log("Profile updated with Aadhar info:", updateResult);
        } catch (updateError) {
          console.error(
            "Error updating profile with Aadhar info:",
            updateError,
          );
        }

        setType("address");
        nextStep();
      } else {
        throw new Error(result.message || "OTP verification failed");
      }
    } catch (error) {
      console.error("OTP verification error:", error);
      setVerificationError(
        error instanceof Error
          ? error.message
          : "An error occurred during OTP verification",
      );
      setType("address");
      nextStep();
    } finally {
      setIsVerifying(false);
    }
  };

  // const onSubmit = (data: { otp: string }) => {
  //   onVerify(data.otp);
  // };

  const handleResendOTP = async () => {
    try {
      // Set up headers and request body
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        aadhaar: aadhaar, // replace this with a dynamic value if needed
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow" as RequestRedirect,
      };

      // Make API call
      const response = await fetch(
        "https://ab.host.levitation.co.in/client/auth/enter-aadhar",
        requestOptions,
      );
      const result = await response.json();

      // Log or handle the result
      console.log("Resend OTP result:", result);

      // Reset the timer and disable the resend button
      setTimer(30);
      setCanResend(false);

      // Check for success message to handle any error messages from the backend
      if (!result.success && result.message !== "otp send successfully") {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
      setVerificationError(
        error instanceof Error
          ? error.message
          : "Failed to resend OTP. Please try again.",
      );
    }
  };

  return (
    <div className="flex flex-col w-full p-8 overflow-y-auto md:w-1/2 md:p-16">
      <div className="w-full max-w-md mx-auto">
        <p className="mb-2 text-sm text-gray-500">
          Step {stepNumber} of {totalSteps}
        </p>
        <h1 className="mb-4 text-4xl font-bold">Verify OTP</h1>
        <p className="mb-8 text-[#696969] text-[16px] font-normal">
          An OTP has been sent to your Aadhar Registered Mobile Number
        </p>
        <form className="space-y-6">
          <div>
            <label
              htmlFor="otp"
              className="block mb-1 text-sm font-medium text-gray-700"
            >
              OTP
            </label>
            <input
              type="text"
              id="otp"
              {...register("otp", {
                required: "OTP is required",
                pattern: {
                  value: /^[0-9]{6}$/,
                  message: "OTP must be 6 digits",
                },
              })}
              className="block h-[59px] w-full px-3 py-2 mt-1 border border-gray-300 shadow-sm focus:outline-none focus:ring-black focus:border-black text-[16px]"
            />
            {errors.otp && (
              <p className="mt-1 text-xs text-red-500">
                {errors.otp.message as string}
              </p>
            )}
          </div>
          <p className="text-sm text-gray-500">
            {canResend ? (
              <Button
                variant="link"
                type="button"
                className="text-black underline"
                onClick={handleResendOTP}
              >
                Resend OTP
              </Button>
            ) : (
              <>
                <p>UIDAI-generated OTPs take up to 10 minutes to deliver.</p>
                <p>Resend OTP in {timer} seconds</p>
              </>
            )}
          </p>
          <Button
            className={`w-full py-3 px-4 bg-black text-white rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
              !isValid ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-800"
            }`}
            disabled={!isValid || isVerifying}
            onClick={() => verifyOTP({ otp: otp })}
          >
            {isVerifying ? "Verifying..." : "CONTINUE"}
          </Button>
        </form>
      </div>
    </div>
  );
};

export default AadharOTPVerification;

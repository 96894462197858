import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import useMediaQuery from "@/hooks/use-media-query";
import React, { useState } from "react";

interface GlobalProps {
  children: React.ReactNode;
  title?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  TriggerButton: React.ReactNode;
  confirmText?: string;
  isError?: boolean;
}

const GlobalDialog = ({
  children,
  TriggerButton,
  title,
  onConfirm,
  onCancel,
  confirmText = "SAVE",
  isError = false,
}: GlobalProps) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    if (isError) return;
    if (onConfirm) onConfirm();
    // setIsOpen(false);
  };

  const handleCancel = () => {
    if (onCancel) onCancel();
    setIsOpen(false);
  };

  const commonContent = (
    <>
      <div className="grid items-center px-8 font-normal font-sh5 max-h-[70vh] overflow-y-scroll">
        {children}
      </div>
      <div className="flex mt-2 flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
            type="button"
            onClick={handleCancel}
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] px-4 font-medium text-sm text-white"
          variant="default"
          type="submit"
          onClick={handleConfirm}
        >
          {confirmText}
        </Button>
      </div>
    </>
  );

  return (
    <>
      <div onClick={() => setIsOpen(true)}>{TriggerButton}</div>
      {isDesktop ? (
        <Dialog open={isOpen} onOpenChange={setIsOpen}>
          <DialogContent className="min-w-[640px]">
            <DialogHeader className="px-8">
              <DialogTitle className="pb-5 border-[#E5E5E5] border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
                <div className="flex flex-row items-center justify-between">
                  <p>{title ?? "Untitled"}</p>
                  <DialogClose asChild onClick={handleCancel}>
                    <img src="/close1.svg" alt="Close" />
                  </DialogClose>
                </div>
              </DialogTitle>
            </DialogHeader>
            {commonContent}
          </DialogContent>
        </Dialog>
      ) : (
        <Drawer open={isOpen} onOpenChange={setIsOpen}>
          <DrawerContent className="rounded-none">
            <DrawerHeader className="text-left border-b border-solid border-gray-10">
              <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
                <div className="flex flex-row items-center justify-between">
                  <p>{title ?? "Untitled"}</p>
                  <DialogClose
                    asChild
                    className="bg-transparent cursor-pointer"
                  >
                    <img
                      className="cursor-pointer"
                      src="/close1.svg"
                      alt="Close"
                    />
                  </DialogClose>
                </div>
              </DrawerTitle>
            </DrawerHeader>
            {commonContent}
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default GlobalDialog;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import Keycloak from "keycloak-js";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { jwtDecode } from "jwt-decode";
import useUserAPI from "@/apis/user";

// Keycloak configuration
const keycloak = new Keycloak({
  url: "https://lv.up.railway.app",
  realm: "asign",
  clientId: "asign_client",
});

interface AuthState {
  isLogin: boolean;
  token: string | null;
  error: string | null;
  isLoading: boolean;
  email_verified: boolean;
}

interface TokenPayload {
  exp: number;
  userType: string;
  email_verified: boolean;
}

interface AuthContextType extends AuthState {
  login: (token: string) => Promise<boolean>;
  logout: () => Promise<void>;
  keycloak: Keycloak.KeycloakInstance;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { refreshToken } = useUserAPI();
  const cookies = new Cookies();
  const refreshTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [authState, setAuthState] = useState<AuthState>({
    isLogin: false,
    token: null,
    error: null,
    isLoading: true,
    email_verified: false,
  });

  const checkTokenValidity = (
    token: string,
  ): { isValid: boolean; timeUntilExpiry: number } => {
    try {
      const decoded = jwtDecode<TokenPayload>(token);
      const currentTime = Math.floor(Date.now() / 1000);
      const timeUntilExpiry = decoded.exp - currentTime;

      return {
        isValid: timeUntilExpiry > 0,
        timeUntilExpiry,
      };
    } catch (error) {
      console.error("Error decoding token:", error);
      return { isValid: false, timeUntilExpiry: 0 };
    }
  };

  const checkUserAccess = (token: string): boolean => {
    try {
      const decoded = jwtDecode<TokenPayload>(token);
      setAuthState((prev) => ({
        ...prev,
        email_verified: decoded?.email_verified,
      }));
      console.log("Decoded token:", decoded);
      const userType = Number(decoded.userType);
      return userType !== 3;
    } catch (error) {
      console.error("Error checking user access:", error);
      return false;
    }
  };

  const setCookie = (name: string, value: string) => {
    // Remove existing cookie first
    cookies.remove(name, { path: "/" });
    // Set new cookie
    cookies.set(name, value, {
      path: "/",
      sameSite: "strict",
    });
  };

  const startTokenRefresh = (token: string) => {
    if (refreshTimerRef.current) {
      clearInterval(refreshTimerRef.current);
    }

    const { timeUntilExpiry } = checkTokenValidity(token);
    const refreshInterval = Math.min(timeUntilExpiry * 1000 - 60000, 30000);

    refreshTimerRef.current = setInterval(async () => {
      try {
        const refreshed = await refreshToken();

        if (refreshed) {
          const isValidUser = checkUserAccess(refreshed.access_token);
          if (isValidUser) {
            // Replace the existing cookies
            setCookie("token", refreshed.access_token);
            if (refreshed.refresh_token) {
              setCookie("refreshToken", refreshed.refresh_token);
            }

            setAuthState((prev) => ({
              ...prev,
              token: refreshed.access_token,
              error: null,
            }));
            console.log("Token refreshed successfully");
          } else {
            throw new Error("User no longer has access");
          }
        }
      } catch (error) {
        console.error("Failed to refresh token:", error);
        toast.error("Session expired. Please login again.");
        await logout();
      }
    }, refreshInterval);
  };

  const login = async (token: string): Promise<boolean> => {
    try {
      const { isValid } = checkTokenValidity(token);
      if (!isValid) {
        throw new Error("Token is expired or invalid");
      }

      const isValidUser = checkUserAccess(token);
      if (!isValidUser) {
        throw new Error("User not authorized");
      }

      keycloak.token = token;

      setAuthState((prev) => ({
        ...prev,
        isLogin: true,
        token: token,
        error: null,
        isLoading: false,
      }));

      // Set cookies using the new function
      setCookie("token", token);

      startTokenRefresh(token);
      return true;
    } catch (error) {
      console.error("Login failed:", error);
      toast.error(
        error instanceof Error
          ? error.message
          : "Login failed. Please try again.",
      );
      setAuthState((prev) => ({
        ...prev,
        isLogin: false,
        token: null,
        error: "Login failed",
        isLoading: false,
      }));
      return false;
    }
  };

  const logout = async () => {
    try {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }

      // Remove all auth-related cookies
      cookies.remove("token");
      cookies.remove("refreshToken");

      setAuthState((prev) => ({
        ...prev,
        isLogin: false,
        token: null,
        error: null,
        isLoading: false,
      }));
      toast.success("Logged out successfully");
    } catch (error) {
      console.error("Logout failed:", error);
      toast.error("Logout failed");
    }
  };

  useEffect(() => {
    const existingToken = cookies.get("token");
    if (existingToken) {
      const { isValid } = checkTokenValidity(existingToken);
      if (isValid) {
        login(existingToken);
      } else {
        cookies.remove("token", { path: "/" });
        cookies.remove("refreshToken", { path: "/" });
        setAuthState((prev) => ({ ...prev, isLoading: false }));
      }
    } else {
      setAuthState((prev) => ({ ...prev, isLoading: false }));
    }

    return () => {
      if (refreshTimerRef.current) {
        clearInterval(refreshTimerRef.current);
      }
    };
  }, []);

  return (
    <AuthContext.Provider value={{ ...authState, login, logout, keycloak }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };

import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import {
  AlertCircle,
  CheckCircle,
  CheckCircle2,
  Info,
  Loader2,
  Upload,
  X,
} from "lucide-react";
import { Checkbox } from "@/components/ui/checkbox";
import { Card } from "@/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import LeftFormImage from "./LeftFormImage";
import DynamicFormStep from "./UserDetails";
import OTPVerification from "./OTPVerification";
import Agreement from "./Agreement";
import BusinessInfoForm from "./BusinessInfoForm";
import IdentityVerificationForm from "./IdentityVerification";
import AadharOTPVerification from "./AadharOTPVerification";
import AddressForm from "./AddressForm";
import ThankYouPage from "./ThankyouPage";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const formSchema = z
  .object({
    userType: z.enum(["artist", "business", "collector"]),
    email: z.string().email("Invalid email address"),
    accountType: z.string(),
    password: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
        "Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character",
      ),
    confirmPassword: z.string(),
    fullName: z.string().min(2, "Full name must be at least 2 characters"),
    mobileNumber: z
      .string()
      .regex(/^\d{10}$/, "Mobile number must be 10 digits"),
    artistDetails: z
      .object({
        name: z.string(),
        medium: z.string(),
      })
      .optional(),
    businessDetails: z
      .object({
        companyName: z.string(),
        businessType: z.string(),
      })
      .optional(),
    collectorDetails: z
      .object({
        name: z.string(),
        interests: z.string(),
      })
      .optional(),
    termsAccepted: z.boolean(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ["confirmPassword"],
  });

type FormData = z.infer<typeof formSchema>;

export default function MultiStepForm() {
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [type, setType] = useState("detail");
  const [isChecked, setIsChecked] = useState(false);
  const [havePan, setHavePan] = useState(false);
  const [pan, setPan] = useState("");
  const [aadhaar, setAadhaar] = useState("");
  const [gst, setGst] = useState("");
  const [cin, setCin] = useState("");
  const [llp, setLLP] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [isAgreed, setIsAgreed] = useState(false);
  const [isRegistered, setIsRegistered] = useState<string | undefined>(
    undefined,
  );
  const [companyType, setCompanyType] = useState<string | undefined>();
  const [selectedOption, setSelectedOption] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [isAgreementChecked, setIsAgreementChecked] = useState(false);
  const [contactPerson, setContactPerson] = useState("");
  const [businessWebsite, setBusinessWebsite] = useState("");
  const [showThankYou, setShowThankYou] = useState(false);
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [uuid, setUUID] = useState<string | undefined>("");
  const [address, setAddress] = useState<string | undefined>("");
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [uploadStatus, setUploadStatus] = useState<
    "idle" | "loading" | "success" | "error"
  >("idle");

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);
      setUploadStatus("loading");
      setTimeout(() => {
        const fileExtension = selectedFile.name.split(".").pop()?.toLowerCase();
        if (fileExtension === "pdf" || fileExtension === "docx") {
          setUploadStatus("success");
        } else {
          setUploadStatus("error");
        }
      }, 2000);
    }
  };

  const handleRemove = () => {
    setFile(null);
    setUploadStatus("idle");
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    getValues,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
  });
  const { isLogin } = useAuth();
  const userType = watch("userType");
  const selectedAccountType = watch("accountType");

  const onSubmit: SubmitHandler<FormData> = (data) => {
    console.log(data);
    // Handle form submission
  };

  console.log(step, type, selectedOption, companyType, selectedAccountType);

  const handlePanChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPan(e.target.value);
    setIsVerified(e.target.value === "ASOPV1234H");
  };

  const handlePanAadhaarResponse = (value: string) => {
    if (value === "yes") {
      setHavePan(true);
    } else {
      setShowThankYou(true);
    }
  };

  const optionValues: { [key: string]: string } = {
    "AS AN INDIVIDUAL": "individual",
    "AS A COMPANY": "company",
    "AS AN ARTISTS ESTATE": "estate",
  };

  const accountTypes = [
    {
      value: "artist",
      label: "I AM AN ARTIST",
      description:
        "Creators, professional artists, art students and artist's estate",
      className: "h-8 w-8 mr-4 font-sh5",
    },
    {
      value: "business",
      label: "I AM A BUSINESS",
      description: "Art galleries, advisories and dealers",
      className: "h-7 w-7 mr-4 font-sh5",
    },
    {
      value: "collector",
      label: "I AM A COLLECTOR",
      description: "Art collectors, investors, enthusiasts and everyone else",
      className: "h-8 w-8 mr-4 font-sh5",
    },
  ];

  const stepInputs = {
    artist: [
      {
        id: "fullName",
        name: "fullName",
        label: "Full Name",
        type: "text",
        helpText: "As per your Aadhaar.",
      },
      {
        id: "displayName",
        name: "displayName",
        label: (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center space-x-1 font-sh5">
                  <span>Display name</span>
                  <Info className="w-4 h-4 text-gray-500 cursor-pointer" />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="transform -translate-y-1 ml-[-150px] md:ml-[-100px] xl:ml-[-130px] bg-[#f2f2f2] font-sh5 font-normal text-[12px]"
              >
                <p>This name will be displayed on Asign</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        type: "text",
      },
      {
        id: "mobileNumber",
        name: "mobileNumber",
        label: "Mobile Number",
        type: "tel",
        helpText: "OTP will be sent on your mobile number.",
      },
      {
        id: "email",
        name: "email",
        label: "Email",
        type: "email",
        helpText: "A verification email will be sent to you.",
      },
      {
        id: "password",
        name: "password",
        label: "Set Password",
        type: "password",
        helpText:
          "Your password must have at least 8-16 characters and contain an alphabet, a number, and a special character.",
      },
    ],
    collector: [
      {
        id: "fullName",
        name: "fullName",
        label: "Full Name",
        type: "text",
        helpText: "Enter your full name.",
      },
      {
        id: "mobileNumber",
        name: "mobileNumber",
        label: "Mobile Number",
        type: "tel",
        helpText: "OTP will be sent on your mobile number.",
      },
      {
        id: "email",
        name: "email",
        label: "Email",
        type: "email",
        helpText: "A verification email will be sent to you.",
      },
      {
        id: "password",
        name: "password",
        label: "Set Password",
        type: "password",
        helpText:
          "Your password must have at least 8-16 characters and contain an alphabet, a number, and a special character.",
      },
    ],
    business: [
      {
        id: "registeredCompanyName",
        name: "registeredCompanyName",
        label: (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center space-x-1">
                  <span>Registered Company Name</span>
                  <Info className="w-4 h-4 text-gray-500 cursor-pointer" />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="transform -translate-y-1 ml-[-150px] md:ml-[-100px] xl:ml-[-130px] bg-[#f2f2f2] font-sh5 font-normal text-[12px]"
              >
                <p>The name by which your company is registered</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        type: "text",
        helpText: "Enter your registered company name.",
      },
      {
        id: "brandName",
        name: "brandName",
        label: (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center space-x-1">
                  <span>Brand Name</span>
                  <Info className="w-4 h-4 text-gray-500 cursor-pointer" />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="transform -translate-y-1 ml-[-150px] md:ml-[-100px] xl:ml-[-130px] bg-[#f2f2f2] font-sh5 font-normal text-[12px]"
              >
                <p>The name will be displayed on Asign</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        type: "text",
        helpText: "Enter your brand name.",
      },
      {
        id: "mobileNumber",
        name: "mobileNumber",
        label: "Mobile Number",
        type: "tel",
        helpText: "OTP will be sent on your mobile number.",
      },
      {
        id: "email",
        name: "email",
        label: "Email",
        type: "email",
        helpText: "A verification email will be sent to you.",
      },
      {
        id: "password",
        name: "password",
        label: "Set Password",
        type: "password",
        helpText:
          "Your password must have at least 8-16 characters and contain an alphabet, a number, and a special character.",
      },
    ],
    company: [
      {
        id: "registeredCompanyName",
        name: "registeredCompanyName",
        label: (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center space-x-1">
                  <span>Registered company name</span>
                  <Info className="w-4 h-4 text-gray-500 cursor-pointer" />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="transform -translate-y-1 ml-[-150px] md:ml-[-100px] xl:ml-[-130px]"
              >
                <p>Any Description here</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        type: "text",
        helpText: "Enter your registered company name.",
      },
      {
        id: "artistDisplayName",
        name: "artistDisplayName",
        label: (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex items-center space-x-1">
                  <span>Artist display name</span>
                  <Info className="w-4 h-4 text-gray-500 cursor-pointer" />
                </div>
              </TooltipTrigger>
              <TooltipContent
                side="bottom"
                className="transform -translate-y-1 ml-[-150px] md:ml-[-100px] xl:ml-[-130px]"
              >
                <p>Any Description here</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ),
        type: "text",
        helpText: "",
      },
      {
        id: "mobileNumber",
        name: "mobileNumber",
        label: "Mobile Number",
        type: "tel",
        helpText: "OTP will be sent on your mobile number.",
      },
      {
        id: "email",
        name: "email",
        label: "Email",
        type: "email",
        helpText: "A verification email will be sent to you.",
      },
      {
        id: "password",
        name: "password",
        label: "Set Password",
        type: "password",
        helpText:
          "Your password must have at least 8-16 characters and contain an alphabet, a number, and a special character.",
      },
    ],
    estate: [
      {
        id: "artistName",
        name: "artistName",
        label: "Artist Name",
        type: "text",
        helpText: "",
      },
      {
        id: "estateName",
        name: "estateName",
        label: "Estate Name",
        type: "text",
      },
      {
        id: "representativeName",
        name: "representativeName",
        label: "Representative's Name",
        type: "text",
      },
      {
        id: "mobileNumber",
        name: "mobileNumber",
        label: "Representative's Mobile Number",
        type: "tel",
        helpText: "OTP will be sent on your mobile number.",
      },
      {
        id: "email",
        name: "email",
        label: "Representative's Email",
        type: "email",
        helpText: "A verification email will be sent to you.",
      },
      {
        id: "password",
        name: "password",
        label: "Set Password",
        type: "password",
        helpText:
          "Your password must have at least 8-16 characters and contain an alphabet, a number, and a special character.",
      },
    ],
  };

  const addressFields = [
    {
      id: "address1",
      label: "Address line 1",
      type: "input" as const,
      validation: (value: string) =>
        value ? null : "Address line 1 is required",
    },
    {
      id: "address2",
      label: "Address line 2",
      type: "input" as const,
    },
    {
      id: "country",
      label: "Country",
      type: "select" as const,
      options: [{ value: "India", label: "India" }],
      validation: (value: string) => (value ? null : "Country is required"),
    },
    {
      id: "state",
      label: "State",
      type: "select" as const,
      options: [{ value: "Maharashtra", label: "Maharashtra" }],
      validation: (value: string) => (value ? null : "State is required"),
    },
    {
      id: "city",
      label: "City",
      type: "input" as const,
      validation: (value: string) => (value ? null : "City is required"),
    },
    {
      id: "pincode",
      label: "PIN code",
      type: "input" as const,
      validation: (value: string) =>
        value.length === 6 ? null : "PIN code must be 6 digits",
    },
  ];

  const handleVerifyOTP = (otp: string) => {
    console.log("OTP verified:", otp);
    // Handle OTP verification logic here
    setType("agreement");
  };

  const handleEditNumber = () => {
    // Logic to go back to the phone number input step
    setStep(step - 1);
  };

  const handleContinue = () => {
    if (isRegistered === "yes") {
      console.log("here");
      setType("verifypan");
    } else {
      // Handle the case when the company is not registered
      // You might want to skip some steps or show different forms
      setType("someOtherType");
    }
    setStep(step + 1);
  };

  const handleResendOTP = () => {
    // Logic to resend OTP
    console.log("Resending OTP...");
  };

  const isFormValid = isVerified && aadhaar.length === 12 && isAgreed;

  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  if (showThankYou) {
    return <ThankYouPage />;
  }

  // if (isLogin) {
  //   navigate("/discover/feed");
  // }
  const selectedAccountType2 = watch("accountType");

  return (
    <div className="font-sh5">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Step 0 */}
        {step === 0 && (
          <div className="flex h-screen">
            {/* Left side - Image */}
            <LeftFormImage />
            {/* Right side - Form */}
            <div className="flex items-center justify-center w-full p-8 md:w-1/2">
              <div className="w-full max-w-md">
                <h1 className="text-[32px] font-normal mb-[40px]">
                  Create your account
                </h1>

                <div
                  className={`border ${
                    selectedAccountType ? "border-black" : "border-gray-400"
                  } border-solid`}
                >
                  {accountTypes.map((account, index) => (
                    <div
                      key={index}
                      className={`border-b border-solid ${
                        selectedAccountType2 === account.value
                          ? "border-black"
                          : "border-gray-400"
                      } py-[14px] px-[12px]`}
                    >
                      <label className="flex items-center p-4 space-x-2 rounded-lg cursor-pointer">
                        <input
                          type="radio"
                          value={account.value}
                          className={account.className}
                          {...register("accountType", { required: true })}
                        />
                        <div className="flex flex-col">
                          <span className="font-medium">{account.label}</span>
                          <span className="text-[16px] font-normal text-gray-500">
                            {account.description}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
                <Button
                  className={`w-full mt-10 rounded-full py-4 text-[14px] font-medium ${
                    !selectedAccountType
                      ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                      : "bg-black text-white hover:bg-gray-800"
                  }`}
                  onClick={nextStep}
                  disabled={!selectedAccountType}
                >
                  CONTINUE
                </Button>

                <div className="mt-4 text-center text-[14px] text-[#696969] font-normal">
                  Already have an account?{" "}
                  <a
                    href="/login"
                    className="underline text-[12px] font-medium text-black"
                  >
                    LOG IN
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Step 1 */}

        {step === 1 && selectedAccountType === "artist" && (
          <div className="flex h-screen bg-white font-sh5">
            {/* Left side - Image */}
            <LeftFormImage />
            {/* Right side - Form */}
            <div className="flex flex-col w-full px-16 py-20 md:w-1/2">
              <div className="flex flex-col justify-between w-full h-full max-w-md mx-auto">
                <div>
                  <p className="mb-2 text-sm text-gray-500">Step 1 of 4</p>
                  <h1 className="mb-8 text-4xl font-bold">
                    How would you like to register?
                  </h1>
                  <form className="border border-gray-400 border-solid ">
                    {[
                      "AS AN INDIVIDUAL",
                      "AS A COMPANY",
                      "AS AN ARTISTS ESTATE",
                    ].map((option) => (
                      <div
                        key={option}
                        className="border-b border-solid border-gray-400 py-[14px] px-[12px]"
                      >
                        <label className="flex items-center p-4 space-x-2 border rounded-lg cursor-pointer">
                          <input
                            type="radio"
                            name="registerType"
                            value={
                              optionValues[option as keyof typeof optionValues]
                            }
                            checked={
                              selectedOption ===
                              optionValues[option as keyof typeof optionValues]
                            }
                            onChange={() =>
                              setSelectedOption(
                                optionValues[
                                  option as keyof typeof optionValues
                                ],
                              )
                            }
                            className="form-radio text-black focus:ring-black h-[30px] w-[30px] mr-4"
                          />
                          <span className="ml-3 text-sm font-medium">
                            {option}
                          </span>{" "}
                          {/* Display the original text */}
                        </label>
                      </div>
                    ))}
                  </form>
                </div>
                <button
                  type="button"
                  disabled={!selectedOption}
                  className={`w-full mt-8 py-3 px-4 rounded-full transition-colors duration-200 ${
                    selectedOption
                      ? "bg-black text-white hover:bg-gray-800"
                      : "bg-gray-300 text-gray-500 cursor-not-allowed"
                  }`}
                  onClick={nextStep}
                >
                  CONTINUE
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Step 1 bussiness and collector */}
        {step === 1 &&
          selectedAccountType === "business" &&
          type !== "otp" &&
          type !== "agreement" && (
            <div className="flex h-screen bg-white">
              <LeftFormImage />
              <DynamicFormStep
                stepTitle="Let's set you up"
                stepNumber={step + 1}
                inputFields={stepInputs["business"] as any}
                register={register}
                errors={errors}
                onNext={nextStep}
                watch={watch}
                setType={setType}
                setOtp={setOtp}
                setUserId={setUserId}
                setPhoneNumber={setPhoneNumber}
                setError={setError}
              />
            </div>
          )}

        {step === 1 &&
          selectedAccountType === "collector" &&
          type !== "otp" &&
          type !== "agreement" && (
            <div className="flex h-screen bg-white">
              <LeftFormImage />
              <DynamicFormStep
                stepTitle="Let's set you up"
                stepNumber={step + 1}
                inputFields={stepInputs["collector"]}
                register={register}
                errors={errors}
                onNext={nextStep}
                watch={watch}
                setType={setType}
                setOtp={setOtp}
                setUserId={setUserId}
                setPhoneNumber={setPhoneNumber}
                setError={setError}
              />
            </div>
          )}

        {/* Step 2 artist */}
        {step === 2 && type === "detail" && selectedOption === "individual" && (
          <div className="flex h-screen bg-white">
            <LeftFormImage />
            <DynamicFormStep
              stepTitle="Let's set you up"
              stepNumber={step}
              inputFields={stepInputs["artist"]}
              register={register}
              errors={errors}
              onNext={nextStep}
              watch={watch}
              setType={setType}
              setOtp={setOtp}
              setUserId={setUserId}
              setPhoneNumber={setPhoneNumber}
              setError={setError}
            />
          </div>
        )}

        {(step === 2 || step === 1) && type === "otp" && (
          <div className="flex h-screen bg-white">
            <LeftFormImage />

            <OTPVerification
              phoneNumber={phoneNumber || ""}
              onVerify={handleVerifyOTP}
              onEditNumber={handleEditNumber}
              onResendOTP={handleResendOTP}
              stepNumber={2}
              totalSteps={4}
              setType={setType}
              otpVal={otp}
              userId={userId || ""}
            />

            {/* Close button */}
            {/* <Button
              variant="link"
              className="absolute text-gray-500 top-4 right-4 hover:text-black"
              onClick={() => console.log("cross")}
            ></Button> */}
          </div>
        )}

        {/* Step 2 Agreement Page */}

        {(step === 2 || step === 1) &&
          type === "agreement" &&
          selectedOption !== "estate" && (
            <div className="flex h-screen bg-white font-sh5">
              {/* Left side - Image */}
              <LeftFormImage />
              {/* Right side - Form */}
              <Agreement
                title={`${selectedAccountType} Onboarding Agreement`}
                introduction="Asign is a first-of-its-kind product & services company built specifically for the art and collectibles ecosystem. Our proprietary technology physically connects artworks and collectibles to our secure digital environment."
                content="Force Majeure: Regardless of anything stated in this Agreement, neither Party is liable for any failure to fulfil its obligations if the failure is due to a Force Majeure event. In such cases, the obligations of the affected Party will be suspended for the duration of the Force Majeure Event.Assignment:The Business cannot transfer or assign its rights."
                checkboxLabel="I confirm that I have read, consent, and agree to Asign's Gallery Onboarding Agreement."
                buttonText="SUBMIT"
                isChecked={isAgreementChecked}
                onCheckChange={setIsAgreementChecked}
                nextStep={nextStep}
                isEstate={false}
                setType={setType}
                userId={userId || ""}
              />
            </div>
          )}

        {/* Agreement for estate artist */}
        {(step === 2 || step === 1) &&
          type === "agreement" &&
          selectedOption === "estate" && (
            <div className="flex h-screen bg-white font-sh5">
              {/* Left side - Image */}
              <LeftFormImage />
              {/* Right side - Form */}
              <Agreement
                title={`${selectedAccountType} Onboarding Agreement`}
                introduction="Asign is a first-of-its-kind product & services company built specifically for the art and collectibles ecosystem. Our proprietary technology physically connects artworks and collectibles to our secure digital environment."
                content="Force Majeure: Regardless of anything stated in this Agreement, neither Party is liable for any failure to fulfil its obligations if the failure is due to a Force Majeure event. In such cases, the obligations of the affected Party will be suspended for the duration of the Force Majeure Event.Assignment:The Business cannot transfer or assign its rights."
                checkboxLabel="I confirm that I have read, consent, and agree to Asign's Gallery Onboarding Agreement."
                buttonText="SUBMIT"
                isChecked={isAgreementChecked}
                onCheckChange={setIsAgreementChecked}
                nextStep={nextStep}
                isEstate={true}
                setType={setType}
                userId={userId || ""}
              />
            </div>
          )}

        {/* Step 3 artist individual */}
        {step === 3 &&
          selectedAccountType === "artist" &&
          selectedOption === "individual" &&
          type !== "verifypan" &&
          type !== "otp2" && (
            <div className="flex h-screen bg-white font-sh5">
              {/* Left side - Image */}
              <LeftFormImage />
              {/* Right side - Form */}
              <div className="flex flex-col w-full p-8 md:w-1/2 md:p-16">
                <div className="flex-grow w-full max-w-md mx-auto">
                  <p className="mb-2 text-sm text-gray-500">Step 3 of 4</p>
                  <h1 className="mb-4 text-[32px] font-normal">
                    Verify your identity
                  </h1>
                  <p className="mb-8 text-gray-600 font-normal text-[16px] text-[#696969]">
                    Everyone is required to be KYC-verified to make Asign a
                    secure space for all.
                  </p>

                  <div className="mb-8">
                    <p className="mb-4 font-normal text-[16px]">
                      Do you have a PAN and Aadhaar?
                    </p>
                    <RadioGroup
                      onValueChange={handlePanAadhaarResponse}
                      className="gap-0"
                    >
                      <div className="py-[28px] px-[24px] border border-gray-300 border-solid  ">
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="yes" id="yes" />
                          <Label htmlFor="yes">YES</Label>
                        </div>
                      </div>
                      <div className="py-[28px] px-[24px] border border-gray-300 border-solid ">
                        <div className="flex items-center space-x-2">
                          <RadioGroupItem value="no" id="no" />
                          <Label htmlFor="no" className="font-medium text-sm">
                            NO
                          </Label>
                        </div>
                      </div>
                    </RadioGroup>
                  </div>

                  <Button
                    className="w-full hover:bg-gray-300 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!havePan}
                    onClick={() => setType("verifypan")}
                  >
                    CONTINUE
                  </Button>
                </div>
              </div>
            </div>
          )}

        {/* Step 3 business form */}

        {(step === 3 || step === 2) &&
          type === "businessform" &&
          selectedAccountType !== "collector" && (
            <div className="flex h-screen bg-white">
              <LeftFormImage />
              <BusinessInfoForm
                stepNumber={step}
                totalSteps={4}
                isRegistered={isRegistered || ""}
                setIsRegistered={setIsRegistered}
                companyType={companyType || ""}
                setCompanyType={setCompanyType}
                contactPerson={contactPerson}
                setContactPerson={setContactPerson}
                businessWebsite={businessWebsite}
                setBusinessWebsite={setBusinessWebsite}
                nextStep={nextStep}
                setType={setType}
                selectedOption={selectedOption}
                userId={userId || ""}
                selectedAccountType={selectedAccountType}
              />
            </div>
          )}

        {(step === 3 || step === 2) &&
          type === "businessform" &&
          selectedAccountType === "collector" && (
            <div className="flex h-screen bg-white font-sh5">
              {/* Left side - Image */}
              <LeftFormImage />
              {/* Right side - Form */}
              <div className="flex flex-col w-full px-16 py-20 md:w-1/2">
                <div className="flex flex-col justify-between w-full h-full max-w-md mx-auto">
                  <div>
                    <p className="mb-2 text-sm text-gray-500">
                      Step {step} of 4
                    </p>
                    <h1 className="mb-8 text-4xl font-bold">
                      How would you like to register?
                    </h1>
                    <form className="border border-gray-400 border-solid ">
                      {["AS AN INDIVIDUAL", "AS A COMPANY"].map((option) => (
                        <div
                          key={option}
                          className="border-b border-solid border-gray-400 py-[14px] px-[12px]"
                        >
                          <label className="flex items-center p-4 space-x-2 border rounded-lg cursor-pointer">
                            <input
                              type="radio"
                              name="registerType"
                              value={optionValues[option]} // Use the mapped value
                              checked={selectedOption === optionValues[option]} // Check against the mapped value
                              onChange={() =>
                                setSelectedOption(optionValues[option])
                              } // Set the mapped value
                              className="form-radio text-black focus:ring-black h-[30px] w-[30px] mr-4"
                            />
                            <span className="ml-3 text-sm font-medium">
                              {option}
                            </span>{" "}
                            {/* Display the original text */}
                          </label>
                        </div>
                      ))}
                    </form>
                    {selectedOption === "individual" && (
                      <div className="mt-10 mb-8">
                        <p className="mb-4 font-medium">
                          Do you have a PAN and Aadhaar?
                        </p>
                        <RadioGroup onValueChange={handlePanAadhaarResponse}>
                          <div className="p-4 border border-gray-300 border-solid rounded-md ">
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="yes" id="yes" />
                              <Label htmlFor="yes">YES</Label>
                            </div>
                          </div>
                          <div className="p-4 border border-gray-300 border-solid rounded-md">
                            <div className="flex items-center space-x-2">
                              <RadioGroupItem value="no" id="no" />
                              <Label htmlFor="no">NO</Label>
                            </div>
                          </div>
                        </RadioGroup>
                      </div>
                    )}

                    {selectedOption === "company" && (
                      <BusinessInfoForm
                        stepNumber={step}
                        totalSteps={4}
                        isRegistered={isRegistered || ""}
                        setIsRegistered={setIsRegistered}
                        companyType={companyType || ""}
                        setCompanyType={setCompanyType}
                        contactPerson={contactPerson}
                        setContactPerson={setContactPerson}
                        businessWebsite={businessWebsite}
                        setBusinessWebsite={setBusinessWebsite}
                        nextStep={nextStep}
                        setType={setType}
                        selectedOption={selectedOption}
                        userId={userId || ""}
                        selectedAccountType={selectedAccountType}
                      />

                      // <form className="mt-10 space-y-6">
                      //   <div>
                      //     <p className="mb-4 font-medium">
                      //       Is your company registered in India?
                      //     </p>
                      //     <RadioGroup
                      //       value={isRegistered}
                      //       onValueChange={setIsRegistered}
                      //     >
                      //       <div className="flex items-center p-4 mb-2 space-x-2 border border-gray-300 rounded-md">
                      //         <RadioGroupItem value="yes" id="yes" />
                      //         <Label htmlFor="yes">YES</Label>
                      //       </div>
                      //       <div className="flex items-center p-4 space-x-2 border border-gray-300 rounded-md">
                      //         <RadioGroupItem value="no" id="no" />
                      //         <Label htmlFor="no">NO</Label>
                      //       </div>
                      //     </RadioGroup>
                      //   </div>

                      //   {isRegistered === "yes" && (
                      //     <>
                      //       <div>
                      //         <label
                      //           htmlFor="companyType"
                      //           className="block mb-2 text-sm font-medium text-gray-700"
                      //         >
                      //           Type of company
                      //         </label>
                      //         <Select
                      //           value={companyType}
                      //           onValueChange={setCompanyType}
                      //         >
                      //           <SelectTrigger className="w-full">
                      //             <SelectValue placeholder="Proprietorship" />
                      //           </SelectTrigger>
                      //           <SelectContent>
                      //             <SelectItem value="proprietorship">
                      //               Proprietorship
                      //             </SelectItem>
                      //             <SelectItem value="partnership">
                      //               Partnership
                      //             </SelectItem>
                      //             <SelectItem value="llp">
                      //               Limited Liability Partnership
                      //             </SelectItem>
                      //             <SelectItem value="privateLimited">
                      //               Private Limited
                      //             </SelectItem>
                      //             <SelectItem value="publicLimited">
                      //               Public Limited
                      //             </SelectItem>
                      //           </SelectContent>
                      //         </Select>
                      //       </div>

                      //       <div>
                      //         <label
                      //           htmlFor="contactPerson"
                      //           className="block mb-2 text-sm font-medium text-gray-700"
                      //         >
                      //           Contact person
                      //         </label>
                      //         <Input
                      //           id="contactPerson"
                      //           value={contactPerson}
                      //           onChange={(e) =>
                      //             setContactPerson(e.target.value)
                      //           }
                      //           className="w-full"
                      //         />
                      //       </div>

                      //       <div>
                      //         <label
                      //           htmlFor="businessWebsite"
                      //           className="block mb-2 text-sm font-medium text-gray-700"
                      //         >
                      //           Business website
                      //         </label>
                      //         <Input
                      //           id="businessWebsite"
                      //           value={businessWebsite}
                      //           onChange={(e) =>
                      //             setBusinessWebsite(e.target.value)
                      //           }
                      //           className="w-full"
                      //         />
                      //       </div>
                      //     </>
                      //   )}
                      // </form>
                    )}
                  </div>
                  {selectedOption === "individual" && (
                    <button
                      type="button"
                      disabled={!selectedOption}
                      className={`w-full mt-8 py-3 px-4 rounded-full transition-colors duration-200 ${
                        selectedOption
                          ? "bg-black text-white hover:bg-gray-800"
                          : "bg-gray-300 text-gray-500 cursor-not-allowed"
                      }`}
                      onClick={() => {
                        setType("verifypan"), nextStep();
                      }}
                    >
                      CONTINUE
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}

        {step === 3 &&
          type === "verifypan" &&
          selectedOption === "individual" && (
            <div className="flex h-screen bg-white">
              {/* Left side - Image */}
              <LeftFormImage />

              {/* Right side - Form */}
              <IdentityVerificationForm
                stepNumber={3}
                totalSteps={4}
                title="Verify your identity"
                description="Everyone is required to be KYC-verified to make Asign a secure space for all."
                inputFields={[
                  {
                    id: "pan",
                    label: "PAN",
                    value: pan,
                    onChange: (value: string) => setPan(value),
                    placeholder: "Enter your PAN",
                    isVerified: isVerified,
                    verifiedName: isVerified ? "JAYANTHI RAJ" : undefined,
                  },
                  {
                    id: "aadhaar",
                    label: "Aadhaar",
                    value: aadhaar,
                    onChange: setAadhaar,
                    placeholder: "Enter your Aadhaar number",
                  },
                ]}
                isAgreed={isAgreed}
                setIsAgreed={setIsAgreed}
                termsLink="#"
                isFormValid={isFormValid}
                onBack={() => {
                  setType("not");
                  // setHavePan(false);
                }}
                setType={setType}
                gstVal={false}
                nextStep={nextStep}
                selectedAccountType={selectedAccountType}
                setUUID={setUUID}
                userId={userId || ""}
                selectedOption={selectedOption}
              />
            </div>
          )}

        {step === 3 &&
          type === "verifypan" &&
          (selectedOption === "company" ||
            selectedAccountType === "business") &&
          companyType !== "llp" &&
          companyType !== "privateLimited" && (
            <div className="flex h-screen bg-white">
              {/* Left side - Image */}
              <LeftFormImage />

              {/* Right side - Form */}
              <IdentityVerificationForm
                stepNumber={3}
                totalSteps={4}
                title="Verify your identity"
                description="Everyone is required to be KYC-verified to make Asign a secure space for all."
                inputFields={[
                  {
                    id: "pan",
                    label: "PAN",
                    value: pan,
                    onChange: (value: string) => setPan(value),
                    placeholder: "Enter your PAN",
                    isVerified: isVerified,
                    verifiedName: isVerified ? "JAYANTHI RAJ" : undefined,
                  },
                  {
                    id: "gst",
                    label: "GST",
                    value: gst,
                    onChange: setGst,
                    placeholder: "Eg. 22AA00000A154R",
                  },
                ]}
                isAgreed={isAgreed}
                setIsAgreed={setIsAgreed}
                termsLink="#"
                isFormValid={isFormValid}
                onBack={() => {
                  setType("not");
                  // setHavePan(false);
                }}
                setType={setType}
                gstVal={true}
                nextStep={nextStep}
                selectedAccountType={selectedAccountType}
                setUUID={setUUID}
                userId={userId || ""}
                selectedOption={selectedOption}
              />
            </div>
          )}

        {step === 3 &&
          type === "verifypan" &&
          (selectedOption === "company" ||
            selectedAccountType === "business") &&
          companyType === "privateLimited" && (
            <div className="flex h-screen bg-white">
              {/* Left side - Image */}
              <LeftFormImage />

              {/* Right side - Form */}
              <IdentityVerificationForm
                stepNumber={3}
                totalSteps={4}
                title="Verify your identity"
                description="Everyone is required to be KYC-verified to make Asign a secure space for all."
                inputFields={[
                  {
                    id: "pan",
                    label: "PAN",
                    value: pan,
                    onChange: (value: string) => setPan(value),
                    placeholder: "Enter your PAN",
                    isVerified: isVerified,
                    verifiedName: isVerified ? "JAYANTHI RAJ" : undefined,
                  },
                  {
                    id: "gst",
                    label: "GST",
                    value: gst,
                    onChange: setGst,
                    placeholder: "Eg. 22AA00000A154R",
                  },
                  {
                    id: "cin",
                    label: "CIN",
                    value: cin,
                    onChange: setCin,
                    placeholder: "Eg. L0000GHKPLC0000045000",
                  },
                ]}
                isAgreed={isAgreed}
                setIsAgreed={setIsAgreed}
                termsLink="#"
                isFormValid={isFormValid}
                onBack={() => {
                  setType("not");
                  // setHavePan(false);
                }}
                setType={setType}
                gstVal={true}
                nextStep={nextStep}
                selectedAccountType={selectedAccountType}
                setUUID={setUUID}
                userId={userId || ""}
                selectedOption={selectedOption}
              />
            </div>
          )}

        {step === 3 &&
          type === "verifypan" &&
          (selectedOption === "company" ||
            selectedAccountType === "business") &&
          companyType === "llp" && (
            <div className="flex h-screen bg-white">
              {/* Left side - Image */}
              <LeftFormImage />

              {/* Right side - Form */}
              <IdentityVerificationForm
                stepNumber={3}
                totalSteps={4}
                title="Verify your identity"
                description="Everyone is required to be KYC-verified to make Asign a secure space for all."
                inputFields={[
                  {
                    id: "pan",
                    label: "PAN",
                    value: pan,
                    onChange: (value: string) => setPan(value),
                    placeholder: "Enter your PAN",
                    isVerified: isVerified,
                    verifiedName: isVerified ? "JAYANTHI RAJ" : undefined,
                  },
                  {
                    id: "gst",
                    label: "GST",
                    value: gst,
                    onChange: setGst,
                    placeholder: "Eg. 22AA00000A154R",
                  },
                  {
                    id: "llp",
                    label: "LLPIN",
                    value: llp,
                    onChange: setLLP,
                    placeholder: "Eg. L0000GHKPLC0000045000",
                  },
                ]}
                isAgreed={isAgreed}
                setIsAgreed={setIsAgreed}
                termsLink="#"
                isFormValid={isFormValid}
                onBack={() => {
                  setType("not");
                  // setHavePan(false);
                }}
                setType={setType}
                gstVal={true}
                nextStep={nextStep}
                selectedAccountType={selectedAccountType}
                setUUID={setUUID}
                userId={userId || ""}
                selectedOption={selectedOption}
              />
            </div>
          )}

        {step === 3 && type === "otp2" && (
          <div className="flex h-screen bg-white">
            <LeftFormImage />

            <AadharOTPVerification
              phoneNumber="+914487487483"
              onVerify={handleVerifyOTP}
              onEditNumber={handleEditNumber}
              onResendOTP={handleResendOTP}
              stepNumber={3}
              totalSteps={4}
              setType={setType}
              nextStep={nextStep}
              uuid={uuid}
              setAddress={setAddress}
              userId={userId || ""}
              aadhaar={aadhaar || ""}
            />

            {/* Close button */}
            <Button
              variant="ghost"
              className="absolute top-4 right-4 "
              onClick={() => setType("verifypan")}
            >
              X
            </Button>
          </div>
        )}

        {step === 4 &&
          type === "address" &&
          selectedOption === "individual" && (
            <div className="flex h-screen bg-white">
              {/* Left side - Image */}
              <LeftFormImage />
              {/* Right side - Form */}
              <div className="flex flex-col w-full p-8 md:w-1/2 md:p-16">
                <div className="flex-grow w-full max-w-md mx-auto">
                  <p className="mb-2 text-sm text-gray-500">Step 4 of 4</p>
                  <h1 className="mb-4 text-4xl font-bold">
                    Confirm your address
                  </h1>
                  <p className="mb-8 text-gray-600">
                    Address fetched from your Aadhaar.
                  </p>

                  <Card className="p-4 mb-6 border border-gray-200 border-solid">
                    <p className="text-gray-800">{address}</p>
                  </Card>

                  <Button
                    variant="link"
                    className="w-full h-auto p-0 mb-8 text-center text-gray-600 hover:text-gray-800"
                    onClick={() => setType("addresspage")}
                  >
                    + ADD NEW ADDRESS
                  </Button>

                  <Button
                    className="w-full text-white bg-gray-800 rounded-full hover:bg-gray-700"
                    onClick={() => navigate("/catalog/detail")}
                  >
                    CONFIRM ADDRESS
                  </Button>
                </div>
              </div>
            </div>
          )}

        {step === 4 &&
          type === "addresspage" &&
          selectedOption === "individual" && (
            <div className="flex h-screen bg-white">
              <LeftFormImage />
              <AddressForm
                title="Add new address"
                description="Enter a new address you wish to add to Asign."
                fields={addressFields}
                onSubmit={() => console.log("form")}
                onCancel={() => setType("address")}
                register={register}
                watch={watch}
                handleSubmit={handleSubmit as any}
                control={control}
                userId={userId || ""}
              />
            </div>
          )}

        {step === 4 &&
          type === "addresspage" &&
          (selectedOption === "company" ||
            selectedAccountType === "business") && (
            <div className="flex h-screen bg-white">
              <LeftFormImage />
              <AddressForm
                title="Enter business Address"
                description="Enter a new address you wish to add to Asign."
                fields={addressFields}
                onSubmit={() => console.log("form")}
                onCancel={() => setType("previousStep")}
                register={register}
                watch={watch}
                handleSubmit={handleSubmit as any}
                control={control}
                userId={userId || ""}
              />
            </div>
          )}

        {/* TO HERE */}

        {step === 2 && type === "detail" && selectedOption === "company" && (
          <div className="flex h-screen bg-white">
            {/* Left side - Image */}
            <LeftFormImage />

            {/* Right side - Form */}
            <DynamicFormStep
              stepTitle="Let's set you up"
              stepNumber={step}
              inputFields={stepInputs["company"] as any}
              register={register}
              errors={errors}
              onNext={nextStep}
              watch={watch}
              setType={setType}
              setOtp={setOtp}
              setUserId={setUserId}
              setPhoneNumber={setPhoneNumber}
              setError={setError}
            />
          </div>
        )}

        {/* Step 2 estate */}

        {step === 2 && type === "detail" && selectedOption === "estate" && (
          <div className="flex h-screen bg-white">
            {/* Left side - Image */}
            <LeftFormImage />

            {/* Right side - Form */}
            <DynamicFormStep
              stepTitle="Let's set you up"
              stepNumber={step}
              inputFields={stepInputs["estate"]}
              register={register}
              errors={errors}
              onNext={nextStep}
              watch={watch}
              setType={setType}
              setOtp={setOtp}
              setUserId={setUserId}
              setPhoneNumber={setPhoneNumber}
              setError={setError}
            />
          </div>
        )}

        {step === 2 && type === "otp" && (
          <div className="flex h-screen bg-white">
            {/* Left side - Image */}
            <LeftFormImage />
            {/* Right side - Form */}
            <div className="flex flex-col w-full p-8 overflow-y-auto md:w-1/2 md:p-16">
              <div className="w-full max-w-md mx-auto">
                <p className="mb-2 text-sm text-gray-500">Step 2 of 4</p>
                <h1 className="mb-4 text-4xl font-bold">Verify OTP</h1>
                <p className="mb-8">
                  An OTP has been sent to +914487487483{" "}
                  <button className="text-black underline">EDIT NUMBER</button>
                </p>
                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="otp"
                      className="block mb-1 text-sm font-medium text-gray-700"
                    >
                      OTP
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-black focus:border-black"
                    />
                  </div>
                  <p className="text-sm text-gray-500">
                    <button className="text-black underline">Resend OTP</button>
                  </p>
                  <button
                    className="w-full px-4 py-3 text-white bg-black rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    onClick={() => setType("agreement")}
                  >
                    SET UP ACCOUNT
                  </button>
                </form>
              </div>
            </div>

            {/* Close button */}
            <button
              className="absolute text-gray-500 top-4 right-4 hover:text-black"
              onClick={() => setType("detail")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}

        {/* Upload component */}
        {step === 3 && type === "upload" && selectedOption === "estate" && (
          <div className="flex h-screen bg-white">
            <LeftFormImage />

            {/* Right side - Form */}
            <div className="flex flex-col w-full p-8 md:w-1/2 md:p-16">
              <div className="flex-grow w-full max-w-md mx-auto">
                <p className="mb-2 text-sm text-gray-500">Step 3 of 4</p>
                <h1 className="mb-4 text-4xl font-bold">
                  Verify as a representative
                </h1>
                <p className="mb-8 text-gray-600">
                  Submit a document that verifies you as the legal
                  representative of the artist and/or their estate, such as a
                  Legal Heir Certificate, Foundation Certificate, or Formation
                  of Estate.
                </p>

                <div className="space-y-4">
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="file-upload"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 "
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        {uploadStatus === "idle" && (
                          <>
                            <Upload className="w-8 h-8 mb-4 text-gray-500" />
                            <p className="mb-2 text-sm text-gray-500">
                              <span className="font-semibold">
                                Drag and drop
                              </span>{" "}
                              or UPLOAD DOCUMENT
                            </p>
                            <p className="text-xs text-gray-500">
                              Up to 20MB in .docx or .pdf format
                            </p>
                          </>
                        )}
                        {uploadStatus === "loading" && (
                          <Loader2 className="w-8 h-8 mb-4 text-gray-500 animate-spin" />
                        )}
                        {uploadStatus === "success" && (
                          <div className="flex flex-col items-center">
                            <CheckCircle className="w-8 h-8 mb-4 text-green-500" />
                            <p className="text-sm text-gray-700">
                              {file?.name}
                            </p>
                            <button
                              onClick={handleRemove}
                              className="mt-2 text-sm text-gray-500 hover:text-gray-700"
                            >
                              REMOVE
                            </button>
                          </div>
                        )}
                        {uploadStatus === "error" && (
                          <div className="flex flex-col items-center">
                            <AlertCircle className="w-8 h-8 mb-4 text-red-500" />
                            <p className="text-sm text-red-500">
                              Error: Unsupported file format
                            </p>
                            <button
                              onClick={handleRemove}
                              className="mt-2 text-sm text-gray-500 hover:text-gray-700"
                            >
                              Try Again
                            </button>
                          </div>
                        )}
                      </div>
                      <input
                        id="file-upload"
                        type="file"
                        className="hidden"
                        onChange={handleFileChange}
                        accept=".pdf,.docx"
                      />
                    </label>
                  </div>
                  <Button
                    type="button"
                    className="w-full text-white bg-gray-900 hover:bg-gray-800"
                    onClick={() => setType("verifypan")}
                  >
                    CONTINUE
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {step === 3 && type === "otp2" && selectedOption === "estate" && (
          <div className="flex h-screen bg-white">
            {/* Left side - Image */}
            <div className="relative hidden w-1/2 md:block">
              <img
                src="/register-banner.svg"
                alt="Decorative image"
                className="object-cover w-full h-full"
              />
              <div className="absolute text-2xl font-light text-white top-4 left-4">
                {"<SIGN"}
              </div>
              <div className="absolute text-sm text-white bottom-4 left-4">
                Aditya Pande, Let's Go West, 2021
              </div>
            </div>
            {/* Right side - Form */}
            <div className="flex flex-col w-full p-8 overflow-y-auto md:w-1/2 md:p-16">
              <div className="w-full max-w-md mx-auto">
                <p className="mb-2 text-sm text-gray-500">Step 3 of 4</p>
                <h1 className="mb-4 text-4xl font-bold">Verify OTP</h1>
                <p className="mb-8">
                  An OTP has been sent to your Aadhar registered mobile number
                </p>
                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="otp"
                      className="block mb-1 text-sm font-medium text-gray-700"
                    >
                      OTP
                    </label>
                    <input
                      type="text"
                      id="otp"
                      name="otp"
                      className="block w-full px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-black focus:border-black"
                    />
                  </div>
                  <p className="text-sm text-gray-500">
                    <button className="text-gray-500 ">
                      Resend OTP in 08:50 seconds
                    </button>
                  </p>
                  <button
                    className="w-full px-4 py-3 text-white bg-black rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                    onClick={() => {
                      nextStep();
                      setType("address");
                    }}
                  >
                    SET UP ACCOUNT
                  </button>
                </form>
              </div>
            </div>

            {/* Close button */}
            <button
              className="absolute text-gray-500 top-4 right-4 hover:text-black"
              onClick={() => setType("detail")}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}

        {/* Step 3 company */}

        {step === 3 && type === "verifypan" && selectedOption === "estate" && (
          <div className="flex h-screen bg-white">
            <LeftFormImage />

            <IdentityVerificationForm
              stepNumber={3}
              totalSteps={4}
              title="Verify your identity"
              description="Everyone is required to be KYC-verified to make Asign a secure space for all."
              inputFields={[
                {
                  id: "pan",
                  label: "Representative's PAN",
                  value: pan,
                  onChange: (value: string) => setPan(value),
                  placeholder: "Enter your PAN",
                  isVerified: isVerified,
                  verifiedName: isVerified ? "JAYANTHI RAJ" : undefined,
                },
                {
                  id: "aadhaar",
                  label: "Representative's Aadhaar",
                  value: aadhaar,
                  onChange: setAadhaar,
                  placeholder: "Enter your Aadhaar number",
                },
              ]}
              isAgreed={isAgreed}
              setIsAgreed={setIsAgreed}
              termsLink="#"
              isFormValid={isFormValid}
              onBack={() => {
                setType("not");
                // setHavePan(false);
              }}
              setType={setType}
              gstVal={false}
              nextStep={nextStep}
              selectedAccountType={selectedAccountType}
              setUUID={setUUID}
              userId={userId || ""}
              selectedOption={selectedOption}
            />
          </div>
        )}

        {/* Step 3 company private limited */}

        {step === 3 &&
          type === "verifypan" &&
          selectedOption === "company" &&
          companyType === "privateLimited" && (
            <div className="flex h-screen bg-white">
              {/* Left side - Image */}
              <div className="relative hidden w-1/2 md:block">
                <img
                  src="/register-banner.svg"
                  alt="Decorative image"
                  className="object-cover w-full h-full"
                />
                <div className="absolute text-2xl font-light text-white top-4 left-4">
                  {"<SIGN"}
                </div>
                <div className="absolute text-sm text-white bottom-4 left-4">
                  Aditya Pande, Let's Go West, 2021
                </div>
              </div>

              {/* Right side - Form */}
              <div className="flex flex-col w-full p-8 md:w-1/2 md:p-16">
                <div className="flex-grow w-full max-w-md mx-auto">
                  <p className="mb-2 text-sm text-gray-500">Step 3 of 4</p>
                  <h1 className="mb-4 text-4xl font-bold">
                    Verify your identity
                  </h1>
                  <p className="mb-8 text-gray-600">
                    Everyone is required to be KYC-verified to make Asign a
                    secure space for all.
                  </p>

                  <div className="space-y-6">
                    <div>
                      <Label htmlFor="pan" className="block mb-2 font-medium">
                        PAN
                      </Label>
                      <div className="relative">
                        <Input
                          id="pan"
                          value={pan}
                          onChange={handlePanChange}
                          className="pr-10"
                          placeholder="Enter your PAN"
                        />
                        {isVerified && (
                          <CheckCircle2
                            className="absolute text-green-500 transform -translate-y-1/2 right-3 top-1/2"
                            size={20}
                          />
                        )}
                      </div>
                    </div>

                    {isVerified && (
                      <div>
                        <p className="font-medium">JAYANTHI RAJ</p>
                        <p className="text-sm text-gray-500">Name as per PAN</p>
                      </div>
                    )}

                    <div>
                      <Label
                        htmlFor="aadhaar"
                        className="block mb-2 font-medium"
                      >
                        GST number
                      </Label>
                      <Input
                        id="gst"
                        value={gst}
                        onChange={(e) => setAadhaar(e.target.value)}
                        placeholder="Enter your GST number"
                      />
                    </div>

                    <div>
                      <Label
                        htmlFor="aadhaar"
                        className="block mb-2 font-medium"
                      >
                        CIN
                      </Label>
                      <Input
                        id="cin"
                        value={cin}
                        onChange={(e) => setAadhaar(e.target.value)}
                        placeholder="Enter your GST number"
                      />
                    </div>

                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id="terms"
                        checked={isAgreed}
                        onCheckedChange={(checked) =>
                          setIsAgreed(checked as boolean)
                        }
                      />
                      <label
                        htmlFor="terms"
                        className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                      >
                        I agree to the{" "}
                        <a href="#" className="underline">
                          Terms and Conditions
                        </a>{" "}
                        for PAN, GST and CIN.
                      </label>
                    </div>
                  </div>
                </div>

                <div className="flex items-center justify-between mt-8">
                  <Button
                    variant="ghost"
                    className="text-gray-800"
                    onClick={() => {
                      setType("not");
                      setHavePan(false);
                    }}
                  >
                    BACK
                  </Button>
                  <Button
                    className="text-white bg-gray-800 hover:bg-gray-700 disabled:opacity-50 disabled:cursor-not-allowed"
                    disabled={!isFormValid}
                    onClick={() => {
                      nextStep();
                      setType("address");
                    }}
                  >
                    CONTINUE
                  </Button>
                </div>
              </div>
            </div>
          )}

        {/* Step 4 */}

        {step === 4 && type !== "address" && selectedOption === "estate" && (
          <div className="flex h-screen bg-white">
            {/* Left side - Image */}
            <div className="relative hidden w-1/2 md:block">
              <img
                src="/register-banner.svg"
                alt="Decorative image"
                className="object-cover w-full h-full"
              />
              <div className="absolute text-2xl font-light text-white top-4 left-4">
                {"<SIGN"}
              </div>
              <div className="absolute text-sm text-white bottom-4 left-4">
                Aditya Pande, Let's Go West, 2021
              </div>
            </div>

            {/* Right side - Form */}
            <div className="flex flex-col w-full p-8 md:w-1/2 md:p-16">
              <div className="flex-grow w-full max-w-md mx-auto">
                <p className="mb-2 text-sm text-gray-500">Step 4 of 4</p>
                <h1 className="mb-4 text-4xl font-bold">
                  Confirm your address
                </h1>
                <p className="mb-8 text-gray-600">
                  Address fetched from your Aadhaar.
                </p>

                <Card className="p-4 mb-6 border border-gray-200 border-solid">
                  <p className="text-gray-800">
                    28, Bhavana, Tarun Bharat Sahar Road, Andheri West, Mumbai,
                    400099
                  </p>
                </Card>

                <Button
                  variant="link"
                  className="w-full h-auto p-0 mb-8 text-center text-gray-600 hover:text-gray-800"
                  onClick={() => setType("addresspage")}
                >
                  + ADD NEW ADDRESS
                </Button>

                <Button
                  className="w-full text-white bg-gray-800 rounded-full hover:bg-gray-700"
                  onClick={() => navigate("/discover/feed")}
                >
                  CONFIRM ADDRESS
                </Button>
              </div>
            </div>
          </div>
        )}

        {step === 4 && type === "address" && selectedOption === "estate" && (
          <div className="flex h-screen bg-white">
            {/* Left side - Image */}
            <div className="relative hidden w-1/2 md:block">
              <img
                src="/register-banner.svg"
                alt="Decorative image"
                className="object-cover w-full h-full"
              />
              <div className="absolute text-2xl font-light text-white top-4 left-4">
                {"<SIGN"}
              </div>
              <div className="absolute text-sm text-white bottom-4 left-4">
                Aditya Pande, Let's Go West, 2021
              </div>
            </div>

            {/* Right side - Form */}
            <div className="flex flex-col w-full md:w-1/2 ">
              <div className="flex justify-end mb-8">
                <Button
                  variant="ghost"
                  className="text-gray-500 hover:text-black"
                >
                  <X size={24} />
                </Button>
              </div>

              <div className="flex-grow w-full max-w-md mx-auto">
                <h1 className="mb-4 text-3xl font-bold">Add new address</h1>

                <form className="space-y-6">
                  <div>
                    <label
                      htmlFor="address1"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Address line 1
                    </label>
                    <Input id="address1" className="w-full" />
                  </div>

                  <div>
                    <label
                      htmlFor="address2"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Address line 2
                    </label>
                    <Input id="address2" className="w-full" />
                  </div>

                  <div>
                    <label
                      htmlFor="country"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      Country
                    </label>
                    <Select>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a country" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="India">India</SelectItem>
                        {/* Add more countries as needed */}
                      </SelectContent>
                    </Select>
                  </div>

                  <div>
                    <label
                      htmlFor="state"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      State
                    </label>
                    <Select>
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a state" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="Maharashtra">Maharashtra</SelectItem>
                        {/* Add more states as needed */}
                      </SelectContent>
                    </Select>
                  </div>

                  <div>
                    <label
                      htmlFor="city"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      City
                    </label>
                    <Input id="city" className="w-full" />
                  </div>

                  <div>
                    <label
                      htmlFor="pincode"
                      className="block mb-2 text-sm font-medium text-gray-700"
                    >
                      PIN code
                    </label>
                    <Input id="pincode" className="w-full" />
                  </div>
                  <div className="flex items-center justify-between mt-8">
                    <Button variant="ghost" className="text-gray-800">
                      CANCEL
                    </Button>
                    <Button className="text-white bg-gray-800 hover:bg-gray-700">
                      SAVE
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
      </form>
    </div>
  );
}

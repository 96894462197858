import React from "react";
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";
import LeftFormImage from "./LeftFormImage";

export default function ThankYouPage() {
  return (
    <div className="flex h-screen bg-white font-sh5">
      {/* Left side - Image */}
      <LeftFormImage />
      <div className="flex flex-col items-center justify-center min-h-screen bg-white p-4 text-center m-auto">
        <div className="w-16 h-16 bg-green-100 rounded-full flex items-center justify-center mb-8">
          <Check className="w-8 h-8 text-green-600" />
        </div>
        <h1 className="text-4xl font-bold mb-4 text-gray-900">
          Thank you for sharing
          <br />
          your information
        </h1>
        <p className="text-gray-600 mb-8 max-w-md">
          As you do not have a PAN and Aadhaar, our team will get in touch with
          you offline for further verification.
        </p>
        <div>
          <span className="text-gray-600 mr-2">Need help?</span>
          <Button
            variant="link"
            className="font-semibold text-gray-900 underline p-0 h-auto"
          >
            REQUEST A CALLBACK
          </Button>
        </div>
      </div>
    </div>
  );
}

import React from "react";

const Spinner = () => {
  return (
    <div>
      {" "}
      <div className="loader-overlay">
        <div className="loader">
          <span className="spinner" />
        </div>
      </div>
    </div>
  );
};

export default Spinner;

import useContentLib from "@/apis/contentlib";
import Header from "@/components/Global/header/Header";
import Image from "@/components/Global/Image";
import {
  Breadcrumb,
  BreadcrumbList,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Content } from "@/types/global.type";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

const calculateReadingTime = (text: string) => {
  const wordsPerMinute = 200; // Average reading speed
  const words = text.trim().split(/\s+/).length;
  const minutes = Math.ceil(words / wordsPerMinute);
  return minutes;
};

const WritingDetail = () => {
  const { id } = useParams();
  const { getContentById } = useContentLib();
  const { data: writing } = useQuery<Content>({
    queryKey: ["writing", id],
    queryFn: () => getContentById(id as string),
    enabled: Boolean(id),
  });

  // Calculate reading time
  const readingTime = useMemo(() => {
    if (writing?.content?.description) {
      return calculateReadingTime(writing.content.description);
    }
    return 0;
  }, [writing]);

  return (
    <div className="container font-normal font-sh5 max-w-[1110px] pb-10 md:m-auto ml-3 px-3">
      <Header />
      <Breadcrumb className="py-5">
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink href="/">Discover</BreadcrumbLink>
          </BreadcrumbItem>
          <span className="text-black">/</span>
          {/* <BreadcrumbItem>
            <BreadcrumbLink href={-1}>Events</BreadcrumbLink>
          </BreadcrumbItem> */}
          {/* <span className="text-black">/</span> */}
          <BreadcrumbItem>
            <BreadcrumbPage>
              {writing?.content?.title ?? "Loading..."}
            </BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <div className="w-full h-[500px]">
        <Image
          src={writing?.content?.coverImage ?? "/fallback-image.jpg"}
          alt={writing?.content?.authorName ?? "Author"}
          className="object-cover object-center w-full h-full"
        />
      </div>
      <h3 className="my-5 text-6xl font-normal capitalize font-eb">
        {writing?.content?.title ?? "Loading..."}
      </h3>
      <p>
        {format(new Date(), "MMM d, yyyy")} | {readingTime} mins read
      </p>
      <Button variant="link" className="p-0 text-gray-900 uppercase">
        {writing?.content?.authorName ?? "Author"}
      </Button>
      <div
        dangerouslySetInnerHTML={{
          __html: writing?.content?.description ?? "",
        }}
      />
    </div>
  );
};

export default WritingDetail;

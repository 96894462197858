import React from "react";
import Header from "../components/Global/header/Header";
import Sidebar from "../components/Global/Sidebar";

const Layout: React.FC<{
  children: React.ReactNode;
  viewMobTopNav?: boolean;
}> = ({ children, viewMobTopNav }) => {
  return (
    <div className="w-full overflow-hidden h-screen relative bg-white flex flex-col items-start justify-start leading-[normal] tracking-[normal] container">
      <Header />
      <main className="flex flex-row items-start overflow-auto h-full relative self-stretch justify-center max-w-full mq750:p-0 mq450:flex-col mq450:p-0 mq450:w-full mq450:relative mq975:box-border">
        <Sidebar viewMobTopNav={viewMobTopNav} />
        <section className="mq450:relative flex-1 mq750:max-h-[calc(100vh-20px)] max-h-[calc(100vh-10px)] overflow-y-scroll flex flex-col  items-start justify-start  box-border  max-w-[calc(100%_-_252px)] text-left text-13xl text-grey100 mq975:max-w-full mq725:gap-[15px] mq450:w-full">
          {children}
        </section>
      </main>
      {/* <div className="w-full border-t-[1px] border-solid border-gray-20 h-[57px] py-5 justify-between items-center inline-flex z-50">
        <div className="inline-flex flex-col items-center justify-center gap-6 grow shrink basis-0">
          <div className="inline-flex items-center self-stretch justify-center gap-2">
            <div className="text-center text-[#686868] text-sm font-normal font-['Neue Montreal'] leading-[16.80px] mq750:leading-5 max-w-[90%]">
              Terms & Conditions  |  Privacy Policy  |  Contact us  |  © 2024  Democrart Technologies Private Limited
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Layout;

import React from "react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../ui/tooltip";

interface itemCaptionprop {
  caption: string;
}
const CaptionItem: React.FC<itemCaptionprop> = ({ caption }) => {
  return (
    <div>
      {" "}
      <div className="bg-white border-solid border-[1px] border-gray-20 px-4 py-3">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <div className="flex flex-row items-center justify-start gap-2">
                <img src="/description.svg" alt="desc icon" />
                <p className="text-sm font-normal text-black font-sh5 line-clamp-2">
                  {caption}
                </p>
              </div>
            </TooltipTrigger>
            <TooltipContent className="z-50 text-white bg-gray-800 max-w-[300px]">
              <p>{caption}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
};

export default CaptionItem;

import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import GlobalDialog from "@/modal/GlobalDialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useObjectContext } from "@/context/objects";

const formSchema = z.object({
  image: z.string().optional(),
  authorName: z.string().min(1, "Author is required"),
  name: z.string().min(1, "Publication name is required"),
  location: z.string().min(1, "Location is required"),
  publishedBy: z.string().min(1, "Publisher is required"),
  publicationDay: z.string().min(1, "Day is required"),
  publicationMonth: z.string().min(1, "Month is required"),
  publicationYear: z.string().min(1, "Year is required"),
  pageNumber: z.coerce.number().min(1, "Page number is required"),
});

type FormValues = z.infer<typeof formSchema>;

const AddPublication = ({
  children,
  defaultId,
}: {
  children: React.ReactNode;
  defaultId?: string;
}) => {
  const { updateOrAddItem, currentObject } = useObjectContext();
  const defaultPublication =
    currentObject?.provenance?.publicationHistory?.find(
      (item: any) => item._id === defaultId,
    );

  const defaultDate = defaultPublication?.date
    ? new Date(defaultPublication.date)
    : null;

  const defaultValues = defaultPublication
    ? {
        image: defaultPublication.image,
        authorName: defaultPublication.authorName,
        name: defaultPublication.name,
        location: defaultPublication.location,
        publishedBy: defaultPublication.publishedBy,
        publicationYear: defaultDate ? String(defaultDate.getFullYear()) : "",
        publicationMonth: defaultDate ? String(defaultDate.getMonth() + 1) : "",
        publicationDay: defaultDate ? String(defaultDate.getDate()) : "",
        pageNumber: Number(defaultPublication.pageNumber),
      }
    : {
        image: "",
        authorName: "",
        name: "",
        location: "",
        publishedBy: "",
      };
  const [previewUrl, setPreviewUrl] = useState<string | null>(
    defaultValues?.image || null,
  );
  const fileInputRef = useRef<HTMLInputElement>(defaultValues?.image || null);

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size <= 50 * 1024 * 1024) {
        // 50MB limit
        const base64 = await convertToBase64(file);
        setPreviewUrl(URL.createObjectURL(file));
        form.setValue("image", base64);
      } else {
        alert("File size should be less than 50MB");
      }
    }
  };

  const handleDrop = async (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.size <= 50 * 1024 * 1024) {
        // 50MB limit
        const base64 = await convertToBase64(file);
        setPreviewUrl(URL.createObjectURL(file));
        form.setValue("image", base64);
      } else {
        alert("File size should be less than 50MB");
      }
    }
  };

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  };

  const onSubmit = (data: FormValues) => {
    // Convert the date to ISO string format
    const date = new Date(
      parseInt(data.publicationYear),
      parseInt(data.publicationMonth) - 1,
      parseInt(data.publicationDay),
    );
    const isoDate = date.toISOString();

    // Create the final data object with the ISO date string
    const finalData = {
      ...data,
      date: isoDate,
      // Remove individual date fields
    };
    if (defaultId) {
      updateOrAddItem({
        provenance: {
          publicationHistory: [
            {
              ...finalData,
              _id: defaultId,
            },
          ],
        },
      });
      return;
    }

    updateOrAddItem({
      provenance: {
        publicationHistory: [finalData],
      },
    });
    // Handle form submission with finalData
  };

  return (
    <GlobalDialog
      title="Add Publication"
      TriggerButton={children}
      onConfirm={form.handleSubmit(onSubmit)}
    >
      <Form {...form}>
        <form className="space-y-6">
          <FormField
            control={form.control}
            name="image"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div
                    className="flex items-center justify-center w-full h-32 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                    onClick={() => fileInputRef.current?.click()}
                    onDrop={handleDrop}
                    onDragOver={(e) => e.preventDefault()}
                  >
                    {previewUrl ? (
                      <img
                        src={previewUrl}
                        alt="Preview"
                        className="object-contain h-full"
                      />
                    ) : (
                      <div className="text-center">
                        <svg
                          className="w-8 h-8 mx-auto mb-2 text-gray-400"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                          />
                        </svg>
                        <p className="text-sm text-gray-500">
                          Drag and drop or browse file
                        </p>
                        <p className="text-xs text-gray-400">
                          Up to 50 MB, in jpg or png format
                        </p>
                      </div>
                    )}
                    <input
                      type="file"
                      className="hidden"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".jpg,.jpeg,.png"
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="authorName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Author(s)</FormLabel>
                <FormControl>
                  <Input placeholder="Enter authorName(s)" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Publication name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter publication name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Location</FormLabel>
                <FormControl>
                  <Input placeholder="Enter location" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="publishedBy"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Published by</FormLabel>
                <FormControl>
                  <Input placeholder="Enter publisher" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <div className="grid grid-cols-3 gap-4">
            <FormField
              control={form.control}
              name="publicationDay"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Publication day</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Day" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Array.from({ length: 31 }, (_, i) => i + 1).map(
                        (day) => (
                          <SelectItem key={day} value={day.toString()}>
                            {day}
                          </SelectItem>
                        ),
                      )}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="publicationMonth"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Publication month</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Month" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {[
                        "January",
                        "February",
                        "March",
                        "April",
                        "May",
                        "June",
                        "July",
                        "August",
                        "September",
                        "October",
                        "November",
                        "December",
                      ].map((month, index) => (
                        <SelectItem key={month} value={(index + 1).toString()}>
                          {month}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="publicationYear"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Publication year</FormLabel>
                  <Select
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Year" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {Array.from(
                        { length: 30 },
                        (_, i) => new Date().getFullYear() - i,
                      ).map((year) => (
                        <SelectItem key={year} value={year.toString()}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="pageNumber"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Page number</FormLabel>
                <FormControl>
                  <Input
                    type="number"
                    placeholder="Enter page number"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </GlobalDialog>
  );
};

export default AddPublication;

import React from "react";
import { ArtistItem } from "@/types/boards.type";
import { Button } from "@/components/ui/button";
import AddItemDropDown from "../AddItemDropDown";

interface ArtistProps {
  items: ArtistItem[];
}
const getYearFromDateString = (dateString: string): number => {
  const date = new Date(dateString);
  return date.getFullYear();
};

const Artist: React.FC<ArtistProps> = ({ items }) => {
  return (
    <div>
      {items.length === 0 ? (
        <p>No Artist found.</p>
      ) : (
        items.map((item) => (
          <div
            key={item._id}
            className={`min-w-[297px] w-full h-full min-h-[297px] relative group`}
          >
            <img className="object-cover w-full h-full" src={item.profilePic} />

            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
            <AddItemDropDown itemID={item._id} boardID={""} itemType={"artist"}>
              <span className="absolute flex invisible gap-1 p-2 text-sm transition-all duration-300 ease-in-out scale-95 bg-white rounded-full opacity-0 cursor-pointer top-4 text-other-cta right-4 group-hover:opacity-100 group-hover:scale-100 group-hover:visible">
                <div className="flex flex-row items-center gap-1">
                  <img className="w-6 h-6" src="/label.svg" alt="label" />
                </div>
              </span>
            </AddItemDropDown>
            <div className="absolute bottom-0 left-0 p-4">
              <p className="text-5xl font-normal text-white font-eb">
                {item.name}
              </p>
              <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                b. {getYearFromDateString(item.dob)}
              </p>
            </div>
            <div className="absolute bottom-0 right-0 p-4">
              <Button
                size="icon"
                variant="outline"
                className="w-[104px] text-white rounded-full font-sh5 font-normal text-sm"
              >
                FOLLOW
              </Button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Artist;

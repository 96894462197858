import React, { ReactNode } from "react";
import { DialogTrigger } from "@radix-ui/react-dialog";
import { Drawer, DrawerContent, DrawerHeader } from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import AddText from "./Addtext";
import UploadMedia from "./UploadFile";
import AddLink from "./AddLink";
import MoreLikeThis from "@/components/Boards/MoreLikeThis";

type AddToBoardProps = {
  children: ReactNode;
  boardId: string;
};

const AddToBoard: React.FC<AddToBoardProps> = ({ children, boardId }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  return isDesktop ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>{children}</DropdownMenuTrigger>
      <DropdownMenuContent className="border border-solid rounded-none border-gainsboro">
        <DropdownMenuGroup className="flex flex-col gap-2 text-base text-gray-60 font-sh5">
          <ProfileForm boardId={boardId} />
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <Drawer>
      <DialogTrigger className="text-lg text-white bg-transparent">
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none ">
        <DrawerHeader className="p-0 "></DrawerHeader>
        <ProfileForm boardId={boardId} />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
};

function ProfileForm({ className, boardId }: ProfileFormProps) {
  return (
    <form
      className={cn(
        "grid items-center gap-4 mq450:gap-8 mq450:p-6 p-4",
        className
      )}
    >
      <MoreLikeThis>
        <div className="hidden text-base font-normal text-center cursor-pointer mq450:block text-gray-60 mq450:text-left font-sh5">
          More like this
        </div>
      </MoreLikeThis>
      <AddText boardId={boardId}>
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 font-sh5">
          Text
        </div>
      </AddText>
      <UploadMedia boardId={boardId}>
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 font-sh5">
          Upload
        </div>
      </UploadMedia>
      <AddLink boardId={boardId}>
        <div className="text-base font-normal text-left cursor-pointer text-gray-60 font-sh5">
          Link
        </div>
      </AddLink>
    </form>
  );
}

export default AddToBoard;

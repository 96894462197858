import React, { useState } from "react";
import { Skeleton } from "../ui/skeleton";

const Image = (props: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [loading, setLoading] = useState(true);

  if (!props.src) {
    return <Skeleton className={props.className} />;
  }
  return (
    <>
      {loading && <Skeleton className={props.className} />}
      <img
        {...props}
        onLoad={() => setLoading(false)}
        className={`${loading ? "invisible" : "visible"} ${props.className}`}
        onContextMenu={(e) => e.preventDefault()}
        loading="lazy"
      />
    </>
  );
};

export default Image;

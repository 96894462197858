import Image from "@/components/Global/Image";
import Like from "@/components/Global/intreactions/Like";
import { Button } from "@/components/ui/button";
import { useUserContext } from "@/context/user";
import { findById, formatInRuppes, getId } from "@/lib/helper";
import { cn } from "@/lib/utils";
import AddBoardDropDown from "@/components/Boards/AddItemDropDown";
import { ObjectVaritantOne } from "@/types/objects.type";
import { format } from "date-fns";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { ToolTipText } from "../Artists/Big";
interface Props {
  data: ObjectVaritantOne[];
  title: string;
  type?: "4x" | "big";
  sectionId?: string;
  heading?: string;
}
const OBig = ({ data, title, type = "big", sectionId, heading }: Props) => {
  const sliderRef = useRef<Slider | null>(null);
  const navigate = useNavigate();
  const next = () => {
    sliderRef.current?.slickNext();
  };

  const previous = () => {
    sliderRef.current?.slickPrev();
  };

  const containerRef = useRef<HTMLDivElement>(null);
  const [slidesToShow, setSlidesToShow] = useState(2);

  const updateSlidesToShow = (width: number) => {
    if (width >= 1024) {
      setSlidesToShow(4);
    } else if (width >= 400) {
      setSlidesToShow(type === "4x" ? 3 : 2);
    } else {
      setSlidesToShow(1);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        updateSlidesToShow(entry.contentRect.width);
      }
    });

    resizeObserver.observe(container);
    return () => resizeObserver.disconnect();
  }, [type]);
  let dragging = false;
  const settings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      slidesToShow,
      slidesToScroll: slidesToShow,
      arrows: false,
      // centerMode: true,
      // centerPadding: "10%",
      beforeChange: () => (dragging = true),
      afterChange: () => (dragging = false),
    }),
    [slidesToShow]
  );
  const renderArtistCard = (
    item: ObjectVaritantOne | number,
    index: number
  ) => {
    const isPlaceholder = typeof item === "number";
    const object = isPlaceholder ? null : item;

    return (
      <div
        key={isPlaceholder ? index : object?._id}
        className="relative px-2 group"
      >
        <div className="relative">
          <div className="relative overflow-hidden aspect-square group">
            {isPlaceholder || !object?.image ? (
              <div className="w-full h-full bg-gradient-to-b from-gray-200 to-black max-w-[297px] max-h-[297px]" />
            ) : (
              <>
                <Image
                  src={object.image}
                  alt="profile pic"
                  className="object-cover w-full h-full cursor-pointer"
                  loading="lazy"
                />
                <div
                  className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer"
                  onClick={() => {
                    if (dragging) return;
                    navigate(`/objects/detail/${item._id}`);
                  }}
                />
              </>
            )}
          </div>
          <AddBoardDropDown
            itemID={getId(object)}
            boardID={""}
            itemType={"object"}
          >
            <span className="absolute top-4 bg-white text-other-cta rounded-full right-4 p-[5.5px_12px] text-sm flex gap-x-1 opacity-0 scale-95 group-hover:opacity-100 group-hover:scale-100 invisible group-hover:visible cursor-pointer transition-all duration-300 ease-in-out items-center">
              <img src="/label.svg" alt="label" /> Board
            </span>
          </AddBoardDropDown>
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-start justify-between w-full mt-5">
            <div>
              {" "}
              {object?.assignProtect && (
                <p className="flex items-center text-xs truncate font-sh5">
                  <img src="/protect.svg" alt="asign protect" />
                  Asign Protect+
                </p>
              )}
              <p className="text-sm mq750:text-lg mt-[5px]">Artist Name</p>
              <p className="mq750:text-[16px] text-gray-60 text-sm">
                {object?.objectName}
              </p>
              <p className=" text-gray-60  mq750:text-[16px] text-sm ">
                {object?.publishedYear &&
                  "b. " + format(object.publishedYear, "yyyy")}
              </p>
              <p className="mq750:text-[16px] mq750:mt-4 font-[500] font-sh5 text-lg mt-3">
                {formatInRuppes.format(object?.price as number)}
              </p>
            </div>

            {type !== "4x" && (
              <LikeObject
                id={object?._id}
                className="w-10 h-10 "
                imageClassName="w-[20px] h-[20px]"
              />
            )}
          </div>
          {type === "4x" && (
            <div className="">
              <LikeObject
                id={object?._id}
                imageClassName="w-[20px] h-[20px]"
                className="w-10 h-10"
              />
              <div className="p-1 mt-2 rounded-full h-10 w-10 bg-bg-5 grid place-items-center">
                <img src="/label.svg" alt="" className="w-[20px]" />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="font-sh5" ref={containerRef}>
      <div className="flex items-center justify-between mb-8 mq750:mb-6">
        <ToolTipText title={title} heading={heading} />

        <div className="flex flex-row items-center">
          <Button
            className="text-sm font-medium underline bg-transparent mq750:text-xs"
            variant="link"
            size="sm"
            onClick={() => navigate(`/objects/view/${sectionId}`)}
          >
            VIEW ALL
          </Button>
          <div className="lg:flex flex-row items-center gap-[5px] hidden">
            {[true, false].map((flip, index) => (
              <img
                key={index}
                className="p-[3px] bg-[#EEEEEA] rounded-[100%] h-[26px] w-[26px] cursor-pointer"
                style={flip ? { transform: "scaleX(-1)" } : {}}
                src="/arrow-right.svg"
                alt={flip ? "Previous" : "Next"}
                onClick={flip ? previous : next}
              />
            ))}
          </div>
        </div>
      </div>
      <Slider {...settings} ref={sliderRef}>
        {(data.length <= 1 ? [1, 2, 3, 4, 5] : data).map((item, index) =>
          renderArtistCard(item, index)
        )}
      </Slider>
    </div>
  );
};

export default OBig;

export const LikeObject = ({
  id,
  className,
  imageClassName,
}: {
  id?: string;
  className?: string;
  imageClassName?: string;
}) => {
  const { interactions } = useUserContext();

  return (
    <Like
      itemId={id ?? " "}
      action={findById(interactions?.objectsLiked, id ?? "") ? "remove" : "add"}
      itemType="object"
    >
      <div
        className={cn(
          "p-1 rounded-full cursor-pointer h-9 w-9 bg-bg-5 grid place-items-center",
          className
        )}
      >
        {findById(interactions?.objectsLiked, id ?? "") ? (
          <img
            src="/fill-heart.svg"
            alt=""
            className={cn("w-full", imageClassName)}
          />
        ) : (
          <img
            src="/heart.svg"
            alt=""
            className={cn("w-full", imageClassName)}
          />
        )}
      </div>
    </Like>
  );
};

import React, { ChangeEvent, ReactNode, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import "react-toastify/dist/ReactToastify.css";
import { cn } from "@/lib/utils";
import { useMediaQuery } from "@mui/material";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddItemResponse } from "@/types/boards.type";
import { AddItemParams } from "@/hooks/useFilter";
import useBoard from "@/apis/board";
import toast from "react-hot-toast";

type AddLinkProps = {
  children: ReactNode;
  boardId: string;
};

const { addItem } = useBoard();

const AddLink: React.FC<AddLinkProps> = ({ children, boardId }) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [link, setLink] = useState("");
  const [open, setOpen] = useState(false); // State to manage open/close status

  const handleClose = () => {
    setOpen(false); // Function to close the dialog/drawer
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="text-lg bg-transparent text-gray-60"
        asChild
        onClick={() => setOpen(true)}
      >
        {children}
      </DialogTrigger>
      <DialogContent className="max-w-[640px]">
        <DialogHeader className="mx-[32px]">
          <DialogTitle className="border-solid  border-[#E5E5E5] pb-5 border-b-[1px]  text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Add Link</p>
              <div className="flex flex-row items-center gap-4 cursor-pointer">
                <DialogClose asChild>
                  <img
                    className="cursor-pointer"
                    src="/close1.svg"
                    alt="Close"
                    onClick={handleClose}
                  />
                </DialogClose>
              </div>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm
          setLink={setLink}
          boardId={boardId}
          link={link}
          setOpen={setOpen} // Pass setOpen to ProfileForm
        />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="text-lg bg-transparent text-gray-60"
        asChild
        onClick={() => setOpen(true)}
      >
        {children}
      </DialogTrigger>
      <DrawerContent className="rounded-none">
        <DrawerHeader>
          <DrawerTitle className="border-[#E5E5E5] pb-5 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row justify-between">
              <p>Add Link</p>
              <div className="flex flex-row items-center gap-4">
                <DialogClose asChild>
                  <img
                    className="cursor-pointer"
                    src="/close1.svg"
                    alt="Close"
                    onClick={handleClose}
                  />
                </DialogClose>
              </div>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm
          setLink={setLink}
          boardId={boardId}
          link={link}
          setOpen={setOpen} // Pass setOpen to ProfileForm
        />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  className?: string;
  boardId: string;
  link: string;
  setLink: (link: string) => void;
  setOpen: (open: boolean) => void; // Prop to manage open state
};

function ProfileForm({
  className,
  boardId,
  link,
  setLink,
  setOpen,
}: ProfileFormProps) {
  const { addItem } = useBoard();
  const queryClient = useQueryClient();
  const [urlError, setUrlError] = useState(""); // State to manage URL validation error
  const URL_REGEX = /^(https?:\/\/)?([\w-]+\.)+[\w-]{2,}(\/[\w-./?%&=]*)?$/i;

  const mutation = useMutation<AddItemResponse, Error, AddItemParams>({
    mutationFn: addItem,
    onSuccess: (response) => {
      toast.success("Item added successfully!");
      setLink(""); // Clear the link field on success
      setUrlError(""); // Clear URL error on success
      queryClient.invalidateQueries({
        queryKey: ["single-board"],
      });
      setOpen(false); // Close the dialog/drawer after success
    },
    onError: (error) => {
      toast.error(`Error adding item: ${error.message}`);
    },
  });

  const handleSave = () => {
    if (link.trim() === "") {
      toast.error("Link is required.");
      return;
    }

    if (!URL_REGEX.test(link)) {
      setUrlError("Please enter a valid URL"); // Set error message for invalid URL
      return;
    }

    setUrlError(""); // Clear any existing error
    mutation.mutate({
      boardId,
      itemType: "link",
      link,
    });
  };

  return (
    <form className={cn("grid items-center gap-5 mq450:gap-8", className)}>
      <div className="flex flex-col gap-3 justify-center px-[32px] items-start font-sh5 font-normal">
        <p className="mq450:pb-3">Url</p>
        <input
          placeholder="Enter Url"
          value={link}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setLink(e.target.value)
          }
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleSave();
            }
          }}
          className={cn(
            "w-full p-5 rounded-none  font-sh5 font-medium placeholder:text-[#979797] text-base border-solid border-gray-10 border placeholder:font-normal",
            urlError
              ? "border-red-500 border-2 border-solid "
              : "border-gray-300"
          )}
        />
        {urlError && <p className="mt-1 text-sm text-red-500">{urlError}</p>}
      </div>

      <div className="flex  flex-row items-center justify-between w-full p-5 px-[32px] bg-[#F2F2F2]">
        <button
          className="cursor-pointer h-6 min-w-12 font-medium text-xs relative font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none"
          type="button"
          onClick={() => setOpen(false)}
        >
          CANCEL
        </button>
        <Button
          className="rounded-[50px] min-w-[83px] h-[42px] px-4 font-medium text-sm text-white font-sh5"
          variant="default"
          type="button"
          onClick={handleSave}
        >
          SAVE
        </Button>
      </div>
    </form>
  );
}

export default AddLink;

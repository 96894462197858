import { QueryOptions } from "@/hooks/useFilter";
import { Section, sectionType } from "@/types/global.type";
import { apis } from ".";
import { SectionById } from "./artist";

interface FeedResponse {
  feedCount: number;
  data: Section[];
}

const url = {
  artist: "artist_section",
  business: "business_section",
  objects: "objects_section",
  events: "events_section",
  discover: "discover_section",
};

const useGlobalApis = () => {
  const getFeed = async ({
    params,
    type,
  }: {
    params: QueryOptions;
    type: "artist" | "business" | "objects" | "events" | "discover";
  }): Promise<FeedResponse> => {
    console.log(params);
    try {
      const { data } = await apis.post(`/client/api/v1/${url[type]}/list`, {
        data: params,
      });

      return data.data;
    } catch (error) {
      console.error("Error fetching feed:", error);
      throw error;
    }
  };

  const sectionUrl = {
    artist: "/client/api/v1/artist_section/",
    objects: "/client/api/v1/objects_section/",
    business: "/client/api/v1/business_section/",
    events: "/client/api/v1/events_section/",
    discover: "/client/api/v1/discover_section/",
  };

  const getSectionById = async ({
    id,
    params,
    type,
  }: SectionById): Promise<Section> => {
    const { data } = await apis.post(
      `${sectionUrl[type as keyof typeof sectionUrl]}${id}`,
      {
        data: {
          query: {
            ...params,
          },
        },
      }
    );
    return data.data;
  };

  const getFilters = async (type: sectionType) => {
    try {
      const { data } = await apis.post(
        "/cms-users/api/v1/contentlibrary/filterslist",
        {
          data: {
            query: {
              itemType: type,
            },
            options: {
              pagination: false,
            },
          },
        }
      );

      if (data.data.data.length === 0) {
        return [];
      }
      const options = data.data.data.map((item: any) => ({
        label: item.filterKey,
        name: item.filterKey,
        options: item.filterOptions.map((option: any) => ({
          label: option,
          value: option,
        })),
      }));

      return options;
    } catch (error) {
      console.error("Error fetching filters:", error);
      throw error;
    }
  };

  const locationUrl = {
    events: "client/api/v1/events/list",
    business: "client/api/v1/business/list",
    artist: "client/api/v1/artist/list",
    objects: "client/api/v1/objects/list",
  };

  const getList = async ({
    type,
    params,
  }: {
    type: sectionType;
    params: QueryOptions;
  }) => {
    try {
      const { data } = await apis.post(
        locationUrl[type as keyof typeof locationUrl],
        {
          data: params,
        }
      );

      return data.data;
    } catch (error) {
      console.error("Error fetching filters:", error);
      throw error;
    }
  };

  const submitSurvey = async (
    sectionId: string,
    type: sectionType,
    response: string
  ) => {
    try {
      const data = {
        sectionId,
        itemType: type,
        response: {
          ans: response,
        },
      };
      await apis.post("client/api/v1/userinteractions/survey", {
        data,
      });
    } catch (error) {
      console.error("Error fetching filters:", error);
      throw error;
    }
  };

  return { getFeed, getSectionById, getFilters, getList, submitSurvey };
};

export default useGlobalApis;

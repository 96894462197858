// Create our number formatter.
export const formatInRuppes = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "Inr",
});

// this helps to get the if the user if already or follow the particular object

export const findById = (targetArray?: string[], target?: string): boolean => {
  if (!targetArray || !target) {
    return false;
  }
  return targetArray.includes(target);
};
export const getId = (item: any): string => item?.id || item?._id;

export const capitalizeWords = (str: string) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

import React, { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Menu } from "lucide-react";

const formSchema = z.object({
  otp: z
    .string()
    .length(6, "OTP must be 6 digits")
    .regex(/^\d+$/, "OTP must contain only numbers"),
});

export default function VerifyOTPStep2({
  phoneNumber,
  onContinue,
  onEditNumber,
  onResendOTP,
  resendDisabled,
  resendTimer,
  isLoading,
}: {
  phoneNumber: string;
  onContinue: (otp: string) => void;
  onEditNumber: () => void;
  onResendOTP: () => void;
  resendDisabled: boolean;
  resendTimer: number;
  isLoading: boolean;
}) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      otp: "",
    },
  });

  function onSubmit(values: z.infer<typeof formSchema>) {
    onContinue(values.otp);
  }

  return (
    <div className="relative w-full h-[70vh] max-w-md m-auto space-y-6">
      <div className="space-y-3">
        <h1 className="text-3xl font-normal">Verify OTP</h1>
        <p className="text-base text-[#696969]">
          An OTP has been sent to {phoneNumber}.{" "}
          <Button
            variant="link"
            onClick={onEditNumber}
            className="h-auto p-0 font-semibold text-black underline"
          >
            EDIT NUMBER
          </Button>
        </p>
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <FormField
            control={form.control}
            name="otp"
            render={({ field }) => (
              <FormItem>
                <FormLabel className="text-base font-normal">OTP</FormLabel>
                <FormControl>
                  <Input
                    {...field}
                    type="text"
                    inputMode="numeric"
                    maxLength={6}
                    placeholder="102345"
                    className="h-14 text-lg  focus:border-[#696969]"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button
            type="button"
            variant="link"
            onClick={onResendOTP}
            disabled={resendDisabled}
            className="h-auto p-0 font-medium text-black underline underline-offset-4"
          >
            RESEND OTP
          </Button>
          <Button
            type="submit"
            className="w-full h-12 rounded-full bg-[#303030] hover:bg-[#262626] disabled:text-[#696969] disabled:bg-[#CFCFCF] disabled:opacity-100"
            disabled={!form.formState.isValid}
          >
            {isLoading ? "Loading..." : "CONTINUE"}
          </Button>
        </form>
      </Form>
    </div>
  );
}

import React, { useState } from "react";
import SideBar from "../add-object/SideBar";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import { ShieldPlus } from "lucide-react";
import { Button } from "@/components/ui/button";
import Header from "@/components/Global/header/Header";
import GlobalDialog from "@/modal/GlobalDialog";
import AsignTimeline from "./AsignTimeline";

const AsignTimePage = () => {
  const [currentStep, setCurrentStep] = useState("1");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const handleConfirm = () => {
    console.log("Confirmed");
    // Handle confirmation logic
  };

  const handleCancel = () => {
    console.log("Cancelled");
    // Handle cancellation logic
  };

  return (
    <div className="sm:container sm:flex-col sm:h-screen font-normal font-sh5">
      <Header />
      <div className="overflow-visible sm:flex flex-col sm:w-full sm:h-full sm:overflow-hidden sm:flex-row">
        <div className="sm:w-1/4 sm:h-full sm:pr-4">
          <SideBar currentStep={currentStep} setCurrentStep={setCurrentStep} />
        </div>
        <div className="sm:w-3/4 bg-[#F6F6F6] sm:py-10 sm:px-12 sm:h-full sm:overflow-y-auto ">
          <AsignTimeline />
        </div>
      </div>
    </div>
  );
};

export default AsignTimePage;

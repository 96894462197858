import React from "react";
import { EventItem } from "@/types/boards.type";
import { Button } from "@/components/ui/button";
import AddItemDropDown from "../AddItemDropDown";

interface EventProps {
  items: EventItem[];
}

const formatDate = (dateString: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

const Events: React.FC<EventProps> = ({ items }) => {
  console.log("Objects Component Items:", items);

  return (
    <div>
      {items.length === 0 ? (
        <p>No Events found.</p>
      ) : (
        items.map((item) => (
          <div
            key={item._id}
            className={` h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 group `}
          >
            <img className="object-cover w-full h-full" src={item.image} />
            <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-t from-gray-100 to-70% opacity-0 group-hover:opacity-100 transition-opacity ease-in-out duration-300 cursor-pointer" />
            <AddItemDropDown itemID={item._id} boardID={""} itemType={"events"}>
              <span className="absolute flex invisible gap-1 p-2 text-sm transition-all duration-300 ease-in-out scale-95 bg-white rounded-full opacity-0 cursor-pointer top-4 text-other-cta right-4 group-hover:opacity-100 group-hover:scale-100 group-hover:visible">
                <div className="flex flex-row items-center gap-1">
                  <img className="w-6 h-6" src="/label.svg" alt="label" />
                </div>
              </span>
            </AddItemDropDown>
            <div>
              {" "}
              <div className="absolute bottom-0 left-0 p-4">
                <p className="text-xs font-normal text-white fobt-sh5">
                  {item.eventName}
                </p>
                <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                  {item.country}
                </p>
                <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                  {formatDate(item.startDate)} - {formatDate(item.endDate)}
                </p>
              </div>
              <div className="absolute bottom-0 right-0 p-4">
                <Button
                  size="icon"
                  variant="outline"
                  className="w-[104px] text-white  rounded-full font-sh5 font-normal text-sm"
                >
                  BUY TICKETS
                </Button>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Events;

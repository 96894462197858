import React, { useCallback, useEffect, useState } from "react";
import useBoard from "@/apis/board";
import {
  BoardItemType,
  GetSingleBoardResponse,
  ObjectItem,
} from "@/types/boards.type";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import EditDropDown from "@/components/Boards/EditDropDown";
import AddToBoard from "@/modal/AddToBoard";
import Layout from "@/layout/DefaultLayout";
import BoardItemsPage from "@/components/Boards/BoardItem";
import ItemTypeFilter from "@/components/Boards/ItemTypeFilter";
import MoreLikeThis from "@/components/Boards/MoreLikeThis";
import debounce from "@mui/material/utils/debounce";
import RearrangeDrawer from "@/components/Boards/RearrangeDrawer";
import TextFooter from "@/components/Global/TermsCondn";
import { useMediaQuery } from "@mui/material";
import BoardItemsSm from "../components/Boards/BoardItemSm";
import PageLoader from "@/components/loader/PageLoader";

const SingleBoardPage = () => {
  const { boardId } = useParams<{ boardId: string }>();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItemType, setSelectedItemType] = useState("");
  const { getSingleBoard } = useBoard();

  // Use React Query to fetch the board data
  const { data, error, isLoading, isError } = useQuery<GetSingleBoardResponse>({
    queryKey: ["single-board", boardId],
    queryFn: () =>
      getSingleBoard(boardId as string, { search: "", itemType: "" }),
    enabled: Boolean(boardId),
    retry: 3,
    staleTime: 1000 * 60 * 5,
  });

  const updateSearchQuery = useCallback(
    debounce((query) => {
      setSearchQuery(query);
    }, 300),
    [],
  );

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateSearchQuery(event.target.value);
  };
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const imageSources = [
    { id: 0, src: "/starLogoWhite.svg", alt: "Star Logo" },
    { id: 1, src: "/caseWhite.svg", alt: "Case Icon" },
    { id: 2, src: "/eyeWhite.svg", alt: "Eye Icon" },
    { id: 3, src: "/frameWhite.svg", alt: "Framing Icon" },
    { id: 4, src: "/paintingAlt.svg", alt: "Paint Icon" },
    { id: 5, src: "/playWhite.svg", alt: "Play Icon" },
    { id: 6, src: "/scholarWhite.svg", alt: "Scholar Icon" },
    { id: 7, src: "/tvWhite.svg", alt: "TV Icon" },
    { id: 8, src: "/starWhite.svg", alt: "Star Alt Icon" },
    { id: 9, src: "/personWhite.svg", alt: "Person Icon" },
    { id: 10, src: "/writingWhite.svg", alt: "Writing Icon" },
    { id: 11, src: "/heartWhite.svg", alt: "heart Icon" },
  ];

  if (isError) return <div>Failed to fetch board: {error?.message}</div>;

  const board = data?.data;

  // Guard against undefined board
  if (!board && !isLoading) return <div>Board not found</div>;

  const imageSource = imageSources.find((image) => image.id === board?.icon);

  // Type guard for item type
  const getImageForObjectItem = (item: BoardItemType): item is ObjectItem => {
    return item.itemType === "object" && "image" in item;
  };

  const defaultObjectImage = board?.boardItems.find(
    (item): item is ObjectItem => getImageForObjectItem(item),
  )?.image;

  const coverImage = board?.cover || defaultObjectImage;

  useEffect(() => {
    setSelectedItemType("");
  }, [boardId]);

  return (
    <Layout>
      {isLoading && <PageLoader />}
      {!isLoading && (
        <div className="w-full h-full max-h-full px-12 pt-4 mq450:px-0 mq450:pb-0 mq750:h-screen mq750:px-0 mq750:pb-0 mq450:h-screen">
          {/* Breadcrumb */}
          <Breadcrumb className="mq450:px-5 mq750:px-5">
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbLink className="text-other-cta" href="/boards">
                  Boards
                </BreadcrumbLink>
              </BreadcrumbItem>
              <span className="text-black">/</span>
              <BreadcrumbItem>
                <BreadcrumbPage className="uppercase text-gray-60">
                  {" "}
                  {board?.name}
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="relative bg-bg-5 my-4 h-[282px]">
            {coverImage && (
              <img
                src={`${coverImage}?t=${new Date().getTime()}`}
                alt="Board Cover"
                className="object-cover object-center w-full h-full "
              />
            )}

            <div className="absolute inset-0 rounded-t-lg bg-gradient-to-b from-zinc-900 to-transparent" />
            {/* Absolute positioning for the edit dropdown in the top-right corner */}
            <div className="absolute right-4 top-4 ">
              {board?.id && board?.name && (
                <EditDropDown
                  nameBoard={board.name}
                  descBoard={board.description}
                  boardId={board.id}
                  boardName={board.name}
                  isCoverIDEmpty={board.cover}
                  icon={board.icon}
                >
                  <img
                    className="cursor-pointer"
                    src="/ellipsis.svg"
                    alt="More options"
                  />
                </EditDropDown>
              )}
            </div>

            <div className="absolute bottom-0 left-0 flex flex-row items-center w-full gap-3 p-4 bg-opacity-75 mq450:flex-col mq450:items-center mq450:justify-center">
              {imageSource && (
                <img src={imageSource.src} className="w-12 h-12" alt="Logo" />
              )}
              <div className="flex flex-col gap-2 mq450:items-center mq450:justify-center mq450:w-full">
                <h1 className="font-medium capitalize text-white mq450:text-2xl font-sh5 text-13xl mq450:font-normal">
                  {board?.name}
                </h1>
                <p className="text-base text-white mq450:text-xs mq450:font-normal">
                  {`${board?.boardItems.length} items`}
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-col mq750:px-5 mq450:px-5">
            <div className="flex flex-row items-center justify-between ">
              <div className=" flex flex-row items-center gap-[10px] mq450:gap-3">
                <div className="mq750:hidden mq450:hidden">
                  <ItemTypeFilter
                    onSelect={(type: string) => setSelectedItemType(type)}
                    selectedType={selectedItemType}
                  ></ItemTypeFilter>
                </div>

                <div className="relative">
                  <input
                    onChange={handleSearchChange}
                    className="w-full  mq750:w-[216px] mq450:min-w-[116px] tracking-[0.7px] border-[1px] min-w-[232px] pl-12 border-solid border-gray-20 focus:border-black focus:ring-0 rounded-[50px] transition-colors duration-300 ease-in-out focus:outline-none focus:ring-5 focus:ring-focus-ring focus:ring-opacity-50 py-[12px]"
                    placeholder="Search Board"
                    defaultValue={searchQuery}
                  />
                  <img
                    className="absolute left-4 top-3.5 w-5 h-5"
                    src="/iconsearch.svg"
                    alt="Search Icon"
                  />
                </div>
              </div>
              <div className="flex flex-row items-center">
                <MoreLikeThis>
                  <Button
                    size="icon"
                    variant="outline"
                    className="w-[156px] mq450:w-10 mq750:w-10 group rounded-full font-sh5 font-normal text-sm relative lg:p-[16px_24px]"
                  >
                    <span className="relative mr-2 mq450:mr-0 mq750:mr-0">
                      <img
                        className="block w-5 h-5 group-hover:hidden"
                        src="/moreThis.svg"
                        alt="More This"
                      />
                      <img
                        className="hidden w-5 h-5 group-hover:block"
                        src="/moreThisWhite.svg"
                        alt="More This Hover"
                      />
                    </span>
                    <span className="transition-all duration-300 mq450:hidden mq750:hidden group-hover:text-white text-other-cta">
                      MORE LIKE THIS
                    </span>
                  </Button>
                </MoreLikeThis>
                <RearrangeDrawer
                  boardId={board?.id as string}
                  boardItems={board?.boardItems as any}
                >
                  <Button
                    size="icon"
                    variant="outline"
                    className="w-[156px] ml-2 pl-2.5 mq450:w-10 mq750:w-10 rounded-full font-sh5 font-normal text-sm hidden mq450:block mq750:block group"
                  >
                    <span className=" mq450:mr-0 mq750:mr-0">
                      <img
                        className="block w-5 h-5 group-hover:hidden"
                        src="/boards.svg"
                        alt="Button Icon"
                      />
                      <img
                        className="hidden w-5 h-5 group-hover:block"
                        src="/moreLikeThisWhite.svg"
                        alt="Button Icon Hover"
                      />
                    </span>
                  </Button>
                </RearrangeDrawer>
                <div>
                  {board?.id && board?.name ? (
                    <AddToBoard boardId={board.id}>
                      <div>
                        <Button
                          size="icon"
                          variant="default"
                          className="w-[156px] ml-2 mq750:w-12 mq750:h-12 mq750:fixed mq750:bottom-[110px] mq750:z-[50] mq750:left-[45%] mq450:w-12 mq450:h-12 mq450:fixed mq450:bottom-[110px] mq450:z-[50] mq450:left-[45%] rounded-full font-sh5 font-normal text-sm bg-other-cta lg:p-[16px_24px]"
                        >
                          <span className="flex flex-row">
                            {" "}
                            <img src="/plus-icon.svg" alt="plus icon" />
                          </span>
                          <p className="ml-1 font-medium mq750:hidden mq450:hidden">
                            {" "}
                            ADD TO BOARD
                          </p>
                        </Button>
                      </div>
                    </AddToBoard>
                  ) : null}
                </div>
              </div>
            </div>

            {board &&
              (isDesktop ? (
                <BoardItemsPage
                  selectedItemType={selectedItemType}
                  searchQuery={searchQuery}
                  boardId={board.id}
                  boardItems={board.boardItems}
                />
              ) : (
                <BoardItemsSm
                  selectedItemType={selectedItemType}
                  searchQuery={searchQuery}
                  boardId={board.id}
                  boardItems={board.boardItems}
                />
              ))}
          </div>

          <div className="py-5 mt-auto ">
            {" "}
            <TextFooter />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default SingleBoardPage;

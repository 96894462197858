import React from "react";
import {
  Sheet,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Button } from "@/components/ui/button";
import { ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";

const menuItems = [
  {
    icon: "/profile/editprofile.svg",
    label: "Your profile",
    href: "/user/profile",
  },
  {
    icon: "/profile/profileinfo.svg",
    label: "Account details",
    href: "/account/details",
  },
  {
    icon: "/profile/paint.svg",
    label: "Artists you follow",
    href: "/artists/following",
  },
  {
    icon: "/profile/work.svg",
    label: "Businesses you follow",
    href: "/businesses/following",
  },
  { icon: "/profile/cube.svg", label: "My purchases", href: "/purchases" },
  { icon: "/profile/rupee.svg", label: "My sales", href: "/sales" },
  { icon: "/profile/stats.svg", label: "Insights", href: "/insights" },
  {
    icon: "/profile/percentage.svg",
    label: "Object royalties",
    href: "/royalties",
  },
  {
    icon: "/profile/protect.svg",
    label: "Insurance manager",
    href: "/insurance",
  },
  { icon: "/profile/call.svg", label: "Contact Asign Care", href: "/contact" },
  { icon: "/profile/exit.svg", label: "Log out", href: null }, // Handle logout separately
];

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleMenuClick = (href: string | null) => {
    if (href) {
      navigate(href);
    } else {
      logout(); // Handle logout when href is null (for the "Log out" item)
    }
  };

  return (
    <Sheet>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetContent className="w-[300px] overflow-auto h-full sm:w-[350px] py-8 px-10">
        <SheetHeader className="py-4 border-b border-gray-200">
          <div className="flex flex-col gap-[30px] justify-between">
            <SheetTitle className="text-xl font-semibold">You</SheetTitle>
            <svg
              width="271"
              height="1"
              viewBox="0 0 271 1"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="271" y2="0.5" stroke="#E5E5E5" />
            </svg>
          </div>
        </SheetHeader>
        <div className="py-1 flex flex-col gap-5">
          {menuItems.map((item, index) => (
            <Button
              key={index}
              variant="link"
              className="justify-start no-underline [&:nth-last-child(2)]:border-[#E5E5E5] [&:nth-last-child(2)]:border-t [&:nth-last-child(2)]:rounded-none [&:nth-last-child(2)]:pt-8 w-full p-0 text-base font-normal"
              onClick={() => handleMenuClick(item.href)}
            >
              <div className="flex  items-center gap-3 ">
                <img src={item.icon} className="mr-4 text-lg" />
                {item.label}
              </div>
              <ChevronRight className="w-5 h-5 ml-auto text-[#1D1D1D]" />
            </Button>
          ))}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default Sidebar;

import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { GetSingleBoardResponse } from "@/types/boards.type";
import { UpdateItemParams } from "@/hooks/useFilter";

type RemoveCaptionProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
};

const RemoveCaption: React.FC<RemoveCaptionProps> = ({
  children,
  boardId,
  itemId,
}) => {
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)");
  const [removing, setRemoving] = useState(false);
  const queryClient = useQueryClient();
  const { editItem } = useBoard();
  // Define the mutation
  const removeCaptionMutation: UseMutationResult<
    GetSingleBoardResponse,
    Error,
    UpdateItemParams
  > = useMutation({
    mutationFn: (params) => editItem({ params }),
    onSuccess: () => {
      setRemoving(false);
      queryClient.invalidateQueries({ queryKey: ["single-board"] });
      toast.success("Caption removed successfully!", {
        id: "remove-caption",
      });
      setOpen(false);
    },
    onError: (error: Error) => {
      toast.error("Failed to remove caption. Please try again.", {
        id: "remove-caption",
      });
    },
    onMutate: () => {
      toast.loading("Removing caption...", {
        id: "remove-caption",
      });
    },
  });

  // Remove the event parameter
  const handleRemoveCaption = () => {
    const params: UpdateItemParams = {
      boardId,
      itemId,
      updatedFields: { caption: "" },
    };

    removeCaptionMutation.mutate(params);
  };

  // Function to handle click and stop propagation
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };

  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      {/* Stop event propagation on trigger click */}
      <DialogTrigger
        className="text-lg text-white bg-transparent"
        onClick={handleClick}
      >
        {children}
      </DialogTrigger>
      <DialogContent
        className="w-[640px]"
        onClick={(e) => e.stopPropagation()} // Stop propagation inside content
      >
        <DialogHeader className="px-5 border-solid pb-5 border-[#E5E5E5] border-b-[1px] ">
          <div className="flex flex-row items-center justify-between ">
            <DialogTitle className="  text-gray-100 font-normal font-sh5 text-[28px]">
              Remove Caption
            </DialogTitle>
            <DialogClose asChild>
              <img src="/close1.svg" />
            </DialogClose>
          </div>
        </DialogHeader>
        <ProfileForm removing={removing} onRemove={handleRemoveCaption} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={handleClick}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent
        className="rounded-none"
        onClick={(e) => e.stopPropagation()}
      >
        <DrawerHeader className="text-left">
          <div className="flex flex-row items-center justify-between">
            <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
              Remove Caption
            </DrawerTitle>
            <DialogClose asChild>
              <img src="/close1.svg" alt="close" />
            </DialogClose>
          </div>
        </DrawerHeader>
        <ProfileForm onRemove={handleRemoveCaption} removing={removing} />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onRemove: () => void; // Keep the parameterless signature
  removing: boolean;
};

function ProfileForm({ onRemove, removing }: ProfileFormProps) {
  return (
    <form
      className="grid items-start gap-4"
      onClick={(e) => e.stopPropagation()} // Prevent form clicks from propagating
    >
      <div className="px-5 font-normal text-center font-sh5 font-base text-gray-60">
        <div className="flex flex-col w-full gap-4">
          <p>Are you sure you want to remove the caption?</p>
        </div>
      </div>
      <div className="flex flex-row justify-between w-full px-5 py-3 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            disabled={removing}
            className="relative text-sm font-bold text-gray-100 underline bg-transparent rounded-none"
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          disabled={removing}
          className="rounded-[50px] px-4 font-bold text-sm text-white"
          variant="default"
          type="button"
          onClick={onRemove} // Pass the function directly
        >
          {removing ? "REMOVING..." : "REMOVE"}
        </Button>
      </div>
    </form>
  );
}

export default RemoveCaption;

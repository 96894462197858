import React from "react";
import TextDropDown from "./TextDropDown";
import ArtistDropDown from "./ArtistDropDown";
import CaptionItem from "./CaptionItem";
// Define the props interface
interface EventProp {
  title: string;
  image: string;
  description: string;
  authorName: string;
  className: string;
  itemId: string;
  boardId: string;
  itemType: string;
  coverId: string;
  imgSrc: string;
  tagList: string[];
  caption?: string;
  showEllipsis?: boolean;
  showDetail?: boolean;
  showCaption?: boolean;
}

const Event: React.FC<EventProp> = ({
  title,
  image,
  className = "",
  boardId,
  itemId,
  itemType,
  authorName,
  description,
  showEllipsis = true,
  showDetail = true,
  coverId,
  imgSrc,
  tagList,
  caption,
  showCaption = true,
}) => {
  return (
    <div className="flex flex-col">
      <div
        className={` h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300  ${className}`}
      >
        <img className="object-cover w-full h-full" src={image} alt={image} />
        <div className="absolute inset-0 bg-gradient-to-t  from-zinc-900 to-transparent rounded-t-lg"></div>
        {showEllipsis && (
          <ArtistDropDown
            itemId={itemId}
            boardId={boardId}
            itemType={itemType}
            coverId={coverId}
            imgSrc={imgSrc}
            tagList={tagList}
          >
            <img
              className="absolute top-0 right-0 p-2"
              src="/ellipsis.svg"
              alt="label"
            />
          </ArtistDropDown>
        )}

        <div>
          {" "}
          <div className="absolute  bottom-0 left-0 p-4">
            <p className="text-white fobt-sh5 font-normal text-xs">{title}</p>
            <p className="text-white fobt-sh5 font-normal text-xs">
              {authorName}
            </p>
          </div>
        </div>
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </div>
  );
};

export default Event;

import React, { useState } from "react";
import { steps } from "./utils";
import Stepper from "./stepper";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { useNavigate, useSearchParams } from "react-router-dom";
import AsignRequest from "../catalog/AsignRequest";
import { cn } from "@/lib/utils";

interface SideBarProps {
  currentStep: string;
  setCurrentStep: (step: string) => void;
}

const SideBar = ({ currentStep, setCurrentStep }: SideBarProps) => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState<string | null>("accordion");
  const [searchParams] = useSearchParams();
  const objectId = searchParams.get("oi");

  const handleNavigation = (path: string, buttonId: string) => {
    setActiveButton(buttonId);
    navigate(path);
  };

  const handleAccordionClick = () => {
    if (objectId) {
      navigate(`/catalog/add/object?oi=${objectId}`);
      return;
    }
    navigate("/catalog/add/object?step=about");
    setActiveButton("accordion");
  };

  return (
    <div className="sm:py-10 sm:px-12 sm:z-50 sm:relative">
      <Accordion type="single" collapsible className="w-full">
        <AccordionItem value="item-1">
          <AccordionTrigger
            className={cn("sm:flex", activeButton === "accordion" && "active")}
            onClick={handleAccordionClick}
          >
            <div className="flex items-center gap-3 pl-5 font-normal sm:pl-0 sm:text-xl">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.82031 7V2.5H19.6803H21.5003V16.38H17.5003"
                  fill="#1D1D1D"
                />
                <path
                  d="M5.82031 7V2.5H19.6803H21.5003V16.38H17.5003"
                  stroke="white"
                  strokeMiterlimit="10"
                />
                <path
                  d="M17.5 21.5H2.5V7H15.76H17.5V21.5Z"
                  fill="#1D1D1D"
                  stroke="white"
                  strokeMiterlimit="10"
                />
              </svg>
              Object details
            </div>
          </AccordionTrigger>
          <AccordionContent>
            <div className="flex flex-col gap-6">
              <Stepper
                title="Information"
                steps={steps.information}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
              <Stepper
                title="Documentation"
                steps={steps.documentation}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>

      {
        <div className="mt-4">
          <Button
            disabled={!objectId}
            variant="link"
            onClick={() =>
              handleNavigation(
                `/catalog/asign/request?oi=${objectId}`,
                "button1",
              )
            }
            className={cn(
              "p-0 my-3 ml-5 sm:my-0 sm:ml-0 text-[14px] sm:flex sm:justify-start sm:gap-3 font-sh5 sm:border-t sm:border-b-0 sm:border-gray-200 sm:rounded-none sm:w-full sm:py-6 sm:pl-0 font-medium sm:text-xl",
              activeButton === "button1" && "active",
            )}
          >
            <img src="/Layer2.svg" className="w-6 h-6" />
            <p className="text-xl font-normal sm:text-xl">Asign Protect+</p>
          </Button>

          <Button
            disabled={!objectId}
            variant="link"
            onClick={() => handleNavigation("/settings", "button2")}
            className={cn(
              "sm:flex sm:justify-start sm:gap-3 sm:p-2 sm:font-sh5 sm:border-t sm:border-b sm:border-gray-200 sm:rounded-none sm:w-full sm:py-6 sm:pl-0",
              activeButton === "button2" && "active",
            )}
          >
            <img src="/exhibitiondesign.svg" className="w-6 h-6" />
            <p className="text-xl font-normal">Sell on Asign</p>
          </Button>
        </div>
      }
    </div>
  );
};

export default SideBar;

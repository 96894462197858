import React, { useState } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { X } from "lucide-react";
import {
  Control,
  Controller,
  UseFormRegister,
  UseFormWatch,
} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface Field {
  id: string;
  label: string;
  type: "input" | "select";
  options?: { value: string; label: string }[];
  validation?: (value: string) => string | null;
  required?: boolean;
}

interface AddressFormProps {
  title: string;
  description: string;
  fields: Field[];
  onSubmit: (formData: Record<string, string>) => void;
  onCancel: () => void;
  register: UseFormRegister<any>;
  watch: UseFormWatch<any>;
  handleSubmit: (e: React.FormEvent) => void;
  control: Control<any>;
  userId: string;
}

const AddressForm: React.FC<AddressFormProps> = ({
  title,
  description,
  fields,
  onSubmit,
  onCancel,
  register,
  watch,
  handleSubmit,
  control,
  userId,
}) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Record<string, string>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const allFormData = watch();
  console.log("AddressForm", allFormData);

  const handleChange = (id: string, value: string) => {
    setFormData((prev) => ({ ...prev, [id]: value }));
    if (errors[id]) {
      setErrors((prev) => ({ ...prev, [id]: "" }));
    }
  };

  // const handleSubmit = (e: React.FormEvent) => {
  //   e.preventDefault();
  //   const newErrors: Record<string, string> = {};
  //   fields.forEach((field) => {
  //     if (field.validation) {
  //       const error = field.validation(formData[field.id] || "");
  //       if (error) {
  //         newErrors[field.id] = error;
  //       }
  //     }
  //   });

  //   if (Object.keys(newErrors).length === 0) {
  //     onSubmit(formData);
  //   } else {
  //     setErrors(newErrors);
  //   }
  // };

  const handleFormSubmit = async (data: any) => {
    let timeoutId: NodeJS.Timeout;

    try {
      await updateProfile(allFormData);
      toast.success("Check your mail, verify your mail, and log in.");

      // Wait for 5 seconds before redirecting
      timeoutId = setTimeout(() => {
        navigate("/catalog/detail");
      }, 5000);
    } catch (error) {
      console.error("Error updating profile:", error);
    }

    // Cleanup on component unmount
    return () => clearTimeout(timeoutId);
  };

  const updateProfile = async (formData: any) => {
    try {
      const updateHeaders = new Headers();
      updateHeaders.append("Content-Type", "application/json");

      const updateRaw = JSON.stringify({
        id: userId,
        address: [
          {
            lineOne: formData.address1,
            lineTwo: formData.address2,
            country: formData.country,
            state: formData.state,
            city: formData.city,
            pinCode: formData.pincode,
          },
        ],
      });

      const updateRequestOptions = {
        method: "POST",
        headers: updateHeaders,
        body: updateRaw,
        redirect: "follow" as RequestRedirect,
      };

      const updateResponse = await fetch(
        "https://ab.host.levitation.co.in/client/auth/update-profile",
        updateRequestOptions,
      );

      const updateResult = await updateResponse.json();

      if (updateResponse.ok) {
        console.log("Profile updated successfully");
        // You might want to update some state or UI here to reflect the successful update
      } else {
        console.error("Failed to update profile:", updateResult.message);
        // Handle the error case in the UI
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      // Handle the error case in the UI
      toast.error("Error updating profile. Please try again.");
    }
  };

  return (
    <div className="flex flex-col w-full md:w-1/2">
      <div className="flex justify-end mb-8">
        <Button variant="ghost" className="text-gray-500" onClick={onCancel}>
          <X size={24} />
        </Button>
      </div>

      <div className="flex-grow w-full max-w-md mx-auto">
        <h1 className="mb-4 text-3xl font-bold">{title}</h1>
        <p className="mb-8 text-gray-600">{description}</p>

        <form onSubmit={handleSubmit} className="space-y-6">
          {fields.map((field) => (
            <div key={field.id}>
              <label
                htmlFor={field.id}
                className="block mb-2 text-sm font-medium text-gray-700"
              >
                {field.label}
              </label>
              {field.type === "input" ? (
                <Input
                  id={field.id}
                  {...register(field.id, {
                    required: "required" in field ? field.required : false,
                  })}
                  className="w-full"
                />
              ) : (
                <Controller
                  name={field.id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select onValueChange={onChange} value={value}>
                      <SelectTrigger className="w-full">
                        <SelectValue
                          placeholder={`Select ${field.label.toLowerCase()}`}
                        />
                      </SelectTrigger>
                      <SelectContent>
                        {field.options?.map((option) => (
                          <SelectItem key={option.value} value={option.value}>
                            {option.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  )}
                />
              )}
              {errors[field.id] && (
                <p className="mt-1 text-sm text-red-600">{errors[field.id]}</p>
              )}
            </div>
          ))}
          <div className="flex items-center justify-between mt-8">
            <Button
              variant="ghost"
              className="text-gray-800"
              onClick={onCancel}
            >
              CANCEL
            </Button>
            <Button
              className="text-white bg-gray-800 hover:bg-gray-700"
              onClick={handleFormSubmit}
            >
              SAVE
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddressForm;

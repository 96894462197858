import { Button } from "@/components/ui/button";
import React, { useState } from "react";
import Addprovenance from "./modal/Addprovenance";
import AddAuction from "./modal/AddAuction";
import Addexhibition from "./modal/Addexhibition";
import AddPublication from "./modal/AddPublication";
import { useObjectContext } from "@/context/objects";
import { ActionDropDown } from ".";

const Provenance = () => {
  const { currentObject, updateOrAddItem } = useObjectContext();

  return (
    <div className="font-normal font-sh5">
      <div className="mb-10">
        <h3 className="mb-2 text-lg font-semibold">Overview*</h3>
        <p className="mb-4 text-sm text-gray-500">
          Share the object’s complete ownership history, from creation up to
          current ownership (if available).
        </p>
        <Addprovenance>
          <Button
            variant="link"
            className="justify-start w-full h-auto p-0 uppercase"
          >
            + Add provenance
          </Button>
        </Addprovenance>
        {currentObject?.provenance?.overview.map((item: any, index: any) => (
          <div
            key={index}
            className="flex items-center justify-between py-2 border-b border-solid border-bdr-10"
          >
            <p>{item.title}</p>
            <ActionDropDown id={item._id} dataKey="provenance.overview" />
          </div>
        ))}
      </div>
      <div className="mb-10">
        <h3 className="mb-2 text-lg">Auction history</h3>
        <p className="mb-4 text-sm text-gray-500">
          You have not added any auction history yet.
        </p>
        <AddAuction>
          {" "}
          <Button variant="link" className="justify-start w-full h-auto p-0">
            + ADD AUCTION HISTORY
          </Button>
        </AddAuction>
        {currentObject?.provenance?.auctionHistory.map(
          (item: any, index: any) => (
            <div
              key={index}
              className="flex items-center justify-between py-2 border-b border-solid border-bdr-10"
            >
              <p>{item.name}</p>
              <ActionDropDown
                id={item._id}
                dataKey="provenance.auctionHistory"
              />
            </div>
          ),
        )}
      </div>

      <div className="mb-10">
        <h3 className="mb-2 text-lg">Exhibition history</h3>
        <p className="mb-4 text-sm text-gray-500">
          You have not added any exhibition history yet.
        </p>
        <Addexhibition>
          <Button variant="link" className="justify-start w-full h-auto p-0 ">
            + ADD EXHIBITION HISTORY
          </Button>
        </Addexhibition>
        {currentObject?.provenance?.exhibitionHistory.map(
          (item: any, index: any) => (
            <div
              key={index}
              className="flex items-center justify-between py-2 border-b border-solid border-bdr-10"
            >
              <p>{item.name}</p>
              <ActionDropDown
                id={item._id}
                dataKey="provenance.exhibitionHistory"
              />
            </div>
          ),
        )}
      </div>

      <div className="mb-10">
        <h3 className="mb-2 text-lg">Publication history</h3>
        <p className="mb-4 text-sm text-gray-500">
          You have not added any publication history yet.
        </p>
        <AddPublication>
          {" "}
          <Button variant="link" className="justify-start w-full h-auto p-0">
            + ADD PUBLICATION HISTORY
          </Button>
        </AddPublication>
        {currentObject?.provenance?.publicationHistory.map(
          (item: any, index: any) => (
            <div
              key={index}
              className="flex items-center justify-between py-2 border-b border-solid border-bdr-10"
            >
              <p>{item.name}</p>
              <ActionDropDown
                id={item._id}
                dataKey="provenance.publicationHistory"
              />
            </div>
          ),
        )}
      </div>
    </div>
  );
};

export default Provenance;

import React from "react";
import Slider from "react-slick";
import { FileItem, VideoItem } from "@/types/boards.type";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactPlayer from "react-player";

const PrevArrow: React.FC<any> = (props) => {
  const { onClick } = props;
  return (
    <button onClick={onClick} className="slick-prev">
      <img src="/arrow1.svg" className="w-6 h-6 rotate-180" />
    </button>
  );
};

const NextArrow: React.FC<any> = (props) => {
  const { onClick } = props;
  return (
    <button onClick={onClick} className="slick-next">
      <img src="/arrow1.svg" className="w-6 h-6" />
    </button>
  );
};

interface ViewImgVidProps {
  videoItems?: VideoItem[];
  fileItems?: FileItem[];
  onClose: () => void;
  initialId: string;
}

// Type guard for video items
const isVideoItem = (item: FileItem | VideoItem): item is VideoItem => {
  return item.itemType === "video";
};

const ViewImgVid: React.FC<ViewImgVidProps> = ({
  videoItems = [],
  fileItems = [],

  onClose,
  initialId,
}) => {
  // Combine file and video items
  const combinedItems = [
    ...fileItems.map((file) => ({ ...file, type: "file" })),
    ...videoItems.map((video) => ({ ...video, type: "video" })),
  ];
  const initialSlideIndex = combinedItems.findIndex(
    (item) => item._id === initialId,
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: initialSlideIndex >= 0 ? initialSlideIndex : 0,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    centerPadding: "0",
    className: "slider-container",
  };

  return (
    <>
      <div
        onClick={onClose}
        className="absolute z-50 p-2 rounded-full cursor-pointer top-4 right-4"
      >
        <img src="/close1.svg" className="w-6 h-6" />
      </div>
      <div className="w-3/4 max-w-screen-xl max-h-screen m-auto">
        {combinedItems.length === 1 && (
          <div>
            {combinedItems[0].type === "file" ? (
              <div className="flex items-center justify-center">
                <img
                  src={combinedItems[0].image}
                  className="w-full object-cover mq750:w-[200px] mq750:h-[200px] mq450:w-[200px] mq450:h-[200px] px-10 max-h-[80vh]"
                />
              </div>
            ) : isVideoItem(combinedItems[0]) ? (
              <div className="flex items-center justify-center">
                <video
                  controls
                  src={combinedItems[0].content.url}
                  className="w-full object-cover mq750:w-[200px] mq750:h-[200px] mq450:w-[200px] mq450:h-[200px] mx-10 max-h-[90vh]"
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : null}
          </div>
        )}
        {combinedItems.length > 1 && (
          <Slider {...sliderSettings}>
            {combinedItems.map((item, index) => (
              <div key={item._id}>
                {item.type === "file" ? (
                  <div className="flex items-center justify-center h-full">
                    <img
                      src={item.image}
                      alt={`File ${index}`}
                      className="min-w-[50vw] object-cover mq750:w-[200px] mq750:h-[200px] mq450:w-[200px] mq450:h-[200px] px-10 max-h-[80vh]"
                    />
                  </div>
                ) : isVideoItem(item) ? (
                  <div className="flex items-center justify-center w-full h-full">
                    <ReactPlayer
                      url={item.content.url}
                      width="100%"
                      height="80vh"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};

export default ViewImgVid;

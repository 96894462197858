import React from "react";

const LeftFormImage = () => {
  return (
    <>
      {/* Left side - Image */}
      <div className="hidden md:block w-1/2 relative">
        <img
          src="/register-banner.svg"
          alt="Decorative image"
          className="w-full h-full object-cover"
        />
        <div className="absolute top-4 left-4 text-white text-2xl font-semibold">
          <span className="inline-block transform -rotate-90">A</span> SIGN
        </div>
        <div className="absolute bottom-4 left-4 text-white text-sm">
          <span className="font-eb text-lg">Aditya Pande, </span>
          <span>Let's Go West, 2021</span>
        </div>
      </div>
    </>
  );
};

export default LeftFormImage;

import { useEffect, useState } from "react";

type ToastPosition =
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left"
  | "bottom-center"
  | "top-center";

const useToasterPosition = (): ToastPosition => {
  const [position, setPosition] = useState<ToastPosition>("top-right");

  const updatePosition = () => {
    if (window.innerWidth <= 750) {
      setPosition("bottom-center");
    } else {
      setPosition("top-right");
    }
  };

  useEffect(() => {
    updatePosition();
    window.addEventListener("resize", updatePosition);
    return () => {
      window.removeEventListener("resize", updatePosition);
    };
  }, []);

  return position;
};

export default useToasterPosition;

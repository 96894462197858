import React from "react";
import { Button } from "@/components/ui/button";
import TextDropDown from "./TextDropDown";
import ArtistDropDown from "./ArtistDropDown";
import CaptionItem from "./CaptionItem";
// Define the props interface
interface EventProp {
  eventName: string;
  image: string;
  description: string;
  state: string;
  country: string;
  startDate: string;
  endDate: string;
  className: string;
  itemId: string;
  boardId: string;
  itemType: string;
  caption: string;
  tagList: string[];
  coverId: string;
  showEllipsis?: boolean;
  showDetail?: boolean;
  showCaption?: boolean;
}
const formatDate = (dateString: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", options);
};

const Event: React.FC<EventProp> = ({
  eventName,
  image,
  country,
  startDate,
  endDate,
  className = "",
  boardId,
  itemId,
  itemType,
  caption,
  showEllipsis = true,
  showDetail = true,
  coverId,
  tagList,
  showCaption = true,
}) => {
  return (
    <>
      <div
        className={`h-[297px] w-full min-w-[297px]  relative flex flex-col justify-end bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 ${className}`}
      >
        <img className="object-cover w-full h-full" src={image} />
        <div className="absolute inset-0 bg-gradient-to-t  from-zinc-900 to-transparent rounded-t-lg"></div>
        {showEllipsis && (
          <ArtistDropDown
            boardId={boardId}
            itemId={itemId}
            itemType={itemType}
            caption={caption}
            coverId={coverId}
            imgSrc={image}
            tagList={tagList}
          >
            <img className="absolute top-0 right-0 p-2" src="/ellipsis.svg" />
          </ArtistDropDown>
        )}
        {showDetail && (
          <div>
            {" "}
            <div className="absolute bottom-0 left-0 p-4">
              <p className="text-white fobt-sh5 font-normal text-xs">
                {eventName}
              </p>
              <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                {country}
              </p>
              <p className="text-[#CCCCCC] font-sh5 font-normal text-xs">
                {formatDate(startDate)} - {formatDate(endDate)}
              </p>
            </div>
            <div className="absolute bottom-0 right-0 p-4">
              <Button
                size="icon"
                variant="outline"
                className="w-[104px] text-white  rounded-full font-sh5 font-normal text-sm"
              >
                BUY TICKETS
              </Button>
            </div>
          </div>
        )}
      </div>
      {showCaption && (caption ? <CaptionItem caption={caption} /> : null)}
    </>
  );
};

export default Event;

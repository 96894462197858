import { Paginator, QueryData } from "@/hooks/useFilter";
import { apis } from ".";
import { User } from "@/types/user.types";
import { useQueryClient } from "@tanstack/react-query";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export interface like {
  itemId: string;
  itemType: "artist" | "object" | "event" | "business";
  action: "add" | "remove";
}

export interface UserInteraction {
  artistsFollowed: string[];
  objectsLiked: string[];
  eventsBooked: string[];
  businessFollowed: string[];
  addedBy: string;
  createdAt: string;
  updatedAt: string;
  isDeleted: boolean;
  isActive: boolean;
  recentArtistsViewed: string[];
  recentObjectsViewed: string[];
  recentbusinessViewed: string[];
  recenteventsViewed: string[];
  surveyResponses: SurveyResponse[];
  id: string;
}

export interface SurveyResponse {
  sectionId?: string;
  response?: {
    ans?: string;
  };
  itemType: "artist" | "object" | "business" | "event";
}
const useUserAPI = () => {
  const queryClient = useQueryClient();
  const searchUsers = async (
    searchParams: QueryData,
  ): Promise<{
    data: User[];
    paginator: Paginator;
  }> => {
    try {
      const { data: response } = await apis.post(
        "/cms-users/api/v1/user/list",
        {
          data: searchParams,
        },
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const userById = async ({ id }: { id: string }): Promise<User> => {
    try {
      const { data } = await apis.get(`/cms-users/api/v1/user/${id}`, {});
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  const myInfo = async (): Promise<User> => {
    try {
      const { data } = await apis.get(`/client/api/v1/user/me`, {});
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const updateMe = async (dataToUpdate: any) => {
    try {
      const { data } = await apis.put(`/client/api/v1/user/me/update`, {
        data: dataToUpdate,
      });
      queryClient.invalidateQueries({
        queryKey: ["me"],
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const addOrRemoveLike = async (boady: like) => {
    try {
      await apis.post("/client/api/v1/userinteractions/create", {
        data: boady,
      });
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const getUserIntreaction = async (): Promise<UserInteraction> => {
    try {
      const { data } = await apis.get(
        "/client/api/v1/userinteractions/interactionsbyuser",
        {},
      );
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const login = async ({
    phone,
    password,
  }: {
    phone: string;
    password: string;
  }) => {
    try {
      const { data } = await apis.post("client/auth/login", {
        data: {
          phone,
          password,
        },
      });
      const tokens = data.data;
      cookies.set("token", tokens.accessToken, { path: "/" });
      cookies.set("refreshToken", tokens.refreshToken, { path: "/" });
      return tokens.user;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const refreshToken = async () => {
    try {
      const { data } = await apis.post("client/auth/refresh-token", {
        data: {
          refreshToken: cookies.get("refreshToken"),
        },
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const resetPassword = async ({
    id,
    password,
  }: {
    id: string;
    password: string;
  }) => {
    try {
      const { data } = await apis.put("client/auth/reset-password", {
        data: {
          id,
          password,
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const sendOtp = async ({ phone }: { phone: string }) => {
    try {
      const { data } = await apis.post("client/auth/send-otp", {
        data: {
          phone,
        },
      });
      return true;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const verifyOtp = async ({ phone, otp }: { phone: string; otp: string }) => {
    try {
      const { data } = await apis.post("client/auth/verify-otp", {
        data: {
          phone,
          otp,
        },
      });
      return data.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    searchUsers,
    userById,
    addOrRemoveLike,
    getUserIntreaction,
    myInfo,
    updateMe,
    login,
    refreshToken,
    verifyOtp,
    sendOtp,
    resetPassword,
  };
};

export default useUserAPI;

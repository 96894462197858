import React, { ReactNode, useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from "@/components/ui/drawer";
import { useMediaQuery } from "@mui/material";
import { Button } from "@/components/ui/button";
import useBoard from "@/apis/board";
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from "@tanstack/react-query";
import toast from "react-hot-toast";
import { AddItemResponse } from "@/types/boards.type";
import { DeleteItemParam } from "@/hooks/useFilter";

type RemoveItemProps = {
  children: ReactNode;
  boardId: string;
  itemId: string;
};

const RemoveItem: React.FC<RemoveItemProps> = ({
  children,
  boardId,
  itemId,
}) => {
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const isDesktop = useMediaQuery("(min-width: 768px)"); // Media query for responsive design
  const { deleteItem } = useBoard();
  const queryClient = useQueryClient();

  const mutation: UseMutationResult<AddItemResponse, Error, DeleteItemParam> =
    useMutation({
      mutationFn: (params: DeleteItemParam) => deleteItem(params), // Call deleteItem with params
      onSuccess: (data) => {
        toast.success(`Item deleted successfully: ${data.message}`, {
          id: "delete",
        }); // Notify on success
        queryClient.refetchQueries({
          queryKey: ["single-board"],
          type: "active",
        });
        setOpen(false); // Close the dialog/drawer after success
      },
      onError: (error: Error) => {
        toast.error(`Failed to delete item: ${error.message}`, {
          id: "delete",
        }); // Notify on error
      },
      onMutate: () => {
        toast.loading("Deleting the item...", {
          id: "delete",
        });
      },
    });

  const handleDelete = () => {
    mutation.mutate({ boardId, itemId }); // Trigger mutation for deletion
  };
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setOpen(true);
  };
  return isDesktop ? (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={handleClick}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="min-w-[640px] z-[100]"
      >
        <DialogHeader className="px-8">
          <DialogTitle className="border-[#E5E5E5] pb-4 border-b-[1px] border-solid text-gray-100 font-normal font-sh5 text-[28px]">
            <div className="flex flex-row items-center justify-between">
              <p>Remove from Board</p>
              <DialogClose asChild className="bg-transparent cursor-pointer">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DialogTitle>
        </DialogHeader>
        <ProfileForm deleting={deleting} onDelete={handleDelete} />
      </DialogContent>
    </Dialog>
  ) : (
    <Drawer open={open} onOpenChange={setOpen}>
      <DialogTrigger
        onClick={handleClick}
        className="text-lg text-white bg-transparent"
      >
        {children}
      </DialogTrigger>
      <DrawerContent
        onClick={(e) => e.stopPropagation()}
        className="rounded-none"
      >
        <DrawerHeader className="mb-5 text-left border-b border-solid border-gray-10">
          <DrawerTitle className="text-5xl font-normal text-gray-100 font-sh5">
            <div className="flex flex-row items-center justify-between">
              Remove from Board
              <DialogClose asChild className="bg-transparent cursor-pointer">
                <img className="cursor-pointer" src="/close1.svg" />
              </DialogClose>
            </div>
          </DrawerTitle>
        </DrawerHeader>
        <ProfileForm onDelete={handleDelete} deleting={deleting} />
      </DrawerContent>
    </Drawer>
  );
};

type ProfileFormProps = {
  onDelete: () => void; // Prop for delete action
  deleting: boolean;
};

function ProfileForm({ onDelete, deleting }: ProfileFormProps) {
  return (
    <form className="grid items-start gap-4">
      <div className="px-8 text-base font-normal text-center font-sh5 text-gray-60">
        <p className="px-16 mq450:px-8">
          Are you sure you want to remove this component from the board? This
          action cannot be undone.
        </p>
      </div>
      <div className="flex flex-row items-center justify-between w-full p-5 bg-[#F2F2F2]">
        <DialogClose asChild>
          <button
            disabled={deleting}
            className="cursor-pointer h-6 min-w-12 font-medium text-xs font-sh5 text-gray-100 border-gray-100 border-b-[1px] border-solid bg-transparent rounded-none"
            type="button"
          >
            CANCEL
          </button>
        </DialogClose>
        <Button
          className="rounded-[50px] min-w-[130px] h-[42PX] px-4 font-medium text-sm text-white font-sh5"
          variant="default"
          type="button"
          disabled={deleting}
          onClick={onDelete} // Call onDelete on button click
        >
          {deleting ? "DELETING..." : "DELETE"}
        </Button>
      </div>
    </form>
  );
}

export default RemoveItem;
